<div class="payment-container">
  <h1 class="title has-text-centered">Paiement sécurisé</h1>
  <p class="subtitle has-text-centered">Veuillez entrer vos informations de carte bancaire</p>

  <form (submit)="handlePayment($event)">
    <!-- Nom sur la carte -->
    <div class="field">
      <label class="label">Nom sur la carte</label>
      <div class="control">
        <input class="input" type="text" [(ngModel)]="cardName" name="cardName" placeholder="Nom complet" required />
      </div>
    </div>

    <!-- Numéro de carte -->
    <div class="field">
      <label class="label">Numéro de carte</label>
      <div class="control card-element-container">
        <div #cardNumberElement></div>
      </div>
    </div>

    <!-- Date d'expiration -->
    <div class="field">
      <label class="label">Date d'expiration</label>
      <div class="control card-element-container">
        <div #cardExpiryElement></div>
      </div>
    </div>

    <!-- CVC -->
    <div class="field">
      <label class="label">CVC</label>
      <div class="control card-element-container">
        <div #cardCvcElement></div>
      </div>
    </div>

    <!-- Boutons -->
    <div class="field is-grouped is-grouped-centered">
      <p class="control">
        <button
          type="submit"
          class="button is-outlined is-blue is-medium is-fullwidth"
          [disabled]="paymentStatus === 'success' || paymentStatus === 'pending'"
        >
          Payer
        </button>
      </p>
      <p class="control">
        <button type="button" class="button is-light is-fullwidth" (click)="closeModal(false)">Annuler</button>
      </p>
    </div>

    <!-- Messages de succès ou d'échec -->
    <p *ngIf="paymentStatus === 'success'" class="has-text-success has-text-centered">Paiement réussi !</p>
    <p *ngIf="paymentStatus === 'fail'" class="has-text-danger has-text-centered">
      {{ "subscription.payment_errors." + paymentStatusReason | translate }}
    </p>
  </form>
</div>
