<div class="layout-content">
  <div class="layout-main">
    <div class="layout-center">
      <div>
        <ng-container *ngIf="bRating && !Feedback.Sent">
          <h1 class="oranienbaum-regular has-text-left is-size-2-fullhd is-size-3-desktop is-size-4-touch mb-4 text-custom-color">
            {{ "shell.feedback.thanks_1" | translate }}
          </h1>
          <p class="subtitle has-text-left poppins-semibold is-size-6-fullhd is-size-7-desktop is-size-7-touch text-custom-color">
            {{ "shell.feedback.evaluate" | translate }}
          </p>

          <div class="mb-6">
            <div class="stars has-text-left mb-4">
              <span
                (mouseenter)="StarMouseover(1)"
                (mouseleave)="StarReset()"
                (click)="StarClick(1)"
                [class.yellow-star]="Feedback.TempRating >= 1 || (Feedback.TempRating === 0 && Feedback.Rating >= 1)"
                [class.blue-star]="Feedback.TempRating < 1 || Feedback.TempRating === 0 || Feedback.Rating < 1"
                class="icon pointer"
              >
                <i
                  class="fa-star fa-xl"
                  [ngClass]="Feedback.TempRating >= 1 || (Feedback.TempRating === 0 && Feedback.Rating >= 1) ? 'fas' : 'far'"
                ></i>
              </span>
              <span
                (mouseenter)="StarMouseover(2)"
                (mouseleave)="StarReset()"
                (click)="StarClick(2)"
                [class.yellow-star]="Feedback.TempRating >= 2 || (Feedback.TempRating === 0 && Feedback.Rating >= 2)"
                [class.blue-star]="Feedback.TempRating < 2 || Feedback.TempRating === 0 || Feedback.Rating < 2"
                class="icon pointer"
              >
                <i
                  class="fa-star fa-xl"
                  [ngClass]="Feedback.TempRating >= 2 || (Feedback.TempRating === 0 && Feedback.Rating >= 2) ? 'fas' : 'far'"
                ></i>
              </span>
              <span
                (mouseenter)="StarMouseover(3)"
                (mouseleave)="StarReset()"
                (click)="StarClick(3)"
                [class.yellow-star]="Feedback.TempRating >= 3 || (Feedback.TempRating === 0 && Feedback.Rating >= 3)"
                [class.blue-star]="Feedback.TempRating < 3 || Feedback.TempRating === 0 || Feedback.Rating < 3"
                class="icon pointer"
              >
                <i
                  class="fa-star fa-xl"
                  [ngClass]="Feedback.TempRating >= 3 || (Feedback.TempRating === 0 && Feedback.Rating >= 3) ? 'fas' : 'far'"
                ></i>
              </span>
              <span
                (mouseenter)="StarMouseover(4)"
                (mouseleave)="StarReset()"
                (click)="StarClick(4)"
                [class.yellow-star]="Feedback.TempRating >= 4 || (Feedback.TempRating === 0 && Feedback.Rating >= 4)"
                [class.blue-star]="Feedback.TempRating < 4 || Feedback.TempRating === 0 || Feedback.Rating < 4"
                class="icon pointer"
              >
                <i
                  class="fa-star fa-xl"
                  [ngClass]="Feedback.TempRating >= 4 || (Feedback.TempRating === 0 && Feedback.Rating >= 4) ? 'fas' : 'far'"
                ></i>
              </span>
              <span
                (mouseenter)="StarMouseover(5)"
                (mouseleave)="StarReset()"
                (click)="StarClick(5)"
                [class.yellow-star]="Feedback.TempRating >= 5 || (Feedback.TempRating === 0 && Feedback.Rating >= 5)"
                [class.blue-star]="Feedback.TempRating < 5 || Feedback.TempRating === 0 || Feedback.Rating < 5"
                class="icon pointer"
              >
                <i
                  class="fa-star fa-xl"
                  [ngClass]="Feedback.TempRating >= 5 || (Feedback.TempRating === 0 && Feedback.Rating >= 5) ? 'fas' : 'far'"
                ></i>
              </span>
            </div>
            <p class="text-custom-color">{{ "shell.feedback.comment" | translate }}</p>
            <textarea
              name="Feedback"
              *ngIf="true || Feedback.Rating > 0"
              [(ngModel)]="Feedback.Message"
              class="textarea step-input"
              style="max-height: 4em"
            ></textarea>
            <a *ngIf="!Feedback.Sent" (click)="SendFeedback()" [class.is-loading]="Feedback.Submitting" class="button is-normal is-cyan">
              {{ "shell.feedback.send" | translate }}
            </a>
            <span *ngIf="Feedback.Error">Error</span>
          </div>
        </ng-container>

        <ng-container *ngIf="!bRating && !messageSent && RejectMessage == undefined">
          <h1 class="oranienbaum-regular has-text-left is-size-2-fullhd is-size-3-desktop is-size-4-touch mb-4 text-custom-color">
            {{ "shell.feedback.unavailable1" | translate }}
          </h1>
          <p class="subtitle has-text-left poppins-semibold is-size-6-fullhd is-size-7-desktop is-size-7-touch text-custom-color">
            {{ "shell.feedback.unavailable2" | translate }}
          </p>
          <div class="mb-6">
            <p>{{ "shell.feedback.content_message" | translate }}:</p>
            <textarea class="textarea step-input" name="MessageContent" [(ngModel)]="Message.Content"></textarea>
            <!--<p class="text-custom-color">{{ "shell.feedback.coordinates_message" | translate }}:</p>
            <input type="text" class="step-input" [(ngModel)]="Message.Contact" style="border: 1px solid transparent; display: block" />-->

            <a (click)="SendMessage()" class="button is-normal is-cyan">{{ "shell.feedback.send" | translate }}</a>
          </div>
        </ng-container>

        <ng-container *ngIf="!bRating && (messageSent === undefined || messageSent === false) && RejectMessage !== undefined">
          <h1 class="oranienbaum-regular has-text-left is-size-2-fullhd is-size-3-desktop is-size-4-touch mb-4 text-custom-color">
            {{ "public_room.unavailable" | translate }}
          </h1>
          <div class="mb-4" style="display: flex">
            <div style="align-content: end">
              <img src="{{ DomainData.RoomLogoURI }}" height="60" width="60" style="border-radius: 8px" />
              <p class="subtitle poppins-semibold is-size-4">{{ DomainData.RoomFriendlyName }}</p>
            </div>
            <div class="panel" style="border-radius: 10px 10px 10px 0px; padding: 15px">{{ RejectMessage }} {{ debugging() }}</div>
          </div>
        </ng-container>

        <div *ngIf="(messageSent !== undefined && messageSent === true) || RejectMessage !== undefined || bRating">
          <h1 class="has-text-left poppins-semibold is-size-4-fullhd is-size-5-desktop is-size-6-touch mb-4 text-custom-color">
            {{ "room_rating.try_vizu" | translate }}
          </h1>
          <p class="subtitle has-text-left poppins-semibold is-size-6-fullhd is-size-7-desktop is-size-7-touch text-custom-color">
            {{ "room_rating.create" | translate }}
          </p>

          <form #form="ngForm" novalidate style="display: inline-block">
            <input
              id="email"
              type="email"
              name="email"
              [(ngModel)]="customerService.CreateAccount.Email"
              placeholder="Addresse email"
              class="step-input"
              style="border: 1px solid transparent; display: block; width: 100%"
            />
            <div style="display: flex">
              <a id="submit-button button" class="button is-normal is-cyan" routerLink="register">
                {{ "room_rating.register" | translate }}
              </a>
              <button class="button is-normal ml-4" (click)="bUpgradeModal = true">
                {{ "room_rating.discover" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      *ngIf="(messageSent !== undefined && messageSent === true) || RejectMessage !== undefined || bRating"
      class="layout-center is-hidden-mobile"
      style="margin-bottom: 0"
    >
      <div class="panel">
        <p class="oranienbaum-bold has-text-left is-size-3">{{ "Free_Version" | translate }}</p>
        <div class="feature-list">
          <div class="list">
            <div class="list-item">
              <span class="icon has-text-success">
                <i class="fa-regular fa-square-check"></i>
              </span>
              <span class="list-text is-size-6">{{ "upgrade_plan.free.advantages.list.first" | translate }}</span>
            </div>
            <div class="list-item">
              <span class="icon has-text-success">
                <i class="fa-regular fa-square-check"></i>
              </span>
              <span class="list-text is-size-6">{{ "upgrade_plan.free.advantages.list.second" | translate }}</span>
            </div>
            <div class="list-item">
              <span class="icon has-text-success">
                <i class="fa-regular fa-square-check"></i>
              </span>
              <span class="list-text is-size-6">{{ "upgrade_plan.free.advantages.list.third" | translate }}</span>
            </div>
            <div class="list-item">
              <span class="icon has-text-success">
                <i class="fa-regular fa-square-check"></i>
              </span>
              <span class="list-text is-size-6">{{ "upgrade_plan.free.advantages.list.fourth" | translate }}</span>
            </div>
            <div class="list-item">
              <span class="icon has-text-success">
                <i class="fa-regular fa-square-check"></i>
              </span>
              <span class="list-text is-size-6">{{ "upgrade_plan.free.advantages.list.fifth" | translate }}</span>
            </div>
          </div>
        </div>
      </div>

      <button *ngIf="IsAnyFileToDownload()" class="button is-medium mt-6" (click)="bDownloadFilesModal = true">
        <span class="icon">
          <i class="fa-solid fa-download"></i>
        </span>
        <span>{{ "room_rating.download_files" | translate }}</span>
      </button>

      <img *ngIf="DomainData.CustomData?.LoginBackground === ''" src="/assets/img/vizu_zen.png" class="is-hidden-mobile zen-logo" />
    </div>
  </div>
</div>

<app-vizu-upgrade-plan
  [CurrentSubscription]="SubscriptionType"
  [bUpgradeModalOpened]="bUpgradeModal"
  (CloseUpgradeModal)="bUpgradeModal = false"
></app-vizu-upgrade-plan>

<app-vizu-download-files
  *ngIf="IsAnyFileToDownload()"
  [Messages]="Messages"
  [allDocsShared]="allDocsShared"
  [bDownloadFilesModalOpened]="bDownloadFilesModal"
  (CloseDownloadFilesModal)="bDownloadFilesModal = false"
></app-vizu-download-files>

<app-notification #notificationComponent></app-notification>
