import { Injectable, ErrorHandler } from "@angular/core";
import { RtcService } from "../services/rtc.service";

@Injectable({
  providedIn: "root",
})
export class ErrorService implements ErrorHandler {
  constructor(private rtc: RtcService) {}

  postCount = 0;

  isJsonString(str: any) {
    try {
      JSON.stringify(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  handleError(error: any) {
    if (this.postCount < 100) {
      // ONLY FOR DEBUG
      if (error.stack) {
        console.log("/!\\ ERROR: " + error.stack);
        if (this.isJsonString(error.stack)) {
          this.rtc.sendErrorMessage(error.stack).subscribe(() => {
            this.postCount++;
          });
        }
      }
    }
  }
}
