import { Component, EventEmitter, Input, Output } from "@angular/core";
import * as JSZip from "jszip";

@Component({
  selector: "app-vizu-download-files",
  templateUrl: "./vizu-download-files.component.html",
  styleUrl: "./vizu-download-files.component.scss",
})
export class VizuDownloadFilesComponent {
  @Input() bDownloadFilesModalOpened: boolean;
  @Input() Messages: any[] = [];
  @Input() allDocsShared: any[] = [];
  @Output() CloseDownloadFilesModal = new EventEmitter<void>();

  openModal() {
    this.bDownloadFilesModalOpened = true;
  }

  closeModal() {
    this.bDownloadFilesModalOpened = false;
    this.CloseDownloadFilesModal.emit();
  }

  downloadSelectedFiles() {
    const selectedFiles = this.Messages.filter((message) => message.selected);
    console.log("Fichiers sélectionnés pour le téléchargement:", selectedFiles);
    for (let file of selectedFiles) {
      file.Url();
    }
  }

  downloadTar() {
    const zip = new JSZip();
    const selectedFiles = this.Messages.filter((message) => message.selected);

    selectedFiles.forEach((file) => {
      zip.file(file.Text, file.Data);
    });

    const selectedDocs = this.allDocsShared.filter((doc) => doc.selected);
    selectedDocs.forEach((file) => {
      zip.file(file.Name, file.Data);
    });

    zip.generateAsync({ type: "blob" }).then((content: any) => {
      const url = URL.createObjectURL(content);
      const link = document.createElement("a");
      link.href = url;
      link.download = "files.tar";
      link.click();
      window.URL.revokeObjectURL(url);
    });
  }
}
