<div [ngSwitch]="State" [ngClass]="State == 'appointment_closed' ? 'grey-cover' : 'grey-uncover'">
  <app-vizu-main
    *ngSwitchCase="'appointment_closed'"
    [audioDeviceSelected]="audioDeviceSelected"
    [videoDeviceSelected]="videoDeviceSelected"
    (EnterRoom)="EnterRoom($event)"
  ></app-vizu-main>
  <app-vizu-room
    *ngSwitchCase="'appointment_started'"
    [Public]="false"
    [Limitations]="Limitations"
    [Contacts]="Contacts"
    [Customer]="Customer"
    [Appointment]="Room"
    [WaitingRequest]="WaitingRequest"
    (Closed)="State = 'appointment_closed'"
  ></app-vizu-room>
</div>
<div [class.is-active]="Disconnected" class="modal">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ "shell.disconnected.title" | translate }}</p>
    </header>
    <section class="modal-card-body">
      {{ "shell.disconnected.text" | translate }}
    </section>
    <footer class="modal-card-foot">
      <a (click)="Reload()" class="button is-danger">{{ "shell.disconnected.reload" | translate }}</a>
    </footer>
  </div>
</div>
