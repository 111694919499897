<app-vizu-home-layout>
  <div style="margin-bottom: 10vh">
    <div *ngIf="bCompanyFound == false">
      <div class="name-form name-text">{{ "public_room.not_found_url_title" | translate }}</div>
      <div class="name-form step-step-unknown">{{ "public_room.not_found_main" | translate }}</div>
    </div>

    <div *ngIf="bCompanyFound" class="panel">
      <h1 class="title has-text-centered poppins-semibold">{{ "login.title" | translate }}</h1>
      <p *ngIf="bRegisterPanel" class="subtitle is-6 has-text-centered" style="color: #a5a5bf">version gratuite</p>
      <form (ngSubmit)="onSubmit()" class="login-form">
        <input type="login" placeholder="{{ 'login.login' | translate }}" [(ngModel)]="Local.Login" name="login" class="login-input" />
        <input
          type="password"
          placeholder="{{ 'login.password' | translate }}"
          [(ngModel)]="Local.Password"
          name="password"
          class="login-input"
        />
        <div class="custom-checkbox">
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="Local.RememberMe" (change)="isCookieEnabled($event)" name="password" />
            {{ "login.remember" | translate }}
          </label>
          <a class="forgot-password" href="/password-reset-request">{{ "login.forgot" | translate }}</a>
        </div>

        <button type="submit" class="button is-medium is-danger is-outlined login-button" [class.is-loading]="inProgress">
          <div class="enter-button-base">
            <div class="enter-button-out">
              <div class="enter-button-in">
                <span class="enter-button"></span>
              </div>
            </div>
          </div>
          <b class="button-text pointer">{{ "login.submit" | translate }}</b>
        </button>
      </form>
      <div *ngIf="error" class="login-error has-text-danger">{{ ErrorMessage | translate }}</div>
      <!--<div class="login-links">
                <a routerLink="/signup" [ngStyle]="setTextColor()">{{ 'login.signin' | translate }}</a>
                <a routerLink="/passwordreset" [ngStyle]="setTextColor()">{{ 'login.forgot' | translate }}</a>
            </div>-->
    </div>

    <div *ngIf="bCompanyFound && bRegisterPanel" class="panel">
      <div class="fix-width flex-centered">
        <p>{{ "login.new_to_vizu" | translate }}</p>
        <a href="/register">{{ "login.create_account" | translate }}</a>
      </div>
    </div>
  </div>
</app-vizu-home-layout>
<app-vizu-recaptcha #recaptchaComponent [userAction]="userAction" (authAction)="handleRecaptcha($event)"></app-vizu-recaptcha>
<app-vizu-cookie
  *ngIf="rtc?.cookies == undefined && bCompanyFound"
  [showCookieSettings]="showCookieSettings"
  (showCookieSettingsChange)="handleSettingsChange($event)"
  style="width: 100%"
></app-vizu-cookie>
