import { Component, EventEmitter, OnInit, ViewChild, Output, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { CustomerService } from "../../services/customer.service";
import { DomainData } from "../../domain/domain";
import { AuthService } from "../../services/auth.service";

import { VizuRecaptchaComponent } from "../vizu-recaptcha/vizu-recaptcha.component";

import * as crypto from "crypto-js";
import { RtcService } from "src/app/services/rtc.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  @ViewChild("recaptchaComponent") recaptchaComponent!: VizuRecaptchaComponent;
  @Output() success = new EventEmitter<void>();
  @Input() DomainData: DomainData;
  inProgress = false;
  error = false;
  ErrorMessage = "";
  bCompanyFound: boolean = undefined;
  bLoginPanel: boolean = true;
  bRegisterPanel: boolean = false;
  userDetected: boolean = true;

  Local = {
    Version: "3,04",
    Logout: false,
    RememberMe: false,
    Domain: "",
    Login: "",
    Password: "",
  };

  CreateAccount = {
    Email: "",
    Password: "",
    ConfirmPassword: "",
    DisplayName: "",
  };

  domain = {
    name: "s",
  };

  showCookieSettings: boolean = false;
  userAction: string = "login";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private customerService: CustomerService,
    public rtc: RtcService
  ) {}

  async ngOnInit(): Promise<void> {
    console.log("Login component");
    this.domain.name = window.location.host;
    let pathname = window.location.host.split(".");
    if (pathname.length < 3) {
      this.bRegisterPanel = true;
    }
    let bAutoLoginNeeded = true;

    this.customerService.GetDomainPublicTemplate("").subscribe({
      next: (data) => {
        this.bCompanyFound = true;
        let domainData: any = data;
        this.DomainData = {
          Name: data.Name,
          SharedUsers: data.SharedUsers,
          RoomLogoURI: data.RoomLogoURI,
          RoomFriendlyName: data.RoomFriendlyName,
          CustomData: JSON.parse(domainData.CustomData),
        };
        this.inProgress = false;
        this.customerService.setColors(this.DomainData.CustomData.BackgroundColor, this.DomainData.CustomData.TextColor);
      },
      error: (response) => {
        this.bCompanyFound = false;
        /*if (response.status === 404) {
                } else if (response.status === 400) {
                }*/
      },
    });

    if (localStorage.getItem("VizuLive\\RememberMe") !== "null" && localStorage.getItem("VizuLive\\RememberMe") !== null) {
      if (localStorage.getItem("VizuLive\\Version") !== "null" && localStorage.getItem("VizuLive\\Version") !== null) {
        this.Local.Version = localStorage.getItem("VizuLive\\Version");
        if (localStorage.getItem("VizuLive\\Domain") !== "null" && localStorage.getItem("VizuLive\\Domain") !== null) {
          this.Local.Domain = localStorage.getItem("VizuLive\\Domain");
          if (localStorage.getItem("VizuLive\\Login") !== "null" && localStorage.getItem("VizuLive\\Login") !== null) {
            if (localStorage.getItem("VizuLive\\Password") !== "null" && localStorage.getItem("VizuLive\\Password") !== null) {
              let tempRememberMe = localStorage.getItem("VizuLive\\RememberMe");
              tempRememberMe === "true" ? (this.Local.RememberMe = true) : (this.Local.RememberMe = false);
              this.Local.RememberMe = this.Local.RememberMe;
              this.Local.Login = localStorage.getItem("VizuLive\\Login");
              this.Local.Password = localStorage.getItem("VizuLive\\Password");
              if (this.Local.Version === "3,02") {
              }
              if (this.Local.Version === "3,03") {
                let decryptedLogin = crypto.AES.decrypt(this.Local.Login, "hr=br.ac.ge+a.f");
                let decryptedPassword = crypto.AES.decrypt(this.Local.Password, "hr=br.ac.ge+a.f");
                let decryptedDomain = crypto.AES.decrypt(this.Local.Domain, "hr=br.ac.ge+a.f");

                this.Local.Login = decryptedLogin.toString(crypto.enc.Utf8);
                this.Local.Password = decryptedPassword.toString(crypto.enc.Utf8);
                this.Local.Domain = decryptedDomain.toString(crypto.enc.Utf8);
              }
            }
          }
        }
      }
    }

    if (localStorage.getItem("VizuLive\\Logout") !== "null" && localStorage.getItem("VizuLive\\Logout") !== null) {
      let tempLogout = localStorage.getItem("VizuLive\\Logout");
      localStorage.removeItem("VizuLive\\Logout");
      this.Local.Logout = tempLogout === "true" ? true : false;
    }
    if (this.Local.Logout) {
      bAutoLoginNeeded = false;
    }
    if (bAutoLoginNeeded) {
      try {
        if (this.Local.RememberMe) {
          this.auth.Authenticated().subscribe({
            next: (success) => {
              this.loginSuccess();
            },
            error: (e) => {
              this.auth.Authenticate(this.Local.Login, this.Local.Password).subscribe({
                next: (loginSuccess) => {
                  this.loginSuccess();
                  this.customerService.GetDomainPublicTemplate("").subscribe({
                    next: (data) => {
                      let domainData: any = data;
                      this.DomainData = {
                        Name: data.Name,
                        SharedUsers: data.SharedUsers,
                        RoomLogoURI: data.RoomLogoURI,
                        RoomFriendlyName: data.RoomFriendlyName,
                        CustomData: JSON.parse(domainData.CustomData),
                      };
                      this.customerService.setColors(this.DomainData.CustomData.BackgroundColor, this.DomainData.CustomData.TextColor);
                    },
                    error: (response) => {},
                  });
                },
                error: (err) => {
                  this.inProgress = false;
                  this.error = true;
                  if (err.status === 429) {
                    this.ErrorMessage = "login.toomanyreq";
                  } else {
                    this.ErrorMessage = "login.badcreds";
                  }
                },
              });
            },
          });
        }
      } catch (error) {
        this.inProgress = false;
      }
    }
  }

  setDomainAltColor() {
    let style = undefined;
    if (this.DomainData !== undefined) {
      style = {
        "background-color": this.DomainData.CustomData.ColorHomeAlt,
      };
    } else {
      style = "";
    }
    return style;
  }

  async onSubmit(): Promise<void> {
    this.inProgress = true;
    this.Local.Version = "3,03";

    this.recaptchaComponent.callRecaptcha("login").subscribe({
      next: (token) => {
        this.rtc.getConfidenceLevel(token, this.userAction).subscribe(
          (confidenceServer: any) => {
            //Then check the confidence we got from the server
            this.checkActionToken(confidenceServer.confidence);
          },
          (error: any) => {
            console.log(`error:`, error);
            this.handleRecaptcha(true);
            this.authenticateUser();
            this.inProgress = false;
          }
        );
      },
      error: (e) => {
        console.log(`error:`, e);
        this.handleRecaptcha(true);
        this.authenticateUser();
        this.inProgress = false;
      },
    });
  }

  authenticateUser() {
    try {
      this.auth.Authenticate(this.Local.Login, this.Local.Password).subscribe({
        next: (loginSuccess) => {
          this.loginSuccess();
          this.customerService.GetDomainPublicTemplate("").subscribe((myData) => {
            if (this.rtc.cookies?.performance) {
              localStorage.setItem("VizuLive\\Version", this.Local.Version);
              localStorage.setItem("VizuLive\\RememberMe", this.Local.RememberMe.toString());
              if (this.Local.RememberMe) {
                const encryptedLogin = crypto.AES.encrypt(this.Local.Login, "hr=br.ac.ge+a.f").toString();
                const encryptedPassword = crypto.AES.encrypt(this.Local.Password, "hr=br.ac.ge+a.f").toString();
                const encryptedDomain = crypto.AES.encrypt(this.domain.name, "hr=br.ac.ge+a.f").toString();

                localStorage.setItem("VizuLive\\Login", encryptedLogin);
                localStorage.setItem("VizuLive\\Password", encryptedPassword);
                localStorage.setItem("VizuLive\\Domain", encryptedDomain);
              }
            }
            this.bCompanyFound = true;
            let domainData: any = myData;
            this.DomainData = {
              Name: myData.Name,
              SharedUsers: myData.SharedUsers,
              RoomLogoURI: myData.RoomLogoURI,
              RoomFriendlyName: myData.RoomFriendlyName,
              CustomData: domainData.CustomData === "" ? "" : JSON.parse(domainData.CustomData),
            };
            this.customerService.setColors(this.DomainData.CustomData.BackgroundColor, this.DomainData.CustomData.TextColor);
          });
        },
        error: (err) => {
          this.inProgress = false;
          this.error = true;
          if (err.status === 429) {
            this.ErrorMessage = "login.toomanyreq";
          } else {
            this.ErrorMessage = "login.badcreds";
          }
        },
      });
    } catch (e) {
      const error: HttpErrorResponse = e;
      if (error.status === 429) {
        this.ErrorMessage = "login.toomanyreq";
      } else if (error.status === 401) {
        this.ErrorMessage = "login.badcreds";
      } else {
        this.ErrorMessage = "login.error";
      }

      this.error = true;
      this.inProgress = false;
    }
  }

  setTextColor() {
    let style = undefined;
    if (this.DomainData !== undefined) {
      if (
        this.DomainData.CustomData.ColorHomeMain === "white" ||
        this.DomainData.CustomData.ColorHomeMain === "#FFFFFF" ||
        this.DomainData.CustomData.ColorHomeMain === "rgb(255,255,255)"
      ) {
        style = {
          color: "black",
        };
      } else {
        style = {
          color: "white",
        };
      }
    }
    return style;
  }

  private loginSuccess(): void {
    if (this.route.snapshot.url.length > 0 && this.route.snapshot.url[0].path === "login") {
      this.router.navigate(["/"]);
    } else {
      this.success.emit();
    }
  }

  handleSettingsChange(newValue: boolean) {
    this.showCookieSettings = newValue;
  }
  isCookieEnabled(event: Event) {
    if (!this.rtc.cookies?.performance) {
      (event.target as HTMLInputElement).checked = false;
      this.eraseCookie();
      this.rtc.cookies = undefined;
      this.showCookieSettings = true;
    }
  }

  //MAKE A SERVICE FOR THE COOKIES IN ORDER TO CLEAN THIS AND NOT DELETE IT IN THE LOGIN COMPONENT
  eraseCookie() {
    document.cookie = "cookie_settings=; Max-Age=-99999999;";
  }

  handleRecaptcha(isAuthorized: boolean) {
    if (isAuthorized) {
      this.userDetected = true;
    } else {
      this.userDetected = false;
    }
  }

  checkActionToken(confidence: number) {
    console.log("CAPTCHA SCORE: " + confidence);
    if (confidence >= 0.3) {
      this.handleRecaptcha(true);
      this.authenticateUser();
    } else {
      this.handleRecaptcha(false);
      this.inProgress = false;
    }
  }
}
