import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from "@angular/core";

import { CustomerService } from "../../services/customer.service";

import { RoomConfig, Customer, Appointment } from "../../domain/domain";
import { EmitterService } from "src/app/services/emitter.service";
import { RtcService } from "../../services/rtc.service";
import { NotificationComponent } from "../notification/notification.component";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Component({
  selector: "app-vizu-admin-config",
  templateUrl: "./vizu-admin-config.component.html",
  styleUrls: ["./vizu-admin-config.component.scss"],
})
export class VizuAdminConfigComponent implements OnInit, AfterViewInit {
  @Input() AdminCompany: string;
  @Input() isServiceProvider: boolean;
  @Input() adminUserInfos: any;
  @ViewChild("navBurger") navBurger: ElementRef;
  @ViewChild("navMenu") navMenu: ElementRef;
  @ViewChild("notificationComponent") notificationComponent!: NotificationComponent;
  @ViewChild("canvasPreview")
  canvasPreview: ElementRef<HTMLCanvasElement>;
  contextCanvasPreview: CanvasRenderingContext2D;
  blobImage: any;

  Loading = true;
  Error = false;

  error = "";
  required: any = {};

  //Domains: DomainData[]
  Customers: Customer[];
  Rooms: any;
  Users: any;
  Domains: any;
  end_user_profiles: any;
  domainSelected: any;

  LogoURIInput: string = "";
  bLogoURIFailed: boolean = false;
  imageChangedEvent: Event | null = null;
  logoImage: string = "";
  imageFileName: string = "";

  sharedRooms: Appointment[];
  privateRooms: Appointment[];

  domainFilter: any = "";

  Params = {
    Name: "",
    Room: "",
    Email: {
      Password: "",
      Email: "",
    },
    Password: {
      Strength: 0,
      Old: "",
      New: "",
    },
    Config: new RoomConfig(),
  };

  ErrorUserRoom: boolean = false;
  roomNameEdit: string = "";
  SubmittingName = false;
  SubmittingRoom = false;
  ErrorName = false;
  ErrorRoom = false;
  SuccessRoom = false;
  SmallRoom = false;
  SubmittingEmail = false;
  ErrorEmail = false;
  SubmittingPassword = false;
  ErrorPassword = false;
  ErrorConfig = false;
  SubmittingConfig = false;
  bNewDomain = false;

  bNewEditView = false;
  bEdit = false;
  editUserId = -1;
  bEditPrivate = false;
  bNewPrivate = false;
  validEmail = false;

  bDisplay = "domains";

  roomsViewState = "view";
  roomInfos: any = {
    Enabled: true,
    DomainId: "",
  };

  usersViewState = "view";
  autoComplete = true;
  userInfos: any = {
    Enabled: true,
    Name: "",
    Password: "",
    Email: "",
    DomainId: "",
    Room: "",
    SubscriptionType: "enterprise",
    Administrator: false,
  };

  SubscriptionTypes: any[] = [{ value: "free" }, { value: "pro" }, { value: "enterprise" }];

  domainsViewState = "view";
  domainInfos: any = {
    Enabled: true,
    id: "",
    ServiceProvider: false,
    CustomData: {
      Name: "",
      LogoURI: "",
      ImgBackground: "/assets/img/brown-wooden-style.jpg",
      LoginBackground: "/assets/img/brown-wooden-style.jpg",
      ColorHomeMain: "#343640",
      ColorHomeAlt: "#c4283d",
      ColorHomeText: "white",
      ColorRoomHeader: "white",
      ColorHomeAltText: "white",
      ColorHomeMainText: "#1f1f1f",
      ImgLogoRoom: "",
    },
    SharedUsers: true,
    /*"Name":"mydomain.devngmedia.live",
        "FriendlyName":"myCOMPany",
        "LogoURI":"https://ngmedia.vizu.live/static/assets/domains/ngmedia/ngmedia.png",
        "ImgBackground":"https://ngmedia.vizu.live/static/assets/domains/ngmedia/ngmedia_background.png",
        "ColorHomeMain":"white",
        "ColorHomeAlt":"#c4283d",
        "ColorHomeText":"white",
        "ColorRoomHeader":"white",
        "ColorHomeAltText":"white",
        "ColorHomeMainText":"#1f1f1f",
        "ImgLogoRoom":"https://ngmedia.vizu.live/static/assets/domains/ngmedia/ngmedia.png"}*/
    MediaProfileId: "1",
    SIPProfileId: "1",
  };
  fullDomain: string = "";

  bModalConfirmDelete: boolean = false;

  emptyMail = false;
  emptyName = false;
  emptyRoom = false;
  emptyPassword = false;
  errorDomainExists = false;
  errorNewUser = false;
  inProgress = true;

  copyTable: any = {};

  constructor(private customerService: CustomerService, private NgmsService: EmitterService, private sanitizer: DomSanitizer) {}

  changeAdminView(view: string) {
    this.Cancel();
    this.bDisplay = view;
    if (!this.isServiceProvider) {
      if (this.Domains !== undefined) {
        this.domainInfos = this.Domains[0];
      }
      this.domainFilter = this.domainInfos.id;
      this.domainFilterChanged();
      if (this.bDisplay === "domains") {
        this.domainsViewState = "edit";
      }
    }
  }

  async ngAfterViewInit() {
    await sleep(100);
    this.RefreshCanvas();
  }

  RefreshCanvas() {
    if (this.canvasPreview?.nativeElement !== undefined) {
      this.contextCanvasPreview = this.canvasPreview.nativeElement.getContext("2d");
      this.contextCanvasPreview.fillStyle = this.domainInfos.CustomData.BackgroundColor;
      this.contextCanvasPreview.fillRect(0, 0, this.canvasPreview.nativeElement.width, this.canvasPreview.nativeElement.height);

      this.contextCanvasPreview.font = "30px Oranienbaum serif"; //"Oranienbaum 40px serif"
      this.contextCanvasPreview.fillStyle = this.getContrastingTextColor(this.domainInfos.CustomData.BackgroundColor);
      this.contextCanvasPreview.textAlign = "center";
      this.contextCanvasPreview.fillText("Text", 90, 60);
    }
  }

  async refreshTables() {
    let myThis = this;
    this.customerService.GetDomains(this.isServiceProvider).subscribe((dataDomains) => {
      if (this.isServiceProvider) {
        myThis.Domains = dataDomains;
      } else {
        myThis.Domains = [];
        myThis.Domains.push(dataDomains);
      }
      for (let h = 0; h < myThis.Domains.length; h++) {
        //Delete * Default domain
        if (myThis.Domains[h].Name === "Default") {
          myThis.Domains.splice(h, 1);
        }
      }
      for (let k = 0; k < myThis.Domains.length; k++) {
        if (myThis.Domains[k] !== undefined) {
          if (myThis.Domains[k].subDomain === undefined) {
            if (myThis.Domains[k].Domain !== undefined) {
              let urlParsed = myThis.Domains[k].Domain.split(".");
              if (urlParsed.length === 3) {
                myThis.domainInfos.subDomain = urlParsed[0];
              } else {
                myThis.domainInfos.subDomain = "";
              }
            }
          }
          if (myThis.Domains[k].Name === myThis.AdminCompany) {
            myThis.domainFilter = myThis.Domains[k].id;
            myThis.domainFilterChanged();
          }
          if (myThis.Domains[k].CustomData !== undefined) {
            myThis.Domains[k].CustomData = JSON.parse(myThis.Domains[k].CustomData);
          }
        }
      }

      if (!this.isServiceProvider) {
        myThis.domainInfos = dataDomains;
        myThis.domainFilter = myThis.domainInfos.id;
      }
      //myThis.copyTable.Domains = JSON.parse(JSON.stringify(myThis.Domains));
      myThis.copyTable.Domains = myThis.Domains;
    });

    //Need a clean here
    //It would be better to call the functions below after the success of getdomains
    await sleep(50);

    this.customerService.GetRooms(this.isServiceProvider).subscribe((dataRooms) => {
      myThis.Rooms = dataRooms;
      for (let i = 0; i < myThis.Rooms.length; i++) {
        for (let j = 0; j < myThis.Domains.length; j++) {
          if (myThis.Rooms[i].DomainId === myThis.Domains[j].id) {
            myThis.Rooms[i].DomainName = myThis.Domains[j].Name;
          }
        }
      }
      myThis.copyTable.Rooms = myThis.Rooms;
    });
    this.customerService.GetUsers(this.isServiceProvider).subscribe((dataUsers) => {
      myThis.Users = dataUsers;
      for (let i = 0; i < myThis.Users.length; i++) {
        if (this.isServiceProvider) {
          for (let j = 0; j < myThis.Domains.length; j++) {
            if (myThis.Users[i].DomainId === myThis.Domains[j].id) {
              myThis.Users[i].DomainName = myThis.Domains[j].Name;
            }
          }
        } else {
          myThis.Users[i].DomainName = myThis.domainInfos.Name;
          myThis.Users[i].DomainId = myThis.domainInfos.id;
        }
      }
      //myThis.copyTable.Users = JSON.parse(JSON.stringify(myThis.Users));
      myThis.copyTable.Users = myThis.Users;
    });
    this.customerService.GetUsersProfiles(this.isServiceProvider).subscribe((dataEndUserProfiles) => {
      myThis.end_user_profiles = dataEndUserProfiles;
    });
    /*this.customerService.GetSIPProfiles(this.isServiceProvider)
        .subscribe(dataSipProfiles => {
            myThis.sip_profiles = dataSipProfiles
        })
        this.customerService.GetMediaProfiles(this.isServiceProvider)
        .subscribe(dataMediaProfiles => {
            myThis.media_profiles = dataMediaProfiles
        })*/

    this.customerService.GetDomainPublicTemplate(this.adminUserInfos.Room).subscribe({
      next: (data) => {
        let DomainData = {
          Name: data.Name,
          RoomLogoURI: data.RoomLogoURI,
          RoomFriendlyName: data.RoomFriendlyName,
          CustomData: data.CustomData === "" ? "" : JSON.parse(data.CustomData),
        };
        myThis.NgmsService.EmitNgmsSettings(DomainData, "OnDomainData");
      },
    });

    this.Loading = false;
  }

  initDomainInfos() {
    console.log("!!!!!!! domainInfos = ", this.domainInfos);
    let domainLength = this.domainInfos.Domain.split(".").length;
    this.domainInfos.subDomain = domainLength > 2 ? this.domainInfos.Domain.split(".")[0] : "";
  }

  ngOnInit() {
    let urlParsed = window.location.host.split(".");
    this.fullDomain = urlParsed[1] + "." + urlParsed[2];
    //this.Domains = await this.customerService.GetDomains()
    this.refreshTables();

    //this.copyTable.Rooms = JSON.parse(JSON.stringify(this.Rooms));
    //this.copyTable.Users = JSON.parse(JSON.stringify(this.Users));
    //this.copyTable.Domains = JSON.parse(JSON.stringify(this.Domains));

    if (!this.isServiceProvider) {
      this.changeAdminView("domains");
    }
  }

  /*getContrastingTextColor(backgroundColor: string) {
    // Convertir la couleur de fond en un format RGB
    var rgb = null;
    if (backgroundColor !== undefined) {
      rgb = this.hexToRgb(backgroundColor);
    }

    if (rgb === null) {
      throw new Error("Couleur de fond invalide. Assurez-vous d'utiliser un format hexadécimal valide.");
    }
    // Calculer la luminosité de la couleur
    const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
    // Retourner la couleur du texte en fonction de la luminosité
    return luminance > 0.5 ? "hsl(0, 0%, 30%)" : "#FFFFFF";
  }*/

  getContrastingTextColor(backgroundColor: string) {
    // Convertir la couleur de fond en un format RGB
    const rgb = this.hexToRgb(backgroundColor);
    if (!rgb) {
      throw new Error("Couleur de fond invalide. Assurez-vous d'utiliser un format hexadécimal valide.");
    }
    // Calculer la luminosité de la couleur
    const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
    // Retourner la couleur du texte en fonction de la luminosité
    return luminance > 0.5 ? "hsl(0, 0%, 30%)" : "#FFFFFF";
  }

  hexToRgb(hex: string) {
    // Supprimer le caractère # s'il est présent
    hex = hex.replace(/^#/, "");

    // Convertir les valeurs hexadécimales en valeurs RGB
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return { r, g, b };
  }

  async ChangeColors() {
    this.domainInfos.CustomData.TextColor = this.getContrastingTextColor(this.domainInfos.CustomData.BackgroundColor);
    document.documentElement.style.setProperty("--background-color", this.domainInfos.CustomData.BackgroundColor);
    document.documentElement.style.setProperty("--text-color", this.domainInfos.CustomData.TextColor);
  }

  ApplyLogoURI() {
    this.domainInfos.CustomData.LogoURI = this.logoImage;
  }

  fileChangeEvent(event: any): void {
    const file: File = event.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert("invalid format");
      return;
    }
    this.blobImage = file;
    this.imageFileName = event.target.files[0].name;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e: any) {
    let reader = e.target;
    this.logoImage = reader.result;
  }

  toggleNavbar() {
    this.navBurger.nativeElement.classList.toggle("is-active");
    this.navMenu.nativeElement.classList.toggle("is-active");
  }

  reload() {
    window.location.reload();
  }

  domainFilterChanged() {
    for (let i = 0; i < this.Domains.length; i++) {
      if (this.Domains[i].id === this.domainFilter) {
        this.domainSelected = this.Domains[i];
        break;
      }
    }
  }

  onAdministratorChange() {
    this.userInfos.Administrator = !this.userInfos.Administrator;
    console.log("Admin changed " + this.userInfos.Administrator);
  }

  async ChangeDomainInfos() {
    //await this.customerService.EditDomain(this.domainInfos)
  }

  clearError = function () {
    this.required = {};
    this.error = "";
  };

  UserRoomUpdateKey() {
    this.userInfos.Room = this.userInfos.Room.replace(/ /g, "-");
  }

  UserRoomToLowerCase() {
    this.userInfos.Room = this.userInfos.Room.toLowerCase();
  }

  RoomUpdateKey() {
    this.roomInfos.Room = this.roomInfos.Room.replace(/ /g, "-");
  }

  RoomToLowerCase() {
    this.roomInfos.Room = this.roomInfos.Room.toLowerCase();
  }

  OnDomainChange() {
    this.domainInfos.subDomain = this.domainInfos.subDomain.toLowerCase();
  }

  EditRoom() {
    this.clearError();
    let myThis = this;
    this.customerService.EditRoom(this.roomInfos, this.isServiceProvider).subscribe({
      next: () => {
        for (let i = 0; i < myThis.Rooms.length; i++) {
          if (myThis.Rooms[i].id === myThis.roomInfos.id) {
            myThis.Rooms[i] = myThis.roomInfos;
            break;
          }
        }
        myThis.notificationComponent.displayNotification("showSuccessResult");
        myThis.roomsViewState = "view";
        myThis.clearDataInfos();
        myThis.refreshTables();
      },
      error: (response) => {
        myThis.notificationComponent.displayNotification("showFailedResult");
        if (response.error) {
          if (response.error.title) {
            myThis.error = response.error.title + ". ";
          }
          if (response.error.parameters) {
            myThis.error += response.error.parameters[0].reason;
            myThis.required[response.error.parameters[0].name] = true;
          }
        } else {
          myThis.roomsViewState = "view";
          myThis.clearDataInfos();
        }
        myThis.refreshTables();
      },
    });
  }

  AddRoom() {
    this.clearError();
    let myThis = this;
    this.customerService.AddRoom(this.roomInfos, this.isServiceProvider).subscribe({
      next: () => {
        myThis.roomsViewState = "view";
        myThis.customerService.GetRooms(this.isServiceProvider).subscribe((dataRooms) => {
          myThis.Rooms = dataRooms;
          for (let i = 0; i < myThis.Rooms.length; i++) {
            for (let j = 0; j < myThis.Domains.length; j++) {
              if (myThis.Rooms[i].DomainId === myThis.Domains[j].id) {
                myThis.Rooms[i].DomainName = myThis.Domains[j].Name;
              }
            }
          }
          myThis.notificationComponent.displayNotification("showSuccessResult");
          //myThis.copyTable.Rooms = JSON.parse(JSON.stringify(myThis.Rooms));
          myThis.copyTable.Rooms = myThis.Rooms;
          myThis.clearDataInfos();
          myThis.refreshTables();
        });
      },
      error: (response) => {
        myThis.notificationComponent.displayNotification("showFailedResult");
        if (response.error) {
          if (response.error.title) {
            myThis.error = response.error.title + ". ";
          }
          if (response.error.parameters) {
            myThis.error += response.error.parameters[0].reason;
            myThis.required[response.error.parameters[0].name] = true;
          }
        }
        myThis.refreshTables();
      },
    });
  }

  DeleteRoom() {
    this.clearError();
    let myThis = this;
    this.customerService.DeleteRoom(this.roomInfos, this.isServiceProvider).subscribe({
      next: () => {
        myThis.roomsViewState = "view";
        for (let i = 0; i < this.Rooms.length; i++) {
          if (myThis.Rooms[i].id === myThis.roomInfos.id) {
            myThis.Rooms.splice(i, 1);
            break;
          }
        }
        myThis.notificationComponent.displayNotification("showSuccessResult");
        //myThis.copyTable.Rooms = JSON.parse(JSON.stringify(this.Rooms));
        myThis.copyTable.Rooms = this.Rooms;
        myThis.clearDataInfos();
        myThis.refreshTables();
      },
      error: (response) => {
        myThis.notificationComponent.displayNotification("showFailedResult");
        if (response.error) {
          if (response.error.title) {
            myThis.error = response.error.title + ". ";
          }
          if (response.error.detail) {
            myThis.error += response.error.detail;
          }
        }
        myThis.refreshTables();
      },
    });
  }

  EditUser() {
    this.clearError();
    let response: any = undefined;
    let myThis = this;
    if (this.userInfos.Email.indexOf(" ") >= 0 || this.userInfos.Password.indexOf(" ") >= 0) {
      //If whitespace, error
      this.error = "You can't use a whitespace in the Email or Password field";
    } else if (this.validEmail === false) {
      this.error = "Invalid email format";
    } else {
      this.customerService.EditUser(this.userInfos, this.isServiceProvider).subscribe({
        next: (data) => {
          response = data;
          myThis.notificationComponent.displayNotification("showSuccessResult");
          myThis.usersViewState = "view";
          myThis.clearDataInfos();
          myThis.refreshTables();
        },
        error: (errorData) => {
          myThis.notificationComponent.displayNotification("showFailedResult");
          if (errorData.error) {
            if (errorData.error.title) {
              myThis.error = errorData.error.title + ". ";
            }
            if (errorData.error.parameters) {
              myThis.error += errorData.error.parameters[0].reason;
              myThis.required[errorData.error.parameters[0].name] = true;
            }
          }
          myThis.refreshTables();
        },
      });
    }
  }

  OnNameChange(valueChanged: string) {
    if (valueChanged === "name") {
      this.autoComplete = false;
    } else if (valueChanged === "first" || valueChanged === "last") {
      if (this.autoComplete) {
        this.userInfos.Name = this.userInfos.FirstName + " " + this.userInfos.LastName;
      }
    }
  }

  OnEmailChange(newValue: string) {
    if (
      String(newValue)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
    this.userInfos.Email = this.userInfos.Email.toLowerCase();
  }

  onSubscriptionTypeChange(SubscriptionValue: string) {
    let freeRadio: any = document.getElementById("subscription-radio-free");
    let proRadio: any = document.getElementById("subscription-radio-pro");
    let enterpriseRadio: any = document.getElementById("subscription-radio-enterprise");

    if (SubscriptionValue === "enterprise") {
      freeRadio.checked = false;
      proRadio.checked = false;
    } else if (SubscriptionValue === "pro") {
      freeRadio.checked = false;
      enterpriseRadio.checked = false;
    } else if (SubscriptionValue === "free") {
      enterpriseRadio.checked = false;
      proRadio.checked = false;
    }

    this.userInfos.SubscriptionType = SubscriptionValue;
  }

  AddUser() {
    this.clearError();
    this.matchProfileIdCompany();
    if (this.userInfos.Email.indexOf(" ") >= 0 || this.userInfos.Password.indexOf(" ") >= 0) {
      //If whitespace, error
      this.error = "You can't use a whitespace in the Email or Password field";
    } else if (this.validEmail === false) {
      this.error = "Invalid email format";
    } else {
      for (let i = 0; i < this.end_user_profiles.length; i++) {
        if (this.isServiceProvider) {
          if (this.end_user_profiles[i].DomainId === this.userInfos.DomainId && this.end_user_profiles[i].Name !== "Anonymous") {
            this.userInfos.ProfileId = this.end_user_profiles[i].id;
            if (this.userInfos.Password !== undefined && this.userInfos.Email !== undefined) {
              this.customerService.AddUser(this.userInfos, this.isServiceProvider).subscribe({
                next: () => {
                  this.usersViewState = "view";
                  let myThis = this;
                  this.customerService.GetUsers(this.isServiceProvider).subscribe((data) => {
                    myThis.Users = data;
                    for (let i = 0; i < myThis.Users.length; i++) {
                      for (let j = 0; j < myThis.Domains.length; j++) {
                        if (myThis.Users[i].DomainId === myThis.Domains[j].id) {
                          myThis.Users[i].DomainName = myThis.Domains[j].Name;
                        }
                      }
                    }
                    myThis.notificationComponent.displayNotification("showSuccessResult");
                    //myThis.copyTable.Users = JSON.parse(JSON.stringify(myThis.Users));
                    myThis.copyTable.Users = myThis.Users;
                    myThis.clearDataInfos();
                    myThis.autoComplete = true;
                    myThis.refreshTables();
                  });
                },
                error: (errorData) => {
                  this.notificationComponent.displayNotification("showFailedResult");
                  if (errorData.error) {
                    if (errorData.error.title) {
                      this.error = errorData.error.title + ". ";
                    }
                    if (errorData.error.parameters) {
                      this.error += errorData.error.parameters[0].reason;
                      this.required[errorData.error.parameters[0].name] = true;
                    }
                  }
                },
              });
              break;
            }
          }
        } else {
          // DOMAIN ADMIN
          if (this.end_user_profiles[i].Name !== "Anonymous") {
            this.userInfos.ProfileId = this.end_user_profiles[i].id;
            if (this.userInfos.Password !== undefined && this.userInfos.Email !== undefined) {
              this.customerService.AddUser(this.userInfos, this.isServiceProvider).subscribe(() => {
                this.usersViewState = "view";
                this.notificationComponent.displayNotification("showSuccessResult");
                let myThis = this;
                this.customerService.GetUsers(this.isServiceProvider).subscribe({
                  next: (data) => {
                    myThis.Users = data;
                    for (let i = 0; i < myThis.Users.length; i++) {
                      myThis.Users[i].DomainName = myThis.Domains[0].Name;
                    }
                    //myThis.copyTable.Users = JSON.parse(JSON.stringify(myThis.Users));
                    myThis.copyTable.Users = myThis.Users;
                    myThis.clearDataInfos();
                    myThis.autoComplete = true;
                    myThis.refreshTables();
                  },
                  error: (errorData) => {
                    myThis.notificationComponent.displayNotification("showFailedResult");
                    if (errorData.error) {
                      if (errorData.error.title) {
                        this.error = errorData.error.title + ". ";
                      }
                      if (errorData.error.parameters) {
                        this.error += errorData.error.parameters[0].reason;
                        this.required[errorData.error.parameters[0].name] = true;
                      }
                    }
                  },
                });
              });
              break;
            }
          }
        }
      }
    }
  }

  DeleteUser() {
    this.clearError();
    let myThis = this;
    this.customerService.DeleteUser(this.userInfos, this.isServiceProvider).subscribe({
      next: () => {
        myThis.usersViewState = "view";
        for (let i = 0; i < myThis.Users.length; i++) {
          if (myThis.Users[i].id === myThis.userInfos.id) {
            myThis.Users.splice(i, 1);
            break;
          }
        }
        myThis.notificationComponent.displayNotification("showSuccessResult");
        //myThis.copyTable.Users = JSON.parse(JSON.stringify(myThis.Users));
        myThis.copyTable.Users = myThis.Users;
        myThis.clearDataInfos();
        myThis.refreshTables();
      },
      error: (e) => {
        myThis.notificationComponent.displayNotification("showFailedResult");
        if (e.error) {
          if (e.error.title) {
            myThis.error = e.error.title + ". ";
          }
          if (e.error.detail) {
            myThis.error += e.error.detail;
          }
        }
        myThis.refreshTables();
      },
    });
  }

  async AddDomain() {
    this.clearError();
    if (this.domainInfos.subDomain === "") {
      this.domainInfos.Domain = this.fullDomain;
    } else {
      this.domainInfos.Domain = this.domainInfos.subDomain + "." + this.fullDomain;
    }
    let myThis = this;
    this.ChangeColors();
    //BE CAREFUL HERE, ERRORS NOT HANDLED

    let timestamp = new Date().getTime().toString();
    let name = "cl-" + timestamp + "." + this.imageFileName.split(".")[1];
    this.customerService.importImage(this.blobImage, name).subscribe({
      next: async (success) => {
        this.domainInfos.CustomData.LogoURI = success.path;
        this.customerService.AddDomain(this.domainInfos, this.isServiceProvider).subscribe({
          next: (success: any) => {
            myThis.domainsViewState = "view";
            myThis.Domains = undefined;
            myThis.notificationComponent.displayNotification("showSuccessResult");
            myThis.customerService.GetDomains(this.isServiceProvider).subscribe((data) => {
              myThis.Domains = data;
              for (let j = 0; j < myThis.Domains.length; j++) {
                if (myThis.Domains[j].Name === "Default") {
                  myThis.Domains.splice(j, 1);
                  break;
                }
              }
              for (let i = 0; i < myThis.Domains.length; i++) {
                if (myThis.Domains[i].subDomain === undefined) {
                  let urlParsed = myThis.Domains[i].Domain.split(".");
                  if (urlParsed.length === 3) {
                    myThis.domainInfos.subDomain = urlParsed[0];
                  } else {
                    myThis.domainInfos.subDomain = "";
                  }
                }
                if (myThis.Domains[i].Name === myThis.domainInfos.Name && myThis.Domains[i].Domain === myThis.domainInfos.Domain) {
                  myThis.domainFilter = myThis.Domains[i].id;
                }
              }
              //myThis.copyTable.Domains = JSON.parse(JSON.stringify(myThis.Domains));
              myThis.copyTable.Domains = myThis.Domains;
              myThis.clearDataInfos();
              myThis.autoComplete = true;
              myThis.refreshTables();
            });
          },
          error: (e: any) => {
            myThis.notificationComponent.displayNotification("showFailedResult");
            if (e.error) {
              if (e.error.title) {
                this.error = e.error.title + ". ";
              }
              if (e.error.parameters) {
                this.error += e.error.parameters[0].reason;
                this.required[e.error.parameters[0].name] = true;
              }
            }
            this.autoComplete = true;
            this.refreshTables();
          },
        });
      },
      error: (e) => {
        if (e.error) {
          if (e.error.title) {
            this.error = e.error.title + ". ";
          }
          if (e.error.parameters) {
            this.error += e.error.parameters[0].reason;
            this.required[e.error.parameters[0].name] = true;
          }
        }
      },
    });
  }

  async EditDomain() {
    this.clearError();
    if (this.domainInfos.subDomain === "") {
      this.domainInfos.Domain = this.fullDomain;
    } else {
      this.domainInfos.Domain = this.domainInfos.subDomain + "." + this.fullDomain;
    }
    let myThis = this;
    this.ChangeColors();

    let timestamp = new Date().getTime().toString();
    let name = "cl-" + timestamp + "." + this.imageFileName.split(".")[1];
    this.customerService.importImage(this.blobImage, name).subscribe({
      next: async (success) => {
        this.domainInfos.CustomData.LogoURI = success.path;
        this.customerService.EditDomain(this.domainInfos, this.isServiceProvider).subscribe({
          next: () => {
            for (let i = 0; i < myThis.Domains.length; i++) {
              if (myThis.Domains[i].id === myThis.domainInfos.id) {
                let urlParsed = myThis.Domains[i].Domain.split(".");
                if (urlParsed.length === 3) {
                  myThis.domainInfos.subDomain = urlParsed[0];
                } else {
                  myThis.domainInfos.subDomain = "";
                }
                myThis.Domains[i] = myThis.domainInfos;
                break;
              }
            }
            myThis.notificationComponent.displayNotification("showSuccessResult");
            myThis.domainsViewState = "view";
            myThis.refreshTables();
          },
          error: (response) => {
            myThis.notificationComponent.displayNotification("showFailedResult");
            myThis.Error = true;
            if (response.error) {
              if (response.error.title) {
                myThis.error = response.error.title + ". ";
              }
              if (response.error.parameters) {
                myThis.error += response.error.parameters[0].reason;
                myThis.required[response.error.parameters[0].name] = true;
              }
            }
            myThis.refreshTables();
          },
        });
      },
      error: (e) => {
        if (e.error) {
          if (e.error.title) {
            this.error = e.error.title + ". ";
          }
          if (e.error.parameters) {
            this.error += e.error.parameters[0].reason;
            this.required[e.error.parameters[0].name] = true;
          }
        }

        this.customerService.EditDomain(this.domainInfos, this.isServiceProvider).subscribe({
          next: () => {
            for (let i = 0; i < myThis.Domains.length; i++) {
              if (myThis.Domains[i].id === myThis.domainInfos.id) {
                let urlParsed = myThis.Domains[i].Domain.split(".");
                if (urlParsed.length === 3) {
                  myThis.domainInfos.subDomain = urlParsed[0];
                } else {
                  myThis.domainInfos.subDomain = "";
                }
                myThis.Domains[i] = myThis.domainInfos;
                break;
              }
            }
            myThis.notificationComponent.displayNotification("showSuccessResult");
            myThis.domainsViewState = "view";
            myThis.refreshTables();
          },
          error: (response) => {
            myThis.notificationComponent.displayNotification("showFailedResult");
            myThis.Error = true;
            if (response.error) {
              if (response.error.title) {
                myThis.error = response.error.title + ". ";
              }
              if (response.error.parameters) {
                myThis.error += response.error.parameters[0].reason;
                myThis.required[response.error.parameters[0].name] = true;
              }
            }
            myThis.refreshTables();
          },
        });
      },
    });
  }

  /*async DeleteDomain() {
        this.clearError();
        let response: any = await this.customerService.DeleteDomain(this.domainInfos, true)
        if (response.error) {
            if(response.error.title) {
                this.error = response.error.title + '. '
            } 
            if(response.error.detail) {
                this.error += response.error.detail;
            } 
        } else {
            this.domainsViewState = 'view';
            this.Domains = await this.customerService.GetDomains(true)
            this.copyTable.Domains = JSON.parse(JSON.stringify(this.Domains));
            this.clearDataInfos()
        }
        await this.refreshTables()
    }*/

  CloseConfirmDeleteModal() {
    this.bModalConfirmDelete = false;
  }

  DeleteItem() {
    switch (this.bDisplay) {
      /*case "domains": 
                this.DeleteDomain()
                break;*/
      case "users":
        this.DeleteUser();
        break;
      case "rooms":
        this.DeleteRoom();
        break;
    }
    this.bModalConfirmDelete = false;
  }

  matchProfileIdCompany() {
    for (let i = 0; i < this.end_user_profiles.length; i++) {
      if (this.end_user_profiles[i].DomainId === this.userInfos.DomainId) {
        if (this.end_user_profiles[i].Name === "Default") {
          this.userInfos.ProfileId = this.end_user_profiles[i].id;
        }
      }
    }
  }

  ChangeDomainColors(color1: string, color2: string) {
    if (this.domainInfos.CustomData === undefined) {
      this.domainInfos.CustomData = {};
    }
    this.domainInfos.CustomData.ColorHomeMain = color1;
    this.domainInfos.CustomData.ColorHomeAlt = color2;
  }

  setHeight() {
    let containerBox = document.getElementById("admin-box-container");
    let windowHeight = window.innerHeight - 80 - 20;
    let style = { height: "auto" };
    if (containerBox.scrollHeight < windowHeight) {
      style.height = windowHeight.toString() + "px";
    } else {
      style = { height: "auto" };
    }
    return style;
  }

  onResize(event: any) {
    event.target.height = event.target.innerHeight - 80 - 20;
  }

  clearDataInfos() {
    this.roomInfos = {
      Enabled: true,
      DomainId: "",
    };
    this.userInfos = {
      Enabled: true,
      Name: "",
      Email: "",
      Password: "",
      DomainId: "",
      Room: "",
      SubscriptionType: "enterprise",
      Administrator: false,
    };
    this.domainInfos = {
      Enabled: true,
      ServiceProvider: false,
      CustomData: {
        Name: "",
        LogoURI: "",
        ImgBackground: "/assets/img/brown-wooden-style.jpg",
        LoginBackground: "/assets/img/brown-wooden-style.jpg",
        ColorHomeMain: "#343640",
        ColorHomeAlt: "#c4283d",
        ColorHomeText: "white",
        ColorRoomHeader: "white",
        ColorHomeAltText: "white",
        ColorHomeMainText: "#1f1f1f",
        ImgLogoRoom: "",
      },
      SharedUsers: true,
      MediaProfileId: "1",
      SIPProfileId: "1",
    };
  }

  CreateNewDomain() {
    this.bNewDomain = true;
    this.domainInfos.Name = "";
    this.domainInfos.CustomData.LogoURI = "";
    this.domainInfos.CustomData.ColorHomeMain = "";
    this.domainInfos.CustomData.ColorHomeAlt = "";
    this.domainInfos.CustomData.ColorHomeText = "";
    this.domainInfos.CustomData.ColorRoomHeader = "";
    this.domainInfos.CustomData.ColorHomeAltText = "";
    this.domainInfos.CustomData.ColorHomeMainText = "";
    this.domainInfos.CustomData.ImgLogoRoom = "";
    this.domainInfos.CustomData.FriendlyName = "";
    this.domainInfos.CustomData.SharedUsers = true;
  }

  CreateEditView(bEdit: boolean, bPrivate: boolean) {
    if (bEdit) {
      this.bEditPrivate = bPrivate;
    } else {
      this.bNewPrivate = bPrivate;
    }
    this.bNewEditView = true;
    this.bEdit = bEdit;
  }

  Cancel() {
    /*
            this.inProgress = false
            this.bNewDomain = false
            this.editUserId = -1
            this.ClearValues()
        */
    this.error = "";
    this.required = {};
    this.validEmail = false;

    //this.Rooms = JSON.parse(JSON.stringify(this.copyTable.Rooms));
    //this.Users = JSON.parse(JSON.stringify(this.copyTable.Users));
    //this.Domains = JSON.parse(JSON.stringify(this.copyTable.Domains));

    this.Rooms = this.copyTable.Rooms;
    this.Users = this.copyTable.Users;
    this.Domains = this.copyTable.Domains;

    this.roomsViewState = "view";
    this.usersViewState = "view";
    this.domainsViewState = "view";

    this.clearDataInfos();
  }

  Delete() {}

  ClearValues() {
    this.bNewEditView = false;
    this.privateRooms = [];
    this.sharedRooms = [];
  }
}

async function sleep(duration: number): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      resolve(undefined);
    }, duration);
  });
}
