import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AuthService } from "../../services/auth.service";
//import { ApiService } from '../../services/api.service';

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  state = "init";
  mobileDevice = false;
  mobileDeviceIsAndroid = false;

  constructor(private auth: AuthService) {
    //this.ApiService.subscribe('sampleEventName', this.angularLocalFunction)
  }

  angularLocalFunction() {
    console.log("---------Angular function called with API");
  }

  ngOnInit(): void {
    this.state = "loading";
    var pathname = window.location.pathname.split("/");
    if (pathname.length > 2) {
      if (pathname[1] === "send-sms") {
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          this.mobileDevice = true;
          this.mobileDeviceIsAndroid = false;
        } else if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) {
          this.mobileDevice = true;
          this.mobileDeviceIsAndroid = true;
        } else {
          this.mobileDevice = false;
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (urlParams.get("invitefrom")) {
          let inviteFrom = urlParams.get("invitefrom");
          let upperCaseName = inviteFrom.charAt(0).toUpperCase() + inviteFrom.slice(1);
          if (urlParams.get("room")) {
            let urlParamRoom = urlParams.get("room");
            let urlParamContact = undefined;
            if (urlParams.get("toContact")) {
              urlParamContact = urlParams.get("toContact");
            }
            let roomUrl = location.protocol + "//" + location.host + "/" + urlParamRoom;
            let messageBody = encodeURI(
              "Bonjour,\r\n" + upperCaseName + " vous invite à le rejoindre sur: " + roomUrl + "\r\nA tout de suite !"
            );
            if (this.mobileDeviceIsAndroid) {
              if (urlParamContact === undefined) {
                document.location.href = "sms:?body=" + messageBody;
              } else {
                document.location.href = "sms:" + urlParamContact + "?body=" + messageBody;
              }
            } else {
              if (urlParamContact === undefined) {
                document.location.href = "sms:///&body=" + messageBody;
              } else {
                document.location.href = "sms:/" + urlParamContact + "//&body=" + messageBody;
              }
            }
          }
        }
      } else if (pathname[1] === ".well-known") {
        if (pathname[2] === "change-password") {
          this.state = "pwd-reset";
        }
      } else if (pathname[1] === "password-reset") {
        this.state = "pwd-reset";
      } else if (pathname[1] === "verify-account") {
        this.state = "verify-account";
      } else if (pathname.some((str) => str.includes("register"))) {
        //else if (pathname[1] === 'register') {
        let currentHostname = window.location.hostname;
        if (currentHostname === "vizulive.com" || currentHostname === "vizu.live" || currentHostname === "devngmedia.live") {
          this.state = "register";
          window.history.replaceState({}, "", `/register`);
        } else {
          window.location.href = "https://vizu.live/register";
        }
      } else if (pathname[1] === "password-reset-request") {
        this.state = "pwd-reset-request";
      } else if (pathname[1] !== "login") {
        this.state = "public";
      }
    } else {
      this.auth.UnauthenticatedSig.subscribe(() => {
        this.state = "login";
      });

      this.state = "login";
    }
    /*if (this.auth.LoggedIn()) {
            this.state = 'login'
        } else {
            window.location.assign('/home')
        }*/
  }

  onAuthenticated(): void {
    this.state = "authenticated";
  }
}
