<div class="is-offline presence-state mr-3" *ngIf="!bMessageView && Contact.State !== undefined && Contact.State === 'closed'">
  <div class="is-vizu-look space-element">
    <div class="enter-button-out-offline">
      <div class="enter-button-in">
        <span class="enter-button-offline"> </span>
      </div>
    </div>
  </div>
</div>

<div
  class="is-busy presence-state mr-3"
  *ngIf="!bMessageView && Contact.State !== undefined && Contact.State === 'busy' && Contact.data.InCall !== undefined"
>
  <div class="is-vizu-look space-element">
    <div class="enter-button-out">
      <div class="enter-button-in">
        <span class="enter-button"> </span>
      </div>
    </div>
  </div>
  <b class="classic-text margin-left-5" style="position: absolute">{{ Contact.data.InCall }}</b>
</div>

<div
  class="is-online presence-state mr-3"
  *ngIf="!bMessageView && Contact.State !== undefined && Contact.State === 'opened' && Contact.data.InCall === undefined"
>
  <div class="is-vizu-look space-element">
    <div class="enter-button-out-online">
      <div class="enter-button-in">
        <span class="enter-button-online"> </span>
      </div>
    </div>
  </div>
</div>

<div
  class="is-offline presence-state mr-3"
  *ngIf="bMessageView && DomainData.SharedUsers && Contact.Type === 'User' && Contact.State !== undefined && Contact.State === 'closed'"
>
  <div class="is-vizu-look space-element">
    <div class="enter-button-out-offline">
      <div class="enter-button-in">
        <span class="enter-button-offline"> </span>
      </div>
    </div>
  </div>
</div>

<div
  class="is-busy presence-state mr-3"
  *ngIf="
    bMessageView &&
    DomainData.SharedUsers &&
      Contact.Type === 'User' &&
      Contact.State !== undefined &&
      Contact.State === 'busy' &&
      Contact.data.InCall !== undefined
  "
>
  <div class="is-vizu-look space-element">
    <div class="enter-button-out">
      <div class="enter-button-in">
        <span class="enter-button"> </span>
      </div>
    </div>
  </div>
</div>

<div
  class="is-online presence-state mr-3"
  *ngIf="
    bMessageView && DomainData.SharedUsers && Contact.State !== undefined && Contact.State === 'opened' && Contact.data.InCall === undefined
  "
>
  <div class="is-vizu-look space-element">
    <div class="enter-button-out-online">
      <div class="enter-button-in">
        <span class="enter-button-online"> </span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="bMessageView && DomainData.SharedUsers && Contact.Type === 'Anonymous'" class="mr-3" style="width: 22px; height: 22px"></div>
