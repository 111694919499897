<app-vizu-header [Public]="false" [Room]="Customer.Room">
  <div class="header-right">
    <app-button-feedback></app-button-feedback>
    <div *ngIf="bAccountConfig" class="is-right is-hoverable">
      <button class="button is-danger aligned-top" (click)="sendClickInfoMessage('CloseAccountConfig'); Config()">
        <span>Fermer</span>
      </button>
    </div>
    <div *ngIf="bAdminConfig" class="is-right is-hoverable">
      <button class="button is-danger aligned-top" (click)="sendClickInfoMessage('CloseAdminConfig'); Administrate()">
        <span>Fermer</span>
      </button>
    </div>
    <div *ngIf="!bAccountConfig && !bAdminConfig" class="dropdown is-hoverable">
      <!--<i class="fas fa-user-cog fa-4x"></i>-->
      <div class="dropdown-trigger" *ngIf="Customer !== undefined">
        <!--<button class="button aligned-top">
                    <span>Menu</span>
                    <span class="icon is-small">
                        <i class="fas fa-angle-down"></i>
                    </span>
                </button>-->
        <!--<i class="fas fa-webcam fa-4x"></i>-->
        <img
          src="{{ Customer.LogoURI }}"
          *ngIf="Customer.LogoURI != undefined && Customer.LogoURI != ''"
          height="80"
          width="80"
          style="border-radius: 18px; padding: 9px"
        />
        <svg width="80" height="80" *ngIf="Customer.LogoURI == undefined || Customer.LogoURI == ''">
          <image xlink:href="/assets/icons/profile-default.svg" width="80" height="80" />
        </svg>
      </div>
      <div class="dropdown-menu" id="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <a (click)="Config()" class="dropdown-item">
            {{ "shell.menu.account_config" | translate }}
          </a>
          <a *ngIf="!bAdminConfig && IsAdmin" (click)="sendClickInfoMessage('OpenAdminConfig'); Administrate()" class="dropdown-item">
            Admin
          </a>
          <a (click)="sendClickInfoMessage('Logout'); Logout()" class="dropdown-item">
            {{ "shell.disconnected.title" | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <audio id="welcome_audio" autoplay>
    <source src="/assets/welcome.m4a" type="audio/x-m4a" />
  </audio>
</app-vizu-header>

<app-vizu-audio-perm
  *ngIf="isAudioPermAllowed === false"
  (click)="sendClickInfoMessage('AudioPermissionsEnabled'); modifyAudioPerm()"
></app-vizu-audio-perm>

<app-loading-screen *ngIf="DomainData == undefined">
  <ng-container *ngIf="rtc.bWebsocketReconnecting">
    <p class="text-custom-color">{{ "common.link_failure1" | translate }}</p>
    <p class="text-custom-color">{{ "common.link_failure2" | translate }}</p>
    <br />
  </ng-container>
</app-loading-screen>
<div *ngIf="DomainData !== undefined" class="image-background" [ngStyle]="setImageBackground()" style="overflow: auto">
  <div class="opacity-background" [ngStyle]="setDomainMainColor()"></div>

  <div *ngIf="bAccountConfig">
    <app-vizu-account-config [errorMonitor]="errorMonitor" [userInfos]="Customer" class="full-config-height"></app-vizu-account-config>
  </div>
  <!--[SubscriptionType]="this.Customer.SubscriptionType"-->

  <div *ngIf="bAdminConfig">
    <app-vizu-admin-config
      [AdminCompany]="DomainData.CustomData.FriendlyName"
      [isServiceProvider]="isServiceProvider"
      [adminUserInfos]="Customer"
      class="full-config-height"
    ></app-vizu-admin-config>
  </div>

  <div *ngIf="!bAccountConfig && !bAdminConfig" class="layout-background is-hidden-touch" style="height: 100%">
    <div class="layout-content waiting-content">
      <div class="layout-main waiting-main">
        <div class="layout-center mb-0" style="max-width: 50%">
          <app-self-room
            [Customer]="Customer"
            [Attendees]="Attendees"
            [Limitations]="Limitations"
            [PrivateRoom]="PrivateRoom"
            [mobileDevice]="mobileDevice"
            [bTelephonyMode]="bTelephonyMode"
            [sendClickInfoMessage]="sendClickInfoMessage.bind(this)"
            [CopyLink]="CopyLink.bind(this)"
            [displayQrCodeLink]="displayQrCodeLink.bind(this)"
            [displayQrCodeSms]="displayQrCodeSms.bind(this)"
            [EnterOwnRoom]="EnterOwnRoom.bind(this)"
          ></app-self-room>

          <app-shared-room
            [Customer]="Customer"
            [Rooms]="Rooms"
            [Limitations]="Limitations"
            [bAdminConfig]="bAdminConfig"
            [bAccountConfig]="bAccountConfig"
            [sendClickInfoMessage]="sendClickInfoMessage.bind(this)"
            [CopyLink]="CopyLink.bind(this)"
            [displayQrCodeLink]="displayQrCodeLink.bind(this)"
            [displayQrCodeSms]="displayQrCodeSms.bind(this)"
            [EnterOwnRoom]="EnterOwnRoom.bind(this)"
          ></app-shared-room>
        </div>
        <div class="layout-center">
          <div class="panel" style="position: relative; padding: 0; display: grid; max-height: calc(100% - 15px)">
            <div class="tabs is-boxed m-0" style="border-bottom: solid 1px #afdbdb">
              <ul style="border: unset">
                <li>
                  <a
                    (click)="GlobalMenuClicked('contacts')"
                    class="global-menu"
                    [style.background-color]="GlobalMenu.Contacts == true ? '#AFDBDB' : ''"
                  >
                    <span class="icon m-0" style="place-self: center">
                      <svg width="60" height="60" class="pointer">
                        <image xlink:href="/assets/icons/group.svg" width="60" height="60" />
                      </svg>
                    </span>
                    <span class="subtitle is-7 has-text-centered mt-2">équipe</span>
                  </a>
                </li>
                <li>
                  <a
                    (click)="GlobalMenuClicked('messages')"
                    class="global-menu"
                    [style.background-color]="GlobalMenu.Messages == true ? '#AFDBDB' : ''"
                  >
                    <div
                      *ngIf="getTotalUnreadMessages() > 0"
                      class="conf-icon-wrapper contact-messages-count-circle"
                      style="position: absolute; right: 30px; top: 0"
                    >
                      <div class="conf-participant-count">
                        {{ getTotalUnreadMessages() }}
                      </div>
                    </div>
                    <span class="icon m-0" style="place-self: center">
                      <svg width="60" height="60" class="pointer">
                        <image xlink:href="/assets/icons/email.svg" width="60" height="60" />
                      </svg>
                    </span>
                    <span class="subtitle is-7 has-text-centered mt-2">messages</span>
                  </a>
                </li>
              </ul>
            </div>

            <div
              *ngIf="(GlobalMenu.Messages && messagesRecepient == '') || (Limitations?.TeamPanelHidden === false && GlobalMenu.Contacts)"
              class="search-bar-container"
            >
              <div class="control top-container">
                <input
                  type="text"
                  name="SearchBar"
                  [(ngModel)]="SearchField"
                  class="input input-text-bar"
                  placeholder="Rechercher un nom..."
                />
              </div>
            </div>

            <app-contacts
              [ngStyle]="GlobalMenu.Contacts == true ? { display: 'contents' } : { display: 'none' }"
              [Customer]="Customer"
              [Limitations]="Limitations"
              [messagesRecepient]="messagesRecepient"
              [DomainData]="DomainData"
              [Contacts]="Contacts"
              [SearchField]="SearchField"
              [setUpgradeModal]="setUpgradeModal.bind(this)"
              [Contacts_and_Anonymous]="Contacts_and_Anonymous.bind(this)"
              [sendClickInfoMessage]="sendClickInfoMessage.bind(this)"
              [displayMessages]="displayMessages.bind(this)"
              [CopyLink]="CopyLink.bind(this)"
              [EnterOtherRoom]="EnterOtherRoom.bind(this)"
            ></app-contacts>

            <app-messages
              [ngStyle]="GlobalMenu.Messages == true ? { display: 'contents' } : { display: 'none' }"
              [Customer]="Customer"
              [Limitations]="Limitations"
              [messagesRecepient]="messagesRecepient"
              [isAnonymeDisplay]="isAnonymeDisplay"
              [DomainData]="DomainData"
              [messagesDisplayed]="messagesDisplayed"
              [messagesTo]="messagesTo"
              [SearchField]="SearchField"
              [lastMessage]="lastMessage"
              [Contacts_and_Anonymous]="Contacts_and_Anonymous.bind(this)"
              [sendClickInfoMessage]="sendClickInfoMessage.bind(this)"
              [displayMessages]="displayMessages.bind(this)"
              [deleteChatMessages]="deleteChatMessages.bind(this)"
              [backMessages]="backMessages.bind(this)"
              [deleteChatMessage]="deleteChatMessage.bind(this)"
            ></app-messages>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="header-container is-hidden-desktop" *ngIf="!bAccountConfig && !bAdminConfig">
    <svg width="60" height="60" (click)="sendClickInfoMessage('HomeMenu-Touch'); GlobalMenuClicked('home')" class="pointer">
      <image *ngIf="GlobalMenu.Home" xlink:href="/assets/icons/home.svg" width="60" height="60" />
      <image *ngIf="!GlobalMenu.Home" xlink:href="/assets/icons/home-unfill.svg" width="60" height="60" />
    </svg>
    <div class="conf-icon-wrapper flex-column-container">
      <svg width="60" height="60" (click)="sendClickInfoMessage('RoomsMenu-Touch'); GlobalMenuClicked('rooms')" class="pointer">
        <image *ngIf="GlobalMenu.Rooms" xlink:href="/assets/icons/users-full.svg" width="60" height="60" />
        <image *ngIf="!GlobalMenu.Rooms" xlink:href="/assets/icons/users-empty.svg" width="60" height="60" />
      </svg>
      <!--<span *ngIf="bAttendeesWaiting" class="enter-button-out stack-notif-to-menu"></span>-->
    </div>
    <svg width="60" height="60" (click)="sendClickInfoMessage('ContactsMenu-Touch'); GlobalMenuClicked('contacts')" class="pointer">
      <image *ngIf="GlobalMenu.Contacts" xlink:href="/assets/icons/profile-full.svg" width="60" height="60" />
      <image *ngIf="!GlobalMenu.Contacts" xlink:href="/assets/icons/profile.svg" width="60" height="60" />
    </svg>

    <svg width="60" height="60" (click)="sendClickInfoMessage('MessagesMenu-Touch'); GlobalMenuClicked('messages')" class="pointer">
      <image *ngIf="GlobalMenu.Messages" xlink:href="/assets/icons/email-full.svg" width="60" height="60" />
      <image *ngIf="!GlobalMenu.Messages" xlink:href="/assets/icons/email.svg" width="60" height="60" />
    </svg>
  </div>

  <div class="main-space is-hidden-desktop" *ngIf="!bAccountConfig && !bAdminConfig">
    <app-self-room
      *ngIf="!bAccountConfig && !bAdminConfig && GlobalMenu.Home"
      [Customer]="Customer"
      [Attendees]="Attendees"
      [Limitations]="Limitations"
      [PrivateRoom]="PrivateRoom"
      [mobileDevice]="mobileDevice"
      [bTelephonyMode]="bTelephonyMode"
      [sendClickInfoMessage]="sendClickInfoMessage.bind(this)"
      [CopyLink]="CopyLink.bind(this)"
      [displayQrCodeLink]="displayQrCodeLink.bind(this)"
      [displayQrCodeSms]="displayQrCodeSms.bind(this)"
      [EnterOwnRoom]="EnterOwnRoom.bind(this)"
      style="margin-top: 10px"
    ></app-self-room>

    <app-shared-room
      *ngIf="!Limitations?.RoomPanelHidden && !bAccountConfig && !bAdminConfig && GlobalMenu.Rooms"
      [Customer]="Customer"
      [Rooms]="Rooms"
      [Limitations]="Limitations"
      [bAdminConfig]="bAdminConfig"
      [bAccountConfig]="bAccountConfig"
      [sendClickInfoMessage]="sendClickInfoMessage.bind(this)"
      [CopyLink]="CopyLink.bind(this)"
      [displayQrCodeLink]="displayQrCodeLink.bind(this)"
      [displayQrCodeSms]="displayQrCodeSms.bind(this)"
      [EnterOwnRoom]="EnterOwnRoom.bind(this)"
      style="margin-top: 10px; max-width: 100%; overflow-y: auto"
    ></app-shared-room>

    <div class="contacts-container" *ngIf="!bAccountConfig && !bAdminConfig && (GlobalMenu.Contacts || GlobalMenu.Messages)">
      <!--<div class="contacts-title">
                {{ 'shell.room.collaborators' | translate }}
            </div>-->
      <div class="conf-card-content" [class.messages-box]="GlobalMenu.Messages && messagesRecepient != ''">
        <div
          *ngIf="(GlobalMenu.Messages && messagesRecepient == '') || (Limitations?.TeamPanelHidden === false && GlobalMenu.Contacts)"
          class="search-bar-container"
        >
          <div class="control top-container">
            <input type="text" name="SearchBar" [(ngModel)]="SearchField" class="input input-text-bar" placeholder="Rechercher un nom..." />
          </div>
        </div>

        <app-contacts
          [ngStyle]="GlobalMenu.Contacts == true ? { display: 'contents' } : { display: 'none' }"
          [Customer]="Customer"
          [Limitations]="Limitations"
          [messagesRecepient]="messagesRecepient"
          [DomainData]="DomainData"
          [Contacts]="Contacts"
          [SearchField]="SearchField"
          [setUpgradeModal]="setUpgradeModal.bind(this)"
          [Contacts_and_Anonymous]="Contacts_and_Anonymous.bind(this)"
          [sendClickInfoMessage]="sendClickInfoMessage.bind(this)"
          [displayMessages]="displayMessages.bind(this)"
          [CopyLink]="CopyLink.bind(this)"
          [EnterOtherRoom]="EnterOtherRoom.bind(this)"
        ></app-contacts>

        <app-messages
          [ngStyle]="GlobalMenu.Messages == true ? { display: 'contents' } : { display: 'none' }"
          [Customer]="Customer"
          [Limitations]="Limitations"
          [messagesRecepient]="messagesRecepient"
          [isAnonymeDisplay]="isAnonymeDisplay"
          [DomainData]="DomainData"
          [messagesDisplayed]="messagesDisplayed"
          [messagesTo]="messagesTo"
          [SearchField]="SearchField"
          [lastMessage]="lastMessage"
          [Contacts_and_Anonymous]="Contacts_and_Anonymous.bind(this)"
          [sendClickInfoMessage]="sendClickInfoMessage.bind(this)"
          [displayMessages]="displayMessages.bind(this)"
          [deleteChatMessages]="deleteChatMessages.bind(this)"
          [backMessages]="backMessages.bind(this)"
          [deleteChatMessage]="deleteChatMessage.bind(this)"
        ></app-messages>
      </div>
    </div>
    <div *ngIf="bWebsocketReconnecting" class="offline-notification">
      <p class="text-custom-color">{{ "common.link_failure1" | translate }}</p>
      <p class="text-custom-color">{{ "common.link_failure2" | translate }}</p>
      <br />
    </div>
  </div>
  <app-vizu-footer></app-vizu-footer>

  <div *ngIf="EmailNotification" class="notification email-notif">
    <button (click)="CloseEmailNotification()" class="delete"></button>
    Un email d'invitation a été envoyé à votre correspondant.
  </div>

  <app-notification #notificationComponent></app-notification>

  <!--<div [class.is-active]="bSelectDevicesActive" class="modal">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="box">
            <div class="step-text is-hidden-touch">
                Un dernier coup d'oeil dans le miroir ?
            </div>

            <div class="step-title is-hidden-desktop" *ngIf="DomainData != undefined">
                {{ 'public_room.welcome' | translate }}{{ DomainData.CustomData.FriendlyName }}
            </div>
            
            <div class="landscape-mobile">
                <div class="panel-size smaller-preview">
                    
                    <video id="{{LocalStream.id}}" *ngIf="LocalStream != undefined" [appNgmediaVideo]="LocalStream" playsinline autoplay [muted]="true" class="video-preview video-view-participant" [ngStyle]="setDomainMainColor()"></video>
                    <video *ngIf="LocalStream == undefined" [muted]="true" class="video-preview video-view-participant" [ngStyle]="setDomainMainColor()"></video>
                    
                    <div class="control-bar">
                                    
                        <div>
                            <progress class="progress-bar" [value]="meterValue" max="1">{{meterValue}}%</progress>
                            <svg width="60" height="60" class="is-hidden-touch control-item relative-item pointer" *ngIf="LocalStreamAudio" (click)="sendClickInfoMessage('BeforeCall-ToggleMic-Desktop'); ToggleMic()">
                                <image xlink:href="/assets/icons/micro-dark.svg" width="60" height="60" *ngIf="LocalStreamAudioEnabled"/>
                                <image xlink:href="/assets/icons/micro-slash.svg" width="60" height="60" *ngIf="!LocalStreamAudioEnabled"/>
                            </svg>
                        </div>
                        <svg width="60" height="60" class="is-hidden-touch control-item pointer" *ngIf="LocalStreamVideo" (click)="sendClickInfoMessage('BeforeCall-ToggleVid-Desktop'); ToggleVid()">
                            <image xlink:href="/assets/icons/video-dark.svg" width="60" height="60" *ngIf="LocalStreamVideoEnabled"/>
                            <image xlink:href="/assets/icons/video-slash.svg" width="60" height="60" *ngIf="!LocalStreamVideoEnabled"/>
                        </svg>
                        <svg width="40" height="40" class="is-hidden-desktop control-item pointer" *ngIf="LocalStreamAudio" (click)="sendClickInfoMessage('BeforeCall-ToggleMic-Touch'); ToggleMic()">
                            <image xlink:href="/assets/icons/micro-dark.svg" width="40" height="40" *ngIf="LocalStreamAudioEnabled"/>
                            <image xlink:href="/assets/icons/micro-slash.svg" width="40" height="40" *ngIf="!LocalStreamAudioEnabled"/>
                        </svg>
                        <svg width="40" height="40" class="is-hidden-desktop control-item pointer" *ngIf="LocalStreamVideo" (click)="sendClickInfoMessage('BeforeCall-ToggleVid-Touch'); ToggleVid()">
                            <image xlink:href="/assets/icons/video-dark.svg" width="40" height="40" *ngIf="LocalStreamVideoEnabled"/>
                            <image xlink:href="/assets/icons/video-slash.svg" width="40" height="40" *ngIf="!LocalStreamVideoEnabled"/>
                        </svg>
                    </div>
                </div>
            </div>
            
            <div>
                <div class="field is-horizontal">
                    <div class="label-media">
                        <label class="label">
                            Audio:
                        </label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <select class="select video-view-participant"(change)="updateAudioDevice($event.target.value)">
                                    <option *ngFor="let audioDevice of audioDevices; index as i" value="{{i}}" [selected]="(i == audioDeviceSelected ? true : false)">{{audioDevice.label}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="label-media">
                        <label class="label">
                            Video:
                        </label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <select class="select video-view-participant" (change)="updateVideoDevice($event.target.value)">
                                    <option *ngFor="let videoDevice of videoDevices; index as i" value="{{i}}" [selected]="(i == videoDeviceSelected ? true : false)">{{videoDevice.label}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->

  <div [class.is-active]="IncomingCall" class="modal">
    <div class="modal-background"></div>
    <div class="modal-content fixed-size-desktop">
      <div class="modal-card-head">
        <div class="incoming-call-title has-text-primary">Nouvel Appel</div>
      </div>
      <div class="modal-card-body no-overflow">
        <div class="incoming-call-icon">
          <i class="fas fa-user"></i>
        </div>
        <div *ngIf="waitingGetUserMedia" class="outgoing-call-icon">
          <!--<i class="fas fa-spinner fa-spin"></i>-->
          <app-loader-points></app-loader-points>
        </div>
        <ng-container *ngIf="!waitingGetUserMedia">
          <div *ngFor="let remoteData of remoteDatas; index as i" class="incoming-attendee-container">
            <div class="incoming-call-title has-text-primary">{{ remoteData.Data.Name }}&nbsp;</div>
            <div class="incoming-attendee-buttons">
              <button
                type="button"
                (click)="sendClickInfoMessage('AcceptSingleAttendee'); EnterAndAccept(Customer.Room, remoteData)"
                class="button full-width is-success margin-2"
              >
                <span class="icon is-small">
                  <i class="fas fa-check"></i>
                </span>
              </button>

              <div class="dropdown is-hoverable">
                <button type="button" class="button full-width incoming-call-button-refuse margin-2">
                  <span class="icon is-small">
                    <i class="fas fa-ban"></i>
                  </span>
                </button>
                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                  <div class="dropdown-content position-fixed">
                    <a
                      class="dropdown-item"
                      (click)="sendClickInfoMessage('RejectSpecificCallNoMessage'); RefuseIncomingSpecificCall(remoteData)"
                    >
                      {{ "shell.room.reject_call" | translate }}
                    </a>
                    <a class="dropdown-item" (click)="sendClickInfoMessage('OpenRejectMessage'); DisplayRefuseMessage(remoteData)">
                      {{ "shell.room.reject_with_message" | translate }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="incoming-call-buttons has-text-primary">
            <!--<div *ngIf="!Limitations?.RoomPanelHidden && !bTelephonyMode && remoteDatas.length > 1" (click)="sendClickInfoMessage('AcceptAllAttendees');EnterAndAccept(Customer.Room, remoteDatas)" class="incoming-call-button incoming-call-button-accept margin-2">
                        <i class="fas fa-check incoming-call-button-icon"></i>
                        Accepter tous
                    </div>
                    
                    TODO FAIRE PATIENTER
                    <div (click)="IncomingCall = false" class="incoming-call-button incoming-call-button-wait">
                        <i class="fas fa-hourglass-half incoming-call-button-icon"></i>
                        Faire patienter
                    </div>
                    
                    <div (click)="sendClickInfoMessage('RefuseMultipleAttendees');RefuseIncomingCall()" class="incoming-call-button incoming-call-button-refuse margin-2">
                        <i class="fas fa-ban incoming-call-button-icon"></i>
                        Rejeter tous
                    </div>-->

            <button
              *ngIf="!Limitations?.RoomPanelHidden && !bTelephonyMode && remoteDatas.length > 1"
              class="button is-success"
              (click)="sendClickInfoMessage('AcceptAllAttendees'); EnterAndAccept(Customer.Room, remoteDatas)"
            >
              <span class="icon is-small">
                <i class="fas fa-check"></i>
              </span>
              <span>{{ "shell.room.accept_all" | translate }}</span>
            </button>

            <button
              *ngIf="!Limitations?.RoomPanelHidden && remoteDatas.length > 1"
              class="button is-danger"
              (click)="sendClickInfoMessage('RefuseMultipleAttendees'); RefuseIncomingCall()"
            >
              <span class="icon is-small">
                <i class="fas fa-ban"></i>
              </span>
              <span>{{ "shell.room.reject_all" | translate }}</span>
            </button>

            <button *ngIf="Limitations?.RoomPanelHidden && !bTelephonyMode && remoteDatas.length > 1" class="button is-success" disabled>
              <span class="icon is-small">
                <i class="fas fa-check"></i>
              </span>
              <span>{{ "shell.room.accept_all" | translate }}</span>
            </button>

            <button *ngIf="Limitations?.RoomPanelHidden && remoteDatas.length > 1" class="button is-danger" disabled>
              <span class="icon is-small">
                <i class="fas fa-ban"></i>
              </span>
              <span>{{ "shell.room.reject_all" | translate }}</span>
            </button>
          </div>
        </ng-container>
      </div>
      <div class="modal-card-foot">
        <div *ngIf="refuseMessageShown">
          <div class="control has-icons-right">
            <textarea
              class="textarea refuse-input"
              id="textarea-refuse-message"
              rows="2"
              placeholder="{{ 'shell.room.reject_message_placeholder' | translate }}"
              [(ngModel)]="refuseMessage"
              (ngModelChange)="valueRejectMessageChange()"
              maxlength="140"
            ></textarea>
            <span class="icon is-small is-right" (click)="refuseMessage = ''" style="height: 60px; width: 30px">
              <i class="fas fa-times"></i>
            </span>
          </div>
          <span>{{ refuseMessageRemaining }} / 140</span>
        </div>
        <div *ngIf="refuseMessageShown" class="margin-20">
          <button type="button" class="button is-danger" (click)="RefuseIncomingSpecificCallWithMessage()">
            {{ "shell.room.reject" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div [class.is-active]="deniedPermissionsModal" class="modal">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="modal-card-head">
        <p class="modal-card-title" *ngIf="deniedPermissions.audio && deniedPermissions.video">
          {{ "room.permissions_denied_cam_and_mic_title" | translate }}
        </p>
        <p class="modal-card-title" *ngIf="deniedPermissions.audio && !deniedPermissions.video">
          {{ "room.permissions_denied_mic_title" | translate }}
        </p>
        <p class="modal-card-title" *ngIf="!deniedPermissions.audio && deniedPermissions.video">
          {{ "room.permissions_denied_cam_title" | translate }}
        </p>
        <button class="delete" (click)="clearDeniedPermissionsModal()" aria-label="close"></button>
      </div>
      <div class="modal-card-body">
        <div class="flex-row-container">
          <img src="/assets/denied-permissions.gif" class="shadow-light" width="300px" height="300px" />
          <div class="font-large margin-10">
            <p>
              {{ "room.permissions_denied_content_1" | translate }}
            </p>
            <br />
            <p *ngIf="deniedPermissions.audio && deniedPermissions.video">
              {{ "room.permissions_denied_content_cam_and_mic_2" | translate }}
            </p>
            <p *ngIf="deniedPermissions.audio && !deniedPermissions.video">
              {{ "room.permissions_denied_content_mic_2" | translate }}
            </p>
            <p *ngIf="!deniedPermissions.audio && deniedPermissions.video">
              {{ "room.permissions_denied_content_cam_2" | translate }}
            </p>
            <br />
            <p>
              {{ "room.permissions_denied_content_3" | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="modal-card-foot"></div>
    </div>
  </div>

  <div [class.is-active]="OutgoingCall" class="modal">
    <div class="modal-background"></div>
    <div class="modal-content fixed-size-desktop">
      <div class="modal-card-head">
        <div class="incoming-call-title has-text-primary">
          {{ "shell.room.call_to" | translate }}
          {{ OutgoingCallName }}
        </div>
        <button *ngIf="OutgoingCallRefused" class="delete" (click)="CancelOutgoingCall()" aria-label="close"></button>
      </div>
      <div class="modal-card-body">
        <div *ngIf="!OutgoingCallRefused" class="outgoing-call-icon">
          <!--<i class="fas fa-spinner fa-spin"></i>-->
          <app-loader-points [isDark]="true"></app-loader-points>
        </div>
        <button
          *ngIf="!OutgoingCallRefused"
          type="button"
          (click)="sendClickInfoMessage('CancelOutgoingCall'); sendClickInfoMessage('CancelIncomingCall'); CancelOutgoingCall()"
          class="button outgoing-call-button-refuse"
        >
          <span>{{ "shell.room.call_cancel" | translate }}</span>
          <span class="icon is-small">
            <i class="fas fa-ban"></i>
          </span>
        </button>
        <div class="flex-column-container" *ngIf="OutgoingCallRefusedMessage">
          <span class="font-large">{{ "shell.room.call_reject_message" | translate }}</span>
          <br />
          <b class="font-large">{{ OutgoingCallRefusedMessage }}</b>
        </div>
      </div>
      <div class="modal-card-foot"></div>
    </div>
  </div>

  <div [class.is-active]="bQrCodeSmsModal && qrCodeSafeUrl != undefined" class="modal">
    <div class="modal-background"></div>
    <div class="modal-content">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ "shell.room.qrcode_sms_title" | translate }}
        </p>
        <button class="delete" (click)="clearQrCodeObject()" aria-label="close"></button>
      </header>
      <section class="modal-card-body">
        <div class="flex-row-container">
          <div class="font-large">
            <p>1. {{ "shell.room.qrcode_sms_1" | translate }}</p>
            <br />
            <p>2. {{ "shell.room.qrcode_sms_2" | translate }}</p>
            <br />
            <p>3. {{ "shell.room.qrcode_sms_3" | translate }}</p>
            <br />
            <p>{{ "shell.room.qrcode_sms_number" | translate }}:</p>
            <div class="field has-addons">
              <div class="control">
                <input type="text" class="input" [ngModel]="contactToSms" (ngModelChange)="updateQrCode($event)" />
              </div>
              <div class="control">
                <button class="button tooltip" (click)="pasteContent()" [ngStyle]="setDomainMainColor()">
                  <i class="fas fa-paste is-white-button"></i>
                  <span class="tooltiptext">{{ "shell.room.paste" | translate }}</span>
                </button>
              </div>
            </div>
          </div>
          <img [src]="qrCodeSafeUrl" />
        </div>
      </section>
      <footer class="modal-card-foot">
        <a (click)="sendClickInfoMessage('SensSMSQrCodeModal-Ok'); clearQrCodeObject()" class="button">Ok</a>
      </footer>
    </div>
  </div>

  <div [class.is-active]="bQrCodeLinkModal && qrCodeSafeUrl != undefined" class="modal">
    <div class="modal-background"></div>
    <div class="modal-content">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ "shell.room.qrcode_link_title" | translate }}
        </p>
        <button class="delete" (click)="clearQrCodeObject()" aria-label="close"></button>
      </header>
      <section class="modal-card-body">
        <div class="flex-row-container">
          <div class="font-large margin-10">
            <p>{{ "shell.room.room_link" | translate }}</p>
            <div class="field has-addons">
              <div class="control" *ngIf="tempQrCode !== undefined">
                <input type="text" class="input" value="{{ tempQrCode.title }}" disabled />
              </div>
              <div class="control">
                <button class="button tooltip" (click)="CopyLink(qrCodeRoom)" [ngStyle]="setDomainMainColor()">
                  <i class="fas fa-copy is-white-button"></i>
                  <span class="tooltiptext">{{ "shell.room.copy" | translate }}</span>
                </button>
              </div>
            </div>
          </div>
          <img [src]="qrCodeSafeUrl" />
        </div>
      </section>
      <footer class="modal-card-foot">
        <a (click)="sendClickInfoMessage('SensSMSQrCodeModal-Ok'); clearQrCodeObject()" class="button">Ok</a>
      </footer>
    </div>
  </div>

  <div [class.is-active]="Customer.Room == ''" class="modal">
    <div class="modal-background"></div>
    <div class="modal-content fixed-size-desktop">
      <div class="modal-card-body" style="border-radius: 6px">
        <h4 class="title is-4">Your Room Name</h4>
        <div class="field is-grouped">
          <p class="control is-expanded">
            <input class="input room-input" type="text" placeholder="MyRoom" [(ngModel)]="roomNameEdit" (ngModelChange)="roomUpdateKey()" />
          </p>
          <p class="control">
            <button class="button is-danger" (click)="setUserRoom()">Save</button>
          </p>
        </div>
        <p *ngIf="ErrorUserRoom" class="help is-danger is-active">This name is invalid</p>
      </div>
    </div>
  </div>

  <app-vizu-upgrade-plan
    [CurrentSubscription]="Customer.SubscriptionType"
    [bUpgradeModalOpened]="bUpgradeModal"
    (CloseUpgradeModal)="setUpgradeModal(false)"
  ></app-vizu-upgrade-plan>

  <audio *ngIf="IncomingCall" src="/assets/ringing.m4a" playsinline autoplay loop></audio>
  <audio *ngIf="IncomingRoomCall" src="/assets/ringing.m4a" playsinline autoplay loop></audio>

  <!--<div [class.is-active]="bReadMessage" class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            <div *ngIf="readMessageId != undefined">
                {{ Messages[readMessageId].Title }}
            </div>
        </header>
        <section class="modal-card-body">
            <div *ngIf="readMessageId != undefined">
                {{ Messages[readMessageId].Content }}
            </div>
        </section>
        <footer class="modal-card-foot-space" *ngIf="readMessageId != undefined">
            <a class="button" (click)="sendClickInfoMessage('ReadMessageModal-Cancel'); CloseReadMessage()">Cancel</a>
            <a *ngIf="Messages[readMessageId].isContact == true" class="button" (click)="sendClickInfoMessage('ReadMessageModal-Reply'); OpenWriteMessage(Messages[readMessageId].From)">Reply</a>
            
        </footer>
    </div>
</div>

<div [class.is-active]="bWriteMessage" class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
        <header class="modal-card-head">
            To:
            <input type="text" name="AddNewParticipant" [(ngModel)]="contactToMessage" class="control-item input" list="contacts-list" placeholder="ex: John Doe">
            <datalist id="contacts-list">
                <option *ngFor="let Contact of Contacts_and_Anonymous()" value="{{Contact.To}}"></option>
            </datalist>
        </header>
        <section class="modal-card-body">
            Title:
            <br/>
            <textarea rows="1" cols="80" class="textarea-improved"></textarea>
            Content:
            <br/>
            <textarea rows="6" cols="80" class="textarea-improved"></textarea>
        </section>
        <footer class="modal-card-foot-space">
            <a (click)="sendClickInfoMessage('ReadMessageModal-Cancel'); CloseWriteMessage();" class="button">Cancel</a>
            <a (click)="sendClickInfoMessage('ReadMessageModal-Reply'); CloseWriteMessage();" class="button">Send</a>
        </footer>
    </div>
</div>-->
</div>
