<div class="notification is-success" *ngIf="notifications.showSuccessResult">
  {{ "notification.edit.success" | translate }}
</div>

<div class="notification is-danger" *ngIf="notifications.showFailedResult">
  {{ "notification.edit.failed" | translate }}
</div>

<div class="notification is-black" *ngIf="notifications.copyLink">
  {{ "notification.copy_link" | translate }}
</div>

<div class="notification is-black" *ngIf="notifications.showSendFilePending">
  {{ "notification.send_file_pending" | translate }}
</div>

<div class="notification is-success" *ngIf="notifications.feedbackSend">
  {{ "notification.feedback" | translate }}
</div>

<div class="notification is-success" *ngIf="notifications.messageSend">
  {{ "notification.message" | translate }}
</div>
