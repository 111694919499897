<h1 class="oranienbaum-regular has-text-left is-size-2-fullhd is-size-3-desktop is-size-4-touch mb-4 text-custom-color">
  {{ "shell.room.welcolme" | translate }}{{ Customer.Name }}
</h1>

<div class="panel mb-4" style="max-width: 500px">
  <h1 class="title has-text-left poppins-semibold is-size-5-fullhd is-size-6" [class.mb-0]="Limitations?.TeamPanelHidden">
    {{ "shell.room.room_of" | translate }}{{ Customer.Name }}
  </h1>
  <p
    *ngIf="Limitations?.TeamPanelHidden && Customer.SubscriptionType == 'free'"
    class="subtitle has-text-left is-6 text-light"
    style="display: contents"
  >
    {{ "free_version" | translate }}
  </p>
  <p
    *ngIf="Limitations?.TeamPanelHidden && Customer.SubscriptionType == 'pro'"
    class="subtitle has-text-left is-6 text-light"
    style="display: contents"
  >
    {{ "pro_version" | translate }}
  </p>

  <div class="own-room">
    <div class="conf-icon-wrapper" *ngIf="Customer !== undefined">
      <!--<app-people-icon [On]="Attendees.length > 0"></app-people-icon>-->
      <img src="{{ Customer.LogoURI }}" *ngIf="Customer.LogoURI != undefined && Customer.LogoURI != ''" class="own-room-img" />
      <svg width="80" height="80" *ngIf="Customer.LogoURI == undefined || Customer.LogoURI == ''">
        <image xlink:href="/assets/icons/profile-default.svg" width="80" height="80" />
      </svg>
      <div *ngIf="Attendees.length > 0" class="conf-participant-count-circle">
        <div class="conf-participant-count">{{ Attendees.length }}</div>
      </div>
    </div>

    <input *ngIf="Customer !== undefined" type="text" id="{{ Customer.Room }}" class="link-content" />
    <div class="flex-row-container">
      <button *ngIf="Customer !== undefined" class="button is-medium is-blue tooltip">
        <span class="icon">
          <svg width="40" height="40" class="pointer svg-white" (click)="sendClickInfoMessage('CopyLinkPrivate'); CopyLink(Customer.Room)">
            <image xlink:href="/assets/icons/copy.svg" width="40" height="40" />
          </svg>
          <span class="tooltiptext">{{ "shell.room.copy_link" | translate }}</span>
        </span>
      </button>

      <div
        *ngIf="Customer !== undefined && !mobileDevice"
        id="{{ Customer.Room }}-invite"
        class="room-element dropdown is-hoverable"
        [href]="Customer.Uri"
        target="_blank"
      >
        <button *ngIf="Customer !== undefined && !mobileDevice" class="button is-medium is-blue">
          <span class="icon">
            <svg width="40" height="40" class="pointer svg-white" (click)="sendClickInfoMessage('SmsPrivateLink')">
              <image xlink:href="/assets/icons/share-nodes.svg" width="40" height="40" />
            </svg>
          </span>
        </button>

        <div class="dropdown-menu" id="dropdown-menu" role="menu">
          <div class="dropdown-content">
            <a
              *ngIf="Customer !== undefined && mobileDevice"
              id="{{ Customer.Room }}-invite"
              class="dropdown-item"
              [href]="Customer.Uri"
              target="_blank"
            >
              {{ "shell.room.send_sms" | translate }}
            </a>
            <a
              *ngIf="Customer !== undefined && !mobileDevice"
              class="dropdown-item"
              (click)="sendClickInfoMessage('QrCodePrivateLink'); displayQrCodeLink(Customer)"
            >
              {{ "shell.room.qrcode_link" | translate }}
            </a>
            <a
              *ngIf="Customer !== undefined && !mobileDevice"
              class="dropdown-item"
              (click)="sendClickInfoMessage('QrCodeSmsPrivateLink'); displayQrCodeSms(Customer)"
            >
              {{ "shell.room.qrcode_sms" | translate }}
            </a>
            <a
              *ngIf="Customer !== undefined && !mobileDevice"
              id="{{ Customer.Room }}-invite"
              class="dropdown-item"
              [href]="Customer.Uri"
              target="_blank"
            >
              {{ "shell.room.send_email" | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <button
      *ngIf="!bTelephonyMode"
      class="button is-medium is-danger is-outlined login-button m-0"
      (click)="sendClickInfoMessage('EnterPrivateRoom'); EnterOwnRoom(PrivateRoom.Name)"
      style="align-self: center"
    >
      <div class="enter-button-base pointer" *ngIf="!waitingToEnter">
        <div class="enter-button-out pointer">
          <div class="enter-button-in pointer">
            <span class="enter-button pointer"></span>
          </div>
        </div>
      </div>
      <b class="button-text pointer" *ngIf="!waitingToEnter">{{ "shell.room.join" | translate }} ?</b>
      <div *ngIf="waitingToEnter">
        <app-loader-points></app-loader-points>
      </div>
    </button>
  </div>
</div>
