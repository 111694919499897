<i class="fa-regular fa-xl fa-face-smile pointer" style="align-content: center; margin-left: 5px" [matMenuTriggerFor]="menuEmojis"></i>
<mat-menu #menuEmojis="matMenu">
  <ng-template matMenuContent>
    <div class="skin-picker" (click)="$event.stopPropagation()">
      <div></div>
      <div
        *ngFor="let skin of skinTonesKeys"
        class="emoji-item"
        style="place-self: center"
        [ngStyle]="{ border: skinTones[skin] == skinTone ? 'solid 2px hsl(153, 53%, 53%)' : 'none' }"
      >
        <div
          class="square"
          (click)="setSkinTone(skin)"
          [ngStyle]="{
            'background-color':
              skin === 'Light'
                ? '#FFD7C2'
                : skin === 'MediumLight'
                ? '#EEBFAA'
                : skin === 'Medium'
                ? '#C68D7B'
                : skin === 'MediumDark'
                ? '#966661'
                : skin === 'Dark'
                ? '#573739'
                : '#FFCC00'
          }"
        ></div>
      </div>
    </div>
    <div class="emoji-picker">
      <div *ngFor="let emoji of emojis" (click)="selectEmoji(emoji)" class="emoji-item">
        {{ emoji }}
      </div>
    </div>
  </ng-template>
</mat-menu>
