export const en = {
  landing_page: {
    main_text: "Next Generation Videoconferencing",
    video: "Video chat",
    screensharing: "Screen sharing",
    file_sharing: "File share",
    try: "Try for free",
    login: "Login",
  },
  add_user: {
    title: "Signin",
    email: "Email",
    security: "Security",
    password: "Password",
    name: "Displayed name",
    submit: "Signin",
    error: "An error occured",
    admin_added: "User succesfully added",
  },
  home: {
    description: "Find your customers in a dedicated, secure location that reflects your image.",
  },
  register: {
    room_exist: "Room already exist.",
    displayname_empty: "Displayname field is mandatory",
    email_empty: "Email address field is mandatory.",
    email_exist: "Email address already exist.",
    password_empty: "Password empty.",
    password_weak: "Password is not strong enough.",
    password_confirm: "Password and Confirm Password fields are not the same.",
    confirm: "Confirm",
    resend: "Resend",
    resend_success: "Code resend!",
    invalid_code: "Incorrect code",
  },
  login: {
    title: "Login",
    email: "Email",
    login: "Login",
    password: "Password",
    confirm_password: "Confirm password",
    displayname: "Display name",
    roomname: "Room name",
    submit: "Login",
    signup: "Register",
    forgot: "Forgot password?",
    badcreds: "Wrong email or password",
    toomanyreq: "Too many login attempts.\nPlease try later",
    error: "An error occured",
    remember: "Remember me",
    new_to_vizu: "New to Vizu.live ?",
    create_account: "Create an account",
    account_activated: "Your account is now activated!",
    code_validation: "Validation",
    email_validation_sent1: "An email containing a validation code has been sent to ",
    email_validation_sent2: "Enter the code below",
    promo_code: "Promo code (facultative)",
  },
  password_reset: {
    title: "Reset password",
    email: "Email",
    submit: "Reset password",
    error: "An error occured",
    success: "You received an email to reset your password if it is associated with an Vizu.live account.",
  },
  password_reset_new: {
    password: "New password",
    comfirm_password: "Comfirm password",
    same_password: "Passwords must be the same",
    success: "Your password has been changed",
  },
  email_validation: {
    title: "Loading",
    success: "Your email address is verified",
  },
  cookie: {
    description: "Vizu.live uses cookies to enable and improve the use of the website. Please see our Cookie Policy for more information.",
    settings: "Settings",
    allow: "Allow",
    cookie_settings: "Cookie settings",
    necessary: {
      title: "Necessary",
      text: "These cookies are necessary for Vizu.live to function and cannot be deactivated.",
    },
    performance: {
      title: "Performance",
      text: "These cookies help improve your online experience by storing user choices on the Website. For example, they are used to log you in automatically by remembering your credentials.",
    },
    advertising: {
      title: "Advertising",
      text: "Vizu.live does not collect your data for advertising purposes and therefore does not have this kind of cookie.",
    },
    accept: "Accept",
    reject: "Reject",
    save: "Save",
    cancel: "Cancel",
  },
  shell: {
    menu: {
      rooms: "Rooms",
      appointments: "Appointments",
      list: "List",
      new: "New",
      configuration: "Configuration",
      account_config: "Account",
      account_subscription: "Subscription",
      description: "Public page",
      pricing: "Pricing",
      call_logs: "Logs",
      admin: "Administration",
      admin_no_uppercase: "Avoid uppercase letters",
      admin_new_user: "New user",
      admin_user_list: "User list",
      admin_call_logs: "Call logs",
      admin_call_quality: "Call Quality",
      admin_current_calls: "Current calls",
      admin_feedback: "Feedback",
    },
    disconnected: {
      title: "Disconnect",
      text: "You have been disconnected",
      reload: "Reload",
    },
    shared_room: {
      available: "Available",
      busy: "Busy",
    },
    room: {
      not_exist: "The room doesn't exist",
      welcolme: "Welcolme ",
      my_room: "My Room",
      room_of: "Room ",
      room_link: "Invite a participant with this link",
      title: "Meeting rooms",
      copy: "Copy",
      copied: "Link copied",
      paste: "Paste",
      locked_tooltip: "Remote cannot join a room without acceptation",
      unlocked_tooltip: "Remote can join a room without acceptation",
      invite: "Invite participants",
      send_email: "Invite by email",
      send_sms: "Send direct link via SMS",
      share: "Share",
      qrcode_sms_title: "Invite by SMS",
      qrcode_sms_1: "Open any QR Code Reader application with your smartphone",
      qrcode_sms_2: "Scan the QR Code",
      qrcode_sms_3: "Send the invitation by SMS",
      qrcode_sms_number: "Remote phone number",
      qrcode_link: "Get a QRCode",
      qrcode_link_title: "Share a link or a QRCode",
      qrcode_sms: "Invite by sms",
      copy_link: "Copy direct link",
      error_video: "Camera access failure !",
      error_audio: "Microphone access failure !",
      error_solve: "Check if it's plugged in or already in use",
      error_notfound1: "We can't access to the devices",
      error_notfound2: "Thanks to check if they are not used by another application",
      public: "public",
      private: "private",
      room: "Room",
      enter: "Enter",
      join: "Join",
      team: "Team",
      attendees: "Participants",
      waiting: "Waiting",
      free: "Available",
      busy: "Busy",
      collaborators: "My collaborators",
      reject: "Reject",
      reject_all: "Reject all",
      accept_all: "Accept all",
      reject_call: "Reject call",
      reject_with_message: "Reject with message",
      reject_message_placeholder: "I'm not available for the moment, I suggest you to call me later.",
      new_call: "New call",
      new_call_of: "New call of",
      call_to: "Call to ",
      call_cancel: "Cancel the call",
      call_reject_message: "Reject message received:",
      free_called_nomessage: "",
      end_user_message_forbidden:
        "This guest was unable to let you a message because of your free account limitations. Upgrade to Pro to enjoy this feature",
      team_panel_hidden1: "This area is dedicated to Enterprise accounts",
      team_panel_hidden2: "For more informations about this type of subscription click below",
      messages: {
        not_verified: "Not verified",
        unknown_user_verbose: "Unknown user (External), messages from coming from unkown people could be undesirable.",
      },
      devtools: {
        title: "Debug Tools",
      },
    },
    feedback: {
      title: "Send feedback",
      placeholder: "Your message",
      question_1_2: "We are sorry about this ! What problems did you encounter ?",
      question_3_4: "How do you like this service ?",
      evaluate: "Evaluate the quality of this videoconference:",
      thanks_1: "Thanks for participating!",
      thanks_2: "Would you also like to organize your own video conferences with Vizu.Live?",
      send: "Send",
      sent: "Not a member yet ?",
      sent_arg1: "Simple and easy registration",
      sent_arg2: "Invite with a simple link or SMS",
      sent_arg3: "Collaborate with no time limit",
      error: "An error occured",
      unavailable1: "This contact is not available",
      unavailable2: "You can send him a message below",
      unavailableTitle1: "You were unable to meet your contact.",
      unavailableTitle2: "But maybe you want to use Vizu.Live for your own video conferences?",
      content_message: "Message",
      coordinates_message: "Contact",
      message_sent: "The message has successfully been sent",
      comment: "Comment:",
    },
    buttons: {
      config: "Configuration",
      feedback: "Feedback",
      logout: "Logout",
      close: "Close",
      lock: "Lock",
      unlock: "Unlock",
    },
  },
  room_list: {
    attendees: "Attendees",
    copy_link: "Copy link",
    no_room: "You don't have any room yet",
    new_room: "Create room",
    enter_room: "Enter room",
  },
  room_rating: {
    try_vizu: "Want to test vizu.live for your own video conferences?",
    create: "Create a free account:",
    register: "Register",
    discover: "Discover our professional solutions",
    files: "Files",
    download_files: "Download conference files",
    downloads: "Downloads",
  },
  new_room: {
    name: "Room Name",
    submit_button: "Add",
    add_attendee: "Add attendee",
    contact_name: "Attendee Name",
    contact_email: "Attendee Email",
    name_required: "The room name is required",
    email_required: "The attendee's email is required",
    error: "An error occured",
    cancel_button: "Cancel",
    config: {
      audio: {
        name: "Audio",
        description: "Start with audio",
      },
      video: {
        name: "Video",
        description: "Start with video",
      },
      picture: {
        name: "Picture",
        description: "Enable take picture",
      },
      screen_sharing: {
        name: "Screen Sharing",
        description: "Enable screen sharing",
      },
      display_name: {
        name: "Attendee Name",
        description: "Ask for attendee name",
      },
    },
  },
  new_appointment: {
    contact_name: "Contact Name",
    contact_email: "Contact Email",
    date: "Date",
    time: "Time",
    submit_button: "Add",
  },
  description_editor: {
    header: "Header",
    title: "Title",
    subtitle: "Subtitle",
    sections: "Sections",
    add_section: "Add section",
    section_title: "Section title",
    section_content: "Section content",
    save_button: "Save",
  },
  account_config: {
    confirm: "Confirm",
    delete: "Delete",
    cancel: "Cancel",
    save: "Save",
    confirm_delete: "Are you sure about this deletion ?",
    room_name: "Room Name",
    apply: "Apply",
    profile_picture_uri: "Profile picture URL",
    profile_preview: "Profile picture preview:",
    change_password: "Change password",
    change_email: "Changer email address",
    confirm_password: "Confirm password",
    old_password: "Current password",
    new_password: "New password",
    password_strength1: "The password needs a minimum of:",
    password_strength2: "8 characters in length",
    password_strength3: "1 lower case character",
    password_strength4: "1 upper case character",
    password_strength5: "1 number",
    password_strength6: "1 special character",
    new_email: "New email",
    browse: "Choose a file…",
    import_profile: "Import your profile picture",
    import_company: "Import your company picture",
    background_color: "Background color",
  },
  admin_config: {
    company_filter: "Company filter",
    companies: "Companies",
    users: "Users",
    rooms: "Rooms",
    no_room: "No room",
    no_user: "No user",
    no_company: "No company",
    enabled: "Enabled",
    select: "Select",
    room: {
      display_name: "Display Name",
      room: "Room",
      company: "Company",
      edit_room: "Edit room",
      new_room: "New room",
    },
    user: {
      display_name: "Display Name",
      admin: "Admin",
      room: "Room",
      email: "Email",
      company: "Company",
      firstname: "Firstname",
      lastname: "Lastname",
      edit_user: "Edit user",
      new_user: "New user",
      subscription_type: "Subscription Type",
      password: "Password",
    },
    company: {
      company: "Company",
      display_name: "Display Name",
      company_url: "Company URL",
      edit_company: "Edit company",
      new_company: "New company",
      logouri: "Logo URI",
      import_company_picture: "Import your company picture",
      background_color: "Background color",
    },
  },
  upgrade_plan: {
    title: "Upgrade a Plan",
    alt_title: "This feature is available on paid offers",
    subtitle: "If you need more info, please check",
    subtitle_link: "Pricing Guidelines",
    monthly: "Monthly",
    annual: "Annual",
    popular: "Populaire",
    perMonth: "/ mon",
    buttons: {
      valid: "Upgrade Plan",
      cancel: "Cancel",
    },
    enterprise: {
      title: "Enterprise",
      subtitle: "The most complete version of Vizu.Live",
      advantages: {
        title: "What's in Enterprise Plan?",
        subtitle: "Include Free and Pro advantages for only 15€ per user/month",
        list: {
          first: "Reserved subdomain and room names",
          second: "1 room shared every 3 users",
          third: "Instant messaging between team members",
          fourth: "Customize company images/logo",
          fifth: "Premium technical support",
        },
      },
    },
    pro: {
      title: "Pro",
      subtitle: "Best for startups",
      advantages: {
        title: "What's in Pro Plan?",
        subtitle: "Include Free advantages",
        list: {
          first: "No time limit",
          second: "Offline messages",
          third: "Up to 16 particpants",
          fourth: "Customize home background ans logo",
          fifth: "Support client by mail",
        },
      },
    },
    free: {
      title: "Free",
      subtitle: "To discover Vizu.Live",
      advantages: {
        title: "What's in Free Plan?",
        subtitle: " ",
        list: {
          first: "One personal room",
          second: "Up to 3 participants",
          third: "Videoconference up to 45min",
          fourth: "File transfer during video conference",
          fifth: "Screen & PDF Sharing",
        },
      },
    },
    custom: {
      title: "Custom",
      subtitle: "Request a custom license",
      contact: "Contact",
      quote: "On quote",
    },
  },
  subscription: {
    subscribe_button: "Subscribe",
    ok: "You are subscribed to the service",
    unsubscribe: "Unsubscribe",
    period_end: "Current period end",
    update_card_button: "Update",
    overdue: "Your subscription has not been renewed since",
    payment_update: "Please update your credit card information",
    trial_period_end: "Trial period end",
    trial_overdue: "The trial period is ended since",
    payment_errors: {
      generic_decline: "The card was declined for an unknown reason",
      insufficient_funds: "The card has insufficient funds to complete the purchase",
      lost_card: "The payment was declined because the card is reported lost",
      stolen_card: "The payment was declined because the card is reported stolen",
      expired_card: "The card has expired",
      incorrect_cvc: "The CVC number is incorrect",
      processing_error: "An error occurred while processing the card",
      incorrect_number: "The card number is incorrect",
      card_velocity_exceeded: "Balance, credit limit, or transaction amount limit exceeded on this card",
      network_error: "A network error occurred. Please check your connection and try again.",
      regional_restriction: "The transaction was declined due to regional restrictions set by your bank.",
      suspected_fraud: "This transaction was flagged as suspicious. Please contact your bank.",
      authentication_failed: "Additional authentication was required but failed. Please try again.",
      unsupported_card: "This type of card is not supported. Please use a different card.",
      transaction_limit_exceeded: "This card has reached the limit of allowed transactions. Please contact your bank.",
      temporary_hold: "Your card has been temporarily blocked by the issuer. Please try again later or use a different card.",
      fraudulent: "The payment has been blocked, because he represents a high risk level",
    },
  },
  common: {
    error: "An error occured",
    link_failure1: "Unable to connect to the server",
    link_failure2: "We are trying to restore the connection",
    already_used: "This name is already reserved",
    too_small: "This name is too small",
    reload: "Reload",
  },
  loading: "Loading",
  focus_modal: {
    title: "Required action",
    body1: "New document received",
    body2: "Click here in order to see it",
  },
  add_participant_modal: {
    title: "Add participant",
    body: "Enter the phone number or the name of the contact to add him to the call",
  },
  public_room: {
    help: "Need help ?",
    init: "Loading",
    refused: "You have not been allowed to enter the room.",
    not_found_title: "The room was not found",
    not_found_url_title: "The company was not found",
    not_found_main: "Are you sure you have entered the correct address ?",
    welcome: "Welcome to ",
    get_name: "Enter your name",
    get_text: "Enter your question",
    get_name_placeholder: "Enter your name here",
    get_name_submit: "Join",
    security_test: "Security tests running...",
    step1: "1. Checking microphone access",
    step2: "2. Checking camera access",
    you_will_enter: "Enter your name and join the room of",
    media_granted1: "We try to access to your microphone and webcam stream",
    media_granted2: "Your browser will ask you to share us these devices",
    media_granted3: "Thanks to click on the Allow button",
    media_ask_auth: "Ask authorization",
    media_access: "Access to your media devices",
    media_access_error: "Can't access to your media devices",
    media_access_error_verbose1: "Without camera or microphone, the interactions with other people of the room will be limited",
    media_access_error_verbose2: "You will be able to use the chat to send them messages or files",
    media_access_error_verbose3: "Click on the camera disabled icon in your address bar in order to let us access to your devices",
    media_access_not_supported_old1: "WARNING: Your browser doesn't support real time audio and video communications",
    media_access_not_supported_old2: "Please use a recent browser to access this service (example: Chrome, Firefox, Edge, Safari...).",
    media_access_not_supported_compat1: "Your browser doesn't support realtime audio and video communications on this Apple device.",
    media_access_not_supported_compat2: "Thanks to use Safari to access to this service.",
    media_access_not_supported3: "If you continue, you will only be able to use document sharing and text chat.",
    name_error: "The name is mandatory, enter your display name",
    waiting: "Your appointment will start soon.",
    sub_waiting: "Your host has been informed of your access request.",
    finished: "Your appointment is finished.",
    no_answer: "The remote is not answering",
    let_message: "Let him a message",
    let_message_btn: "Let a message",
    mirror_message: "A last look in the mirror?",
    drag_and_drop_1: "drag / drop a PDF or an image",
    drag_and_drop_2: "or",
    drag_and_drop_3: "click and upload",
    unavailable: "The user is currently busy and send you this message:",
    room_full_title: "This meeting has reached the maximum number of participants",
    room_full_text1: "We informed the organizer that you cannot join his meeting",
    room_full_text2: "We invite you to contact him directly",
  },
  private_room: {
    invite: "Send this link to invite people to the conference.",
  },
  room: {
    conference: "Conference",
    share_link: "Share",
    modal_share_link_title: "Share link",
    room_link: "Invite a participant with this link",
    share_screen: "Share screen",
    sharing_img_pdf: "Share PDF or Image",
    stop_share_screen: "Stop share",
    chat: "Display chat",
    stop_chat: "Hide chat",
    swap_camera: "Swap Camera",
    whiteboard: "Whiteboard",
    add_participant: "Add Participant",
    waiting_for_attendees: "Waiting for attendees",
    media_connecting: "Media connecting",
    out_failed: "Connection failed",
    in_failed: "Connection failed",
    out_failed_connected: "You have been disconnected",
    in_failed_connected: "You have been disconnected",
    retry: "Retry",
    take_picture: "Take picture",
    download: "Download",
    picture: "Picture",
    medialess: "Continue without media",
    upgrade: "Upgrade",
    permissions_denied_cam_and_mic_title: "Microphone and Camera access permissions denied",
    permissions_denied_mic_title: "Microphone access permissions denied",
    permissions_denied_cam_title: "Camera access permissions denied",
    permissions_denied_content_1: "1. Click on the Info icon located in your browser's bar adderss",
    permissions_denied_content_cam_and_mic_2: "2. Enable the Microphone and the Camera",
    permissions_denied_content_mic_2: "2. Enable the Microphone",
    permissions_denied_content_cam_2: "2. Enable the Camera",
    permissions_denied_content_3: "3. Refresh your tab",
    appointment_close_soon_title: "Conference time limit reached soon !",
    appointment_close_soon_text1:
      "Unfortunately, your appointment will automatically close in 5 minutes because you have a Free account...",
    appointment_close_soon_text2: "Upgrade to Pro to avoid this limit !",
    appointment_max_title: "Participants limit reached !",
    appointment_max_text1: "You have reached the limit of 2 guests on your Free account.",
    appointment_max_text2: "To accept up to 16 guests, you must upgrade to Pro version.",
    participant_missed_text1: "would like to join the room, unfortunately you have reached the participant limit",
    participant_missed_text2: "We informed him that he could not join you",
    participant_missed_text3: "Upgrade to Pro to increase this limit",
    message_forbidden: "has a free account, only paid accounts can receive messages",
    control_buttons: {
      mic_on: "Disable the microphone",
      mic_off: "Enable the microphone",
      cam_on: "Disable the camera",
      cam_off: "Enable the camera",
      chat_on: "Hide the chat",
      chat_off: "Show the chat",
      more_options: "More options",
      hangout: "Hang up",
      screen_sharing_on: "Disable the screen sharing",
      screen_sharing_off: "Enable the screen sharing",
      file_sharing_on: "Disable image or PDF sharing",
      file_sharing_off: "Enable image or PDF sharing",
      conf_view: "Conference view",
    },
  },
  call_logs: {
    number: "Total calls:",
    customer: "User",
    room: "Room",
    beginning: "Beginning",
    end: "Ending",
    duration: "Duration",
    indicator: "Indicator",
    browser: "OS/Browser",
  },
  user_list: {
    number: "Registered users:",
    email: "Email",
    name: "Name",
    is_admin: "Admin",
    subscription_start: "Subscription start",
    subscription_end: "Subscription end",
    delete: "Delete",
  },
  notification: {
    edit: {
      success: "Your changes have been saved successfully.",
      failed: "An error has occurred, changes were not saved.",
    },
    copy_link: "Link copied to clipboard.",
    send_file_pending: "Sending file in progress...",
    feedback: "Feedback successfully sent.",
    message: "Message successfully sent.",
  },
  feedback: {
    title: "Feedback",
    type: {
      title: "Type",
      options: {
        select: "Select a option",
        problem: "Problem",
        suggestion: "Suggestion",
        help: "Need help",
        other: "Other",
      },
    },
    message: {
      title: "Message",
      placeholder: "Write here...",
    },
    button: {
      submit: "Send",
      cancel: "Cancel",
    },
  },
  free_version: "free version",
  Free_Version: "Free Version",
  pro_version: "pro version",
};
