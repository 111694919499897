import { Component, ElementRef, HostListener, ViewChild } from "@angular/core";

@Component({
  selector: "app-vizu-bubbles",
  templateUrl: "./vizu-bubbles.component.html",
  styleUrl: "./vizu-bubbles.component.scss",
})
export class VizuBubblesComponent {
  @ViewChild("bubbleCanvas", { static: true }) bubbleCanvas!: ElementRef<HTMLCanvasElement>;
  private ctx!: CanvasRenderingContext2D;
  private bubbles: Bubble[] = [];
  private particles: Particle[] = [];

  ngOnInit() {
    this.ctx = this.bubbleCanvas.nativeElement.getContext("2d")!;
    this.resizeCanvas();
    this.createBubbles();
    this.animate();
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.resizeCanvas();
    this.bubbles = [];
    this.createBubbles();
  }

  private resizeCanvas() {
    const canvas = this.bubbleCanvas.nativeElement;
    canvas.width = canvas.parentElement.clientWidth;
    canvas.height = canvas.parentElement.clientHeight;
  }

  private createBubbles() {
    for (let i = 0; i < 20; i++) {
      const radius = Math.random() * 30 + 10;
      const x = Math.random() * (this.bubbleCanvas.nativeElement.width - radius * 2) + radius;
      const y = Math.random() * this.bubbleCanvas.nativeElement.height;
      this.bubbles.push(new Bubble(x, y, radius));
    }
  }

  private popBubble(index: number) {
    const bubble = this.bubbles[index];
    for (let i = 0; i < 20; i++) {
      this.particles.push(new Particle(bubble.x, bubble.y));
    }
    this.bubbles.splice(index, 1);
    const radius = Math.random() * 30 + 10;
    const x = Math.random() * (this.bubbleCanvas.nativeElement.width - radius * 2) + radius;
    this.bubbles.push(new Bubble(x, this.bubbleCanvas.nativeElement.height + radius, radius));
  }

  private animate() {
    this.ctx.clearRect(0, 0, this.bubbleCanvas.nativeElement.width, this.bubbleCanvas.nativeElement.height);

    this.bubbles.forEach((bubble, index) => {
      bubble.draw(this.ctx);
      bubble.update(this.bubbleCanvas.nativeElement);
    });

    this.particles.forEach((particle, index) => {
      particle.update();
      particle.draw(this.ctx);
      if (particle.opacity <= 0) {
        this.particles.splice(index, 1);
      }
    });

    requestAnimationFrame(() => this.animate());
  }

  onClick(event: MouseEvent) {
    const rect = this.bubbleCanvas.nativeElement.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const clickY = event.clientY - rect.top;

    for (let i = this.bubbles.length - 1; i >= 0; i--) {
      if (this.bubbles[i].isPointInside(clickX, clickY)) {
        this.popBubble(i);
        break;
      }
    }
  }
}

class Bubble {
  constructor(public x: number, public y: number, public radius: number) {
    this.speed = Math.random() * 2 + 1;
    this.opacity = Math.random() * 0.5 + 0.5;
  }
  speed: number;
  opacity: number;

  draw(ctx: CanvasRenderingContext2D) {
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
    ctx.strokeStyle = `rgba(0, 0, 0, ${this.opacity})`;
    ctx.lineWidth = 2;
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.arc(this.x + this.radius * 0.3, this.y - this.radius * 0.3, this.radius * 0.2, 0, Math.PI * 2);
    ctx.fillStyle = `rgba(255, 255, 255, ${this.opacity * 0.8})`;
    ctx.fill();
    ctx.closePath();
  }

  update(canvas: HTMLCanvasElement) {
    this.y -= this.speed;
    if (this.y + this.radius < 0) {
      this.y = canvas.height + this.radius;
    }
  }

  isPointInside(x: number, y: number): boolean {
    const dx = x - this.x;
    const dy = y - this.y;
    return dx * dx + dy * dy <= this.radius * this.radius;
  }
}

class Particle {
  constructor(public x: number, public y: number) {
    this.size = Math.random() * 5 + 1;
    this.speedX = Math.random() * 3 - 1.5;
    this.speedY = Math.random() * 3 - 1.5;
    this.opacity = 1;
  }
  size: number;
  speedX: number;
  speedY: number;
  opacity: number;

  update() {
    this.x += this.speedX;
    this.y += this.speedY;
    if (this.size > 0.1) this.size -= 0.1;
    this.opacity -= 0.02;
  }

  draw(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle = `rgba(0, 0, 0, ${this.opacity})`;
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
    ctx.fill();
  }
}
