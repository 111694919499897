import { Component, OnInit, Output, EventEmitter, AfterViewInit, ElementRef, ViewChild, Input } from "@angular/core";
import { CustomerService } from "../../services/customer.service";
import { ActivatedRoute, Router } from "@angular/router";
import { RtcService } from "src/app/services/rtc.service";
import { EmitterService } from "src/app/services/emitter.service";

// Stripe est chargé globalement depuis le script dans index.html
declare var Stripe: any;

@Component({
  selector: "app-vizu-payment",
  templateUrl: "./vizu-payment.component.html",
  styleUrls: ["./vizu-payment.component.scss"],
})
export class VizuPaymentComponent implements OnInit, AfterViewInit {
  @ViewChild("cardNumberElement") cardNumberElement!: ElementRef;
  @ViewChild("cardExpiryElement") cardExpiryElement!: ElementRef;
  @ViewChild("cardCvcElement") cardCvcElement!: ElementRef;
  @Input() closePaymentModal!: (state: boolean, isSucceedPayment: boolean) => void;
  @Input() planSelected: string;

  stripe: any;
  cardName: string = "";
  cardNumber: any;
  cardExpiry: any;
  cardCvc: any;
  paymentStatus: string | null = null;
  paymentStatusReason: string | null = null;
  successToken: string | null = null;

  constructor(
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private router: Router,
    private rtc: RtcService,
    private NgmsService: EmitterService
  ) {}

  ngOnInit(): void {
    // Initialisation de Stripe avec votre clé publique
    this.stripe = Stripe("pk_test_51QBFGSJTKQ4N6n2iZaKa0DXEUzSrujmZZO6yzcdS10p9tV4zK4FhRhEz9LqzI9TNP1eBoFdoH9kLm4GFVN3u9d9a00CpkJMqUI"); // Clé publique Stripe
    //this.stripe = Stripe("pk_live_51QBFGSJTKQ4N6n2isYOXjTEg1AYWvDjEQN05VZTzNdmHsbCxnC9rr21ZB7objDiyNemXMmlz040mNECPkhg9gi2y003CNHPZtj"); // Clé publique Stripe

    this.rtc.notifyOnMeData = this.onmedata.bind(this);
  }

  async onmedata(userData: any) {
    if (userData.SubscriptionType == this.planSelected) {
      this.paymentStatus = "success";
      this.paymentStatusReason = "";
      setTimeout(() => this.closeModal(true), 2000);
    }
    this.NgmsService.EmitNgmsSettings(userData, "OnMeData");
  }

  ngAfterViewInit(): void {
    // Créez une instance de Stripe Elements et intégrez-la dans le formulaire
    const elements = this.stripe.elements();

    this.cardNumber = elements.create("cardNumber");
    this.cardNumber.mount(this.cardNumberElement.nativeElement);

    this.cardExpiry = elements.create("cardExpiry");
    this.cardExpiry.mount(this.cardExpiryElement.nativeElement);

    this.cardCvc = elements.create("cardCvc");
    this.cardCvc.mount(this.cardCvcElement.nativeElement);
  }

  closeModal(bCloseModal: boolean) {
    this.closePaymentModal(bCloseModal, this.paymentStatus == "success");
  }

  handlePayment(event: Event) {
    event.preventDefault();

    // Créer une session de paiement via votre backend
    this.customerService.createCheckoutSession(this.cardName, this.planSelected).subscribe((session) => {
      if (session.client_secret) {
        this.stripe
          .confirmCardPayment(session.client_secret, {
            payment_method: {
              card: this.cardNumber,
            },
          })
          .then((result: any) => {
            if (result.error) {
              this.paymentStatus = "fail";

              // Gestion des nouveaux codes d'erreur
              const errorCode = result.error.code;
              switch (errorCode) {
                case "card_declined":
                  this.paymentStatusReason = result.error.decline_code || errorCode;
                  break;
                case "insufficient_funds":
                case "lost_card":
                case "stolen_card":
                case "expired_card":
                case "incorrect_cvc":
                case "processing_error":
                case "incorrect_number":
                case "card_velocity_exceeded":
                case "network_error":
                case "regional_restriction":
                case "suspected_fraud":
                case "authentication_failed":
                case "unsupported_card":
                case "transaction_limit_exceeded":
                case "temporary_hold":
                case "fraudulent":
                  this.paymentStatusReason = errorCode;
                  break;
                default:
                  this.paymentStatusReason = "generic_decline";
              }
            } else if (result.paymentIntent && result.paymentIntent.status === "requires_action") {
              // Authentification 3D Secure requise
              this.stripe.handleCardAction(result.paymentIntent.client_secret).then((result: any) => {
                if (result.error) {
                  this.paymentStatus = "fail";
                  this.paymentStatusReason = result.error.message;
                } else if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
                  // Paiement réussi après authentification
                  this.paymentStatus = "success";
                  this.paymentStatusReason = "";
                  this.successToken = session.token;
                  setTimeout(() => this.closeModal(true), 2000);
                }
              });
            } else if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
              // Paiement réussi sans authentification supplémentaire
              this.paymentStatus = "success";
              this.paymentStatusReason = "";
              this.successToken = session.token;
              setTimeout(() => this.closeModal(true), 2000);
            }
          });
      } else {
        this.paymentStatus = "pending";
        this.paymentStatusReason = "";
        this.successToken = session.token;
      }
    });
  }
}
