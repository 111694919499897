import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

declare const DataServer: any;

@Injectable({
  providedIn: "root",
})
export class AuthService {
  UnauthenticatedSig = new Subject<void>();

  ngmsUrl: any = "";

  constructor(private http: HttpClient) {
    this.ngmsUrl = DataServer;
  }

  //ngmsUrl = "https://" + location.host + ":5081"

  /*LoggedIn(): boolean {
        return localStorage.getItem('loggedIn') === 'true'
    }

    private setLoggedIn(loggedIn: boolean): void {
        localStorage.setItem('loggedIn', `${loggedIn}`)
    }*/

  Authenticated(): Observable<any> {
    /*try {
            this.http.get(this.ngmsUrl + '/ngmconf/user/ws-noauth/login')
            return true
        } catch (e) {
            const error: HttpErrorResponse = e
            if (error.status === 401 || error.status === 403) {
                this.setLoggedIn(false)
                return false
            }
            throw error
        }*/
    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/user/ws-noauth/login";
    } else {
      url = "/ngmconf/user/ws-noauth/login";
    }
    return this.http.get(url);

    //return this.http.get(this.ngmsUrl + '/ngmconf/user/ws-noauth/login')
  }

  Authenticate(email: string, password: string): Observable<any> {
    let params = {
      Username: email,
      Password: password,
    };
    //return this.http.put(this.ngmsUrl + '/ngmconf/user/ws-noauth/login', params)

    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/user/ws-noauth/login"; //?userinfo=' + this.params.Username + ':' + this.params.Password
    } else {
      url = "/ngmconf/user/ws-noauth/login";
    }

    return this.http.put(url, params);

    /*try {
            this.http.put(this.ngmsUrl + '/ngmconf/user/ws-noauth/login', params)
            //this.http.put<void>(this.ngmsUrl + '/ngmsbc/user/ws-noauth/login', params).toPromise()
            this.setLoggedIn(true)
        } catch (e) {
            const error: HttpErrorResponse = e
            if (error.status === 401) {
                this.setLoggedIn(false)
            }
            throw error
        }*/
  }

  Unauthenticate(): Observable<any> {
    //this.UnauthenticatedSig.next()

    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/user/ws-noauth/logout"; //?userinfo=' + this.params.Username + ':' + this.params.Password
    } else {
      url = "/ngmconf/user/ws-noauth/logout";
    }

    return this.http.put(url, {});
    //return this.http.put(this.ngmsUrl + '/ngmconf/user/ws-noauth/logout', {})
  }

  CatchUnauthentication(error: HttpErrorResponse): void {
    if (error.status === 401) {
      //this.setLoggedIn(false)
      this.UnauthenticatedSig.next();
    }
  }

  RequestPasswordReset(email: string): Observable<any> {
    const body = {
      email: email,
    };
    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/user/ws-noauth/forgot-password"; //?userinfo=' + this.params.Username + ':' + this.params.Password
    } else {
      url = "/ngmconf/user/ws-noauth/forgot-password";
    }
    return this.http.post(url, body, { responseType: "text" });
  }

  CheckPasswordResetToken(token: string): Observable<any> {
    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/user/ws-noauth/reset-password/" + token; //?userinfo=' + this.params.Username + ':' + this.params.Password
    } else {
      url = "/ngmconf/user/ws-noauth/reset-password/" + token;
    }
    return this.http.get(url, { responseType: "text" });
  }

  CheckVerifyAccountToken(token: string): Observable<any> {
    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/user/ws-noauth/verify-account/" + token; //?userinfo=' + this.params.Username + ':' + this.params.Password
    } else {
      url = "/ngmconf/user/ws-noauth/verify-account/" + token;
    }
    return this.http.get(url, { responseType: "text" });
  }

  ResetPassword(token: string, newPassword: string): Observable<any> {
    const body = {
      token: token,
      newPassword: newPassword,
    };
    let url = undefined;
    if (this.ngmsUrl !== "" && (this.ngmsUrl.Url !== undefined || this.ngmsUrl.Url !== "")) {
      url = "/" + this.ngmsUrl.Url + "/user/ws-noauth/reset-password"; //?userinfo=' + this.params.Username + ':' + this.params.Password
    } else {
      url = "/ngmconf/user/ws-noauth/reset-password";
    }
    return this.http.post(url, body, { responseType: "text" });
  }
}
