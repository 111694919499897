import {
  ViewChild,
  Component,
  ElementRef,
  Input,
  Output,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  EventEmitter,
  AfterViewChecked,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

import { CustomerService } from "../../services/customer.service";
import { MouseService, AppMouseEvent } from "../../services/mouse.service";
import { EmitterService } from "../../services/emitter.service";

import { RtcService } from "../../services/rtc.service";
import { Customer, RoomConfig, DomainData, Collaborator, Limitations } from "../../domain/domain";
import { NotificationComponent } from "../notification/notification.component";

import { VizuRecaptchaComponent } from "../vizu-recaptcha/vizu-recaptcha.component";

declare const QRCodeStyling: any;
import * as platform from "platform";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-vizu-room",
  templateUrl: "./vizu-room.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./vizu-room.component.scss"],
})
export class VizuRoomComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild("recaptchaComponent") recaptchaComponent!: VizuRecaptchaComponent;
  @ViewChild("notificationComponent") notificationComponent!: NotificationComponent;
  @Input() Public: boolean;
  @Input() Customer: Customer;
  @Input() Contacts: Collaborator[];
  @Input() Limitations: Limitations;
  @Input() WaitingRequest: boolean;
  @Input() Appointment: string;
  @Output() Closed = new EventEmitter<void>();
  @Input() DomainData: DomainData;

  bTextCall: boolean = false;

  hasBeenConnected: boolean = false;
  Disconnected = false;
  imageLoaded = false;

  pdfContent: any;
  userDetected: boolean = false;

  meterValue: number = 0;
  soundMeter: any = {};
  videoDevices: Array<{ deviceId: string; groupId: string; kind: string; label: string }> = [];
  videoDeviceSelected: number = 0;
  audioDevices: Array<{ deviceId: string; groupId: string; kind: string; label: string }> = [];
  audioDeviceSelected: number = 0;
  activeVideoDevice = 0;

  bUpgradeModal: boolean = false;
  b5MinRemaining: boolean = false;
  bSendMessageForbidden: boolean = false;

  SessionDuration = 0;
  SessionMinutes = "00";
  SessionSeconds = "00";
  TimerStarted = false;
  private sessionInterval: number;

  AppointmentID: string;
  SubscriptionType: string = "";

  State: string;

  Rooms: any[];

  UserInfo = {
    Name: "",
    ReconnectID: "",
  };
  isNameError: boolean = false;

  LocalStreamIsMain: boolean = false;
  LocalStream: any;
  ScreenStream: any;
  ScreenStreamId = "local_screenstream";
  ScreenStreamMiniId = "local_miniscreenstream";

  getUserMediaDone: boolean = false;
  bGetUserMediaSucceeded: boolean = false;

  LocalStreamVideo: boolean = undefined;
  LocalStreamAudio: boolean = undefined;

  LocalStreamVideoEnabled = true;
  LocalStreamAudioEnabled = true;

  AttendeeInMem: any = undefined;
  AttendeeInMemId: any = undefined;
  AudioParticipants: any[] = [];
  Participants: any[] = [];
  LockedAttendees: any[] = [];

  MainAttendee: any = undefined;
  MainAttendeeId: string = undefined;

  renderingPdf: any;
  WhiteboardEnabled = false;
  ScreenSharingEnabled = false;
  ExtensionNotInstalledModal = false;
  ExtensionInstalled = false;
  ScreenCaptureErrorModal = false;
  NeedAddParticipantModal = false;
  RemoteScreenStream: any;
  RemoteScreenStreamId: any;

  Docs: any[] = [];

  // PDF Vect
  PDFVectAdmin = false;
  PDFVectSharingEnabled = false;
  PageMaxVect: number;
  private pdfVect: any;
  PDFScale = 1;
  PageSelected = 1;
  PDFErrorModal = false;
  ContainerWidth: number;
  RemoteScrolled = false;
  CurrentPDF: any;
  cancelledPdfId: number = undefined;
  requestBeforeCall: string = "";
  participantToAdd: string = "";
  callTimerTooLong: boolean = false;

  roomLink: string = "";
  ShareLinkModal: boolean = false;
  qrCodeData: any = undefined;
  qrCodeSafeUrl: string = "";

  FirstSwap = true;
  CameraSwapped = true;
  RemoteCameraSwapped = false;

  DotsEnabled = false;

  bSharingImage: boolean = false;
  SharedImageData: SafeResourceUrl;

  Laser = {
    MouseDown: false,
    Enabled: false,
    X: 0,
    Y: 0,
    ScrollX: 0,
    ScrollY: 0,
  };

  //MODIF MM BELOW:
  queryAppointment: string;
  noAppointment: boolean = true;
  smallAppointment: boolean = false;
  mediumAppointment: boolean = false;
  largeAppointment: boolean = false;
  hugeAppointment: boolean = false;
  smallMiniAppointment: boolean;
  bNavBurgerDisplay: boolean;
  OSBrowser = {
    Browser: "",
    OS: "",
  };

  deniedPermissionsModal = false;
  deniedPermissions = {
    audio: false,
    video: false,
  };

  testString: string = "";
  bSupported: boolean;
  bIEReason: boolean;

  mainVideoMuted: boolean;

  //RoomFound = false
  bCompanyFound: boolean = true;
  bRoomFound: boolean = undefined;
  bRoomFull: boolean = undefined;
  RejectMessage: string = undefined;
  missedParticipant: any = undefined;

  inCallRefuseMessageShown: boolean = false;
  inCallRefuseMessage = "";
  inCallAttendeeDataRefused: any = undefined;
  inCallRefuseMessageRemaining: number = 140;

  RoomConfig = new RoomConfig();

  domain = {
    name: "",
  };

  EmiterHandler = false;
  localStorage = {
    LastConnection: "",
    AppointmentID: "",
  };

  refresh: any;
  hasFocusInterval: any;
  timeInterval: any;
  hasFocus: boolean;
  NeedFocusModal: boolean = false;
  messageQueue: any[] = [];

  rightPanel = {
    Display: false,
    Cameras: false,
    Chat: true,
  };
  leftPanel = {
    Display: false,
  };

  bThelephonyMode = false;
  PrivRoom: any;
  pingInterval: any;

  // login / signup / password_reset / get_price / payment_add / checkout / transaction_confirmed
  userAction: string = "login";

  //CHAT
  @ViewChild("messageBox") messageBox: ElementRef;

  Data = {
    ChatText: "",
  };

  Messages: any[] = [];
  private newMessage = false;

  detectInfos: any = undefined;

  //currentWidth: number

  constructor(
    public ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private rtc: RtcService,
    private customerService: CustomerService,
    private mouse: MouseService,
    private NgmsService: EmitterService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
    this.NgmsService.EmitNgmsSettings$.subscribe({
      next: this.handleNewEmittedMsg.bind(this),
    });

    this.refresh = setInterval(() => {
      this.ref.detectChanges();
    }, 10);
  }

  /*async initiateError() {
        await sleep(1000)
        let myString = JSON.stringify({ toto: 'toto' })
        let parsed = JSON.parse(myString)
        let myCrash = parsed.heyHo
        setTimeout(function() { myCrash.crash(); }, 0);
    }*/

  handleNewEmittedMsg(data: any) {
    let messageRcv = data.message;
    let dataRcv = data.data;
    if (messageRcv == "OnStreamAdded") {
      this.onstreamadded(dataRcv);
    } else if (messageRcv == "OnMediaStreamAdded") {
      this.onmediastreamadded(dataRcv);
    } else if (messageRcv == "OnStreamRemoved") {
      this.onstreamremoved(dataRcv);
    } else if (messageRcv == "OnParticipantAdded") {
      this.onparticipantadded(dataRcv);
    } else if (messageRcv == "OnParticipantUpdated") {
      this.onparticipantupdated(dataRcv);
    } else if (messageRcv == "OnParticipantRemoved") {
      this.onparticipantremoved(dataRcv);
    } else if (messageRcv == "OnDisconnected") {
      this.ondisconnected(dataRcv);
    } else if (messageRcv == "OnMessage") {
      this.EmiterHandler = true;
      this.onmessage(dataRcv);
    } else if (messageRcv == "OnMessageDataChannel") {
      this.EmiterHandler = true;
      this.onmessagedatachannel(dataRcv);
    }
  }

  handleLimitations() {
    if (this.SubscriptionType === "pro") {
      this.Limitations = {
        MessagePanelHidden: false,
        TeamPanelHidden: true,
        RoomPanelHidden: true,
        InConfRestriction: false,
      };
    } else if (this.SubscriptionType === "enterprise") {
      this.Limitations = {
        MessagePanelHidden: false,
        TeamPanelHidden: false,
        RoomPanelHidden: false,
        InConfRestriction: false,
      };
    } else {
      this.Limitations = {
        MessagePanelHidden: true,
        TeamPanelHidden: true,
        RoomPanelHidden: true,
        InConfRestriction: true,
      };
    }
  }

  OnKeyDown(event: any) {
    if (event.keyCode === 13) {
      event.preventDefault();
      //document.getElementById("sendMessageSubmit").click();
      this.sendChatMessage();
    }
  }

  async manageRightPanelState(panel: string, display: boolean) {
    //manage only 1 active when we will have more than 2
    console.log(this.rightPanel);
    if ((panel === "chat" && !this.rightPanel.Chat) || (panel === "cam" && !this.rightPanel.Cameras)) {
      this.rightPanel.Cameras = !this.rightPanel.Cameras;
      this.rightPanel.Chat = !this.rightPanel.Chat;
    }
    this.rightPanel.Display = display;
  }

  async manageLeftPanelState() {
    this.leftPanel.Display = !this.leftPanel.Display;
  }

  async refreshAppointmentSize(): Promise<void> {
    //Attention, le localSteam ajoute 1 balise, donc calculer en conséquence
    if (this.Participants.length === 0) {
      this.noAppointment = true;
      this.smallAppointment = false;
      this.mediumAppointment = false;
      this.largeAppointment = false;
      this.hugeAppointment = false;
    } else if (this.Participants.length < 2) {
      this.noAppointment = false;
      this.smallAppointment = true;
      this.mediumAppointment = false;
      this.largeAppointment = false;
      this.hugeAppointment = false;
    } else if (this.Participants.length < 4) {
      this.noAppointment = false;
      this.smallAppointment = false;
      this.mediumAppointment = true;
      this.largeAppointment = false;
      this.hugeAppointment = false;
    } else if (this.Participants.length < 8) {
      this.noAppointment = false;
      this.smallAppointment = false;
      this.mediumAppointment = false;
      this.largeAppointment = true;
      this.hugeAppointment = false;
    } else if (this.Participants.length >= 8) {
      this.noAppointment = false;
      this.smallAppointment = false;
      this.mediumAppointment = false;
      this.largeAppointment = false;
      this.hugeAppointment = true;
    }
  }

  setImageBackground() {
    let style = undefined;
    if (this.DomainData !== undefined) {
      if (this.DomainData.CustomData !== undefined) {
        if (this.DomainData.CustomData.ImgBackground !== undefined) {
          if (this.DomainData.CustomData.ImgBackground === "") {
            style = {
              "background-image": "url('/assets/img/brown-wooden-style.jpg')",
            };
          } else if (
            this.DomainData.CustomData.ImgBackground.startsWith("/") ||
            this.DomainData.CustomData.ImgBackground.startsWith("http")
          ) {
            style = {
              "background-image": "url('" + this.DomainData.CustomData.ImgBackground + "')",
            };
          } else {
            style = {
              "background-image":
                "url('" + "/assets/companies/" + this.DomainData.Name + "/" + this.DomainData.CustomData.ImgBackground + "')",
            };
          }
        } else {
          style = {
            "background-image": "url('/assets/img/brown-wooden-style.jpg')",
          };
        }
      } else {
        style = {
          "background-image": "url('/assets/img/brown-wooden-style.jpg')",
        };
      }
    } else {
      style = {
        "background-image": "url('/assets/img/brown-wooden-style.jpg')",
      };
    }
    //return style; DISABLED
    return "";
  }

  setDomainHeader() {
    let style = undefined;
    if (this.DomainData !== undefined) {
      style = {
        //x"background": "url('/static/assets/companies/" + this.domainTemplate.Name + "/"+this.domainTemplate.ImgBackground+"') no-repeat center 75%",
        "background-color": this.DomainData.CustomData.ColorRoomHeader,
      };
    } else {
      style = "";
    }
    return style;
  }

  setDomainAltColor() {
    let style = undefined;
    if (this.DomainData !== undefined) {
      style = {
        color: this.DomainData.CustomData.ColorHomeAltText + " !important",
        "background-color": this.DomainData.CustomData.ColorHomeAlt,
      };
    } else {
      style = "";
    }
    return style;
  }

  setDomainMainColor() {
    let style = undefined;
    if (this.DomainData !== undefined) {
      style = {
        "background-color": this.DomainData.CustomData.ColorHomeMain,
      };
    } else {
      style = "";
    }
    return style;
  }

  async GrantLocalStream() {
    let myThis = this;
    //return new Promise((resolve: any, reject: any) => {
    this.getLocalMediaAccess(null).then(
      function (stream: any) {
        //function(stream: any) {
        myThis.rtc.setMediaGranted(true);
        if (myThis.State !== "started") {
          myThis.State = "media_access_success";
        }
        myThis.AccessLocalStream();
      },
      function (error: any) {
        myThis.rtc.setMediaGranted(false);
        if (myThis.State !== "started") {
          myThis.State = "media_access_error";
        }
        console.log("-------PERMISSIONS FAILED: " + error);

        //IF ERROR PRINT PROBLEM MEDIA, BUT ACCESSLOCALSTREAM EVEN IF EMPTY
      }
    );
    //})
    //console.log("GrantLocalStream permissions: " + permissionsGranted)
    /*.then(async function() {
            await myThis.rtc.GetLocalMediaPermissions_Video()
            .then(async function(permission: any) {
                console.log("_____________IN VIZU_ROOM: Permissions: " + permission.state)
                if (permission.state === "granted") {
                    myThis.State = 'media_access'
                } else {

                }
            })
        })*/

    //if navigator.permissions
    //    if navigator.permissions.query
  }

  async waitGetUserMediaCallback(bReset: boolean) {
    if (this.Public && this.OSBrowser.Browser === "Firefox") {
      //Waiting getusermediasucceeded callback
      while (this.bGetUserMediaSucceeded === false) {
        await sleep(50);
      }
      //Resetting bGetUserMediaSucceeded because the localStream has been closed
      //in getusermediasucceeded callback, after first getUserMedia access
      if (bReset) {
        this.bGetUserMediaSucceeded = false;
      }
    }
  }

  async AccessLocalStream() {
    if (!this.rtc.deviceInfos.hasPermissions) {
      await this.waitGetUserMediaCallback(true);
    }
    if (this.videoDevices.length <= 0) {
      console.log("------VIDEODEVICES EMPTY");
      let myThis = this;
      if (this.LocalStream !== undefined) {
        //this.LocalStream = this.rtc.LocalStream
        myThis.rtc.SetLocalMedia();
        if (!myThis.Public) {
          if (!myThis.rtc.bPrivateOutboundCall) {
            myThis.rtc.answerCall();
          }
        }
        //myThis.LocalStreamAudio = myThis.rtc.LocalStreamAudio
        //myThis.LocalStreamVideo = myThis.rtc.LocalStreamVideo
        await navigator.mediaDevices.enumerateDevices().then(function (devices) {
          myThis.rtc.setDevices(devices);
          devices.forEach(function (device) {
            //console.log(device.kind + ": " + device.label +
            //" id = " + device.deviceId);
            if (device.kind !== undefined) {
              if (device.kind === "audioinput") {
                //console.log("Audio Device");
                myThis.audioDevices.push({
                  deviceId: device.deviceId,
                  groupId: device.groupId,
                  kind: device.kind,
                  label: device.label,
                });
              }
              if (device.kind === "videoinput") {
                myThis.videoDevices.push({
                  deviceId: device.deviceId,
                  groupId: device.groupId,
                  kind: device.kind,
                  label: device.label,
                });
              }
            }
          });
        });
      } else {
        //return new Promise((resolve: any, reject: any) => {
        this.getLocalMedia(null).then(async function () {
          await myThis.waitGetUserMediaCallback(false);
          //myThis.LocalStream = myThis.rtc.LocalStream
          myThis.rtc.SetLocalMedia();
          if (!myThis.Public) {
            if (!myThis.rtc.bPrivateOutboundCall) {
              myThis.rtc.answerCall();
            }
          }
          //myThis.LocalStreamAudio = myThis.rtc.LocalStreamAudio
          //myThis.LocalStreamVideo = myThis.rtc.LocalStreamVideo
          navigator.mediaDevices
            .enumerateDevices()
            .then(function (devices) {
              myThis.rtc.setDevices(devices);
              devices.forEach(function (device) {
                //console.log(device.kind + ": " + device.label + " id = " + device.deviceId);
                if (device !== undefined) {
                  if (device.kind === "audioinput") {
                    //console.log("Audio Device");
                    myThis.audioDevices.push({
                      deviceId: device.deviceId,
                      groupId: device.groupId,
                      kind: device.kind,
                      label: device.label,
                    });
                  }
                  if (device.kind === "videoinput") {
                    myThis.videoDevices.push({
                      deviceId: device.deviceId,
                      groupId: device.groupId,
                      kind: device.kind,
                      label: device.label,
                    });
                  }
                }
              });
            })
            .catch(function (err) {
              console.log(err.name + ": " + err.message);
            });
        });
        /*    },
                        function(err: any) {
                            console.log(err.name + ": " + err.message);
                        }
                    )*/
        //})
      }
    }

    if (this.Public) {
      if (localStorage.getItem("VizuLive\\Name") !== "null" && localStorage.getItem("VizuLive\\Name") !== null) {
        this.UserInfo.Name = localStorage.getItem("VizuLive\\Name");
        //Si cookie perso accept�, le gars aura son nom, mais si refus�, il n'existe plus et s'appelle "VizuUser"
      }
      if (localStorage.getItem("VizuLive\\LastConnection") !== "null" && localStorage.getItem("VizuLive\\LastConnection") !== null) {
        var dateToday = new Date();
        var dateLastDisconnection: any = new Date(Number(localStorage.getItem("VizuLive\\LastConnection")));
        var diffDates: any = dateToday.getTime() - dateLastDisconnection.getTime();
        var Seconds_from_T1_to_T2 = diffDates / 1000;
        var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
        if (Seconds_Between_Dates < 30) {
          if (localStorage.getItem("VizuLive\\ReconnectID") !== "null" && localStorage.getItem("VizuLive\\ReconnectID") !== null) {
            this.AppointmentID = localStorage.getItem("VizuLive\\AppointmentID");
            this.UserInfo.ReconnectID = localStorage.getItem("VizuLive\\ReconnectID");
            this.SetName();
          }
        }
        if (localStorage.getItem("VizuLive\\LastConnection") !== "null" && localStorage.getItem("VizuLive\\LastConnection") !== null) {
          localStorage.removeItem("VizuLive\\LastConnection");
        }
        if (localStorage.getItem("VizuLive\\AppointmentID") !== "null" && localStorage.getItem("VizuLive\\AppointmentID") !== null) {
          localStorage.removeItem("VizuLive\\AppointmentID");
        }
        if (localStorage.getItem("VizuLive\\ReconnectID") !== "null" && localStorage.getItem("VizuLive\\ReconnectID") !== null) {
          localStorage.removeItem("VizuLive\\ReconnectID");
        }
      }
    } else {
      this.State = "started";
      this.startTimer();
      await sleep(100);
      this.dragAndDropInit();
      if (this.bTextCall) {
        await sleep(1000);
        this.Data.ChatText = "Bonjour, je suis à votre disposition pour répondre à votre demande.";
        this.sendChatMessage();
        this.Data.ChatText = "";
      }
    }
  }

  async ngAfterViewChecked(): Promise<void> {
    if (this.newMessage) {
      await sleep(100);
      this.newMessage = false;
      this.scrollToBottom();
    }
  }

  async ngOnInit() {
    let myThis = this;
    console.log(this.Limitations);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    this.translate.get("shell.room.reject_message_placeholder").subscribe((translation: string) => {
      if (localStorage.getItem("VizuLive\\RefuseMessage")) {
        this.inCallRefuseMessage = localStorage.getItem("VizuLive\\RefuseMessage");
      } else {
        this.inCallRefuseMessage = translation;
      }
      this.valueRejectMessageChange();
    });

    //this.customerService.notifyOnConfOrUserExist = this.onconforuserexist.bind(this)
    if (urlParams.get("dataChannel")) {
      let urlParamDataChannel = urlParams.get("dataChannel");
      this.rtc.bUseDataChannel = urlParamDataChannel === "true"; // if "false" set to false / if "true" set to true
      if (!this.rtc.bUseDataChannel) {
        console.log("-------DATACHANNEL DISABLED / WEBSOCKET MODE ENABLED");
      }
    }
    if (urlParams.get("text")) {
      let urlParamDataChannel = urlParams.get("text");
      this.bTextCall = urlParamDataChannel === "true"; // if "false" set to false / if "true" set to true
      this.rightPanel.Display = true;
      if (this.bTextCall) {
        console.log("-------DATA CALL DETECTED");
      }
    }

    //let fullDomain = window.location.host.split('.');
    //this.DomainData.Name = fullDomain[0]
    //console.log(Date.now())

    /*this.currentWidth = window.innerWidth
        window.addEventListener("resize", function () {
            myThis.currentWidth = window.innerWidth
        });*/

    this.OSBrowser = this.rtc.getOSBrowser();

    this.hasFocusInterval = setInterval(() => {
      this.hasFocus = document.hasFocus();
      if (this.hasFocus) {
        this.NeedFocusModal = false;
        if (this.messageQueue.length > 0) {
          for (let i = 0; i < this.messageQueue.length; i++) {
            this.processMessageReceived(this.messageQueue[i]);
            this.messageQueue.splice(i, 1);
            break;
          }
        }
      }
    }, 500);

    if (this.Public) {
      var pathname = window.location.pathname.split("/");
      const appointmentID = pathname[1];
      this.AppointmentID = appointmentID;

      this.customerService.GetDomainPublicTemplate(this.AppointmentID).subscribe({
        next: (data) => {
          this.bRoomFound = true;
          let domainData: any = data;
          this.DomainData = {
            Name: data.Name,
            SharedUsers: data.SharedUsers,
            RoomLogoURI: data.RoomLogoURI,
            RoomFriendlyName: data.RoomFriendlyName,
            CustomData: domainData.CustomData === "" ? "" : JSON.parse(domainData.CustomData),
          };
          this.customerService.setColors(this.DomainData.CustomData.BackgroundColor, this.DomainData.CustomData.TextColor);
          //Only used as public
          //Logged users use the limitations received from other components
          if (data.SubscriptionType !== undefined) {
            this.SubscriptionType = data.SubscriptionType;
            this.handleLimitations();
          }
        },
        error: (response) => {
          if (response.status === 404 || response.status === 400) {
            this.bRoomFound = false;
            //User not found, get company infos
            this.customerService.GetDomainPublicTemplate("").subscribe({
              next: (data) => {
                this.bCompanyFound = true;
                let domainData: any = data;
                this.DomainData = {
                  Name: data.Name,
                  SharedUsers: data.SharedUsers,
                  RoomLogoURI: data.RoomLogoURI,
                  RoomFriendlyName: data.RoomFriendlyName,
                  CustomData: domainData.CustomData === "" ? "" : JSON.parse(domainData.CustomData),
                };
                this.customerService.setColors(this.DomainData.CustomData.BackgroundColor, this.DomainData.CustomData.TextColor);
              },
              error: (response) => {
                this.bCompanyFound = false;
              },
            });
          } else if (response.status === 400) {
          }
        },
      });

      this.roomLink = window.location.href;
      this.initQrCode(this.AppointmentID).then(function (success) {
        myThis.qrCodeData = success;
        myThis.qrCodeSafeUrl = myThis.qrCodeData.safeUrl;
      });
      //this.RoomConfig = await this.customerService.GetConfig(this.AppointmentID, true || this.route.snapshot.url[0].path === 'room')

      //this.customerService.GetRoomExist(this.AppointmentID)
      if (localStorage.getItem("Name") !== "null" && localStorage.getItem("Name") !== null) {
        this.UserInfo.Name = localStorage.getItem("Name");
      }

      //RTC SERVICE CALLBACKS
      //this.rtc.notifyOnCallConnected = this.onconnected.bind(this)
      this.rtc.notifyOnCallConnected = this.onconnected.bind(this);
      this.rtc.notifyOnCallDisconnected = this.ondisconnected.bind(this);
      this.rtc.notifyOnMediaStreamAdded = this.onmediastreamadded.bind(this);
      this.rtc.notifyOnStreamAdded = this.onstreamadded.bind(this);
      this.rtc.notifyOnStreamRemoved = this.onstreamremoved.bind(this);
      this.rtc.notifyOnParticipantAdded = this.onparticipantadded.bind(this);
      this.rtc.notifyOnParticipantUpdated = this.onparticipantupdated.bind(this);
      this.rtc.notifyOnParticipantRemoved = this.onparticipantremoved.bind(this);
      this.rtc.notifyOnMessage = this.onmessage.bind(this);
      this.rtc.notifyOnMessageDataChannel = this.onmessagedatachannel.bind(this);
      this.rtc.notifyOnMessageDeliveryProgress = this.onmessagedeliveryprogress.bind(this);
      this.rtc.notifyOnAuthorization = this.onauthorization.bind(this);
      this.rtc.notifyOnAuthorizationForbidden = this.onauthorizationforbidden.bind(this);

      const appointmentQuery = this.route.snapshot.queryParamMap.keys[0];
      if (appointmentQuery !== undefined || appointmentQuery !== null) {
        this.queryAppointment = appointmentQuery;
      } else {
        this.queryAppointment = "";
      }

      let myThis = this;
      this.rtc.GetLocalMediaPermissions_Audio().then(
        function (response: any) {
          //Audio Permission checked and OK
        },
        function (error: any) {
          //Audio devices checked and ERROR
          if (error.name === "microphone" && error.state === "not_supported") {
            //FIREFOX ERROR
            console.log("FIREFOX detected - can't get Audio permissions !");
          } else if (error.state == "denied") {
            myThis.deniedPermissionsModal = true;
            myThis.deniedPermissions.audio = true;
            //Audio BLOCKED ERROR
          }
        }
      );

      this.rtc.GetLocalMediaPermissions_Video().then(
        function (response: any) {
          //Video Permission checked and OK
        },
        function (error: any) {
          //Video devices checked and ERROR
          if (error.name === "camera" && error.state === "not_supported") {
            //FIREFOX ERROR
            console.log("FIREFOX detected - can't get Video permissions !");
          } else if (error.state == "denied") {
            myThis.deniedPermissionsModal = true;
            myThis.deniedPermissions.video = true;
            //VIDEO BLOCKED ERROR
          }
        }
      );
    } else {
      console.log(this);
      this.AppointmentID = this.Appointment;
      this.roomLink = window.location.href + this.AppointmentID;
      this.customerService.GetDomainPublicTemplate(this.AppointmentID).subscribe({
        next: (data) => {
          let domainData: any = data;
          this.DomainData = {
            Name: data.Name,
            SharedUsers: data.SharedUsers,
            RoomLogoURI: data.RoomLogoURI,
            RoomFriendlyName: data.RoomFriendlyName,
            CustomData: domainData.CustomData === "" ? "" : JSON.parse(domainData.CustomData),
          };
          this.customerService.setColors(this.DomainData.CustomData.BackgroundColor, this.DomainData.CustomData.TextColor);
        },
        error: (response) => {
          if (response.status === 404) {
            this.bRoomFound = false;
          } else if (response.status === 400) {
          }
        },
      });

      await this.initQrCode(this.AppointmentID).then(function (success) {
        myThis.qrCodeData = success;
        myThis.qrCodeSafeUrl = myThis.qrCodeData.safeUrl;
      });
      this.Customer.Name = this.Customer.Name.charAt(0).toUpperCase() + this.Customer.Name.slice(1);
      //this.rtc.notifyOnNewCall            = this.onnewcall.bind(this)
      if (!this.bThelephonyMode) {
        this.rtc.notifyOnPrivateRoomChanged = this.onprivateroomchanged.bind(this);
        //this.onprivateroomchanged(this.rtc.privRoom)
      }
      this.rtc.notifyOnConfRoomsChanged = this.onconfroomschanged.bind(this);
      this.rtc.notifyOnParticipantMissed = this.notifyonparticipantmissed.bind(this);
      if (this.State !== "started") this.State = "media_access";

      await this.reconnectWebSocket();
    }

    if (!this.Public) {
      let myThis = this;
      this.rtc.generateInternalVizuID();
      this.AccessLocalStream();
      //this.State = 'media_access_success'
      this.State = "started";
      this.mouse.MouseEvents.subscribe((event) => {
        if (myThis.State !== "started" || myThis.PDFVectSharingEnabled === false) {
          return;
        }

        switch (event.Type) {
          case "down":
            this.LaserDown();
            break;
          case "up":
            this.LaserUp();
            break;
          case "move":
            this.LaserMove();
            break;
        }
      });
      if (this.rtc.AttendeesAccepted !== undefined) {
        for (let i = 0; i < this.rtc.AttendeesAccepted.length; i++) {
          this.AnswerLockedAttendee(this.rtc.AttendeesAccepted[i]);
        }
        this.rtc.unsetAttendeesAccepted();
      }
    } else {
      let myThis = this;
      this.rtc.generateInternalVizuID();
      //CHECK IF ACCESS GRANTED
      await navigator.mediaDevices.enumerateDevices().then(async function (devices) {
        if (devices[0] !== undefined) {
          if (devices[0].label == "") {
            //ACCESS NOT GRANTED
            myThis.rtc.setMediaGranted(false);
            myThis.GrantLocalStream();
            if (myThis.State !== "started") myThis.State = "media_granted";
            console.log("media_granted");
          } else {
            myThis.rtc.setMediaGranted(true);
            myThis.AccessLocalStream();
            if (myThis.State !== "started") myThis.State = "media_access_success";
          }
        }
      });
    }
    //this.initiateError()
    /*
        if (this.videoDevices.length <= 0) {
            let myThis = this
            //CHECK IF ACCESS GRANTED
            await navigator.mediaDevices.enumerateDevices()
            .then(async function(devices) {
                if (devices[0].label == "") {
                    //ACCESS NOT GRANTED
                    myThis.State = 'media_granted'
                    console.log("media_granted")
                    await myThis.AccessLocalStream()
                }
            })
            
            this.LocalStream = await this.getLocalMedia(null)
            //IF GRANTED
            .then(async function() {
                myThis.LocalStream = myThis.rtc.LocalStream
                myThis.rtc.SetLocalMedia()
                if (!myThis.Public) {
                    if (!myThis.rtc.bPrivateOutboundCall) {
                        myThis.rtc.answerCall()
                    }
                }
                myThis.LocalStreamAudio = myThis.rtc.LocalStreamAudio
                myThis.LocalStreamVideo = myThis.rtc.LocalStreamVideo
                await navigator.mediaDevices.enumerateDevices()
                .then(function(devices) {
                    devices.forEach(function(device) {
                            //console.log(device.kind + ": " + device.label +
                                        //" id = " + device.deviceId);
                        if (device.kind === 'audioinput') {
                            //console.log("Audio Device");
                            myThis.audioDevices.push({
                                deviceId: device.deviceId,
                                groupId: device.groupId,
                                kind: device.kind,
                                label: device.label
                            });
                        }
                        if (device.kind === 'videoinput') {
                            myThis.videoDevices.push({
                                deviceId: device.deviceId,
                                groupId: device.groupId,
                                kind: device.kind,
                                label: device.label
                            });
                        }
                    });
                })
                .catch(function(err) {
                    console.log(err.name + ": " + err.message);
                });
            });
        }

        //let constraints = this.getVideoConstraints()
        //if (this.rtc.LocalStream === undefined) {
        //    this.LocalStream = await this.getLocalMedia(constraints)
        //}

        if (this.Public) {
            if (this.localStorage.LastConnection !== null) {
                const lastDateLeft = Number(this.localStorage.LastConnection)
                const deltaMS = Math.abs(Date.now() - lastDateLeft);
                if (deltaMS <= 20000 && (this.AppointmentID === this.localStorage.AppointmentID)) { //reconnection sous 20sec = auto reconnect
                    this.SetName();
                }
            }
        } else {
            this.State = 'started'
            if (!this.WaitingRequest) {
                //this.rtc.AcceptAppointment(this.Appointment.ID)
                this.startTimer()
            }
            await sleep(100)
            this.dragAndDropInit()
        }
    */
  }

  getZIndex(attendee: any) {
    let zIndex = 0;
    for (let i = 0; i < this.LockedAttendees.length; i++) {
      if (attendee === this.LockedAttendees[i]) {
        zIndex = this.LockedAttendees.length - i;
        break;
      }
    }
    return { "z-index": zIndex.toString() };
  }

  DisplayRefuseMessage(data: any) {
    this.inCallRefuseMessageShown = true;
    this.inCallAttendeeDataRefused = data;
  }

  async RefuseIncomingSpecificCallWithMessage() {
    if (this.inCallRefuseMessageShown && this.inCallAttendeeDataRefused !== undefined) {
      let refuseMessagePlaceholder: any = document.getElementById("textarea-refuse-message");
      let inCallRefuseMessage = this.inCallRefuseMessage !== "" ? this.inCallRefuseMessage : refuseMessagePlaceholder.placeholder;
      if (this.rtc.cookies?.performance) {
        localStorage.setItem("VizuLive\\RefuseMessage", this.inCallRefuseMessage);
      }
      for (let i = 0; i < this.LockedAttendees.length; i++) {
        console.log("------ Attendee value: " + this.inCallAttendeeDataRefused);
        if (this.LockedAttendees[i] === this.inCallAttendeeDataRefused) {
          let bSuccess = this.rtc.RejectLockedAttendeeWithMessage(this.LockedAttendees[i], inCallRefuseMessage);
          if (bSuccess) {
            this.removeLockedAttendee(this.inCallAttendeeDataRefused);
            //this.LockedAttendees.splice(i, 1)
          }
        }
      }
    }
    this.inCallRefuseMessageShown = false;
    this.inCallRefuseMessage = "";
  }

  valueRejectMessageChange() {
    this.inCallRefuseMessageRemaining = 140 - this.inCallRefuseMessage.length;
  }

  async reconnectWebSocket() {
    //If rtc reconnect case, reconnect websocket
    if (this.rtc.options.reconnectId !== "") {
      await this.rtc.callbackWebSocket();
    }
  }

  clearDeniedPermissionsModal() {
    this.deniedPermissionsModal = false;
    this.deniedPermissions = {
      audio: false,
      video: false,
    };
  }

  blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  CopyLink(): void {
    let roomId = this.AppointmentID;
    let toCopy: HTMLInputElement = <any>document.getElementById(roomId + "-copylink");
    let value = location.protocol + "//" + location.host + "/" + roomId;
    if (!(navigator as NavigatorInterface).clipboard) {
      if (platform.name == "Safari") {
        toCopy.contentEditable = "true";
        toCopy.readOnly = false;

        const range = document.createRange();
        range.selectNodeContents(toCopy);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        toCopy.setSelectionRange(0, 100000);
        document.execCommand("copy");
      } else {
        toCopy.select();
        document.execCommand("copy");
      }
    } else {
      (navigator as NavigatorInterface).clipboard
        .writeText(value)
        .then(function () {})
        .catch(function (err: any) {
          console.log("Failed to copy to clipboard");
        });
    }
  }

  async initQrCode(Room: string) {
    let title = Room;
    //let urlData = https://mycompany.devngmedia.live/send-sms?inviteFrom=bob&Room=accueil
    let urlData = this.roomLink;

    //let extension = 'canvas';
    const qrCode = new QRCodeStyling({
      width: 300,
      height: 300,
      data: urlData,
      //image: "https://ngmedia.vizu.live/assets/vizu2.png",
      image: location.protocol + "//" + location.host + "/assets/favicon.png",
      backgroundOptions: {
        color: "#ffffff",
      },
      dotsOptions: {
        color: "#343640",
        type: "extra-rounded",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 0,
      },
      cornersSquareOptions: {
        color: "#c4283d",
        type: "dot",
      },
      cornersDotOptions: {
        color: "#c4283d",
        type: "dot",
      },
    });

    let blob = undefined;
    let myThis = this;
    return new Promise((resolve: any, reject: any) => {
      qrCode.getRawData("svg").then(function (rawData: any) {
        blob = rawData;
        let base64Content = undefined;
        myThis.blobToBase64(blob).then(function (result) {
          base64Content = result;
          let sanitizedData: SafeResourceUrl = myThis.sanitizer.bypassSecurityTrustResourceUrl(base64Content.toString());

          resolve({ title: title, safeUrl: sanitizedData, qrCode: qrCode });
        });
      });
    });
  }

  updateAudioDevice(deviceSelected: number) {
    this.audioDeviceSelected = deviceSelected;
    console.log(this.audioDevices[this.audioDeviceSelected].deviceId + ", " + this.audioDevices[this.audioDeviceSelected].label);
    this.updateGetLocalMedia();
  }

  updateVideoDevice(deviceSelected: number) {
    this.videoDeviceSelected = deviceSelected;
    console.log(this.videoDevices[this.videoDeviceSelected].deviceId + ", " + this.videoDevices[this.videoDeviceSelected].label);
    this.updateGetLocalMedia();
  }

  async updateGetLocalMedia() {
    this.rtc.CloseUserMedia();
    this.LocalStream = undefined;
    //this.LocalStream.getTracks().forEach((track: any) => track.stop())
    const constraints = {
      audio: { deviceId: this.audioDeviceSelected !== null ? { exact: this.audioDevices[this.audioDeviceSelected].deviceId } : undefined },
      video: { deviceId: this.videoDeviceSelected !== null ? { exact: this.videoDevices[this.videoDeviceSelected].deviceId } : undefined },
    };
    await this.getLocalMedia(constraints);
    if (this.rtc.LocalStream !== undefined) {
      this.LocalStream = this.rtc.LocalStream;
    }
  }

  soundMeterStop() {
    if (this.soundMeter !== undefined) {
      if (this.soundMeter.audioContext) {
        if (this.soundMeter.javascriptNode) {
          this.soundMeter.javascriptNode = undefined;
        }
        if (this.soundMeter.microphone) {
          this.soundMeter.microphone = undefined;
        }
        if (this.soundMeter.analyser) {
          this.soundMeter.analyser = undefined;
        }
        if (this.soundMeter.audioContext) {
          this.soundMeter.audioContext = undefined;
        }
      }
    }
  }

  async soundMeterStart(stream: any) {
    this.soundMeterStop();
    this.soundMeter.audioContext = new AudioContext();
    let deprecated = false;
    if (deprecated) {
      this.soundMeter.analyser = this.soundMeter.audioContext.createAnalyser();
      this.soundMeter.microphone = this.soundMeter.audioContext.createMediaStreamSource(stream);
      this.soundMeter.javascriptNode = this.soundMeter.audioContext.createScriptProcessor(2048, 1, 1);

      this.soundMeter.analyser.smoothingTimeConstant = 0.8;
      this.soundMeter.analyser.fftSize = 1024;

      this.soundMeter.microphone.connect(this.soundMeter.analyser);
      this.soundMeter.analyser.connect(this.soundMeter.javascriptNode);
      this.soundMeter.javascriptNode.connect(this.soundMeter.audioContext.destination);

      let myThis = this;
      this.soundMeter.javascriptNode.onaudioprocess = function () {
        var array = new Uint8Array(myThis.soundMeter.analyser.frequencyBinCount);
        myThis.soundMeter.analyser.getByteFrequencyData(array);
        var values = 0;

        var length = array.length;
        for (var i = 0; i < length; i++) {
          values += array[i];
        }

        var average = values / length;
        var per100 = average;
        if (per100 > 100) {
          per100 = 100;
        }
        var per1 = per100 / 100;

        myThis.meterValue = Number(per1.toFixed(2));
      };
    } else {
      //let myThis = this
      if (this.soundMeter !== undefined) {
        if (this.soundMeter.audioContext !== undefined) {
          if (this.soundMeter.audioContext.audioWorklet !== undefined) {
            await this.soundMeter.audioContext.audioWorklet.addModule("../assets/js/process_microphone.js");
            this.soundMeter.microphone = this.soundMeter.audioContext.createMediaStreamSource(stream);
            this.soundMeter.javascriptNode = new AudioWorkletNode(this.soundMeter.audioContext, "vumeter");
            this.soundMeter.javascriptNode.port.onmessage = (event: any) => {
              if (event.data.volume) {
                if (this.meterValue !== event.data.volume) {
                  this.meterValue = event.data.volume;
                } else {
                  this.meterValue = 0;
                }
              }
            };
            this.soundMeter.microphone.connect(this.soundMeter.javascriptNode).connect(this.soundMeter.audioContext.destination);
          }
        }
      }
    }
  }

  dragAndDropInit() {
    //Manage Drag and Drop
    let myThis = this;
    const dropArea = document.querySelector(".doc-upload");
    if (dropArea !== null) {
      dropArea.addEventListener("dragover", (event) => {
        event.preventDefault(); //preventing from default behaviour
        dropArea.classList.add("active");
      });
      //If user leave dragged File from DropArea
      dropArea.addEventListener("dragleave", () => {
        dropArea.classList.remove("active");
      });
      dropArea.addEventListener("drop", (event: any) => {
        dropArea.classList.remove("active");
        event.preventDefault(); //preventing from default behaviour
        //getting user select file and [0] this means if user select multiple files then we'll select only the first one
        myThis.SharePDFVect(event);
      });
    }
  }

  changeVideoConstraints() {
    let constraints = {};
    if (this.audioDeviceSelected === undefined || this.videoDeviceSelected === undefined) {
      constraints = {
        audio: { deviceId: this.audioDeviceSelected },
        video: { deviceId: this.videoDeviceSelected },
      };
    } else {
      if (this.videoDeviceSelected >= this.videoDevices.length - 1) {
        this.videoDeviceSelected = 0;
      } else if (this.videoDeviceSelected < this.videoDevices.length) {
        this.videoDeviceSelected++;
      }
      /*constraints = {
                audio: {deviceId: this.audioDeviceSelected  !== null ? {exact: this.audioDevices[this.audioDeviceSelected].deviceId} : undefined},
                video: {deviceId: this.videoDeviceSelected  !== null ? {exact: this.videoDevices[this.videoDeviceSelected].deviceId} : undefined}
            };*/
    }

    return constraints;
  }

  getVideoConstraints() {
    /*if (this.activeVideoDevice >= this.videoDevices.length - 1) {
            this.activeVideoDevice = 0
        } else {
            this.activeVideoDevice++;
        }*/
    let constraints = {};
    if (this.audioDeviceSelected === undefined || this.videoDeviceSelected === undefined) {
      constraints = {
        audio: { deviceId: this.audioDeviceSelected },
        video: { deviceId: this.videoDeviceSelected },
      };
    } else {
      constraints = {
        audio: {
          deviceId: this.audioDeviceSelected !== null ? { exact: this.audioDevices[this.audioDeviceSelected].deviceId } : undefined,
        },
        video: {
          deviceId: this.videoDeviceSelected !== null ? { exact: this.videoDevices[this.videoDeviceSelected].deviceId } : undefined,
        },
      };
    }
    return constraints;
  }

  cancelCall() {
    this.State = "media_access_success";
    this.rtc.clearCall();
    this.SessionDuration = 0;
  }

  skipCallAndLetMessage() {
    if (this.Limitations?.MessagePanelHidden) {
      let myThis = this;
      this.bSendMessageForbidden = true;
      this.rtc.sendOfflineMessage(this.AppointmentID, "");
      setTimeout(function () {
        myThis.bSendMessageForbidden = false;
      }, 4000);
    } else {
      this.hasBeenConnected = false;
      this.rtc.clearCall();
      this.rtc.CloseUserMedia();
      this.stopTimer();
      this.State = "closed";
    }
  }

  async ngOnDestroy(): Promise<void> {
    if (this.renderingPdf) {
      this.renderingPdf.cancel();
    }
    if (this.pdfVect) {
      this.pdfVect.destroy();
    }
    clearInterval(this.refresh);
    //this.rtc.CloseUserMedia()
    this.stopTimer();
  }

  async onconnected(event: any) {
    let myThis = this;
    this.hasBeenConnected = true;
    this.rtc.sendLogMessage().subscribe(() => {});
    this.State = "started";
    this.mouse.MouseEvents.subscribe((event) => {
      if (myThis.State !== "started" || myThis.PDFVectSharingEnabled === false) {
        return;
      }

      switch (event.Type) {
        case "down":
          this.LaserDown();
          break;
        case "up":
          this.LaserUp();
          break;
        case "move":
          this.LaserMove();
          break;
      }
    });
    this.startTimer();

    await sleep(100);
    if (this.Public) {
      let reconnectId = this.rtc.getReconnectId();
      const appointmentID = location.pathname.substr(1);

      this.rtc.ToggleMic(this.LocalStreamAudioEnabled);
      this.rtc.ToggleVid(this.LocalStreamVideoEnabled);

      if (reconnectId !== undefined) {
        localStorage.setItem("VizuLive\\ReconnectID", reconnectId);
        localStorage.setItem("VizuLive\\AppointmentID", appointmentID);
      }
      localStorage.setItem("VizuLive\\Name", this.UserInfo.Name);
      this.timeInterval = setInterval(function () {
        const dateLeft = Date.now();
        localStorage.setItem("VizuLive\\LastConnection", dateLeft.toString());
      }, 1000);
    }
    this.dragAndDropInit();
    if (this.bTextCall) {
      if (this.Public) {
        await sleep(1000);
        this.Data.ChatText = this.requestBeforeCall;
        this.sendChatMessage();
        this.Data.ChatText = "";
      }
    }
  }

  messageInputChange(chatValue: string) {
    this.Data.ChatText = chatValue;
  }

  ondisconnected(event: any) {
    // VOIR POUR RECUPERER TOUS LES TYPES ERREURS
    // 20 SUSCRIBER ABSENT
    if (this.rtc.LocalStream !== undefined && this.State != "media_access_success") {
      this.rtc.CloseUserMedia();
    }
    if (this.ScreenSharingEnabled) {
      this.ToggleScreenSharing();
    }

    if (event.subject) {
      // USER BUSY
      if (this.timeInterval != undefined) {
        clearInterval(this.timeInterval);
        if (localStorage.getItem("VizuLive\\LastConnection") !== "null" && localStorage.getItem("VizuLive\\LastConnection") !== null) {
          localStorage.removeItem("VizuLive\\LastConnection");
        }
        if (localStorage.getItem("VizuLive\\AppointmentID") !== "null" && localStorage.getItem("VizuLive\\AppointmentID") !== null) {
          localStorage.removeItem("VizuLive\\AppointmentID");
        }
        if (localStorage.getItem("VizuLive\\ReconnectID") !== "null" && localStorage.getItem("VizuLive\\ReconnectID") !== null) {
          localStorage.removeItem("VizuLive\\ReconnectID");
        }
        if (
          localStorage.getItem("VizuLive\\LocalStreamAudioEnabled") !== "null" &&
          localStorage.getItem("VizuLive\\LocalStreamAudioEnabled") !== null
        ) {
          localStorage.removeItem("VizuLive\\LocalStreamAudioEnabled");
        }
        if (
          localStorage.getItem("VizuLive\\LocalStreamVideoEnabled") !== "null" &&
          localStorage.getItem("VizuLive\\LocalStreamVideoEnabled") !== null
        ) {
          localStorage.removeItem("VizuLive\\LocalStreamVideoEnabled");
        }
      }
      this.stopTimer();
      this.State = "closed";
      this.RejectMessage = event.subject;
    } else {
      if (event.cause === 1 || event.cause === 53) {
        // ROOM NOT EXIST
        this.bRoomFound = false;
        //} else if (event.cause === 17 || event.cause === 21) { // USER BUSY
      } else {
        if (this.Public) {
          if (this.timeInterval != undefined) {
            clearInterval(this.timeInterval);
            if (localStorage.getItem("VizuLive\\LastConnection") !== "null" && localStorage.getItem("VizuLive\\LastConnection") !== null) {
              localStorage.removeItem("VizuLive\\LastConnection");
            }
            if (localStorage.getItem("VizuLive\\AppointmentID") !== "null" && localStorage.getItem("VizuLive\\AppointmentID") !== null) {
              localStorage.removeItem("VizuLive\\AppointmentID");
            }
            if (localStorage.getItem("VizuLive\\ReconnectID") !== "null" && localStorage.getItem("VizuLive\\ReconnectID") !== null) {
              localStorage.removeItem("VizuLive\\ReconnectID");
            }
            if (
              localStorage.getItem("VizuLive\\LocalStreamAudioEnabled") !== "null" &&
              localStorage.getItem("VizuLive\\LocalStreamAudioEnabled") !== null
            ) {
              localStorage.removeItem("VizuLive\\LocalStreamAudioEnabled");
            }
            if (
              localStorage.getItem("VizuLive\\LocalStreamVideoEnabled") !== "null" &&
              localStorage.getItem("VizuLive\\LocalStreamVideoEnabled") !== null
            ) {
              localStorage.removeItem("VizuLive\\LocalStreamVideoEnabled");
            }
          }
          this.stopTimer();
          if (this.State != "media_access_success") {
            this.State = "closed";
          }
        } else {
          this.Closed.emit();
        }
      }
    }
  }

  /*onnewcall(attendee: any) {
        if (attendee.Data !== undefined && attendee.Name !== undefined) {
            if (attendee.Data.User !== undefined) {
                if (this.Customer.id !== attendee.Data.User && attendee.State != "conferenced") {
                    this.addLockedAttendee(attendee)
                }
            }
        }
    }*/

  notifyonparticipantmissed(participantInfos: any) {
    let myThis = this;
    this.missedParticipant = participantInfos;
    setTimeout(function () {
      myThis.missedParticipant = undefined;
    }, 10000);
  }

  onconfroomschanged(confRooms: any) {
    this.Rooms = confRooms;
    for (let i = 0; i < this.Rooms.length; i++) {
      //Check that the notif is for the room we are in
      if (this.Rooms[i].Active) {
        if (this.Rooms[i].Participants.length > 0) {
          //PATH 1 Check if new attendee, or accepted attendee state changed
          for (let j = 0; j < this.Rooms[i].Participants.length; j++) {
            /*if (this.Rooms[i].Participants[j].State != "conferenced") {
                            this.addLockedAttendee(this.Rooms[i].Participants[j])
                        }*/
            if (this.Rooms[i].Participants[j].Data.User !== this.Customer.id) {
              if (this.LockedAttendees.length > 0) {
                let bFound = false;
                let index = 0;
                for (let k = 0; k < this.LockedAttendees.length; k++) {
                  //if (this.LockedAttendees[j].Data.User === this.PrivRoom.Participants[i].Data.User) {
                  if (this.LockedAttendees[k].Data.Call === this.Rooms[i].Participants[j].Data.Call) {
                    bFound = true;
                    index = k;
                    break;
                  }
                }
                if (bFound) {
                  if (this.Rooms[i].Participants[j].State == "conferenced") {
                    this.removeLockedAttendee(this.LockedAttendees[index]);
                  }
                } else {
                  if (this.Rooms[i].Participants[j].State != "conferenced") {
                    this.addLockedAttendee(this.Rooms[i].Participants[j]);
                  }
                }
              } else {
                if (this.Rooms[i].Participants[j].State == "accepted") {
                  this.addLockedAttendee(this.Rooms[i].Participants[j]);
                }
              }
            }
          }
          //PATH 2 check if some participats have been deleted from queue
          for (let k = 0; k < this.LockedAttendees.length; k++) {
            let bFound = false;
            let index = 0;
            for (let j = 0; j < this.Rooms[i].Participants.length; j++) {
              //if (this.LockedAttendees[j].Data.User === this.PrivRoom.Participants[i].Data.User) {
              if (this.LockedAttendees[k].Data.Call === this.Rooms[i].Participants[j].Data.Call) {
                bFound = true;
                index = k;
                break;
              }
            }
            if (!bFound) {
              this.removeLockedAttendee(this.LockedAttendees[index]);
            }
          }
        } else {
          this.LockedAttendees = [];
        }
      }
    }
    //console.log("---------- VIZUROOMS - ROOMS UPDATED")
  }

  onprivateroomchanged(privRoom: any) {
    this.PrivRoom = privRoom;
    //Check that the notif is for the room we are in
    if (this.AppointmentID === this.PrivRoom.Room) {
      if (this.PrivRoom.Participants.length > 0) {
        let bDeleteLockedAttendees = true;
        for (let i = 0; i < this.PrivRoom.Participants.length; i++) {
          var splittedPath = this.PrivRoom.Participants[i].Path.split("/");
          if (splittedPath[2] == this.Customer.id) {
            if (this.PrivRoom.Participants[i].Data.User !== this.Customer.id) {
              if (this.LockedAttendees.length > 0) {
                let bFound = false;
                let index = 0;
                for (let j = 0; j < this.LockedAttendees.length; j++) {
                  if (this.LockedAttendees[j].Data.Call === this.PrivRoom.Participants[i].Data.Call) {
                    bFound = true;
                    bDeleteLockedAttendees = false;
                    index = j;
                    break;
                  }
                }
                if (bFound) {
                  if (this.PrivRoom.Participants[i].State == "conferenced") {
                    this.removeLockedAttendee(this.LockedAttendees[index]);
                  }
                } else {
                  if (this.PrivRoom.Participants[i].State != "conferenced") {
                    this.addLockedAttendee(this.PrivRoom.Participants[i]);
                    bDeleteLockedAttendees = false;
                  }
                }
              } else {
                if (this.PrivRoom.Participants[i].State == "accepted") {
                  this.addLockedAttendee(this.PrivRoom.Participants[i]);
                  bDeleteLockedAttendees = false;
                }
              }
            }
          }
        }
        //Locked attendee has been deleted
        if (bDeleteLockedAttendees) {
          this.LockedAttendees = [];
        }
      } else {
        this.LockedAttendees = [];
      }
    }
  }

  async onmediastreamadded(event: any) {
    console.log("----------ROOM / MEDIASTREAM_ADDED----------");
    let stream = event.stream;
    let mediaStream = undefined;
    let audioTracks = undefined;
    let videoTracks = undefined;
    if (stream.mediaStream) {
      mediaStream = stream.mediaStream;
      audioTracks = mediaStream.getAudioTracks();
      videoTracks = mediaStream.getVideoTracks();
      for (let i = 0; i < this.Participants.length; i++) {
        if (this.Participants[i].participantId === stream.participantId) {
          for (let j = 0; j < this.Participants[i].Streams.length; j++) {
            if (this.Participants[i].Streams[j].id === stream.streamId) {
              if (audioTracks[0] !== undefined) {
                this.Participants[i].Streams[j].isAudio = true;
                let audioStream = mediaStream.clone();
                this.AudioParticipants.push({
                  id: audioStream.id,
                  stream: audioStream,
                });
              } else {
                this.Participants[i].Streams[j].isAudio = false;
              }

              if (videoTracks[0] !== undefined) {
                this.Participants[i].Streams[j].isVideo = true;
              } else {
                this.Participants[i].Streams[j].isVideo = false;
              }
            }
          }
          break;
        }
      }
    }
  }

  async onstreamadded(event: any) {
    console.log("----------ROOM / STREAM_ADDED----------");
    let stream = event.stream;
    console.log(event.stream);
    if (!stream.isScreenSharingLocal()) {
      if (!stream.isAnnouncement()) {
        if (!stream.isScreenSharingRemote()) {
          for (let i = 0; i < this.Participants.length; i++) {
            if (this.Participants[i].participantId === stream.participantId) {
              this.Participants[i].Streams.push({
                local: false,
                id: stream.streamId,
                stream: event,
                isAudio: stream.audio,
                isVideo: stream.video,
              });
              /*this.Attendees.push({
                                local: false,
                                id: stream.streamId,
                                remoteName: this.Participants[i].remoteName.charAt(0).toUpperCase() + this.Participants[i].remoteName.slice(1),
                                stream: event,
                                isScreenSharing: false,
                                isAudio: stream.audio,
                                isVideo: stream.video,
                                LaserState: {
                                    Enabled: false,
                                }
                            });*/
              //WAIT TO INITIALISE STREAM / SO THE NEGOCIATION IS RECEIVED
              //await sleep(100)
              //event.remoteMediaTagId = undefined;
            }
          }
        } else {
          //SET GOOD ATTENDEE AS SCREENSHARING MODE AND REMOVE CURRENT SCREENSHARING
          //IF THERE IS ONE CURRENTLY BROADCASTING
          if (this.ScreenSharingEnabled) {
            this.ToggleScreenSharing();
          }
          this.RemoteScreenStream = event;
          this.RemoteScreenStreamId = stream.streamId;
          this.manageRightPanelState("cam", true);
          for (let i = 0; i < this.Participants.length; i++) {
            if (this.Participants[i].participantId == stream.participantId) {
              this.Participants[i].isScreenSharing = true;
              stream.setRemoteMediaTag(stream.streamId);
              /*for (let j = 0; j < this.Participants[i].Streams.length; j++) {
                                if (this.Participants[i].Streams[j].streamId === stream.streamId) {
                                    this.Participants[i].Streams[j].stream.stream.setRemoteMediaTag(this.Participants[i].Streams[j].id)
                                }
                            }*/
            }
          }
          this.PDFVectSharingEnabled = false;
          this.bSharingImage = false;
          const viewArea = document.querySelector(".view");
          viewArea.addEventListener("click", (event) => {
            const video: any = event.target;
            if (video.id === this.RemoteScreenStreamId) {
              if (video.requestFullscreen) video.requestFullscreen();
              else if (video.webkitRequestFullscreen) video.webkitRequestFullscreen();
              else if (video.msRequestFullScreen) video.msRequestFullScreen();
            }
          });
        }
      } else {
        console.log("----------ANNOUNCEMENT STREAM---------");
      }
    } else {
      this.ScreenStream = this.rtc.ScreenStream;
      this.ScreenStreamMiniId = stream.streamId;
      event.stream.setRemoteMediaTag(event.stream.streamId);
      for (let i = 0; i < this.Participants.length; i++) {
        if (this.Participants[i].participantId == stream.participantId) {
          for (let j = 0; j < this.Participants[i].Streams.length; j++) {
            if (this.Participants[i].Streams[j].streamId === stream.streamId) {
              this.Participants[i].Streams[j].stream.stream.setRemoteMediaTag(this.Participants[i].Streams[j].id);
            }
          }
        }
      }
      /*for (let i = 0; i < this.Participants.length; i++) {
                this.Participants[i].Streams.stream.stream.setRemoteMediaTag(this.Participants[i].id)
            }*/
      const viewArea = document.querySelector(".view");
      viewArea.addEventListener("click", (event) => {
        const video: any = event.target;
        if (video.id === this.ScreenStreamMiniId) {
          if (video.requestFullscreen) video.requestFullscreen();
          else if (video.webkitRequestFullscreen) video.webkitRequestFullscreen();
          else if (video.msRequestFullScreen) video.msRequestFullScreen();
        }
      });
    }
  }

  async onstreamremoved(event: any) {
    console.log("----------STREAM_REMOVED----------");
    console.log("----------DISCONNECTED----------");
    if (this.RemoteScreenStream !== undefined) {
      if (event.stream.streamId === this.RemoteScreenStream.stream.streamId) {
        this.RemoteScreenStream = undefined;
        const viewArea = document.querySelector(".view");
        viewArea.removeEventListener("click", event, true);
        this.manageRightPanelState("cam", false);
        for (let i = 0; i < this.Participants.length; i++) {
          if (this.Participants[i].participantId === event.stream.participantId) {
            this.Participants[i].isScreenSharing = false;
            break;
          }
        }
      } else {
        for (let i = 0; i < this.Participants.length; i++) {
          if (this.Participants[i].participantId == event.stream.participantId) {
            if (this.Participants[i].Streams !== undefined) {
              for (let j = 0; j < this.Participants[i].Streams.length; j++) {
                if (this.Participants[i].Streams[j].id == event.stream.streamId) {
                  //await this.refreshAppointmentSize(false)
                  this.Participants[i].Streams.splice(j, 1);
                  break;
                }
              }
            }
          }
        }
      }
    } else if (this.ScreenStream !== undefined) {
      if (!event.stream.incoming && event.stream.category === "screensharing") {
        this.ScreenStream = undefined;
        this.ScreenStreamMiniId = undefined;
        const viewArea = document.querySelector(".view");
        viewArea.removeEventListener("click", event, true);
        this.manageRightPanelState("cam", false);
        this.ScreenSharingEnabled = false;
      }
    } else {
      //CLASSIC STREAMREMOVED
      for (let i = 0; i < this.Participants.length; i++) {
        if (this.Participants[i].participantId == event.stream.participantId) {
          for (let j = 0; j < this.Participants[j].Streams.length; j++) {
            if (this.Participants[i].Streams[j].id == event.stream.streamId) {
              //await this.refreshAppointmentSize(false)
              this.Participants[i].Streams.splice(i, 1);
              break;
            }
          }
        }
      }
      for (let j = 0; j < this.AudioParticipants.length; j++) {
        if (this.AudioParticipants[j].id == event.stream.streamId) {
          this.AudioParticipants[j].stream.getTracks().forEach((track: any) => {
            track.stop();
          });
          this.AudioParticipants.splice(j, 1);
          break;
        }
      }
    }
  }

  async processMessageReceived(message: any): Promise<void> {
    console.log("--------ROOM / ON MESSAGE");
    if (message.contentType.startsWith("x-share")) {
      for (let i = 0; i < this.Participants.length; i++) {
        this.Participants[i].LaserState.Enabled = true;
      }
      let extractedSubType = message.contentType.split("=")[1];
      let extractedGuid = message.contentDisposition.split("=")[1];

      if (!this.leftPanel.Display) {
        this.manageLeftPanelState();
      }

      console.log("-------- extractedSubType = " + extractedSubType + " / extractedGuid = " + extractedGuid);
      if (this.cancelledPdfId === undefined || Number(this.cancelledPdfId) !== Number(extractedGuid)) {
        switch (extractedSubType) {
          case "application/pdf":
            var pdfjs = (window as any)["pdfjs-dist/build/pdf"];
            pdfjs.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.2/pdf.worker.min.js";

            //const pdfData = loadFile(file)

            this.pdfVect = await pdfjs.getDocument({
              data: message.content,
            }).promise;

            this.PageMaxVect = this.pdfVect.numPages;

            this.CurrentPDF = {
              ID: extractedGuid,
              Type: extractedSubType,
              Name: message.filename,
              Pages: this.pdfVect.numPages,
              Data: message.content,
              SrcData: undefined,
              Display: true,
            };
            for (let i = 0; i < this.Docs.length; i++) {
              this.Docs[i].Display = false;
            }
            this.Docs.push(this.CurrentPDF);

            this.PDFVectSharingEnabled = true;
            this.bSharingImage = false;

            await sleep(100);

            await this.SelectPDFPageVect(1, true);

            await sleep(100);

            await this.SelectPDFPageVect(1, true);

            this.DisplayPDFAllPages(extractedGuid);

            if (this.ScreenSharingEnabled) {
              await this.ToggleScreenSharing();
            }
            //const fileInfo = await this.rtc.SendPDF(this.Public ? this.AppointmentID : this.Appointment.FriendlyID, file)
            //this.rtc.SendPDFUploaded(fileInfo.ID, fileInfo.Name, this.PageSelected, false)
            break;
          case "image/png":
          case "image/jpeg":
            var base64Content = btoa(message.content);
            this.SharedImageData = this.sanitizer.bypassSecurityTrustResourceUrl("data:" + extractedSubType + ";base64, " + base64Content);
            this.CurrentPDF = {
              ID: extractedGuid,
              Type: extractedSubType,
              Name: message.filename,
              Data: base64Content,
              SrcData: this.SharedImageData,
              Display: true,
            };
            this.Docs.push(this.CurrentPDF);
            //this.SharedImageData = this.sanitizer.bypassSecurityTrustResourceUrl("data:image/bmd;base64, " + base64Content)
            //this.SharedImageData = "data:"+ extractedSubType +";base64, " + base64Content
            this.bSharingImage = true;
            this.PDFVectSharingEnabled = false;
            if (this.ScreenSharingEnabled) {
              await this.ToggleScreenSharing();
            }
            break;
        }
        if (this.ScreenSharingEnabled) {
          this.ToggleScreenSharing();
        }
      } else {
        this.cancelledPdfId = undefined;
      }
    } else if (message.contentType.startsWith("x-command-select-page")) {
      this.SelectPDFPageVect(Number(message.content), true);
      if (this.ScreenSharingEnabled) {
        this.ToggleScreenSharing();
      }
    } else if (message.contentType.startsWith("x-command-select-document")) {
      this.DocumentSwitch(true, 1, Number(message.content));
      if (this.ScreenSharingEnabled) {
        this.ToggleScreenSharing();
      }
    }
  }

  saveByteArray(reportName: string, byte: any) {
    var blob = new Blob([byte]);
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async onparticipantadded(event: any) {
    event.participant.remoteName = event.participant.remoteName.charAt(0).toUpperCase() + event.participant.remoteName.slice(1);
    event.participant.isScreenSharing = false;
    event.participant.LaserState = {
      Enabled: false,
    };
    event.participant.showMiniVideo = true;
    event.participant.Streams = [];
    this.Participants.push(event.participant);
    if ((this.Participants.length >= 2 && this.Limitations?.InConfRestriction) || this.Participants.length >= 3) {
      for (let i = 0; i < this.LockedAttendees.length; i++) {
        this.RejectLockedAttendee(this.LockedAttendees[i]);
      }
    }
    await this.refreshAppointmentSize();
  }

  async onparticipantupdated(event: any) {
    for (let i = 0; i < this.Participants.length; i++) {
      if (this.Participants[i].participantId === event.participant.participantId) {
        if (event.participant.audioMuted !== undefined) {
          this.Participants[i].audioMuted = event.participant.audioMuted;
        }
      }
    }
  }

  async onparticipantremoved(event: any) {
    for (let i = 0; i < this.Participants.length; i++) {
      if (this.Participants[i].participantId === event.participant.participantId) {
        this.Participants.splice(i, 1);
        break;
      }
    }
    await this.refreshAppointmentSize();
    if (this.Participants.length < 1) {
      this.rtc.CloseUserMedia();
      this.rtc.StopScreenShare();
      if (!this.Public) {
        this.rtc.clearCall();
        this.Closed.emit();
        if (this.rtc.LocalStream !== undefined) {
          this.rtc.CloseUserMedia();
        }
      } else {
        this.rtc.clearCall();
        this.rtc.CloseUserMedia();
        this.stopTimer();
        this.State = "closed";
      }
    }
  }

  private scrollToBottom(): void {
    if (this.messageBox) {
      this.messageBox.nativeElement.scrollTop = this.messageBox.nativeElement.scrollHeight;
      //window.scrollTo(0, this.messageBox.nativeElement.scrollHeight);
      //this.messageBox.nativeElement.scrollTo({ top: this.messageBox.nativeElement.scrollHeight })
    }
  }

  FileTransferUsed(): void {
    //this.Used.emit('file')
  }

  sendChatMessage(): void {
    //let isDataChannel = true
    if (this.Data.ChatText != "") {
      //this.Used.emit('chat')
      let options = undefined;
      /*if (!isDataChannel) {
                options = {
                    content: this.Data.ChatText,
                    contentType: 'text/plain'
                }
            } else {
                options = {
                    content: this.Data.ChatText,
                    contentType: 'application/x-data-channel'
                }
                
            }*/
      options = {
        content: this.Data.ChatText,
        contentType: "text/plain",
        timestamp: new Date().toISOString(),
        logo: this.Customer?.LogoURI ? this.Customer?.LogoURI : "/assets/icons/profile-default.svg",
      };
      this.Messages.push({
        FromMe: true,
        From: "Moi",
        Type: "chat",
        Text: options.content,
        Timestamp: options.timestamp,
        Logo: options.logo,
      });
      this.rtc.sendMessage(options);
      this.newMessage = true;
      this.Data.ChatText = "";
    }
  }

  //sendLaserMessage(enabled: boolean, x: number, y: number, w: number, h: number): void {
  sendLaserMessage(enabled: boolean, x: number, y: number): void {
    //ACTIVATE LASER
    let data = {
      From: this.Public ? this.UserInfo.Name : this.Customer.Name,
      Enabled: enabled,
      X: x,
      Y: y,
    };
    let options = {
      content: JSON.stringify(data),
      contentType: "x-laser",
    };
    this.rtc.sendLaserState(options);
  }

  async onauthorizationforbidden() {
    this.bRoomFull = true;
    this.hasBeenConnected = false;
    this.rtc.clearCall();
    this.rtc.CloseUserMedia();
    this.stopTimer();
    this.State = "closed";
  }

  async onauthorization() {
    if (this.UserInfo.ReconnectID === "") {
      //this.rtc.InitMakeCall(null, true, null, this.UserInfo.ReconnectID)
      this.rtc.InitMakeCall(null, false, null, this.UserInfo.ReconnectID, true);
    } else {
      let myThis = this;
      return new Promise((resolve: any, reject: any) => {
        myThis.rtc.GetUserMedia(true, null).then(
          function (success: any) {
            myThis.rtc.InitMakeCall(null, false, null, myThis.UserInfo.ReconnectID, true);
            resolve(success);
          },
          function (error: any) {
            //GETUSERMEDIA ERROR BUT WE TRY TO CONNECT TO THE CALL
            myThis.rtc.InitMakeCall(null, false, null, myThis.UserInfo.ReconnectID, true);
            reject(error);
          }
        );
      });
    }
  }

  async onmessage(message: any) {
    if (message.contentDisposition === "attachment" || message.contentType === "text/plain" || message.contentType === "x-file") {
      if (!this.rightPanel.Chat || !this.rightPanel.Display) {
        this.manageRightPanelState("chat", true);
      }
      if (message.FromMe) {
        if (message.contentDisposition !== "attachment" && message.contentType === "text/plain") {
          this.Messages.push({
            FromMe: false,
            From: "Moi",
            Type: "chat",
            Text: message.content,
            Timestamp: message.timestamp,
            Logo: message.logo,
          });
        } else if (message.contentDisposition === "attachment") {
          let binaryContent: string = "";
          let contentParsed: any = {};
          if (message.contentType === "x-file") {
            contentParsed = JSON.parse(message.content);
            binaryContent = btoa(contentParsed.file);
          } else {
            binaryContent = btoa(message.content);
          }
          //let binaryContent = btoa(message.content);
          let b64Content = this.base64ToArrayBuffer(binaryContent);
          this.Messages.push({
            FromMe: message.FromMe === true ? message.FromMe : false,
            From: "Moi",
            Type: "file",
            Text: message.filename,
            Timestamp: contentParsed?.timestamp,
            Logo: contentParsed?.logo,
            Url: () => {
              this.saveByteArray(message.filename, b64Content);
            },
          });
        }
        this.newMessage = true;
      } else {
        for (let i = 0; i < this.Participants.length; i++) {
          if (this.Participants[i].participantId === message.participantId) {
            if (message.contentDisposition !== "attachment" && message.contentType === "text/plain") {
              this.Messages.push({
                FromMe: false,
                From: this.Participants[i].remoteName,
                Type: "chat",
                Text: message.content,
                Timestamp: message.timestamp,
                Logo: message.logo,
              });
            } else if (message.contentDisposition === "attachment") {
              let binaryContent: string = "";
              let contentParsed: any = {};
              if (message.contentType === "x-file") {
                contentParsed = JSON.parse(message.content);
                binaryContent = btoa(contentParsed.file);
              } else {
                binaryContent = btoa(message.content);
              }
              let b64Content = this.base64ToArrayBuffer(binaryContent);
              this.Messages.push({
                FromMe: false,
                From: this.Participants[i].remoteName,
                Type: "file",
                Text: message.filename,
                Timestamp: contentParsed?.timestamp,
                Logo: contentParsed?.logo,
                Url: () => {
                  this.saveByteArray(message.filename, b64Content);
                },
              });
            }
            this.newMessage = true;
          }
        }
      }
      /*if (message.FromMe !== true && !this.EmiterHandler) {
                    this.NgmsService.EmitNgmsSettings(message, "OnMessage");
                }*/
      //this.EmiterHandler = false
    } else {
      if (message.contentType.startsWith("x-command-close-pdf")) {
        if (this.CurrentPDF?.ID === message.docId) {
          this.ClosePDFVect(true);
        } else {
          this.cancelledPdfId = message.docId;
        }
      } else if (message.contentType.startsWith("x-command-delete-document")) {
        this.DeleteDoc(true, Number(message.content));
      } else if (message.contentType.startsWith("x-vizu-logs")) {
      } else if (message.contentType.startsWith("x-laser")) {
        this.handleLaserMessage(message);
      } else {
        if (this.hasFocus) {
          this.NeedFocusModal = false;
          this.processMessageReceived(message);
        } else {
          this.NeedFocusModal = true;
          this.messageQueue.push(message);
          console.log("---- Message added to queue: " + message);
        }
      }
    }
  }

  isImage(filename: string) {
    let fileExtension = filename.substr(filename.length - 3 - filename.length - 1);
    if (fileExtension == ".jpg" || fileExtension == ".jpeg" || fileExtension == ".png" || fileExtension == ".gif") {
      return true;
    } else {
      return false;
    }
  }

  async onmessagedatachannel(msg: any) {
    let message = JSON.parse(msg);
    console.log("dataChannelMessage received: " + message);
    if (message.contentDisposition === "attachment" || message.contentType === "text/plain" || message.contentType === "x-file") {
      if (!this.rightPanel.Chat || !this.rightPanel.Display) {
        this.manageRightPanelState("chat", true);
      }
      if (message.FromMe) {
        if (message.contentDisposition !== "attachment" && message.contentType === "text/plain") {
          this.Messages.push({
            FromMe: false,
            From: "Moi",
            Type: "chat",
            Text: message.content,
            Timestamp: message.timestamp,
            Logo: message.logo,
          });
        } else if (message.contentDisposition === "attachment") {
          let binaryContent: string = "";
          let contentParsed: any = {};
          if (message.contentType === "x-file") {
            contentParsed = JSON.parse(message.content);
            binaryContent = btoa(contentParsed.file);
          } else {
            binaryContent = btoa(message.content);
          }
          let b64Content = this.base64ToArrayBuffer(binaryContent);
          let bImage = this.isImage(message.filename);
          this.Messages.push({
            FromMe: message.FromMe === true ? message.FromMe : false,
            From: "Moi",
            Type: "file",
            Text: message.filename,
            Timestamp: contentParsed?.timestamp,
            Logo: contentParsed?.logo,
            ImageData: bImage ? this.sanitizer.bypassSecurityTrustResourceUrl("data:image/png;base64, " + binaryContent) : undefined,
            Url: () => {
              this.saveByteArray(message.filename, b64Content);
            },
          });
        }
        this.newMessage = true;
      } else {
        for (let i = 0; i < this.Participants.length; i++) {
          if (this.Participants[i].participantId === message.participantId) {
            console.log(this.Participants[i]);
            console.log(this.Contacts);
            if (message.contentDisposition !== "attachment" && message.contentType === "text/plain") {
              this.Messages.push({
                FromMe: false,
                From: this.Participants[i].remoteName,
                Type: "chat",
                Text: message.content,
                Timestamp: message.timestamp,
                Logo: message.logo,
              });
            } else if (message.contentDisposition === "attachment") {
              let binaryContent: string = "";
              let contentParsed: any = {};
              if (message.contentType === "x-file") {
                contentParsed = JSON.parse(message.content);
                binaryContent = btoa(contentParsed.file);
              } else {
                binaryContent = btoa(message.content);
              }
              let b64Content = this.base64ToArrayBuffer(binaryContent);
              let bImage = this.isImage(message.filename);
              this.Messages.push({
                FromMe: false,
                From: this.Participants[i].remoteName,
                Type: "file",
                ImageData: bImage ? this.sanitizer.bypassSecurityTrustResourceUrl("data:image/png;base64, " + binaryContent) : undefined,
                Text: message.filename,
                Timestamp: contentParsed?.timestamp,
                Logo: contentParsed?.logo,
                Url: () => {
                  this.saveByteArray(message.filename, b64Content);
                },
              });
            }
            this.newMessage = true;
          }
        }
      }
      /*if (message.FromMe !== true && !this.EmiterHandler) {
                    this.NgmsService.EmitNgmsSettings(message, "OnMessage");
                }*/
      //this.EmiterHandler = false
    } else {
      if (message.contentType.startsWith("x-command-close-pdf")) {
        if (this.CurrentPDF?.ID === message.docId) {
          this.ClosePDFVect(true);
        } else {
          this.cancelledPdfId = message.docId;
        }
      } else if (message.contentType.startsWith("x-command-delete-document")) {
        this.DeleteDoc(true, Number(message.content));
      } else if (message.contentType.startsWith("x-vizu-logs")) {
      } else if (message.contentType.startsWith("x-laser")) {
        this.handleLaserMessage(message);
      } else {
        if (this.hasFocus) {
          this.NeedFocusModal = false;
          this.processMessageReceived(message);
        } else {
          this.NeedFocusModal = true;
          this.messageQueue.push(message);
          console.log("---- Message added to queue: " + message);
        }
      }
    }
  }

  onmessagedeliveryprogress(message: any) {
    console.log("--------ROOM / ON MESSAGE PROGRESS");
    this.NgmsService.EmitNgmsSettings(message, "OnMessageDeliveryProgress");
  }

  base64ToArrayBuffer(base64: any) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  handleRecaptcha(isAuthorized: boolean) {
    if (isAuthorized) {
      this.userDetected = true;
    } else {
      this.userDetected = false;
    }
  }

  checkActionToken(confidence: number) {
    if (confidence >= 0.3) {
      this.handleRecaptcha(true);
      this.SetName();
    } else {
      this.handleRecaptcha(false);
    }
  }

  getcaptcha() {
    this.recaptchaComponent.callRecaptcha("login").subscribe({
      next: (token) => {
        console.log("reCAPTCHAv3 token received: " + token);
        //Send token to server and get confidence index
        this.rtc.getConfidenceLevel(token, this.userAction).subscribe(
          (confidenceServer: any) => {
            //Then check the confidence we got from the server
            this.checkActionToken(confidenceServer.confidence);
          },
          (error: any) => {
            console.log(`error:`, error);
            this.handleRecaptcha(true);
            this.SetName();
          }
        );
      },
      error: (e) => {
        console.log(`error:`, e);
        this.handleRecaptcha(true);
        this.SetName();
      },
    });
  }

  async SetName(): Promise<void> {
    if (this.UserInfo.ReconnectID !== "" || this.UserInfo.ReconnectID !== undefined || this.userDetected) {
      if (this.UserInfo.Name !== "") {
        if (this.bTextCall) {
          await this.ToggleVid();
          await this.ToggleMic();
        }
        this.UserInfo.Name = this.UserInfo.Name.charAt(0).toUpperCase() + this.UserInfo.Name.slice(1);

        this.rtc.getAuthorization(this.AppointmentID, false, this.UserInfo.Name, this.UserInfo.ReconnectID);
        this.rtc.checkRTCStreamErrors();

        this.soundMeterStop();
        this.State = "waiting";
        let myThis = this;
        setTimeout(function () {
          myThis.callTimerTooLong = true;
        }, 15000);
      } else {
        this.isNameError = true;
      }
    }
  }

  private async checkWebRTCSupport(): Promise<void> {
    if (this.OSBrowser.Browser === "IE") {
      this.bSupported = false;
      this.bIEReason = true;
    } else if ((this.OSBrowser.Browser === "Firefox" || this.OSBrowser.Browser === "Chrome") && this.OSBrowser.OS.search("IOS") !== -1) {
      this.bSupported = false;
      this.bIEReason = false;
    } else {
      this.bSupported = true;
    }
  }

  private getBooleanFromLocalStorage(item: string) {
    return localStorage.getItem(item) === "true";
  }

  async ongetusermediasucceeded() {
    this.bGetUserMediaSucceeded = true;
    console.log("GET USER MEDIA DONE ++++++++++");
    //let localStreamCap = myThis.rtc.getLocalStreamCapabilities()
    if (!this.getUserMediaDone && this.Public && this.OSBrowser.Browser === "Firefox" && !this.rtc.deviceInfos.hasPermissions) {
      console.log("CLOSE USER MEDIA DONE ----------");
      this.rtc.CloseUserMedia();
    } else {
      if (this.rtc.LocalStream !== undefined) {
        this.LocalStream = this.rtc.LocalStream;
        this.LocalStreamAudio = this.rtc.LocalStreamAudio;
        this.LocalStreamVideo = this.rtc.LocalStreamVideo;
        this.soundMeterStart(this.LocalStream);
      }
    }
    let audioItem = "VizuLive\\LocalStreamAudioEnabled";
    if (localStorage.getItem(audioItem) !== "null" && localStorage.getItem(audioItem) !== null) {
      this.LocalStreamAudioEnabled = this.getBooleanFromLocalStorage(audioItem);
      this.rtc.ToggleMic(this.LocalStreamAudioEnabled);
    }
    let videoItem = "VizuLive\\LocalStreamVideoEnabled";
    if (localStorage.getItem(videoItem) !== "null" && localStorage.getItem(videoItem) !== null) {
      this.LocalStreamVideoEnabled = this.getBooleanFromLocalStorage(videoItem);
      this.rtc.ToggleVid(this.LocalStreamVideoEnabled);
    }
    this.getUserMediaDone = true;
  }

  private async getLocalMediaAccess(constraints: any): Promise<void> {
    this.checkWebRTCSupport();
    /*let myThis = this
        return new Promise((resolve: any, reject: any) => {
            myThis.rtc.GetUserMedia(true, constraints)
            .then(
                function(success: any) {
                    resolve(success)
                },
                function(error: any) {
                    reject(error)
                }
            )
        })*/
    //myThis.rtc.GetUserMedia(true, constraints)
    //await this.getLocalMedia(constraints)
    let stream = undefined;
    await this.getLocalMedia(constraints);
  }

  private async getLocalMedia(constraints: any): Promise<void> {
    //this.rtc.setLocalMediaTag("preview")
    /*this.State = 'media_access';
        console.log('media_access')*/
    this.checkWebRTCSupport();
    let myThis = this;
    if (this.queryAppointment === "max") {
      try {
        console.log("max room");
        this.rtc.notifyOnGetUserMedia = this.ongetusermediasucceeded.bind(this);
        this.rtc
          .GetUserMediaLow(320, 240)
          .then(function () {
            //GO TO STEP 1: GET AUTH TOKEN
            myThis.LocalStream = myThis.rtc.LocalStream;
            //let localStreamCap = myThis.rtc.getLocalStreamCapabilities()
            myThis.LocalStreamAudio = myThis.rtc.LocalStreamAudio;
            myThis.LocalStreamVideo = myThis.rtc.LocalStreamVideo;
            if (myThis.State !== "started") myThis.State = "media_access_success";
            console.log("media_access_success");
          })
          .catch(function (error: any) {
            console.log("-------GETUSERMEDIA ERROR-------- " + error);
            if (myThis.State !== "started") myThis.State = "media_access_error";
          });
      } catch (error) {
        if (this.State !== "started") this.State = "media_access_error";
        console.log("media_access_error");
        return;
      }
    } else {
      try {
        if (this.queryAppointment === "novid") {
          this.rtc.notifyOnGetUserMedia = this.ongetusermediasucceeded.bind(this);
          this.rtc
            .GetUserMedia(false, constraints)
            .then(function () {
              //GO TO STEP 1: GET AUTH TOKEN
              myThis.LocalStream = myThis.rtc.LocalStream;
              //let localStreamCap = myThis.rtc.getLocalStreamCapabilities()
              myThis.LocalStreamAudio = myThis.rtc.LocalStreamAudio;
              myThis.LocalStreamVideo = myThis.rtc.LocalStreamVideo;
              if (myThis.State !== "started") myThis.State = "media_access_success";
            })
            .catch(function (error: any) {
              console.log("-------GETUSERMEDIA ERROR-------- " + error);
              if (myThis.State !== "started") myThis.State = "media_access_error";
            });
        } else {
          //this.LocalStream = await this.rtc.GetUserMedia(true, constraints)
          this.rtc.notifyOnGetUserMedia = this.ongetusermediasucceeded.bind(this);
          this.rtc
            .GetUserMedia(true, constraints)
            .then(function () {
              //GO TO STEP 1: GET AUTH TOKEN
              myThis.LocalStream = myThis.rtc.LocalStream;
              myThis.LocalStreamAudio = myThis.rtc.LocalStreamAudio;
              myThis.LocalStreamVideo = myThis.rtc.LocalStreamVideo;
              //myThis.ongetusermediasucceeded()
              //myThis.State = 'media_access_success'
              //console.log("media_access_success")
            })
            .catch(function (error: any) {
              console.log("-------GETUSERMEDIA ERROR-------- " + error);
              if (myThis.State !== "started") myThis.State = "media_access_error";
            });
        }
      } catch (error) {
        if (this.State !== "started") this.State = "media_access_error";
        console.log("media_access_error");
        return;
      }
    }
  }

  AnswerLockedAttendee(attendee: any): void {
    /*this.rtc.AnswerLockedAttendee(attendee)
        .subscribe(answered => {
            this.removeLockedAttendee(attendee)
        })*/
    let bSuccess = this.rtc.AnswerLockedAttendee(attendee);
    if (bSuccess) {
      this.removeLockedAttendee(attendee);
    }
  }

  RejectLockedAttendee(attendee: any): void {
    /*this.rtc.RejectLockedAttendee(attendee)
        .subscribe(rejected => {
            this.removeLockedAttendee(attendee)
        })*/
    let bSuccess = this.rtc.RejectLockedAttendee(attendee);
    if (bSuccess) {
      this.removeLockedAttendee(attendee);
    }
  }

  private removeLockedAttendee(attendee: any): void {
    for (let i = 0; i < this.LockedAttendees.length; i++) {
      if (this.LockedAttendees[i] === attendee) {
        this.LockedAttendees.splice(i, 1);
        return;
      }
    }
  }

  private addLockedAttendee(attendee: string): void {
    this.LockedAttendees.push(attendee);
    return;
  }

  Close(): void {
    if (!this.Public) {
      //this.rtc.CloseAppointment(this.Appointment.ID)
      //HERE WE HAVE TO END THE CALL AND KICK EVERYONE
      this.rtc.clearCall();
      this.Closed.emit();
    } else {
      //this.rtc.LeaveAppointment(this.AppointmentID)
      this.rtc.clearCall();
      /*this.eventSubscription.unsubscribe()
            if (this.mediaSubscription != undefined) {
                this.mediaSubscription.unsubscribe()
            }*/
      this.rtc.CloseUserMedia();
      this.bGetUserMediaSucceeded = false;
      this.stopTimer();
      this.State = "closed";
    }
  }

  padStart(string: string, targetLength: any, padString: any) {
    targetLength = targetLength >> 0; //truncate if number or convert non-number to 0;
    padString = String(typeof padString !== "undefined" ? padString : " ");
    if (string.length > targetLength) {
      return String(string);
    } else {
      targetLength = targetLength - string.length;
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
      }
      return padString.slice(0, targetLength) + String(string);
    }
  }

  private startTimer(): void {
    this.stopTimer();
    this.TimerStarted = true;
    this.sessionInterval = window.setInterval(() => {
      this.SessionDuration++;
      let SessionMinutesFloat = this.SessionDuration / 60;
      if (this.Limitations !== undefined) {
        if (this.Limitations.InConfRestriction === true) {
          if (SessionMinutesFloat === 40) {
            if (!this.Public) {
              this.b5MinRemaining = true;
            }
          }
          if (SessionMinutesFloat === 45) {
            this.Close();
          }
        }
      }
      this.SessionMinutes = Math.floor(SessionMinutesFloat).toString();
      this.SessionMinutes = this.padStart(this.SessionMinutes, 2, "0");
      this.SessionSeconds = this.padStart((this.SessionDuration % 60).toString(), 2, "0");
    }, 1000);
  }

  private stopTimer(): void {
    if (this.TimerStarted) {
      window.clearInterval(this.sessionInterval);
      this.TimerStarted = false;
    }
  }

  sendClickInfoMessage(info: string) {
    if (info == "SetPublicName" && this.rtc.cookies?.performance) {
      localStorage.setItem("VizuLive\\Name", this.UserInfo.Name);
    }
    this.rtc.sendClickMessage(info);
  }

  async SetConf(): Promise<void> {
    if (this.MainAttendee != undefined) {
      if (this.LocalStreamIsMain) {
        this.LocalStreamIsMain = false;
      } else {
        this.Participants.splice(this.AttendeeInMemId, 0, this.AttendeeInMem);
        this.AttendeeInMem = undefined;
        this.AttendeeInMemId = undefined;
      }
      this.MainAttendee = undefined;
    }
    if (this.ScreenSharingEnabled) {
      await this.ToggleScreenSharing();
    }
    if (this.PDFVectSharingEnabled || this.bSharingImage) {
      this.ClosePDFVect(false);
    }
    if (!this.LocalStreamAudioEnabled && this.LocalStreamAudio) {
      this.rtc.ToggleMic(this.LocalStreamAudioEnabled);
    }
    if (!this.LocalStreamVideoEnabled && this.LocalStreamVideo) {
      this.rtc.ToggleVid(this.LocalStreamVideoEnabled);
    }
    if (this.CurrentPDF !== undefined) {
      this.CurrentPDF = undefined;
    }
    this.manageRightPanelState("chat", false);
  }

  private async checkExtensionInstallation(): Promise<void> {
    while (this.ExtensionNotInstalledModal) {
      const installed = await this.rtc.IsChromeCaptureScreenExtensionInstalled();
      if (installed) {
        this.ExtensionInstalled = true;
        return;
      }
      await sleep(1000);
    }
  }

  async SwapCamera(): Promise<void> {
    //let constraints = this.changeVideoConstraints()
    //this.updateGetLocalMedia()
    let myThis = this;
    new Promise((resolve: any, reject: any) => {
      myThis.rtc.SwapCamera().then(
        async function (success: any) {
          myThis.CameraSwapped = !myThis.CameraSwapped;
          myThis.rtc.ToggleMic(myThis.LocalStreamAudioEnabled);
          myThis.rtc.ToggleVid(myThis.LocalStreamVideoEnabled);
        },
        (error: any) => {
          console.log(error);
        }
      );
    });
  }

  async AddConfParticipant(numberToConf: string): Promise<void> {
    this.rtc.addParticipant(numberToConf);
  }

  ToggleMic(): void {
    if (this.LocalStreamAudio) {
      this.LocalStreamAudioEnabled = !this.LocalStreamAudioEnabled;
      this.rtc.ToggleMic(this.LocalStreamAudioEnabled);
      localStorage.setItem("VizuLive\\LocalStreamAudioEnabled", this.LocalStreamAudioEnabled.toString());
    }
  }

  ToggleVid(): void {
    if (this.LocalStreamVideo) {
      this.LocalStreamVideoEnabled = !this.LocalStreamVideoEnabled;
      this.rtc.ToggleVid(this.LocalStreamVideoEnabled);
      localStorage.setItem("VizuLive\\LocalStreamVideoEnabled", this.LocalStreamVideoEnabled.toString());
    }
  }

  ToggleDots(): void {
    this.DotsEnabled = !this.DotsEnabled;
  }

  ToggleWhiteboard(): void {
    this.WhiteboardEnabled = !this.WhiteboardEnabled;
    if (this.WhiteboardEnabled) {
      if (this.ScreenSharingEnabled) {
        this.ToggleScreenSharing();
      }
      /*miroBoardsPicker.open({
                clientId: '...', // 1) Put your 'clientId' here.
                action: 'access-link',
                allowCreateAnonymousBoards: true, //2) Enable this option 
                getToken: () => getTokenFromServer(), // Provide token in async way
                success: data => {
                console.log('on success', data)
                document.querySelector('#container').innerHTML = data.embedHtml
                },
                
                error: e => {
                console.log('on error', e)
                },
                
                cancel: () => {
                console.log('on cancel')
                },
                
                windowRef: windowRef, // Link to an open popup window 
            })*/
    } else {
      this.PDFVectSharingEnabled = false;
      this.bSharingImage = false;
      this.manageRightPanelState("cam", true);
      this.ToggleDots();
    }
  }

  async ToggleScreenSharing(): Promise<void> {
    try {
      if (this.ScreenSharingEnabled) {
        this.ScreenSharingEnabled = false;
        this.ToggleDots();
        this.ScreenStream = undefined;
        this.rtc.StopScreenShare();
        this.manageRightPanelState("chat", false);
      } else {
        this.ScreenSharingEnabled = true;
        new Promise((resolve: any, reject: any) => {
          this.rtc.StartScreenShare(this.ScreenStreamId).then(
            (success: any) => {
              console.log("LOCAL SCREENSHARING SUCCESS");
              this.ScreenStream = this.rtc.ScreenStream;
              this.PDFVectSharingEnabled = false;
              this.bSharingImage = false;
              this.manageRightPanelState("cam", true);
            },
            (error: any) => {
              console.log("LOCAL SCREENSHARING FAILED / error: " + error.error);
              this.ScreenSharingEnabled = false;
              this.bSharingImage = false;
              this.ScreenStream = undefined;
              this.rtc.StopScreenShare();
              //this.manageRightPanelState('cam', false) //already false
            }
          );
        });
        /*if (this.rtc.ScreenStream !== undefined || this.rtc.ScreenStream !== null) {
                    this.ScreenStream = this.rtc.ScreenStream
                } else {
                    this.ScreenStream = undefined
                    this.rtc.StopScreenShare()
                }*/
      }
    } catch (error) {
      console.log("screen share error", error);
      if (error.ExtensionNotInstalled) {
        this.ExtensionNotInstalledModal = true;
        this.ExtensionInstalled = false;
        this.checkExtensionInstallation();
      } else if (!error.Canceled) {
        this.ScreenCaptureErrorModal = true;
      }
    }
  }

  OpenAddParticipantModal() {
    this.NeedAddParticipantModal = true;
  }

  ShowHiddenAttendee() {
    if (this.MainAttendee != undefined) {
      if (!this.LocalStreamIsMain) {
        for (let i = 0; i < this.Participants.length; i++) {
          this.Participants[i].showMiniVideo = true;
        }
        /*this.Participants.splice(this.AttendeeInMemId, 0, this.AttendeeInMem)
                this.AttendeeInMem = undefined
                this.AttendeeInMemId = undefined*/
      }
      this.MainAttendee = undefined;
      this.MainAttendeeId = undefined;
    }
  }

  ReinitConf() {
    this.ShowHiddenAttendee();
    this.SetConf();
  }

  async SetMainVideo(attendee: any) {
    this.ShowHiddenAttendee();
    if (attendee === undefined) {
      this.MainAttendee = {
        Name: this.Customer.Name,
        Stream: this.LocalStream,
      };
      this.MainAttendeeId = this.MainAttendee.Stream.id;
      this.LocalStreamIsMain = true;
    } else {
      if (this.LocalStreamIsMain) {
        this.LocalStreamIsMain = false;
      }

      this.MainAttendee = {
        Name: attendee.remoteName,
        Stream: attendee.Streams[0].stream,
      };
      this.MainAttendeeId = this.MainAttendee.Stream.stream.streamId;

      for (let i = 0; i < this.Participants.length; i++) {
        if (this.Participants[i].participantId === attendee.participantId) {
          this.Participants[i].showMiniVideo = false;
          //this.AttendeeInMem = this.Participants[i]
          //this.AttendeeInMemId = i
          //this.Participants.splice(i, 1)
          break;
        }
      }
    }
    this.manageRightPanelState("cam", true);
  }

  async DocumentSwitch(bIncoming: boolean, page: number, guid: any) {
    let wasPDFSharing = this.PDFVectSharingEnabled;
    if (this.ScreenSharingEnabled) {
      await this.ToggleScreenSharing();
    }
    //const isPDF = fileInfo.Name.split('.').pop() === 'pdf'
    //this.rtc.SendPDFUploaded(fileInfo.ID, fileInfo.Name, this.PageSelected, !isPDF)
    if (this.CurrentPDF === undefined) {
      for (let i = 0; i <= this.Docs.length; i++) {
        if (Number(this.Docs[i].ID) === Number(guid)) {
          this.CurrentPDF = this.Docs[i];
          console.log("------ SWITCH TO IMAGE OR PDF ------- INCOMING = " + bIncoming);
          if (!bIncoming) {
            let options = {
              content: this.CurrentPDF.ID.toString(),
              contentType: "x-command-select-document; subtype=text/plain",
              logo: this.Customer?.LogoURI ? this.Customer?.LogoURI : "/assets/icons/profile-default.svg",
            };
            this.rtc.sendMessage(options);
          }

          switch (this.CurrentPDF.Type) {
            case "application/pdf":
              this.PDFVectSharingEnabled = true;
              this.bSharingImage = false;
              var pdfjs = (window as any)["pdfjs-dist/build/pdf"];
              pdfjs.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.2/pdf.worker.min.js";

              //const pdfData = loadFile(file)

              this.pdfVect = await pdfjs.getDocument({
                data: this.CurrentPDF.Data,
              }).promise;

              this.PageMaxVect = this.pdfVect.numPages;

              await sleep(100);

              await this.SelectPDFPageVect(1, bIncoming);
              break;
            case "image/png":
            case "image/jpeg":
              this.SharedImageData = this.sanitizer.bypassSecurityTrustResourceUrl(
                "data:" + this.CurrentPDF.Type + ";base64, " + this.CurrentPDF.Data
              );
              this.bSharingImage = true;
              this.PDFVectSharingEnabled = false;
              break;
          }
          break;
        }
      }
    } else {
      if (Number(this.CurrentPDF.ID) === Number(guid)) {
        switch (this.CurrentPDF.Type) {
          case "application/pdf":
            this.bSharingImage = false;
            this.PDFVectSharingEnabled = true;
            await sleep(100);
            await this.SelectPDFPageVect(page, bIncoming);
            if (!bIncoming) {
              //This one switch to doc page 1 unfortunatelly...
              if (!wasPDFSharing) {
                let optionsDoc = {
                  content: this.CurrentPDF.ID.toString(),
                  contentType: "x-command-select-document; subtype=text/plain",
                  logo: this.Customer?.LogoURI ? this.Customer?.LogoURI : "/assets/icons/profile-default.svg",
                };
                this.rtc.sendMessage(optionsDoc);
              }
              let optionsPage = {
                content: page.toString(),
                contentType: "x-command-select-page; subtype=text/plain",
                logo: this.Customer?.LogoURI ? this.Customer?.LogoURI : "/assets/icons/profile-default.svg",
              };
              this.rtc.sendMessage(optionsPage);
            }
            break;
          case "image/png":
          case "image/jpeg":
            this.SharedImageData = this.sanitizer.bypassSecurityTrustResourceUrl(
              "data:" + this.CurrentPDF.Type + ";base64, " + this.CurrentPDF.Data
            );
            this.bSharingImage = true;
            this.PDFVectSharingEnabled = false;
            break;
        }
      } else {
        for (let i = 0; i <= this.Docs.length; i++) {
          if (Number(this.Docs[i].ID) === Number(guid)) {
            console.log("Current PDF: " + this.CurrentPDF.Name + " / PDF Needed: " + this.Docs[i].Name);
            this.CurrentPDF = this.Docs[i];
            switch (this.CurrentPDF.Type) {
              case "application/pdf":
                this.PDFVectSharingEnabled = true;
                this.bSharingImage = false;
                var pdfjs = (window as any)["pdfjs-dist/build/pdf"];
                pdfjs.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.2/pdf.worker.min.js";

                //const pdfData = loadFile(file)

                this.pdfVect = await pdfjs.getDocument({
                  data: this.CurrentPDF.Data,
                }).promise;

                this.PageMaxVect = this.pdfVect.numPages;

                if (!bIncoming) {
                  let optionsDoc = {
                    content: this.CurrentPDF.ID.toString(),
                    contentType: "x-command-select-document; subtype=text/plain",
                    logo: this.Customer?.LogoURI ? this.Customer?.LogoURI : "/assets/icons/profile-default.svg",
                  };
                  this.rtc.sendMessage(optionsDoc);
                }

                await sleep(100);
                // HERE FORCE TO SEND NOTHING ON THIS SELECTPAGE, BECAUSE INFOS WILL BE SEND LATER
                await this.SelectPDFPageVect(page, bIncoming);
                break;
              case "image/png":
              case "image/jpeg":
                if (!bIncoming) {
                  let options = {
                    content: this.CurrentPDF.ID.toString(),
                    contentType: "x-command-select-document; subtype=text/plain",
                    logo: this.Customer?.LogoURI ? this.Customer?.LogoURI : "/assets/icons/profile-default.svg",
                  };
                  this.rtc.sendMessage(options);
                }
                this.SharedImageData = this.sanitizer.bypassSecurityTrustResourceUrl(
                  "data:" + this.CurrentPDF.Type + ";base64, " + this.CurrentPDF.Data
                );
                this.bSharingImage = true;
                this.PDFVectSharingEnabled = false;
                break;
            }
          }
        }
      }
    }
    /*while (hasFocus !== true) {
            if (document.hasFocus) {
                let bSucceeded = this.pdfUploaded(fileInfo)
                if (bSucceeded) {
                hasFocus = true
                }
            }
        }*/

    //OLD WAS HERE
  }

  /*async DocumentSwitch(fileInfo: any): Promise<void> {
        const isPDF = fileInfo.Name.split('.').pop() === 'pdf'
        this.rtc.SendPDFUploaded(fileInfo.ID, fileInfo.Name, this.PageSelected, !isPDF)
        this.CurrentPDF = fileInfo
        await this.pdfUploaded(fileInfo)
    }*/

  /*private async pdfUploaded(fileInfo: any): Promise<boolean> {
        const appointmentID = this.Public ? this.AppointmentID : this.Appointment.FriendlyID

        if (this.ScreenSharingEnabled) {
            await this.ToggleScreenSharing()
        }

        const isPDF = fileInfo.Name.split('.').pop() === 'pdf'
        if (!isPDF) {
            this.bSharingImage = true
            //this.SharedImageURL = `/rtc/file/${appointmentID}/${fileInfo.ID}`
            this.PDFVectSharingEnabled = false
            return
        }

        try {
            const pdfData = await this.rtc.GetPDF(appointmentID, fileInfo.ID)

            var pdfjs = (window as any)['pdfjs-dist/build/pdf'];
            pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.2/pdf.worker.min.js'

            this.pdfVect = await pdfjs.getDocument({
                data: pdfData,
            }).promise

            this.PageMaxVect = this.pdfVect.numPages

            this.PDFVectSharingEnabled = true
            this.bSharingImage = false

            await sleep(100)
            if (fileInfo.Page <= this.pdfVect.numPages) {
                await this.SelectPDFPageVect(fileInfo.Page, false)
            } else {
                await this.SelectPDFPageVect(1, false)
            }

            return true
        } catch (error) {
            console.log(error)
            return false
        }
    }*/

  async SharePDFVect(event: any): Promise<void> {
    if (event == undefined || event.target == undefined) {
      return;
    }

    if (!this.leftPanel.Display) {
      this.manageLeftPanelState();
    }

    try {
      let files: File[] = event.target.files === undefined || event.target.files === null ? event.dataTransfer.files : event.target.files;
      for (const file of files) {
        let guid = Math.floor(Math.random() * 1000000);
        switch (file.type) {
          case "application/pdf":
            console.log("Sharing PDF");
            var pdfjs = (window as any)["pdfjs-dist/build/pdf"];
            pdfjs.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.2/pdf.worker.min.js";

            const pdfData = await loadFile(file);

            this.pdfVect = await pdfjs.getDocument({
              data: pdfData,
            }).promise;

            this.PageMaxVect = this.pdfVect.numPages;

            this.PDFVectSharingEnabled = true;
            this.bSharingImage = false;
            if (this.ScreenSharingEnabled) {
              this.ToggleScreenSharing();
            }

            await sleep(100);

            //Set bIncoming to true here to not send the page before the PDF
            await this.SelectPDFPageVect(1, true);

            this.PDFVectAdmin = true;

            let pdfRead = new FileReader();
            pdfRead.readAsBinaryString(file);
            let myPdfThis = this;
            pdfRead.onloadend = function () {
              myPdfThis.notificationComponent.displayNotification("showSendFilePending");
              let options = {
                content: pdfRead.result,
                contentType: "x-share; subtype=" + file.type,
                contentDisposition: "attachment; guid=" + guid,
                filename: file.name,
              };
              myPdfThis.rtc.sendMessage(options);
              myPdfThis.CurrentPDF = {
                ID: guid,
                Type: file.type,
                Name: file.name,
                Data: pdfRead.result,
                SrcData: undefined,
                Pages: myPdfThis.PageMaxVect,
                Display: true,
              };
              for (let i = 0; i < myPdfThis.Docs.length; i++) {
                myPdfThis.Docs[i].Display = false;
              }
              myPdfThis.Docs.push(myPdfThis.CurrentPDF);

              myPdfThis.DisplayPDFAllPages(guid);
              //options.FromMe = true
              //myPdfThis.NgmsService.EmitNgmsSettings(options, "OnMessage");
            };

            //const fileInfo = await this.rtc.SendPDF(this.Public ? this.AppointmentID : this.Appointment.FriendlyID, file)
            //this.rtc.SendPDFUploaded(fileInfo.ID, fileInfo.Name, this.PageSelected, false)

            break;
          case "image/png":
          case "image/jpeg":
            console.log("Sharing IMAGE");
            //const imageInfo = await this.rtc.SendPDF(this.Public ? this.AppointmentID : this.Appointment.FriendlyID, file)
            //this.rtc.SendPDFUploaded(imageInfo.ID, imageInfo.Name, this.PageSelected, false)
            let imgRead = new FileReader();
            imgRead.readAsBinaryString(file);
            let myImgThis = this;
            imgRead.onloadend = function () {
              myImgThis.notificationComponent.displayNotification("showSendFilePending");
              let options = {
                content: imgRead.result,
                contentType: "x-share; subtype=" + file.type,
                contentDisposition: "attachment; guid=" + guid,
                filename: file.name,
              };
              myImgThis.rtc.sendMessage(options);
              let base64Content = btoa(<string>imgRead.result);
              myImgThis.SharedImageData = myImgThis.sanitizer.bypassSecurityTrustResourceUrl(
                "data:" + file.type + ";base64, " + base64Content
              );
              myImgThis.CurrentPDF = {
                ID: guid,
                Type: file.type,
                Name: file.name,
                Data: base64Content,
                SrcData: myImgThis.SharedImageData,
                Display: true,
              };
              myImgThis.Docs.push(myImgThis.CurrentPDF);
              myImgThis.bSharingImage = true;
              myImgThis.PDFVectSharingEnabled = false;
            };
            /* BE CAREFUL THIS CODE ISN'T HERE AND COULD BE USEFULL
                    const appointmentID = this.Public ? this.AppointmentID : this.Appointment.FriendlyID*/

            break;
        }

        if (this.ScreenSharingEnabled) {
          await this.ToggleScreenSharing();
        }
      }
    } catch (error) {
      if (this.leftPanel.Display) {
        this.manageLeftPanelState();
      }
      console.log(error);
      this.PDFVectSharingEnabled = false;
      this.pdfVect = undefined;
      this.PDFErrorModal = true;
      return;
    }
    event.target.value = null;
  }

  async DisplayPDFAllPages(pdfId: number) {
    for (let i = 0; i < this.Docs.length; i++) {
      if (this.Docs[i].ID === pdfId) {
        for (let j = 0; j < this.Docs[i].Pages; j++) {
          await sleep(100);
          await this.DisplayPDFPageVect(j + 1, pdfId);
        }
      }
    }
  }

  async DisplayPDFPageVect(pageNumber: number, pdfId: number): Promise<void> {
    if (this.renderingPdf) {
      this.renderingPdf.cancel();
    }
    const page = await this.pdfVect.getPage(pageNumber);
    let viewport = page.getViewport({
      scale: 1,
    });

    const pdfRatio = viewport.width / viewport.height;

    const pdfContainerId = "pdf-container" + pdfId + "-page" + pageNumber;
    const pdfContainer = document.getElementById(pdfContainerId);
    const containerRect = pdfContainer.getBoundingClientRect();
    const containerRatio = containerRect.width / containerRect.height;

    let scale = 1;

    if (containerRatio > pdfRatio) {
      scale = containerRect.height / viewport.height;
    } else {
      scale = containerRect.width / viewport.width;
    }

    scale = containerRect.width / viewport.width;

    //scale *= this.PDFScale

    viewport = page.getViewport({
      scale: scale,
    });

    const pdfCanvasId = "doc" + pdfId + "-page" + pageNumber;
    const canvas = document.getElementById(pdfCanvasId) as HTMLCanvasElement;
    canvas.width = containerRect.width;
    canvas.height = containerRect.height;

    /*canvas.width = 120
        canvas.height = 80*/

    //viewport.height = canvas.height
    //viewport.width = canvas.width

    const renderContext = {
      canvasContext: canvas.getContext("2d"),
      viewport: viewport,
    };

    //console.log(viewport)

    /* NEED TO SEND THE PAGE NUMBER WITH NGMS
        if (send) {
            this.rtc.SendPDFPageNumber(pageNumber)
        }*/
    this.renderingPdf = page.render(renderContext);
    this.renderingPdf.promise.catch(function (err: any) {
      if (err.name === "RenderingCancelledException") {
        return;
      }
    });
    /* OLD THOMAS CODE BUG - IF MULTIPLE RENDER => PROBLEM RENDERING
        await (page.render(renderContext).promise)*/
  }

  async SelectPDFPageVect(pageNumber: number, bIncoming: boolean): Promise<void> {
    if (this.pdfVect !== undefined) {
      this.bSharingImage = false;
      this.PDFVectSharingEnabled = true;
      if (this.renderingPdf) {
        this.renderingPdf.cancel();
      }
      this.PageSelected = pageNumber;
      if (this.PageSelected < 1) {
        this.PageSelected = 1;
      } else if (this.PageSelected > this.pdfVect.numPages) {
        this.PageSelected = this.pdfVect.numPages;
      }
      const page = await this.pdfVect.getPage(this.PageSelected);
      let viewport = page.getViewport({
        scale: 1,
      });

      const pdfRatio = viewport.width / viewport.height;

      const pdfContainer = document.getElementById("pdf-container");
      const containerRect = pdfContainer.getBoundingClientRect();
      const containerRatio = containerRect.width / containerRect.height;

      let scale = 1;
      if (this.ContainerWidth == undefined) {
        this.ContainerWidth = containerRect.width;
      }
      scale = this.ContainerWidth / viewport.width;

      scale *= this.PDFScale;

      viewport = page.getViewport({
        scale: scale,
      });

      const canvas = document.getElementById("pdfvect") as HTMLCanvasElement;
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const renderContext = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };

      if (!bIncoming) {
        let options = {
          content: pageNumber.toString(),
          contentType: "x-command-select-page; subtype=text/plain",
        };
        this.rtc.sendMessage(options);
      }
      this.renderingPdf = page.render(renderContext);
      this.renderingPdf.promise.catch(function (err: any) {
        if (err.name === "RenderingCancelledException") {
          return;
        }
      });
    }
    /* OLD THOMAS CODE BUG - IF MULTIPLE RENDER => PROBLEM RENDERING
        await (page.render(renderContext).promise)*/
  }

  ClosePDFVect(bIncoming: boolean) {
    this.PDFVectSharingEnabled = false;
    this.pdfVect = undefined;
    this.PDFVectAdmin = false;
    this.bSharingImage = false;
    //this.SharedImageURL = undefined

    for (let i = 0; i < this.Participants.length; i++) {
      this.Participants[i].LaserState.Enabled = false;
    }

    if (!bIncoming) {
      let options = {
        content: "close",
        contentType: "x-command-close-pdf; subtype=text/plain",
        docId: this.CurrentPDF.ID,
      };
      this.rtc.sendMessage(options);
    }

    this.CurrentPDF = undefined;
    //HERE WE NEED TO SEND A CLOSE PDF
    //this.rtc.ClosePDF()
  }

  DeleteDoc(bIncoming: boolean, index: number): void {
    this.Docs.splice(index, 1);
    if (!bIncoming) {
      let options = {
        content: index.toString(),
        contentType: "x-command-delete-document; subtype=text/plain",
      };
      this.rtc.sendMessage(options);
    }
    if (this.Docs.length <= 0) {
      this.SetConf();
    }
  }

  Reload(): void {
    window.location.href = window.location.href;
  }

  Fullscreen(): void {
    const video = document.getElementById("screen_video");
    //TOCHECK
    /*if (screenfull.isEnabled) {
            if (screenfull.isFullscreen) {
                screenfull.toggle(video);
            } else {
                screenfull.request(video)
            }
        }*/

    if (!document.fullscreenElement) {
      video.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }

  LaserUp(): void {
    this.Laser.MouseDown = false;
    this.sendLaserMessage(false, 0, 0);
    this.setLaser();
  }

  LaserDown(): void {
    this.Laser.MouseDown = true;
    this.setLaser();
  }

  LaserMove(): void {
    this.setLaser();
  }

  private setLaser(): void {
    this.Laser.X = this.mouse.X - 10;
    this.Laser.Y = this.mouse.Y - 10;

    if (this.PDFVectSharingEnabled || this.bSharingImage === true) {
      const pdfContainer = document.getElementById("pdf-container");
      const pdfCanvas = document.getElementById("pdfvect");
      //this.Laser.Enabled = false
      if (pdfContainer != undefined && pdfCanvas != undefined) {
        const containerRect = pdfContainer.getBoundingClientRect();
        if (
          this.mouse.X < containerRect.left ||
          this.mouse.X > containerRect.right ||
          this.mouse.Y < containerRect.top ||
          this.mouse.Y > containerRect.bottom
        ) {
          if (this.Laser.Enabled) {
            this.sendLaserMessage(false, 0, 0);
          }
          this.Laser.Enabled = false;
          return;
        }

        const pdfRect = pdfCanvas.getBoundingClientRect();

        let laserX = 0;
        let laserY = 0;

        if (pdfRect.width < containerRect.width) {
          //laserX = this.Laser.X - containerRect.left + (totalPdfWidth - pdfRect.x)
          laserX = this.Laser.X - containerRect.left;
        } else {
          //laserX = this.Laser.X - containerRect.left + (totalPdfWidth - pdfRect.x) - this.Laser.ScrollX
          laserX = this.Laser.X - containerRect.left + this.Laser.ScrollX;
        }

        if (pdfRect.height < containerRect.height) {
          //laserY = this.Laser.Y - containerRect.top + (totalPdfHeight + pdfRect.y)
          laserY = this.Laser.Y - containerRect.top;
        } else {
          //laserY = this.Laser.Y - containerRect.top + (totalPdfHeight + pdfRect.y) - this.Laser.ScrollY
          laserY = this.Laser.Y - containerRect.top + this.Laser.ScrollY;
        }
        this.Laser.Enabled = true || this.Laser.MouseDown;

        laserX = Number((laserX / pdfRect.width).toFixed(6));
        laserY = Number((laserY / pdfRect.height).toFixed(6));

        //this.sendLaserMessage(this.Laser.Enabled, laserX, laserY, pdfRect.width, pdfRect.height)
        this.sendLaserMessage(this.Laser.Enabled, laserX, laserY);
      }
    } else if (this.CameraSwapped || this.RemoteCameraSwapped) {
      const video = document.getElementById("camera_swapped");
      if (video != undefined) {
        const videoRect = video.getBoundingClientRect();
        if (
          this.mouse.X < videoRect.left ||
          this.mouse.X > videoRect.right ||
          this.mouse.Y < videoRect.top ||
          this.mouse.Y > videoRect.bottom
        ) {
          this.Laser.Enabled = false;
          return;
        }

        this.Laser.Enabled = this.Laser.MouseDown;
        this.sendLaserMessage(this.Laser.Enabled, this.Laser.X, this.Laser.Y);
      }
    }
  }

  private handleLaserMessage(msg: any): void {
    for (const participant of this.Participants) {
      let contentLaserMessage = JSON.parse(msg.content);
      //remoteName: this.Participants[i].remoteName.charAt(0).toUpperCase() + this.Participants[i].remoteName.slice(1),
      if (participant.remoteName === contentLaserMessage.From) {
        const pdfContainer = document.getElementById("pdf-container");
        const pdfCanvas = document.getElementById("pdfvect");
        if (pdfContainer != undefined && pdfCanvas != undefined) {
          const containerRect = pdfContainer.getBoundingClientRect();
          const pdfRect = pdfCanvas.getBoundingClientRect();

          let laserState = contentLaserMessage;

          //(this.Laser.Enabled, laserX, laserY, pdfRect.width, pdfRect.height)
          let xRatio: number = pdfRect.width / laserState.W;
          let yRatio: number = pdfRect.height / laserState.H;
          let xCoord,
            yCoord = undefined;

          if (pdfRect.width < containerRect.width) {
            if (this.Laser.ScrollX !== undefined || this.Laser.ScrollX === 0) {
              if (xRatio < 1) {
                xCoord = laserState.X * pdfRect.width + containerRect.left - this.Laser.ScrollX;
              } else {
                xCoord = laserState.X * pdfRect.width + containerRect.left - this.Laser.ScrollX;
              }
            } else {
              if (xRatio < 1) {
                xCoord = laserState.X * pdfRect.width + containerRect.left;
              } else {
                xCoord = laserState.X * pdfRect.width + containerRect.left;
              }
            }
          } else {
            if (xRatio < 1) {
              xCoord = laserState.X * pdfRect.width + containerRect.left - this.Laser.ScrollX;
            } else {
              xCoord = laserState.X * pdfRect.width + containerRect.left - this.Laser.ScrollX;
            }
          }
          laserState.X = xCoord;

          if (pdfRect.height < containerRect.height) {
            if (yRatio < 1) {
              yCoord = laserState.Y * pdfRect.height + containerRect.top;
            } else {
              yCoord = laserState.Y * pdfRect.height + containerRect.top;
            }
          } else {
            if (yRatio < 1) {
              yCoord = laserState.Y * pdfRect.height + containerRect.top - this.Laser.ScrollY;
            } else {
              yCoord = laserState.Y * pdfRect.height + containerRect.top - this.Laser.ScrollY;
            }
          }
          laserState.Y = yCoord;
          /*if (pdfRect.width < containerRect.width) {
                        laserX = this.Laser.Y - containerRect.left + (totalPdfWidth - pdfRect.x)
                    } else {
                        laserX = this.Laser.X + this.Laser.ScrollX
                    }
    
                    if (pdfRect.height < containerRect.height) {
                        laserY = this.Laser.Y - containerRect.top + (totalPdfHeight - pdfRect.y)
                    } else {
                        laserY = this.Laser.Y + this.Laser.ScrollY
                    }*/

          participant.LaserState = laserState;
        } else {
          participant.LaserState = contentLaserMessage;
        }
      }
    }
  }

  PDFScrolled(event: any): void {
    this.Laser.ScrollX = event.target.scrollLeft;
    this.Laser.ScrollY = event.target.scrollTop;

    if (this.RemoteScrolled) {
      this.RemoteScrolled = false;
      return;
    }
    //this.rtc.SendPDFScroll(event.target.scrollLeft, event.target.scrollTop, event.target.scrollWidth, event.target.scrollHeight)
  }

  private hashStringToNumber(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }
    return Math.abs(hash);
  }

  generatePastelColor(key: string): string {
    const hash = this.hashStringToNumber(key);
    const r = (hash & 0xff0000) >> 16;
    const g = (hash & 0x00ff00) >> 8;
    const b = hash & 0x0000ff;
    const pastelColor = `rgb(${(r + 255) / 2}, ${(g + 255) / 2}, ${(b + 255) / 2})`;
    return pastelColor;
  }

  getHours(timestamp: number | string | Date) {
    return new Date(timestamp).toLocaleTimeString("default", { hour: "2-digit", minute: "2-digit" });
  }
}

async function loadFile(file: File): Promise<Uint8Array> {
  return new Promise<Uint8Array>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (event: any) => {
      const data = event.target.result as ArrayBuffer;
      resolve(new Uint8Array(data));
    };
  });
}

async function loadFileDataURL(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      const data = event.target.result as string;
      resolve(data);
    };
  });
}

async function sleep(duration: number): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      resolve(undefined);
    }, duration);
  });
}
