import { Component, OnInit, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-button-switch",
  templateUrl: "./button-switch.component.html",
  styleUrls: ["./button-switch.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonSwitchComponent),
      multi: true,
    },
  ],
})
export class ButtonSwitchComponent implements OnInit, ControlValueAccessor {
  private active = false;

  get Active(): boolean {
    return this.active;
  }
  set Active(active: boolean) {
    if (active !== this.active) {
      this.active = active;
      this.onChange(active);
    }
  }

  private onChange = (_: boolean) => {};
  private onTouched = () => {};

  constructor() {}

  ngOnInit() {}

  writeValue(active: boolean): void {
    this.Active = active;
  }

  registerOnChange(f: any): void {
    this.onChange = f;
  }

  registerOnTouched(f: any): void {
    this.onTouched = f;
  }
}
