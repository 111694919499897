import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from "@angular/core";

import { RtcService } from "../../services/rtc.service";
import { CustomerService } from "../../services/customer.service";

import { RoomConfig, Appointment } from "../../domain/domain";
import { EmitterService } from "src/app/services/emitter.service";
import { NotificationComponent } from "../notification/notification.component";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Component({
  selector: "app-vizu-account-config",
  templateUrl: "./vizu-account-config.component.html",
  styleUrls: ["./vizu-account-config.component.scss"],
})
export class VizuAccountConfigComponent implements OnInit, AfterViewInit {
  @Input() errorMonitor: any;
  @Input() userInfos: any;
  @ViewChild("notificationComponent")
  notificationComponent!: NotificationComponent;
  @ViewChild("canvasPreview")
  canvasPreview: ElementRef<HTMLCanvasElement>;
  contextCanvasPreview: CanvasRenderingContext2D;
  Loading = true;
  Error = false;

  imageChangedEvent: Event | null = null;
  croppedImage: SafeUrl = "/assets/icons/profile-default.svg";
  blobImage: Blob;
  imageCropperFileName: string = "profile-default.svg";

  Room: Appointment;

  companyImageFileName: string = "";
  companyLogoImage: string = "";

  LogoURIInput: string = "";
  bLogoURIFailed: boolean = false;
  Params = {
    ID: "",
    Name: "",
    Room: "",
    LogoURI: "",
    SubscriptionType: "",
    BackgroundColor: "#F1F1F1",
    TextColor: "#333333",
    Email: {
      Password: "",
      Email: "",
    },
    Password: {
      Strength: 0,
      Old: "",
      New: "",
    },
    CustomData: {},
    Config: new RoomConfig(),
  };

  SubmittingColor = false;
  SubmittingName = false;
  SubmittingRoom = false;
  SubmittingCompanyLogoURI = false;
  SubmittingLogoURI = false;
  SmallRoom = false;
  SubmittingEmail = false;
  ErrorEmail = false;
  SubmittingPassword = false;
  ErrorPassword = false;
  ErrorConfig = false;
  SubmittingConfig = false;

  specialRegex = new RegExp("[^A-Za-z0-9]");
  upperRegex = new RegExp("[A-Z]");
  lowerRegex = new RegExp("[a-z]");
  numberRegex = new RegExp("[0-9]");
  lengthRegex = new RegExp(".{8,}");

  notifications: any = {
    showSuccessResult: false,
    showFailedResult: false,
  };

  /*imageCroppedChangedEvent: Event | null = null;
    croppedImage: SafeUrl  = '';*/

  constructor(
    private rtcService: RtcService,
    private customerService: CustomerService,
    private NgmsService: EmitterService,
    private sanitizer: DomSanitizer
  ) {
    this.NgmsService.EmitNgmsSettings$.subscribe({
      next: this.handleNewEmittedMsg.bind(this),
    });
  }

  handleNewEmittedMsg(data: any) {
    let messageRcv = data.message;
    if (messageRcv == "OnMeData") {
      this.notificationComponent.displayNotification("showSuccessResult");
    }
  }

  ngOnInit(): void {
    //(success)="successLoading()" (error)="errorLoading()"
    this.errorMonitor = {
      Room: false,
      Name: false,
      LogoURI: false,
    };
    this.Load();
    this.Params.CustomData = this.userInfos.CustomData;

    if (this.userInfos?.LogoURI != "" && this.userInfos?.LogoURI != undefined) {
      this.croppedImage = this.userInfos.LogoURI;
      const URISplit = this.userInfos.LogoURI.split("/");
      this.imageCropperFileName = URISplit[URISplit.length - 1];
    }

    try {
      if (this.userInfos.CustomData !== undefined) {
        let customDataObject = JSON.parse(this.userInfos.CustomData);
        this.Params.TextColor = customDataObject.TextColor;
        this.Params.BackgroundColor = customDataObject.BackgroundColor;
        this.companyLogoImage = customDataObject.CompanyLogoURI;
      }
    } catch (error) {
      console.log(error);
    }

    this.rtcService.notifyOnSelfRoomEditError = this.notifyonselfroomediterror.bind(this);
    this.rtcService.notifyOnSelfNameEditError = this.notifyonselfnameediterror.bind(this);
    this.rtcService.notifyOnSelfLogoURIEditError = this.notifyonselflogouriediterror.bind(this);
  }

  ngAfterViewInit(): void {
    this.RefreshCanvas();
  }

  RefreshCanvas() {
    if (this.canvasPreview?.nativeElement !== undefined) {
      this.contextCanvasPreview = this.canvasPreview.nativeElement.getContext("2d");
      this.contextCanvasPreview.fillStyle = this.Params.BackgroundColor;
      this.contextCanvasPreview.fillRect(0, 0, this.canvasPreview.nativeElement.width, this.canvasPreview.nativeElement.height);

      this.contextCanvasPreview.font = "30px Oranienbaum serif"; //"Oranienbaum 40px serif"
      this.contextCanvasPreview.fillStyle = this.getContrastingTextColor(this.Params.BackgroundColor);
      this.contextCanvasPreview.textAlign = "center";
      this.contextCanvasPreview.fillText("Text", 90, 60);
    }
  }

  onFileChangeEvent(event: any): void {
    const file: File = event.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert("invalid format");
      return;
    }
    this.blobImage = file;
    this.companyImageFileName = event.target.files[0].name;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e: any) {
    let reader = e.target;
    this.companyLogoImage = reader.result;
  }

  fileChangeEvent(event: any): void {
    if (event.target.files.length === 0) return;
    this.imageCropperFileName = event.target.files[0].name;
    this.croppedImage = "";
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    this.blobImage = event.blob;
    // event.blob can be used to upload the cropped image
  }

  Load(): void {
    this.Loading = true;
    this.customerService.GetCustomer().subscribe({
      next: (customerData) => {
        const customer = customerData;
        const customData = customer.CustomData ? JSON.parse(customer.CustomData) : {};
        this.Params.ID = customerData.id;
        this.Params.Name = customer.Name;
        this.Params.Room = customer.Room;
        this.Params.SubscriptionType = customer.SubscriptionType;
        this.LogoURIInput = customer.LogoURI;
        this.Params.LogoURI = customer.LogoURI;
        this.Params.CustomData = customData;
        //this.Params.BackgroundColor = customData.BackgroundColor;
        //this.Params.TextColor = customData.TextColor;
      },
      error: () => {
        this.Error = true;
      },
    });
    this.Loading = false;
  }

  reload() {
    window.location.reload();
  }

  getContrastingTextColor(backgroundColor: string) {
    // Convertir la couleur de fond en un format RGB
    const rgb = this.hexToRgb(backgroundColor);
    if (!rgb) {
      throw new Error("Couleur de fond invalide. Assurez-vous d'utiliser un format hexadécimal valide.");
    }
    // Calculer la luminosité de la couleur
    const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
    // Retourner la couleur du texte en fonction de la luminosité
    return luminance > 0.5 ? "#353540" : "#FFFFFF";
  }

  hexToRgb(hex: string) {
    // Supprimer le caractère # s'il est présent
    hex = hex.replace(/^#/, "");

    // Convertir les valeurs hexadécimales en valeurs RGB
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return { r, g, b };
  }

  userTyping = function () {
    var timer: any = undefined;
    return function (callback: any, ms: number) {
      if (timer !== undefined) {
        clearTimeout(timer);
      }
      timer = setTimeout(callback, ms);
    };
  };

  PreviewLogoURI() {
    let myThis = this;
    let img: any = document.getElementById("trackedImg");
    if (img !== undefined) {
      myThis.Params.LogoURI = this.LogoURIInput;
      img.addEventListener("load", function () {
        myThis.bLogoURIFailed = false;
      });
      img.addEventListener("error", function () {
        myThis.bLogoURIFailed = true;
        myThis.Params.LogoURI = "";
      });
    }
  }

  //Path = /ngmsdata/public/ws-noauth/uploads

  async ChangeCompanyLogoURI(): Promise<void> {
    /*this.SubmittingCompanyLogoURI = true;
    let data = {
      CompanyLogoURI: <any>"",
    };
    data.CompanyLogoURI = this.companyLogoImage;
    let name = "ul." + this.companyImageFileName.split(".")[1];
    await this.customerService.importImage(this.blobImage, name).subscribe({
      next: async (success) => {
        data.CompanyLogoURI = success.path;
        await this.rtcService.ChangeUserSetting(this.Params.ID, data);
        this.notificationComponent.displayNotification("showSuccessResult");
      },
      error: (e) => {
        console.log(e);
      },
    });
    this.SubmittingCompanyLogoURI = false;*/

    this.SubmittingCompanyLogoURI = true;

    let timestamp = new Date().getTime().toString();
    let name = "ul-" + timestamp + "." + this.companyImageFileName.split(".")[1];
    await this.customerService.importImage(this.blobImage, name).subscribe({
      next: async (success) => {
        let CustomData = {
          ...this.Params.CustomData,
          BackgroundColor: this.Params.BackgroundColor,
          TextColor: this.Params.TextColor,
          CompanyLogoURI: <any>"",
        };
        CustomData.CompanyLogoURI = success.path;
        let data = {
          CustomData: JSON.stringify(CustomData),
        };
        await this.rtcService.ChangeUserSetting(this.Params.ID, data);
        this.notificationComponent.displayNotification("showSuccessResult");
        this.SubmittingCompanyLogoURI = false;
      },
      error: (e) => {
        console.log(e);
        this.SubmittingCompanyLogoURI = false;
      },
    });
  }

  async ChangeLogoURI(): Promise<void> {
    this.errorMonitor.LogoURI = false;
    this.SubmittingLogoURI = true;

    if (this.blobImage) {
      let data = {
        LogoURI: <any>"",
      };
      data.LogoURI = this.croppedImage;
      let timestamp = new Date().getTime().toString();
      let name = "up-" + timestamp + "." + this.imageCropperFileName.split(".")[1];
      await this.customerService.importImage(this.blobImage, name).subscribe({
        next: async (success) => {
          data.LogoURI = success.path;
          await this.rtcService.ChangeUserSetting(this.Params.ID, data);
          this.notificationComponent.displayNotification("showSuccessResult");
        },
        error: (e) => {
          console.log(e);
        },
      });
    }

    this.SubmittingLogoURI = false;
  }

  async ChangeName(): Promise<void> {
    this.errorMonitor.Name = false;
    if (this.Params.Name === "" || this.Params.Name === undefined) {
      this.errorMonitor.Name = true;
    } else {
      this.SubmittingName = true;
      let data = {
        Name: this.Params.Name,
      };
      await this.rtcService.ChangeUserSetting(this.Params.ID, data);
      this.notificationComponent.displayNotification("showSuccessResult");
      this.SubmittingName = false;
    }
  }

  async ChangeRoom(): Promise<void> {
    this.errorMonitor.Room = false;
    this.SmallRoom = false;
    let data = {
      Room: this.Params.Room.toLowerCase(),
    };
    if (this.Params.Room.length >= 3) {
      this.SubmittingRoom = true;
      await this.rtcService.ChangeUserSetting(this.Params.ID, data);
      this.notificationComponent.displayNotification("showSuccessResult");
      this.SubmittingRoom = false;
    } else {
      this.SmallRoom = true;
    }
  }

  async ChangeColors() {
    this.Params.TextColor = this.getContrastingTextColor(this.Params.BackgroundColor);
    document.documentElement.style.setProperty("--background-color", this.Params.BackgroundColor);
    document.documentElement.style.setProperty("--text-color", this.Params.TextColor);
    this.SubmittingColor = true;

    let CustomData = {
      ...this.Params.CustomData,
      BackgroundColor: this.Params.BackgroundColor,
      TextColor: this.Params.TextColor,
    };
    let data = {
      CustomData: JSON.stringify(CustomData),
    };
    await this.rtcService.ChangeUserSetting(this.Params.ID, data);
    this.SubmittingColor = false;
  }

  /*async ChangeEmail(): Promise<void> {
        let data = {
            Email: this.Params.Email.Email,
        }
        this.SubmittingEmail = true
        try {
            await this.rtcService.ChangeUserSetting(this.Params.ID, data)
            this.Params.Email.Email = ''
            this.Params.Email.Password = ''
            this.ErrorEmail = false
        } catch (error) {
            this.ErrorEmail = true
        }
        this.SubmittingEmail = false
  }*/

  roomUpdateKey() {
    this.Params.Room = this.Params.Room.replace(/ /g, "-");
  }

  CheckStrength(newPwd: any): number {
    let currentStrength = 0;

    if (this.hasRegex(newPwd, this.specialRegex)) {
      currentStrength += 20;
    }
    if (this.hasRegex(newPwd, this.upperRegex)) {
      currentStrength += 20;
    }
    if (this.hasRegex(newPwd, this.lowerRegex)) {
      currentStrength += 20;
    }
    if (this.hasRegex(newPwd, this.numberRegex)) {
      currentStrength += 20;
    }
    if (this.hasRegex(newPwd, this.lengthRegex)) {
      currentStrength += 20;
    }

    this.Params.Password.Strength = currentStrength;
    return currentStrength;
  }

  hasRegex(str: string, regex: RegExp): boolean {
    return regex.test(str);
  }

  async ChangePassword(): Promise<void> {
    let data = {
      OldPassword: this.Params.Password.Old,
      NewPassword: this.Params.Password.New,
    };
    this.SubmittingPassword = true;
    try {
      await this.rtcService.ChangeUserSetting(this.Params.ID, data);
      this.Params.Password.Old = "";
      this.Params.Password.New = "";
      this.ErrorPassword = false;
    } catch (error) {
      this.ErrorPassword = true;
    }
    this.SubmittingPassword = false;
  }

  /*async ChangeConfig(): Promise<void> {
        this.SubmittingConfig = true
        try {
            await this.customerService.SetConfig(this.Params.Config.String())
            this.ErrorPassword = false
        } catch (error) {
            this.ErrorConfig = true
        }
        this.SubmittingConfig = false
    }*/

  notifyonselfroomediterror(event: any) {
    this.notificationComponent.displayNotification("showFailedResult");
    this.errorMonitor.Room = true;
  }

  notifyonselfnameediterror(event: any) {
    this.notificationComponent.displayNotification("showFailedResult");
    this.errorMonitor.Name = true;
  }

  notifyonselflogouriediterror(event: any) {
    this.notificationComponent.displayNotification("showFailedResult");
    this.errorMonitor.LogoURI = true;
  }
}
