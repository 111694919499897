import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { Observable } from "rxjs";
import { RtcService } from "src/app/services/rtc.service";

@Component({
  selector: "app-vizu-recaptcha",
  templateUrl: "./vizu-recaptcha.component.html",
  styleUrl: "./vizu-recaptcha.component.scss",
})
export class VizuRecaptchaComponent {
  // login / signup / [password_reset] / [get_price] / [payment_add] / checkout / transaction_confirmed
  @Input() userAction: string;
  @Output() authAction = new EventEmitter<boolean>();

  constructor(private recaptchaV3Service: ReCaptchaV3Service, private rtc: RtcService) {}

  callRecaptcha(action: string): Observable<string> {
    return this.recaptchaV3Service.execute(action);
  }
  /*.subscribe({
      next: (token) => {
        console.log("reCAPTCHAv3 token received: " + token)
        //Send token to server and get confidence index
        this.rtc.getConfidenceLevel(token, this.userAction).subscribe(
          (confidenceServer: any) => {
            //Then check the confidence we got from the server
            this.checkActionToken(confidenceServer.confidence)
          },
          (error: any) => {
            console.log(`error:`, error);
            this.authAction.emit(true)
            return true
        });
      },
      error: (e) => {
        console.log(`error:`, e);
        this.authAction.emit(true)
      }
    });*/

  /*public executeRecaptchaV3() {
    //Recaptcha v3 execution requested
    this.recaptchaV3Service.execute('myAction').subscribe(
      (token) => {
        //We have a new token
        this.formatToken(token);
      },
      (error) => {
        //Recaptcha v3 error: see console
        console.log(`error:`, error);
      }
    );
  }

  public onError() {
    //this.log.push(`reCAHPTCHA errored;`);
  }

  public formatToken(token: string | null) {
    return token !== null
      ? `${token.substring(0, 7)}...${token.substring(token.length - 7)}`
      : 'null';
  }*/
}
