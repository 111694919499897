import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-loader-points",
  templateUrl: "./loader-points.component.html",
  styleUrls: ["./loader-points.component.scss"],
})
export class LoaderPointsComponent implements OnInit {
  @Input() isDark: boolean = false;

  constructor() {}

  setBackgroundColor() {
    let style = undefined;
    if (this.isDark) {
      style = {
        //x"background": "url('/static/assets/companies/" + this.domainTemplate.Name + "/"+this.domainTemplate.ImgBackground+"') no-repeat center 75%",
        "background-color": "#343640",
      };
    } else {
      style = {
        //x"background": "url('/static/assets/companies/" + this.domainTemplate.Name + "/"+this.domainTemplate.ImgBackground+"') no-repeat center 75%",
        "background-color": "white",
      };
    }
    return style;
  }

  ngOnInit() {
    console.log("init");
  }
}
