import { Component, Input } from "@angular/core";
import { Collaborator, DomainData, Limitations } from "src/app/domain/domain";

@Component({
  selector: "app-contacts",
  templateUrl: "./contacts.component.html",
  styleUrl: "./contacts.component.scss",
})
export class ContactsComponent {
  @Input() Customer: any = {};
  @Input() Limitations: Limitations;
  @Input() messagesRecepient: string = "";
  @Input() DomainData: DomainData;
  @Input() SearchField: string = "";

  @Input() Contacts: Collaborator[] = [];
  @Input() setUpgradeModal!: (state: boolean) => void;
  @Input() sendClickInfoMessage!: (info: string) => void;
  @Input() Contacts_and_Anonymous!: () => Collaborator[];
  @Input() displayMessages!: (to: string, displayName: string) => void;
  @Input() CopyLink!: (roomId: any) => void;
  @Input() EnterOtherRoom!: (room: string) => void;
}
