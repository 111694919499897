import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Limitations } from "src/app/domain/domain";

@Component({
  selector: "app-self-room",
  templateUrl: "./self-room.component.html",
  styleUrl: "./self-room.component.scss",
})
export class SelfRoomComponent {
  @Input() Customer: any = {};
  @Input() Attendees: any = {};
  @Input() bIsLoading: boolean = false;
  @Input() Limitations: Limitations;
  @Input() PrivateRoom: any = {
    Name: "",
    Participants: ([] = <any>[]),
  };
  @Input() mobileDevice: boolean = false;
  @Input() bTelephonyMode: boolean = false;

  @Input() sendClickInfoMessage!: (info: string) => void;
  @Input() CopyLink!: (roomId: any) => void;
  @Input() displayQrCodeLink!: (showQrCodeElement: any) => void;
  @Input() displayQrCodeSms!: (showQrCodeElement: any) => void;
  @Input() EnterOwnRoom!: (room: string) => void;

  @Output() ChangebIsLoading = new EventEmitter<void>();

  changebIsLoading() {
    this.bIsLoading = !this.bIsLoading;
    this.ChangebIsLoading.emit();
  }
}
