<!--
<nav class="navbar-header" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
        <p   *ngIf="DomainData.CustomData != undefined && (DomainData.CustomData.LogoURI === undefined || DomainData.CustomData.LogoURI === '')" class="text-logo">{{ DomainData.CustomData.FriendlyName }}</p>
        <img *ngIf="DomainData.CustomData != undefined && (DomainData.CustomData.LogoURI !== undefined && DomainData.CustomData.LogoURI !== '') && bLogoFullPath" class="vizu-logo" alt="Logo" src='{{ DomainData.CustomData.LogoURI }}' />
        <img *ngIf="DomainData.CustomData != undefined && (DomainData.CustomData.LogoURI !== undefined && DomainData.CustomData.LogoURI !== '') && !bLogoFullPath" class="vizu-logo" alt="Logo" src='{{ "/assets/companies/" + DomainData.Name + "/" + DomainData.CustomData.LogoURI }}' />
    </div>
</nav>
-->
<app-vizu-header [Public]="true"></app-vizu-header>
<div class="full-height layout-background" *ngIf="DomainData !== undefined" [ngStyle]="setLoginBackground()">
  <div class="layout-content">
    <div class="layout-main">
      <div class="layout-center">
        <ng-content></ng-content>
      </div>
      <div class="layout-center is-hidden-mobile" style="margin-bottom: 0">
        <a class="oranienbaum-regular has-text-left is-size-2-desktop is-size-3-touch text-custom-color" href="https://welcome.vizu.live">
          {{ "home.description" | translate }}
        </a>
        <img *ngIf="DomainData.CustomData?.LoginBackground === ''" src="/assets/img/vizu_zen.png" class="is-hidden-mobile zen-logo" />
      </div>
    </div>
  </div>
</div>
<app-loading-screen *ngIf="DomainData === undefined"></app-loading-screen>
