import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef } from "@angular/core";
import { NotificationComponent } from "../notification/notification.component";

//import QRCodeStyling from 'qr-code-styling';
declare const QRCodeStyling: any;

import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { EmitterService } from "../../services/emitter.service";
import { AuthService } from "../../services/auth.service";
import { RtcService } from "../../services/rtc.service";
import { DomainData, Collaborator, HistoryEntry, Message, Limitations } from "../../domain/domain";

import * as platform from "platform";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-vizu-main",
  templateUrl: "./vizu-main.component.html",
  styleUrls: ["./vizu-main.component.scss"],
})
export class VizuMainComponent implements OnInit, OnDestroy {
  /*@Input() audioDeviceSelected: string
    @Input() videoDeviceSelected: string*/

  @Output() EnterRoom = new EventEmitter<object>();

  //@Output() EnterSpecificRoom = new EventEmitter<Appointment>()

  @ViewChild("messageContainer") private messageContainer: ElementRef;

  bWebsocketReconnecting: boolean = false;
  bUpgradeModal: boolean = false;

  DomainData: DomainData;
  Locked: boolean;
  PrivRoom: any;
  Rooms: any[];
  isServiceProvider: boolean = false;
  Customer = {
    id: "",
    Administrator: false,
    CustomData: "",
    LogoURI: "",
    Name: "",
    Room: <string>undefined,
    Email: "",
    Username: "",
    Uri: <SafeResourceUrl>"",
    Participants: ([] = <any>[]),
    ParticipantsCount: 0,
    SubscriptionType: "",
    qrCode: <any>undefined,
  };
  errorMonitor = {
    Room: false,
    Name: false,
    LogoURI: false,
  };
  Limitations: Limitations;
  Cdrs: HistoryEntry[] = [];
  Messages: Message[] = [];
  messagesRecepient: string = "";
  messagesTo: string = "";
  messagesDisplayed: Message[] = [];
  lastMessage: Message = undefined;
  SearchField: string = "";
  isAnonymeDisplay = false;
  isMessageHover: any[] = [];

  refuseMessageShown: boolean = false;
  refuseMessageRemaining: number = 140;
  refuseMessage: string = "";
  attendeeData: any = undefined;

  deniedPermissionsModal = false;
  deniedPermissions = {
    audio: false,
    video: false,
  };

  imageLoaded = false;
  IncomingRoomCall = false;
  IncomingCall = false;
  OutgoingCall = false;
  OutgoingCallRefused = false;
  OutgoingCallRefusedMessage = "";
  callNotification: any = undefined;
  checkNewCallInterval: any = undefined;
  //OutgoingCallID = ''
  OutgoingCallName = "";
  AppointmentRequested = false;
  waitingGetUserMedia = false;
  waitingToEnter = false;
  makeCallParams = {
    to: "",
    bPrivate: true,
    from: "",
    reconnectId: "",
  };

  isAudioPermAllowed: any = undefined;
  bSelectDevicesActive: any = undefined;

  meterValue: number = 0;
  soundMeter: any = {};
  videoDevices: Array<{ deviceId: string; groupId: string; kind: string; label: string }> = [];
  videoDeviceSelected: number = 0;
  audioDevices: Array<{ deviceId: string; groupId: string; kind: string; label: string }> = [];
  audioDeviceSelected: number = 0;
  activeVideoDevice = 0;

  LocalStream: any = undefined;
  LocalStreamAudio: any = true;
  LocalStreamVideo: any = true;

  bAttendeesWaiting: boolean = false;
  Attendees: any[] = [];
  Contacts: Collaborator[] = [];
  Anonymous: Collaborator[] = [];
  IsAdmin: boolean;

  NewAttendee = {
    Email: "",
  };

  Submitting = false;
  EmailNotification = false;

  bAccountConfig = false;
  bAdminConfig = false;

  remoteDatas: any[] = [];

  domain = {
    name: "",
  };

  GlobalMenu = {
    Home: true,
    Rooms: false,
    Contacts: false,
    History: false,
    Messages: false,
  };

  PrivateRoom = {
    Name: "",
    Participants: ([] = <any>[]),
  };
  bTelephonyMode = false;
  //bEmulateMessages = true;

  RoomDisplayed: number = 0;

  mobileDevice: boolean = false;
  mobileDeviceIsAndroid: boolean = undefined;

  bQrCodeLinkModal: boolean = false;
  bQrCodeSmsModal: boolean = false;
  qrCodeRoom: string = "";
  qrCodeSafeUrl: string = "";
  qrCodes: any[] = [];
  contactToSms: string = "";
  tempQrCode: any = undefined;

  oldWidth: number = 0;

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    if (event.target.innerWidth >= 1024 && this.oldWidth < 1024) {
      this.GlobalMenu = {
        Home: false,
        Rooms: false,
        Contacts: true,
        History: false,
        Messages: false,
      };
    } else if (event.target.innerWidth < 1024 && this.oldWidth >= 1024) {
      this.GlobalMenu = {
        Home: true,
        Rooms: false,
        Contacts: false,
        History: false,
        Messages: false,
      };
    }
    this.oldWidth = event.target.innerWidth;
  }

  constructor(
    private auth: AuthService,
    private NgmsService: EmitterService,
    private sanitizer: DomSanitizer,
    private rtc: RtcService,
    private translate: TranslateService
  ) {
    this.NgmsService.EmitNgmsSettings$.subscribe({
      next: this.handleNewEmittedMsg.bind(this),
    });
  }

  handleNewEmittedMsg(data: any) {
    let messageRcv = data.message;
    if (messageRcv == "OnConnected") {
      this.onconnected();
    } else if (messageRcv == "OnDisconnected") {
      this.ondisconnected(data);
    }
  }

  sendClickInfoMessage(info: string) {
    this.rtc.sendClickMessage(info);
  }

  async ngOnDestroy(): Promise<void> {}

  createCallNotification() {
    const img = "/assets/favicon.ico";
    let names = "";
    let isRenotified = this.remoteDatas.length > 1 ? true : false;
    for (let i = 0; i < this.remoteDatas.length; i++) {
      names += i < 1 ? this.remoteDatas[i].Data.Name : ", " + this.remoteDatas[i].Data.Name;
    }
    const text = "You have a new call from " + names;
    let options = {
      body: text,
      icon: img,
      renotify: isRenotified,
    };
    this.callNotification = new Notification("Vizu Call Notification", options);
    this.callNotification.onclick = function () {
      window.focus();
    };
  }

  //openLink('https://welcome.vizu.live/contact')
  openLink(url: string) {
    window.open(url, "_blank");
  }

  handlePermission() {
    // function to actually ask the permissions
    if (Notification.permission === "denied" || Notification.permission === "default") {
      console.log("Notifications Permission denied or not asked to the user !");
    } else if (Notification.permission === "granted") {
      let myThis = this;
      document.addEventListener("visibilitychange", () => {
        console.log("visibility = " + document.visibilityState);
        if (document.visibilityState === "visible") {
          // The tab has become visible so clear the now-stale Notification.
          console.log("callNotif = " + myThis.callNotification);
          if (myThis.callNotification !== undefined) {
            myThis.callNotification.close();
            myThis.callNotification == undefined;
          }
        }
      });
    }
  }

  handleLimitations() {
    if (this.Customer.SubscriptionType === "pro") {
      this.Limitations = {
        MessagePanelHidden: false,
        TeamPanelHidden: true,
        RoomPanelHidden: true,
        InConfRestriction: false,
      };
      this.GlobalMenuClicked("messages");
    } else if (this.Customer.SubscriptionType === "enterprise") {
      if (this.DomainData.SharedUsers) {
        this.Limitations = {
          MessagePanelHidden: false,
          TeamPanelHidden: false,
          RoomPanelHidden: false,
          InConfRestriction: false,
        };
      } else {
        this.Limitations = {
          MessagePanelHidden: false,
          TeamPanelHidden: true,
          RoomPanelHidden: false,
          InConfRestriction: false,
        };
      }
    } else {
      this.Limitations = {
        MessagePanelHidden: true,
        TeamPanelHidden: true,
        RoomPanelHidden: true,
        InConfRestriction: true,
      };
    }
    this.rtc.Limitations = this.Limitations;
    this.IsAdmin = this.Customer.Administrator;
  }

  askNotificationPermission() {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      console.log("/! --------- /! This browser does not support notifications. /! ------- /! ");
    } else {
      Notification.requestPermission().then(() => {
        this.handlePermission();
      });
    }
  }

  async ngOnInit(): Promise<void> {
    let dateNow = new Date();
    let dateNowIso = dateNow.toISOString();
    let dateSendSmsSanitized = dateNowIso.replace(/\D+/g, "");
    let dateSendSms = dateSendSmsSanitized.slice(0, 12) + "00";
    console.log("--- Date Stringified: " + dateSendSms);

    this.translate.get("shell.room.reject_message_placeholder").subscribe((translation: string) => {
      if (localStorage.getItem("VizuLive\\RefuseMessage")) {
        this.refuseMessage = localStorage.getItem("VizuLive\\RefuseMessage");
      } else {
        this.refuseMessage = translation;
      }
      this.valueRejectMessageChange();
    });

    this.oldWidth = window.innerWidth;
    if (this.oldWidth < 1024) {
      this.GlobalMenu = {
        Home: true,
        Rooms: false,
        Contacts: false,
        History: false,
        Messages: false,
      };
    } else {
      this.GlobalMenu = {
        Home: false,
        Rooms: false,
        Contacts: true,
        History: false,
        Messages: false,
      };
    }
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      this.mobileDevice = true;
      this.mobileDeviceIsAndroid = false;
    } else if (/Android|webOS|Opera Mini/i.test(navigator.userAgent)) {
      this.mobileDevice = true;
      this.mobileDeviceIsAndroid = true;
    } else {
      this.mobileDevice = false;
    }

    this.askNotificationPermission();

    /*let Result = await this.customerService.GetCustomer()
        this.Customer = Result 
        if (!this.bTelephonyMode) {
            this.Customer.Participants = []
            this.Customer.ParticipantsCount = 0
            this.rtc.privRoom = this.Customer
            this.PrivateRoom.Name = this.Customer.Name
        }
        this.IsAdmin = Result.Administrator*/
    this.checkAudioPlay();
    this.rtc.notifyOnMeData = this.onmedata.bind(this);
    this.rtc.notifyOnProductData = this.onproductdata.bind(this);
    this.rtc.notifyOnNewCdr = this.onnewcdr.bind(this);
    this.rtc.notifyOnMessage = this.onmessage.bind(this);
    this.rtc.notifyOnMessageRemoved = this.onmessageremoved.bind(this);
    this.rtc.notifyOnConfRoomsChanged = this.onconfroomschanged.bind(this);
    this.rtc.notifyOnAuthorization = this.onauthorization.bind(this);
    if (!this.bTelephonyMode) {
      this.rtc.notifyOnPrivateRoomChanged = this.onprivateroomchanged.bind(this);
    }
    this.rtc.notifyOnDomainData = this.ondomaindata.bind(this);
    this.rtc.notifyOnContactsChanged = this.oncontactschanged.bind(this);
    this.rtc.notifyOnNewIncomingCall = this.onnewcall.bind(this);
    this.rtc.notifyOnCallStateChanged = this.oncallstatechanged.bind(this);
    this.rtc.notifyOnWebSocketReconnecting = this.notifyonwebsocketreconnecting.bind(this);
    this.rtc.notifyOnSelfRoomEditError = this.notifyonselfroomediterror.bind(this);
    this.rtc.notifyOnSelfNameEditError = this.notifyonselfnameediterror.bind(this);
    this.rtc.notifyOnSelfLogoURIEditError = this.notifyonselflogouriediterror.bind(this);

    if (this.rtc.LocalStream !== undefined) {
      this.rtc.CloseUserMedia();
    }

    let myThis = this;
    this.rtc.GetLocalMediaPermissions_Audio().then(
      function (response: any) {
        //Audio Permission checked and OK
      },
      function (error: any) {
        //Audio devices checked and ERROR
        if (error.name === "microphone" && error.state === "not_supported") {
          //FIREFOX ERROR
          console.log("FIREFOX detected - can't get Audio permissions !");
        } else if (error.state == "denied") {
          myThis.deniedPermissionsModal = true;
          myThis.deniedPermissions.audio = true;
          //Audio BLOCKED ERROR
        }
      }
    );

    this.rtc.GetLocalMediaPermissions_Video().then(
      function (response: any) {
        //Video Permission checked and OK
      },
      function (error: any) {
        //Video devices checked and ERROR
        if (error.name === "camera" && error.state === "not_supported") {
          //FIREFOX ERROR
          console.log("FIREFOX detected - can't get Video permissions !");
        } else if (error.state == "denied") {
          myThis.deniedPermissionsModal = true;
          myThis.deniedPermissions.video = true;
          //VIDEO BLOCKED ERROR
        }
      }
    );

    /*if (this.bEmulateMessages) {
            for (let i = 0; i < this.rtc.messages.length; i++) {
                this.onmessage(this.rtc.messages[i])
            }
        }*/

    await this.rtc.callbackWebSocket();
  }

  roomUpdateKey() {
    this.roomNameEdit = this.roomNameEdit.replace(/ /g, "-");
  }

  soundMeterStop() {
    if (this.soundMeter !== undefined) {
      if (this.soundMeter.audioContext) {
        this.soundMeter.javascriptNode.disconnect();
        this.soundMeter.javascriptNode = undefined;
        this.soundMeter.microphone.disconnect();
        this.soundMeter.microphone = undefined;
        this.soundMeter.analyser.disconnect();
        this.soundMeter.analyser = undefined;
        this.soundMeter.audioContext = undefined;
      }
    }
  }

  async soundMeterStart(stream: any) {
    this.soundMeterStop();

    this.soundMeter.audioContext = new AudioContext();
    let deprecated = true;
    if (deprecated) {
      this.soundMeter.analyser = this.soundMeter.audioContext.createAnalyser();
      this.soundMeter.microphone = this.soundMeter.audioContext.createMediaStreamSource(stream);
      this.soundMeter.javascriptNode = this.soundMeter.audioContext.createScriptProcessor(2048, 1, 1);

      this.soundMeter.analyser.smoothingTimeConstant = 0.8;
      this.soundMeter.analyser.fftSize = 1024;

      this.soundMeter.microphone.connect(this.soundMeter.analyser);
      this.soundMeter.analyser.connect(this.soundMeter.javascriptNode);
      this.soundMeter.javascriptNode.connect(this.soundMeter.audioContext.destination);

      let myThis = this;
      this.soundMeter.javascriptNode.onaudioprocess = function () {
        var array = new Uint8Array(myThis.soundMeter.analyser.frequencyBinCount);
        myThis.soundMeter.analyser.getByteFrequencyData(array);
        var values = 0;

        var length = array.length;
        for (var i = 0; i < length; i++) {
          values += array[i];
        }

        var average = values / length;
        var per100 = average;
        if (per100 > 100) {
          per100 = 100;
        }
        var per1 = per100 / 100;

        myThis.meterValue = Number(per1.toFixed(2));
      };
    } else {
      //const workletFileName = require('file-loader!./assets/js/process_microphone.js');
      let myThis = this;
      return await this.soundMeter.audioContext.audioWorklet.addModule("./assets/js/process_microphone.js").then(() => {
        myThis.soundMeter.microphone = myThis.soundMeter.audioContext.createMediaStreamSource(stream);
        myThis.soundMeter.javascriptNode = new AudioWorkletNode(myThis.soundMeter.audioContext, "vumeter");
        myThis.soundMeter.javascriptNode.port.onmessage = (event: any) => {
          let _volume = 0;
          if (event.data.volume) _volume = event.data.volume;
        };
        myThis.soundMeter.microphone.connect(myThis.soundMeter.javascriptNode).connect(myThis.soundMeter.audioContext.destination);
      });
    }
  }

  clearDeniedPermissionsModal() {
    this.deniedPermissionsModal = false;
    this.deniedPermissions = {
      audio: false,
      video: false,
    };
  }

  updateAudioDevice(deviceSelected: number) {
    this.audioDeviceSelected = deviceSelected;
    console.log(this.audioDevices[this.audioDeviceSelected].deviceId + ", " + this.audioDevices[this.audioDeviceSelected].label);
    this.updateGetLocalMedia();
  }

  updateVideoDevice(deviceSelected: number) {
    this.videoDeviceSelected = deviceSelected;
    console.log(this.videoDevices[this.videoDeviceSelected].deviceId + ", " + this.videoDevices[this.videoDeviceSelected].label);
    this.updateGetLocalMedia();
  }

  async updateGetLocalMedia() {
    this.rtc.CloseUserMedia();
    this.LocalStream = undefined;
    //this.LocalStream.getTracks().forEach((track: any) => track.stop())
    const constraints = {
      audio: { deviceId: this.audioDeviceSelected !== null ? { exact: this.audioDevices[this.audioDeviceSelected].deviceId } : undefined },
      video: { deviceId: this.videoDeviceSelected !== null ? { exact: this.videoDevices[this.videoDeviceSelected].deviceId } : undefined },
    };
    await this.getLocalMedia(constraints);
    if (this.rtc.LocalStream !== undefined) {
      this.LocalStream = this.rtc.LocalStream;
    }
  }

  private async getLocalMedia(constraints: any): Promise<void> {
    //this.checkWebRTCSupport();
    let myThis = this;
    //this.rtc.notifyOnGetUserMedia = this.ongetusermediasucceeded.bind(this)
    this.rtc
      .GetUserMedia(true, constraints)
      .then(function () {
        myThis.LocalStream = myThis.rtc.LocalStream;
        myThis.LocalStreamAudio = myThis.rtc.LocalStreamAudio;
        myThis.LocalStreamVideo = myThis.rtc.LocalStreamVideo;
      })
      .catch(function (error: any) {
        console.log("-------GETUSERMEDIA ERROR-------- " + error);
        /*if (myThis.State !== 'started')
            myThis.State = 'media_access_error'*/
      });
  }

  /*async ongetusermediasucceeded() {
        this.bGetUserMediaSucceeded = true
        console.log("GET USER MEDIA DONE ++++++++++")
        //let localStreamCap = myThis.rtc.getLocalStreamCapabilities()
        if (!this.getUserMediaDone && this.Public && this.OSBrowser.Browser === 'Firefox') {
            console.log("CLOSE USER MEDIA DONE ----------")
            this.rtc.CloseUserMedia()
        } else {
            if (this.rtc.LocalStream !== undefined) {
                this.LocalStream = this.rtc.LocalStream
                this.LocalStreamAudio = this.rtc.LocalStreamAudio
                this.LocalStreamVideo = this.rtc.LocalStreamVideo
                this.soundMeterStart(this.LocalStream);
            }
        }
        this.getUserMediaDone = true
    }*/

  async modifyAudioPerm(): Promise<void> {
    this.isAudioPermAllowed = false;
    //this.bSelectDevicesActive = false
    this.checkAudioPlay();
  }

  async checkAudioPlay(): Promise<void> {
    //this.bSelectDevicesActive = true
    let audioElement: any = document.getElementById("welcome_audio");
    let promise = audioElement.play();
    let myThis = this;
    if (promise !== undefined) {
      promise.then(
        function () {
          myThis.isAudioPermAllowed = true;
          //myThis.bSelectDevicesActive = false
        },
        function (errorPromise: any) {
          myThis.isAudioPermAllowed = false;
          console.log("-------PERMISSIONS FAILED: " + errorPromise);
        }
      );
    }
  }

  /*async getLocalDevices() {
        let myThis = this
        await navigator.mediaDevices.enumerateDevices()
        .then(function(devices) {
            myThis.rtc.setDevices(devices)
            devices.forEach(function(device) {
                if (device.kind === 'audioinput') {
                    myThis.audioDevices.push({
                        deviceId: device.deviceId,
                        groupId: device.groupId,
                        kind: device.kind,
                        label: device.label
                    });
                }
                if (device.kind === 'videoinput') {
                    myThis.videoDevices.push({
                        deviceId: device.deviceId,
                        groupId: device.groupId,
                        kind: device.kind,
                        label: device.label
                    });
                }
            });
        })
    }

    updateAudioDevice(deviceSelected: number) {
        this.audioDeviceSelected = this.audioDevices[deviceSelected].deviceId
        //this.updateGetLocalMedia()
    }

    updateVideoDevice(deviceSelected: number) {
        this.videoDeviceSelected = this.videoDevices[deviceSelected].deviceId
        //this.updateGetLocalMedia()
    }

    async updateGetLocalMedia() {
        this.rtc.CloseUserMedia()
        this.LocalStream = undefined
        
        const constraints = {
            audio: {deviceId: this.audioDeviceSelected !== null ? {exact: this.audioDeviceSelected} : undefined},
            video: {deviceId: this.videoDeviceSelected !== null ? {exact: this.videoDeviceSelected} : undefined}
        };
        await this.getLocalMedia(constraints)
        if (this.rtc.LocalStream !== undefined) {
            this.LocalStream = this.rtc.LocalStream
        }
    }

    private async getLocalMedia(constraints: any): Promise<void> {
        let myThis = this
        //this.rtc.notifyOnGetUserMedia = this.ongetusermediasucceeded.bind(this)
        this.rtc.GetUserMedia(true, constraints)
        .then(function() {
            myThis.LocalStream = myThis.rtc.LocalStream
            myThis.LocalStreamAudio = myThis.rtc.LocalStreamAudio
            myThis.LocalStreamVideo = myThis.rtc.LocalStreamVideo
        })
        .catch(function(error: any) {
            console.log("-------GETUSERMEDIA ERROR-------- " + error)
        });
    }*/

  blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async fadeOutModals() {
    const elements = document.getElementsByClassName("modal");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add("fadeOut");
    }
    await sleep(580);
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("fadeOut");
    }
  }

  async clearQrCodeObject() {
    await this.fadeOutModals();
    this.bQrCodeSmsModal = false;
    this.bQrCodeLinkModal = false;
    this.qrCodeRoom = "";
    this.qrCodeSafeUrl = "";
    this.contactToSms = "";
    this.tempQrCode = undefined;
  }

  displayQrCodeLink(showQrCodeElement: any) {
    this.bQrCodeLinkModal = true;
    if (showQrCodeElement.Room !== undefined) {
      this.qrCodeRoom = showQrCodeElement.Room;
    } else {
      this.qrCodeRoom = showQrCodeElement.To;
    }
    let myThis = this;
    this.initQrCode(this.Customer.Room, false).then(function (success) {
      myThis.tempQrCode = success;
      myThis.qrCodeSafeUrl = myThis.tempQrCode.safeUrl;
    });
  }

  displayQrCodeSms(showQrCodeElement: any) {
    this.bQrCodeSmsModal = true;
    if (showQrCodeElement.Room !== undefined) {
      this.qrCodeRoom = showQrCodeElement.Room;
    } else {
      this.qrCodeRoom = showQrCodeElement.To;
    }
    this.qrCodeSafeUrl = showQrCodeElement.qrCode.safeUrl;
  }

  async updateQrCode(event: string) {
    this.contactToSms = event;
    let myThis = this;
    await this.initQrCode(this.qrCodeRoom, true).then(function (successData) {
      myThis.tempQrCode = successData;
      myThis.qrCodeSafeUrl = myThis.tempQrCode.safeUrl;
    });
  }

  pasteContent() {
    let pasteText: string = "";
    let myThis = this;
    navigator.clipboard.readText().then(function (successData) {
      pasteText = successData;
      myThis.updateQrCode(pasteText);
    });
  }

  async initQrCode(Room: string, bSms: boolean) {
    //let urlData = https://mycompany.devngmedia.live/send-sms?inviteFrom=bob&Room=accueil
    let urlData: string = "";
    if (bSms) {
      if (this.contactToSms === "") {
        urlData = location.protocol + "//" + location.host + "/send-sms?invitefrom=" + this.Customer.Room + "&room=" + Room;
      } else {
        urlData =
          location.protocol +
          "//" +
          location.host +
          "/send-sms?invitefrom=" +
          this.Customer.Room +
          "&room=" +
          Room +
          "&toContact=" +
          this.contactToSms;
      }
    } else {
      urlData = location.protocol + "//" + location.host + "/" + Room;
    }

    //let extension = 'canvas';
    const qrCode = new QRCodeStyling({
      width: 300,
      height: 300,
      data: urlData,
      //image: "https://ngmedia.vizu.live/assets/vizu2.png",
      image: location.protocol + "//" + location.host + "/assets/favicon.png",
      backgroundOptions: {
        color: "#ffffff",
      },
      dotsOptions: {
        color: "#343640",
        type: "extra-rounded",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 0,
      },
      cornersSquareOptions: {
        color: "#c4283d",
        type: "dot",
      },
      cornersDotOptions: {
        color: "#c4283d",
        type: "dot",
      },
    });

    let blob = undefined;
    let myThis = this;
    return new Promise((resolve: any, reject: any) => {
      qrCode.getRawData("svg").then(function (rawData: any) {
        blob = rawData;
        let base64Content = undefined;
        myThis.blobToBase64(blob).then(function (result) {
          base64Content = result;
          let sanitizedData: SafeResourceUrl = myThis.sanitizer.bypassSecurityTrustResourceUrl(base64Content.toString());

          resolve({ title: urlData, safeUrl: sanitizedData, qrCode: qrCode });
        });
      });
    });
  }

  /*onKey(event: any): void {
        this.data = event.target.value;
        this.qrCode.update({
          data: this.data
        });
      }
    
    onChange(event: any): void {
        this.extension = event.target.value;
    }

    download(qrCodeName: string): void {
        for (let i = 0; i < this.qrCodes.length; i++) {
            if (qrCodeName  === this.qrCodes[i].title) {
                this.qrCodes[i].qrCode.download({ extension: this.qrCodes[i].extension as Extension });
            }
        }
    }*/

  ErrorUserRoom: boolean = false;
  roomNameEdit: string = "";
  async setUserRoom() {
    let data = {
      Room: this.roomNameEdit.toLowerCase(),
    };
    this.ErrorUserRoom = false;
    try {
      await this.rtc.ChangeUserSetting(this.Customer.id, data);
      this.roomNameEdit = "";
    } catch (error) {
      this.ErrorUserRoom = true;
    }
  }

  oncontactschanged(contacts: any) {
    //Backup contacts with messages
    let backupContacts = this.Contacts;
    //Erase messages
    this.Contacts = contacts;
    for (let i = 0; i < this.Contacts.length; i++) {
      this.Contacts[i].DisplayName = this.Contacts[i].DisplayName.charAt(0).toUpperCase() + this.Contacts[i].DisplayName.slice(1);
      if (!this.Contacts[i].Messages) {
        this.Contacts[i].Messages = [];
      }

      if (this.Customer !== undefined) {
        if (this.Contacts[i].data.id === this.Customer.id && this.Contacts[i].Type === "User") {
          this.Contacts.splice(i, 1);
          break;
        }
      }

      //Put messages back
      for (let j = 0; j < backupContacts.length; j++) {
        if (this.Contacts[i].data.id === backupContacts[j].data.id) {
          this.Contacts[i].Messages = backupContacts[j].Messages;
          this.Contacts[i].UnreadMessagesNumber = backupContacts[j].UnreadMessagesNumber;
        }
      }
    }
    //this.MessageContacts = this.Contacts
    /*for (let i = 0; i < this.MessageContacts.length; i++) {
            this.MessageContacts[i].DisplayName = this.MessageContacts[i].DisplayName.charAt(0).toUpperCase() + this.MessageContacts[i].DisplayName.slice(1)
            if (this.Customer !== undefined) {
                if ((this.MessageContacts[i].data.id === this.Customer.id) && this.MessageContacts[i].Type === "User") {
                    this.MessageContacts.splice(i, 1)
                }
            }
        }*/
    console.log("---------- CONTACTS UPDATED");
    this.NgmsService.EmitNgmsSettings(contacts, "OnContactsChanged");
  }

  onprivateroomchanged(privRoom: any) {
    this.PrivRoom = privRoom;
    this.remoteDatas = [];
    if (this.PrivRoom.Participants.length > 0) {
      for (let j = 0; j < this.PrivRoom.Participants.length; j++) {
        //this.Rooms[i].Participants[j].Data.User
        //this.Rooms[i].Participants[j].State
        if (this.PrivRoom.Participants[j].State == "accepted") {
          var splittedPath = this.PrivRoom.Participants[j].Path.split("/");
          if (splittedPath[2] == this.Customer.id) {
            this.onnewcall(this.PrivRoom.Participants[j]);
          }
        }
        if (this.PrivRoom.Participants[j].State == "conferenced") {
          this.IncomingCall = false;
        }
      }
    } else {
      this.IncomingCall = false;
      if (this.callNotification !== undefined) {
        this.callNotification.close();
        this.callNotification == undefined;
      }
    }
  }

  async onproductdata(productData: any) {
    this.isServiceProvider = productData.RoleServiceProvider;
  }

  async onmedata(userData: any) {
    this.Customer = userData;
    if (!this.bTelephonyMode) {
      this.Customer.Participants = [];
      this.Customer.ParticipantsCount = 0;
      //Bind /me infos to rtc privRoom values
      this.rtc.privRoom = this.Customer;
      this.PrivateRoom.Name = this.Customer.Room;
      let myThis = this;
      this.initQrCode(this.Customer.Room, true).then(function (success) {
        myThis.Customer.qrCode = success;
      });
    }
    this.handleLimitations();

    //Filter name from contacts
    for (let i = 0; i < this.Contacts.length; i++) {
      this.Contacts[i].DisplayName = this.Contacts[i].DisplayName.charAt(0).toUpperCase() + this.Contacts[i].DisplayName.slice(1);
      if (this.Contacts[i].data.id === this.Customer.id && this.Contacts[i].Type === "User") {
        this.Contacts.splice(i, 1);
        break;
      }
    }
    //await sleep(100)
    this.setInviteLink(true, this.Customer.Room);
    this.NgmsService.EmitNgmsSettings(userData, "OnMeData");
  }

  ondomaindata(DomainData: any) {
    this.DomainData = DomainData;
    let fullDomain = window.location.host.split(".");
    this.DomainData.Name = fullDomain[0];
    this.NgmsService.EmitNgmsSettings(this.DomainData, "OnDomainData");
  }

  async onnewcdr(cdr: HistoryEntry) {
    cdr.ToUri = cdr.ToUri.replace("callto:", "");
    let newCdrDiscDate = new Date(cdr.DisconnectTime);
    cdr.DateFormatted = newCdrDiscDate.toLocaleDateString("fr-FR", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    if (this.Cdrs.length > 0) {
      let bAdded = false;
      for (let i = 0; i < this.Cdrs.length; i++) {
        let tableCdrDiscDate = new Date(this.Cdrs[i].DisconnectTime);
        if (tableCdrDiscDate < newCdrDiscDate) {
          if (i === 0) {
            this.Cdrs.unshift(cdr);
            bAdded = true;
            break;
          } else {
            let beginningCdrs: any[] = this.Cdrs.slice(0, i);
            let endingCdrs: any[] = this.Cdrs.slice(i);
            let newCdrs: any[] = [];
            for (let j = 0; j < beginningCdrs.length; j++) {
              newCdrs.push(beginningCdrs[j]);
            }
            newCdrs.push(cdr);
            for (let k = 0; k < endingCdrs.length; k++) {
              newCdrs.push(endingCdrs[k]);
            }
            this.Cdrs = newCdrs;
            bAdded = true;
            break;
          }
        }
      }
      if (!bAdded) {
        this.Cdrs.push(cdr);
      }
    } else {
      this.Cdrs.push(cdr);
    }
  }

  async onmessage(message: Message) {
    this.lastMessage = message;
    if (message.IsAnonymous) {
      let isExistingAnonyme = false;
      for (let i = 0; i < this.Anonymous.length; i++) {
        if (message.FromName === this.Anonymous[i].DisplayName) {
          isExistingAnonyme = true;
          if (message.From === this.Anonymous[i].To) {
            if (!message.IsRead) {
              this.Anonymous[i].UnreadMessagesNumber++;
            }
          }

          if (message.Content === "end_user_message_forbidden") {
            message.Content = "shell.room." + message.Content;
          }

          const existMsgIndex = this.Anonymous[i].Messages.findIndex((msg) => msg.id === message.id);
          if (existMsgIndex !== -1) {
            this.Anonymous[i].Messages[existMsgIndex] = message;
          } else {
            this.Anonymous[i].Messages.push(message);
          }
        }
      }
      if (!isExistingAnonyme) {
        if (message.Content === "end_user_message_forbidden") {
          message.Content = "shell.room." + message.Content;
        }

        //Check if the anonymous was logged with an account for the actual user company
        let existingContactFound = false;
        for (let j = 0; j < this.Contacts.length; j++) {
          let contact = this.Contacts[j];
          if (message.From === contact.To || message.To === contact.To) {
            existingContactFound = true;
            if (contact.LogoURI !== "") {
              message.LogoURI = contact.LogoURI;
            }

            if (!message.IsRead) {
              if (this.messagesTo === message.To || this.messagesTo === message.From) {
                //Active tab is the sender or receiver of the message
                this.rtc.readOfflineMessage(message.id);
              } else {
                if (contact.UnreadMessagesNumber === undefined) {
                  contact.UnreadMessagesNumber = 1;
                } else {
                  contact.UnreadMessagesNumber++;
                }
              }
            }

            contact.Messages.push(message);
          }
        }

        if (!existingContactFound) {
          let newAnonyme: Collaborator = new Collaborator({
            To: message.From,
            DisplayName: message.FromName ? message.FromName : message.From,
            Type: "Anonymous",
            Messages: [message],
            LogoURI: message.LogoURI,
            UnreadMessagesNumber: message.IsRead ? 0 : 1,
          });
          this.Anonymous.push(newAnonyme);
        }
      }
    } else {
      for (let i = 0; i < this.Contacts.length; i++) {
        if (message.To === this.Contacts[i].To || message.From === this.Contacts[i].To) {
          if (message.From !== this.Customer.Room) {
            if (message.From === this.Contacts[i].To && !message.IsRead) {
              if (this.messagesTo === message.To || this.messagesTo === message.From) {
                //Active tab is the sender or receiver of the message
                this.rtc.readOfflineMessage(message.id);
              } else {
                if (this.Contacts[i].UnreadMessagesNumber === undefined) {
                  this.Contacts[i].UnreadMessagesNumber = 1;
                } else {
                  this.Contacts[i].UnreadMessagesNumber++;
                }
              }
            }
          }
          if (this.Contacts[i].Messages === undefined) {
            this.Contacts[i].Messages = [];
          }

          const existMsgIndex = this.Contacts[i].Messages.findIndex((msg) => msg.id === message.id);
          if (message.From === this.Customer.Room) {
            //Skip notif if message sent by self
            message.IsRead = true;
          }

          if (existMsgIndex !== -1) {
            this.Contacts[i].Messages[existMsgIndex] = message;
          } else {
            this.Contacts[i].Messages.push(message);
          }
        }
        //this.Messages.push(message)
      }
    }

    /*if (this.GlobalMenu.Messages) {
        this.displayMessages(message.to, this.messagesRecepient);
    }*/
  }

  async onmessageremoved(id: string) {
    for (let i = 0; i < this.Anonymous.length; i++) {
      const index = this.Anonymous[i].Messages.findIndex((msg) => msg.id === id);
      if (index !== -1) {
        this.Anonymous[i].Messages.splice(index, 1);
      }
      if (this.Anonymous[i].Messages.length <= 0) {
        this.Anonymous.splice(i, 1);
      }
    }
    for (let i = 0; i < this.Contacts.length; i++) {
      const index = this.Contacts[i].Messages.findIndex((msg) => msg.id === id);
      if (index !== -1) {
        this.Contacts[i].Messages.splice(index, 1);
      }
    }
  }

  async onconfroomschanged(confRooms: any) {
    this.Rooms = confRooms;
    /*for (let i = 0; i < this.Contacts.length; i++) {
            this.Contacts[i].DisplayName = this.Contacts[i].DisplayName.charAt(0).toUpperCase() + this.Contacts[i].DisplayName.slice(1);
        }*/

    //let bWaiting = false
    let hasOneUntrustedPeopleInRoom = false;
    for (let i = 0; i < this.Rooms.length; i++) {
      let myThis = this;
      this.Rooms[i]["IncomingUntrustedCall"] = false;
      this.initQrCode(this.Rooms[i].data.Room, true).then(function (successData) {
        myThis.Rooms[i].qrCode = successData;
      });

      this.Rooms[i].Uri = <SafeResourceUrl>"";
      this.setInviteLink(false, this.Rooms[i].data.Room);
      if (this.Rooms[i].ParticipantsCount !== undefined) {
        if (this.Rooms[i].ParticipantsCount > 0) {
          //bWaiting = true

          //////////// Check if Vizu user present, if not RING, if yes, DON'T RING
          let bVizuUserPresent = false;
          for (let j = 0; j < this.Rooms[i].Participants.length; j++) {
            if (this.Rooms[i].Participants[j].Data.Trusted) {
              bVizuUserPresent = true;
              break;
            }
          }
          if (!bVizuUserPresent) {
            hasOneUntrustedPeopleInRoom = true;
            this.Rooms[i]["IncomingUntrustedCall"] = true;
          }
          ////////////
        }
      }
    }
    if (hasOneUntrustedPeopleInRoom) {
      this.IncomingRoomCall = true;
    } else {
      this.IncomingRoomCall = false;
    }
    //this.bAttendeesWaiting = (bWaiting ? true : false)
    this.NgmsService.EmitNgmsSettings(confRooms, "OnConfRoomsChanged");
    console.log("---------- ROOMS UPDATED");
  }

  onauthorization() {
    if (this.makeCallParams.to !== "") {
      this.rtc.InitMakeCall(
        this.makeCallParams.to,
        this.makeCallParams.bPrivate,
        this.makeCallParams.from,
        this.makeCallParams.reconnectId,
        true
      );
      this.makeCallParams = {
        to: "",
        bPrivate: true,
        from: "",
        reconnectId: "",
      };
    }
    this.NgmsService.EmitNgmsSettings({}, "OnAuthorization");
  }

  onconnected() {
    if (this.OutgoingCall) {
      this.OutgoingCall = false;
      this.EnterRoom.emit({ waitingRequest: !this.AppointmentRequested, appointment: this.OutgoingCallName });
      this.OutgoingCallName = "";
    }
  }

  ondisconnected(evt: any) {
    if (evt?.data?.subject !== undefined) {
      this.OutgoingCallRefused = true;
      this.OutgoingCallRefusedMessage = evt.data.subject;
    } else {
      if (this.OutgoingCall) {
        this.OutgoingCall = false;
        this.OutgoingCallName = "";
      }
    }
  }

  onnewcall(data: any) {
    console.log("----------VIZU MAIN / WE HAVE A NEW CALL----------");
    this.remoteDatas.push(data);
    this.IncomingCall = true;
    if (Notification.permission === "granted") {
      console.log("Visibility == " + document.visibilityState);
      if (document.visibilityState !== "visible") {
        if (this.IncomingCall) {
          this.createCallNotification();
        }
      }
    }
    //this.globalcall.onconnected     	= this.onconnected;
    //this.globalcall.oncallstatechanged  = this.oncallstatechanged;
    //this.globalcall.ondisconnected  	= this.ondisconnected;
    //this.globalcall.onerror         	= this.onerror;
    //this.globalcall.onstreamadded   	= this.onstreamadded;
    //this.globalcall.onstreamremoved 	= this.onstreamremoved;
    //this.globalcall.onmessage       	= this.onmessage;
  }

  oncallstatechanged(event: any) {
    console.log("----------RINGING----------");
  }

  notifyonwebsocketreconnecting(event: boolean) {
    this.bWebsocketReconnecting = event;
  }

  notifyonselfroomediterror(event: any) {
    //Code mathieu pour récupérer erreur room déjà existante
    this.errorMonitor.Room = true;
    //this.NgmsService.EmitNgmsSettings(event, "OnSelfRoomEditError");
  }

  notifyonselfnameediterror(event: any) {
    this.errorMonitor.Name = true;
    //this.NgmsService.EmitNgmsSettings(event, "OnSelfNameEditError");
  }

  notifyonselflogouriediterror(event: any) {
    this.errorMonitor.LogoURI = true;
    //this.NgmsService.EmitNgmsSettings(event, "OnSelfLogoURIEditError");
  }

  /*onconnected(event: any) {
        console.log("----------CONNECTED / MAIN----------")
        //this.State = 'Connected'
    }

    ondisconnected(event: any) {
        console.log("----------DISCONNECTED----------")
    }

    onstreamadded(event: any) {
        console.log("----------STREAM_ADDED----------")
    }

    onstreamremoved(event: any) {
        console.log("----------STREAM_REMOVED----------")
    }

    onmessage(event: any) {
        console.log("----------NEW MESSAGE----------")
    }*/

  setImageBackground() {
    let style = undefined;
    if (this.DomainData !== undefined) {
      if (this.DomainData.CustomData !== undefined) {
        if (this.DomainData.CustomData.ImgBackground !== undefined) {
          if (this.DomainData.CustomData.ImgBackground === "") {
            style = {
              "background-image": "url('/assets/img/brown-wooden-style.jpg')",
            };
          } else if (
            this.DomainData.CustomData.ImgBackground.startsWith("/") ||
            this.DomainData.CustomData.ImgBackground.startsWith("http")
          ) {
            style = {
              "background-image": "url('" + this.DomainData.CustomData.ImgBackground + "')",
            };
          } else {
            style = {
              "background-image":
                "url('" + "/assets/companies/" + this.DomainData.Name + "/" + this.DomainData.CustomData.ImgBackground + "')",
            };
          }
        } else {
          style = {
            "background-image": "url('/assets/img/brown-wooden-style.jpg')",
          };
        }
      } else {
        style = {
          "background-image": "url('/assets/img/brown-wooden-style.jpg')",
        };
      }
    } else {
      style = {
        "background-image": "url('/assets/img/brown-wooden-style.jpg')",
      };
    }
    //return style; DISABLED
    return "";
  }

  setDomainMainColor() {
    let style = undefined;
    if (this.DomainData !== undefined) {
      style = {
        "background-color": this.DomainData.CustomData.ColorHomeMain,
      };
    } else {
      style = "";
    }
    return style;
  }

  setDomainAltColor() {
    let style = undefined;
    if (this.DomainData !== undefined) {
      style = {
        "background-color": this.DomainData.CustomData.ColorHomeAlt,
      };
    } else {
      style = "";
    }
    return style;
  }

  Logout(): void {
    /*if (localStorage.getItem("VizuLive\\RememberMe") !== "null" && localStorage.getItem("VizuLive\\RememberMe") !== null) {
            localStorage.setItem('VizuLive\\RememberMe', "false");
        }*/
    localStorage.setItem("VizuLive\\Logout", "true");
    this.auth.Unauthenticate().subscribe(() => {
      this.rtc.closeWebSocket();
      this.auth.UnauthenticatedSig.next();
    });
    //navigator.credentials.preventSilentAccess()
  }

  EnterOtherRoomFromHistory(Cdr: HistoryEntry) {
    if (this.waitingToEnter !== true) {
      let numberToCall: string = undefined;
      /*if (Cdr.In) {
                numberToCall = Cdr.FromUri
            } else {
                numberToCall = Cdr.ToUri
            }*/
      if (Cdr.FromUri === "callto:" + this.Customer.Room) {
        numberToCall = Cdr.ToUri;
      } else {
        numberToCall = Cdr.FromUri;
      }
      let myThis = this;
      this.waitingToEnter = true;
      myThis.OutgoingCall = true;
      if (numberToCall.match(/[a-z]/i)) {
        myThis.OutgoingCallName = numberToCall.charAt(0).toUpperCase() + numberToCall.slice(1);
      } else {
        myThis.OutgoingCallName = numberToCall;
      }
      return new Promise((resolve: any, reject: any) => {
        myThis.rtc.GetUserMedia(true, null).then(
          function (success: any) {
            myThis.waitingToEnter = false;
            if (myThis.rtc.ngmsUrl !== "" && (myThis.rtc.ngmsUrl.Url !== undefined || myThis.rtc.ngmsUrl.Url !== "")) {
              myThis.makeCallParams = {
                to: numberToCall,
                bPrivate: true,
                from: myThis.Customer.Username,
                reconnectId: "",
              };
              myThis.rtc.getAuthorization(numberToCall, true, myThis.Customer.Name, "");
              myThis.rtc.checkRTCStreamErrors();
            } else {
              myThis.rtc.InitMakeCall(numberToCall, true, myThis.Customer.Username, "", false);
            }
            resolve(success);
          },
          function (error: any) {
            myThis.waitingToEnter = false;
            myThis.rtc.LocalStream = undefined;
            myThis.LocalStream = undefined;
            if (myThis.rtc.ngmsUrl !== "" && (myThis.rtc.ngmsUrl.Url !== undefined || myThis.rtc.ngmsUrl.Url !== "")) {
              myThis.makeCallParams = {
                to: numberToCall,
                bPrivate: true,
                from: myThis.Customer.Username,
                reconnectId: "",
              };
              myThis.rtc.getAuthorization(numberToCall, true, myThis.Customer.Name, "");
              myThis.rtc.checkRTCStreamErrors();
            } else {
              myThis.rtc.InitMakeCall(numberToCall, true, myThis.Customer.Username, "", false);
            }
            reject(error);
          }
        );
      });
    }
  }

  EnterOtherRoom(room: string) {
    let myThis = this;
    if (this.waitingToEnter !== true) {
      this.waitingToEnter = true;
      this.OutgoingCall = true;
      //if (room.match(/[a-z]/i)) {
      //  this.OutgoingCallName = room.charAt(0).toUpperCase() + room.slice(1);
      //} else {
      this.OutgoingCallName = room;
      //}

      this.rtc.GetUserMedia(true, null).then(
        function () {
          myThis.waitingToEnter = false;
          if (myThis.rtc.ngmsUrl !== "" && (myThis.rtc.ngmsUrl.Url !== undefined || myThis.rtc.ngmsUrl.Url !== "")) {
            myThis.makeCallParams = {
              to: room,
              bPrivate: true,
              from: myThis.Customer.Username,
              reconnectId: "",
            };
            myThis.rtc.getAuthorization(room, true, myThis.Customer.Name, "");
            myThis.rtc.checkRTCStreamErrors();
          } else {
            myThis.rtc.InitMakeCall(room, true, myThis.Customer.Username, "", false);
          }
        },
        function () {
          myThis.rtc.LocalStream = undefined;
          myThis.LocalStream = undefined;
          myThis.waitingToEnter = false;
          if (myThis.rtc.ngmsUrl !== "" && (myThis.rtc.ngmsUrl.Url !== undefined || myThis.rtc.ngmsUrl.Url !== "")) {
            myThis.makeCallParams = {
              to: room,
              bPrivate: true,
              from: myThis.Customer.Username,
              reconnectId: "",
            };
            myThis.rtc.getAuthorization(room, true, myThis.Customer.Name, "");
            myThis.rtc.checkRTCStreamErrors();
          } else {
            myThis.rtc.InitMakeCall(room, true, myThis.Customer.Username, "", false);
          }
        }
      );
    }
  }

  async CancelOutgoingCall() {
    await this.fadeOutModals();
    this.waitingToEnter = false;
    this.OutgoingCall = false;
    this.OutgoingCallName = "";

    //If call has been refused, it's already disconnected
    if (this.OutgoingCallRefused) {
      this.OutgoingCallRefused = false;
      this.OutgoingCallRefusedMessage = "";
    } else {
      this.rtc.clearCall();
    }
  }

  async EnterOwnRoom(room: string) {
    let myThis = this;
    if (this.waitingToEnter !== true) {
      this.waitingToEnter = true;
      this.rtc.GetUserMedia(true, null).then(
        function () {
          myThis.waitingToEnter = false;
          if (myThis.rtc.ngmsUrl !== "" && (myThis.rtc.ngmsUrl.Url !== undefined || myThis.rtc.ngmsUrl.Url !== "")) {
            myThis.makeCallParams = {
              to: room,
              bPrivate: true,
              from: myThis.Customer.Username,
              reconnectId: "",
            };
            myThis.rtc.getAuthorization(room, true, myThis.Customer.Name, "");
            myThis.rtc.checkRTCStreamErrors();
            myThis.EnterRoom.emit({ waitingRequest: !myThis.AppointmentRequested, appointment: room });
          } else {
            myThis.EnterRoom.emit({ waitingRequest: !myThis.AppointmentRequested, appointment: room });
            myThis.rtc.InitMakeCall(room, true, myThis.Customer.Username, "", false);
          }
        },
        function () {
          myThis.rtc.LocalStream = undefined;
          myThis.LocalStream = undefined;
          myThis.waitingToEnter = false;
          if (myThis.rtc.ngmsUrl !== "" && (myThis.rtc.ngmsUrl.Url !== undefined || myThis.rtc.ngmsUrl.Url !== "")) {
            myThis.makeCallParams = {
              to: room,
              bPrivate: true,
              from: myThis.Customer.Username,
              reconnectId: "",
            };
            myThis.rtc.getAuthorization(room, true, myThis.Customer.Name, "");
            myThis.rtc.checkRTCStreamErrors();
            myThis.EnterRoom.emit({ waitingRequest: !myThis.AppointmentRequested, appointment: room });
          } else {
            myThis.EnterRoom.emit({ waitingRequest: !myThis.AppointmentRequested, appointment: room });
            myThis.rtc.InitMakeCall(room, true, myThis.Customer.Username, "", false);
          }
        }
      );
    }
  }

  async EnterAndAccept(room: string, attendees: any) {
    let myThis = this;
    this.waitingGetUserMedia = true;
    this.rtc.GetUserMedia(true, null).then(
      function () {
        //EVERYTHING IS OK
        myThis.waitingGetUserMedia = false;
        myThis.rtc.setAttendeesAccepted(attendees);
        if (myThis.rtc.ngmsUrl !== "" && (myThis.rtc.ngmsUrl.Url !== undefined || myThis.rtc.ngmsUrl.Url !== "")) {
          myThis.makeCallParams = {
            to: room,
            bPrivate: true,
            from: myThis.Customer.Username,
            reconnectId: "",
          };
          myThis.rtc.getAuthorization(room, true, myThis.Customer.Name, "");
          myThis.rtc.checkRTCStreamErrors();
          myThis.EnterRoom.emit({ waitingRequest: !myThis.AppointmentRequested, appointment: room });
        } else {
          myThis.EnterRoom.emit({ waitingRequest: !myThis.AppointmentRequested, appointment: room });
          myThis.rtc.InitMakeCall(room, true, myThis.Customer.Username, "", false);
        }
      },
      function () {
        //DEVICE NOT FOUND
        myThis.waitingGetUserMedia = false;
        myThis.rtc.setAttendeesAccepted(attendees);
        if (myThis.rtc.ngmsUrl !== "" && (myThis.rtc.ngmsUrl.Url !== undefined || myThis.rtc.ngmsUrl.Url !== "")) {
          myThis.makeCallParams = {
            to: room,
            bPrivate: true,
            from: myThis.Customer.Username,
            reconnectId: "",
          };
          myThis.rtc.getAuthorization(room, true, myThis.Customer.Name, "");
          myThis.rtc.checkRTCStreamErrors();
          myThis.EnterRoom.emit({ waitingRequest: !myThis.AppointmentRequested, appointment: room });
        } else {
          myThis.EnterRoom.emit({ waitingRequest: !myThis.AppointmentRequested, appointment: room });
          myThis.rtc.InitMakeCall(room, true, myThis.Customer.Username, "", false);
        }
      }
    );
  }

  AnswerIncomingCall() {
    this.EnterRoom.emit({ waitingRequest: !this.AppointmentRequested, appointment: this.Customer.Room });
  }

  async RefuseIncomingCall() {
    await this.fadeOutModals();
    if (this.bTelephonyMode) {
      this.rtc.clearCall();
    } else {
      for (let i = 0; i < this.remoteDatas.length; i++) {
        /*let myThis = this
                this.rtc.RejectLockedAttendee(this.remoteDatas[i])
                .subscribe(() => {
                    myThis.remoteDatas.splice(i, 1)
                })*/
        let bSuccess = this.rtc.RejectLockedAttendee(this.remoteDatas[i]);
        if (bSuccess) {
          this.remoteDatas.splice(i, 1);
          i--;
        }
      }
    }
    this.IncomingCall = false;
    this.Attendees = [];
  }

  async RefuseIncomingSpecificCallWithMessage() {
    if (this.refuseMessageShown && this.attendeeData !== undefined) {
      let refuseMessagePlaceholder: any = document.getElementById("textarea-refuse-message");
      let refuseMessage = this.refuseMessage !== "" ? this.refuseMessage : refuseMessagePlaceholder.placeholder;
      if (this.rtc.cookies?.performance) {
        localStorage.setItem("VizuLive\\RefuseMessage", this.refuseMessage);
      }
      for (let i = 0; i < this.remoteDatas.length; i++) {
        console.log("------ Attendee value: " + this.attendeeData);
        if (this.remoteDatas[i] === this.attendeeData) {
          let bSuccess = this.rtc.RejectLockedAttendeeWithMessage(this.remoteDatas[i], refuseMessage);
          if (bSuccess) {
            this.remoteDatas.splice(i, 1);
          }
        }
      }
    }

    if (this.remoteDatas.length < 1) {
      await this.fadeOutModals();
      this.IncomingCall = false;
      this.refuseMessage = "";
      this.refuseMessageShown = false;
    }
  }

  async RefuseIncomingSpecificCall(attendee: any) {
    for (let i = 0; i < this.remoteDatas.length; i++) {
      console.log("------ Attendee value: " + attendee);
      if (this.remoteDatas[i] === attendee) {
        /*let myThis = this
                this.rtc.RejectLockedAttendee(this.remoteDatas[i])
                .subscribe(() => {
                    myThis.remoteDatas.splice(i, 1)
                })*/
        let bSuccess = this.rtc.RejectLockedAttendee(this.remoteDatas[i]);
        if (bSuccess) {
          this.remoteDatas.splice(i, 1);
        }
      }
    }

    if (this.remoteDatas.length < 1) {
      await this.fadeOutModals();
      this.IncomingCall = false;
      this.refuseMessage = "";
      this.refuseMessageShown = false;
    }
  }

  DisplayRefuseMessage(data: any): void {
    this.refuseMessageShown = true;
    this.attendeeData = data;
    /*document.getElementById("").onkeyup(function() {
            var characterCount = $(this).val().length,
                current = $('#current'),
                maximum = $('#maximum'),
                theCount = $('#the-count');
              
            current.text(characterCount);
           
            if (characterCount < 70) {
              current.css('color', '#666');
            }
            if (characterCount > 70 && characterCount < 90) {
              current.css('color', '#6d5555');
            }
            if (characterCount > 90 && characterCount < 100) {
              current.css('color', '#793535');
            }
            if (characterCount > 100 && characterCount < 120) {
              current.css('color', '#841c1c');
            }
            if (characterCount > 120 && characterCount < 139) {
              current.css('color', '#8f0001');
            }
            
            if (characterCount >= 140) {
              maximum.css('color', '#8f0001');
              current.css('color', '#8f0001');
              theCount.css('font-weight','bold');
            } else {
              maximum.css('color','#666');
              theCount.css('font-weight','normal');
            } 
          });*/
  }

  valueRejectMessageChange() {
    this.refuseMessageRemaining = 140 - this.refuseMessage.length;
  }

  deleteChatMessage(messageId: string) {
    //if (this.Limitations?.MessagePanelHidden) {
    for (let i = 0; i < this.messagesDisplayed.length; i++) {
      if (this.messagesDisplayed[i].id == messageId) {
        this.messagesDisplayed.splice(i, 1);
      }
    }
    for (let j = 0; j < this.Messages.length; j++) {
      if (this.Messages[j].id == messageId) {
        this.Messages.splice(j, 1);
      }
    }
    //}
    this.rtc.deleteOfflineMessage(messageId);
  }

  backMessages() {
    this.messagesRecepient = "";
    this.messagesDisplayed = [];
  }

  deleteChatMessages(to: string, displayName: string) {
    //A fixer avec les requestId websocket, ensuite supprimer ce code
    this.messagesDisplayed = [];
    for (let i = 0; i < this.Contacts.length; i++) {
      if (to == this.Contacts[i].To) {
        this.Contacts[i].Messages = [];
        break;
      }
    }
    for (let i = 0; i < this.Anonymous.length; i++) {
      if (this.Anonymous[i]?.DisplayName === displayName) {
        this.Anonymous.splice(i, 1);
        break;
      }
    }
    this.rtc.deleteOfflineMessages(to, displayName);
  }

  displayMessages(to: string, displayName: string) {
    if (this.Limitations?.MessagePanelHidden) {
      this.setUpgradeModal(true);
    }

    this.messagesTo = to;
    this.messagesRecepient = displayName;

    //A fixer avec les requestId websocket, ensuite supprimer ce code
    if (to === "Anonymous" || to === "anonymous") {
      for (let i = 0; i < this.Anonymous.length; i++) {
        if (this.Anonymous[i].DisplayName === displayName) {
          this.isAnonymeDisplay = true;

          if (this.Anonymous[i].UnreadMessagesNumber !== undefined && this.Anonymous[i].UnreadMessagesNumber > 0) {
            for (let j = 0; j < this.Anonymous[i].Messages.length; j++) {
              if (this.Anonymous[i].Messages[j].IsRead === false && this.Anonymous[i].Messages[j].FromName === displayName) {
                this.rtc.readOfflineMessage(this.Anonymous[i].Messages[j].id);
                this.Anonymous[i].UnreadMessagesNumber--;
              }
            }
          }
          this.messagesDisplayed = this.Anonymous[i].Messages;
          break;
        }
      }
    } else {
      for (let i = 0; i < this.Contacts.length; i++) {
        if (this.Contacts[i].To === to) {
          this.isAnonymeDisplay = false;
          if (this.Contacts[i].UnreadMessagesNumber !== undefined && this.Contacts[i].UnreadMessagesNumber > 0) {
            for (let j = 0; j < this.Contacts[i].Messages.length; j++) {
              if (this.Contacts[i].Messages[j].IsRead === false && this.Contacts[i].Messages[j].From === to) {
                this.rtc.readOfflineMessage(this.Contacts[i].Messages[j].id);
                this.Contacts[i].UnreadMessagesNumber--;
              }
            }
          }
          this.messagesDisplayed = this.Contacts[i].Messages;
          break;
        }
      }
    }
    this.GlobalMenuClicked("messages");
  }

  hasNewMessages() {
    for (let i = 0; i < this.Contacts.length; i++) {
      for (let j = 0; j < this.Contacts[i].Messages.length; j++) {
        if (this.Contacts[i].Messages[j].From != this.Customer.Username && this.Contacts[i].Messages[j].IsRead === false) return true;
      }
    }
    for (let i = 0; i < this.Anonymous.length; i++) {
      for (let j = 0; j < this.Anonymous[i].Messages.length; j++) {
        if (this.Anonymous[i].Messages[j].IsRead === false) return true;
      }
    }
    return false;
  }

  /*async OpenReadMessage(messageId: string) {
        if (this.bWriteMessage) {
            this.bViewMessages = false
        }
        for (let i = 0; i < this.Messages.length; i++) {
            if (this.Messages[i].id === messageId) {
                this.bReadMessage = true
                this.readMessageId = i
                for (let j = 0; j < this.Contacts.length; j++) {
                    if (this.Messages[i].From === this.Contacts[j].To) {
                        this.Messages[i].isContact = true
                        break
                    }
                }
                break
            } 
        }
    }

    async OpenWriteMessage(to: string) {
        if (this.bReadMessage) {
            this.bReadMessage = false
        }
        this.bWriteMessage = true
        if (to !== undefined) {
            this.contactToMessage = to
        }
    }

    async CloseReadMessage() {
        this.bReadMessage = false
        this.bWriteMessage = false
        this.readMessageId = undefined
    }

    async CloseWriteMessage() {
        this.bReadMessage = false
        this.bWriteMessage = false
        this.contactToMessage = ""
        this.contactTextMessage = ""
    }*/

  async Config(): Promise<void> {
    this.bAccountConfig = !this.bAccountConfig;
    /*if (!this.bAccountConfig) {
            this.GetRoom()
        }*/
  }

  async Administrate(): Promise<void> {
    this.bAdminConfig = !this.bAdminConfig;
    /*if (!this.bAdminConfig) {
            this.GetRoom()
        }*/
  }

  DisplayRoom(index: number) {
    this.RoomDisplayed = index;
  }

  GlobalMenuClicked(value: string) {
    if (this.GlobalMenu.Messages === true && value !== "messages") {
      this.messagesTo = "";
      this.messagesRecepient = "";
      this.messagesDisplayed = [];
    }

    if (value === "home") {
      if (!this.GlobalMenu.Home) {
        this.GlobalMenu.Home = true;
        this.GlobalMenu.Rooms = false;
        this.GlobalMenu.Contacts = false;
        this.GlobalMenu.History = false;
        this.GlobalMenu.Messages = false;
      }
    } else if (value === "rooms") {
      if (!this.GlobalMenu.Rooms) {
        this.GlobalMenu.Home = false;
        this.GlobalMenu.Rooms = true;
        this.GlobalMenu.Contacts = false;
        this.GlobalMenu.History = false;
        this.GlobalMenu.Messages = false;
      }
    } else if (value === "contacts") {
      if (!this.GlobalMenu.Contacts) {
        this.GlobalMenu.Home = false;
        this.GlobalMenu.Rooms = false;
        this.GlobalMenu.Contacts = true;
        this.GlobalMenu.History = false;
        this.GlobalMenu.Messages = false;
      }
    } else if (value === "history") {
      if (!this.GlobalMenu.History) {
        this.GlobalMenu.Home = false;
        this.GlobalMenu.Rooms = false;
        this.GlobalMenu.Contacts = false;
        this.GlobalMenu.History = true;
        this.GlobalMenu.Messages = false;
      }
    } else if (value === "messages") {
      if (!this.GlobalMenu.Messages) {
        this.GlobalMenu.Home = false;
        this.GlobalMenu.Rooms = false;
        this.GlobalMenu.Contacts = false;
        this.GlobalMenu.History = false;
        this.GlobalMenu.Messages = true;
      }
    }
    console.log(this.GlobalMenu);
  }

  RefuseAppointment(): void {
    this.IncomingCall = false;
    this.Attendees = [];
  }

  @ViewChild("notificationComponent") notificationComponent!: NotificationComponent;

  CopyLink(roomId: any): void {
    console.log("Trying to sms: " + roomId);
    let toCopy: HTMLInputElement = <any>document.getElementById(roomId);
    toCopy.value = location.protocol + "//" + location.host + "/" + roomId;
    this.notificationComponent.displayNotification("copyLink");
    if (!(navigator as NavigatorInterface).clipboard) {
      if (platform.name == "Safari") {
        toCopy.contentEditable = "true";
        toCopy.readOnly = false;

        const range = document.createRange();
        range.selectNodeContents(toCopy);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        toCopy.setSelectionRange(0, 100000);
        document.execCommand("copy");
      } else {
        toCopy.select();
        document.execCommand("copy");
      }
    } else {
      (navigator as NavigatorInterface).clipboard
        .writeText(toCopy.value)
        .then(function () {})
        .catch(function (err: any) {
          console.log("Failed to copy to clipboard");
        });
    }
  }

  setInviteLink(isPrivate: boolean, roomId: any): void {
    let roomUrl = location.protocol + "//" + location.host + "/" + roomId;
    //let inviteAnchor: any = document.getElementById(roomId + "-invite")
    let upperCaseName = this.Customer.Name.charAt(0).toUpperCase() + this.Customer.Name.slice(1);
    if (isPrivate) {
      if (this.Customer.Room === roomId) {
        if (!this.mobileDevice) {
          console.log("Trying to email: " + roomId);
          this.Customer.Uri = this.sanitizer.bypassSecurityTrustResourceUrl(
            encodeURI(
              "mailto:?subject=Invitation à une conférence Vizu.live&body=Bonjour,\r\n\r\n" +
                upperCaseName +
                " vous invite à le rejoindre sur:\r\n" +
                roomUrl +
                "\r\n\r\nA tout de suite !"
            )
          );
        } else {
          console.log("Trying to sms: " + roomId);
          let messageBody = encodeURI(
            "Bonjour,\r\n" + upperCaseName + " vous invite à le rejoindre sur: " + roomUrl + "\r\nA tout de suite !"
          );
          if (this.mobileDeviceIsAndroid) {
            this.Customer.Uri = this.sanitizer.bypassSecurityTrustResourceUrl("sms:?body=" + messageBody);
          } else {
            this.Customer.Uri = this.sanitizer.bypassSecurityTrustResourceUrl("sms:///&body=" + messageBody);
          }
        }
      }
    } else {
      for (let i = 0; i < this.Rooms.length; i++) {
        if (this.Rooms[i].data.Room === roomId) {
          if (!this.mobileDevice) {
            console.log("Trying to email: " + roomId);
            this.Rooms[i].Uri = this.sanitizer.bypassSecurityTrustResourceUrl(
              encodeURI(
                "mailto:?subject=Invitation à une conférence Vizu.live&body=Bonjour,\r\n\r\n" +
                  upperCaseName +
                  " vous invite à le rejoindre sur:\r\n" +
                  roomUrl +
                  "\r\n\r\nA tout de suite !"
              )
            );
          } else {
            console.log("Trying to sms: " + roomId);
            let messageBody = encodeURI(
              "Bonjour,\r\n" + upperCaseName + " vous invite à le rejoindre sur: " + roomUrl + "\r\nA tout de suite !"
            );
            if (this.mobileDeviceIsAndroid) {
              this.Rooms[i].Uri = this.sanitizer.bypassSecurityTrustResourceUrl("sms:?body=" + messageBody);
            } else {
              this.Rooms[i].Uri = this.sanitizer.bypassSecurityTrustResourceUrl("sms:///&body=" + messageBody);
            }
          }
        }
      }
    }
  }

  /*async InviteEmail(): Promise<void> {
        if (this.NewAttendee.Email.length !== 0) {
            try {
                this.Submitting = true
                await this.customerService.AddAttendee(
                    this.Room.ID,
                    this.Room.FriendlyID,
                    '',
                    this.NewAttendee.Email,
                    false,
                )
                this.NewAttendee.Email = ''
                this.Submitting = false
                await this.OpenEmailNotification()
            } catch (error) {
                this.Submitting = false
            }
        }
    }*/

  CloseEmailNotification(): void {
    this.EmailNotification = false;
  }

  async OpenEmailNotification(): Promise<void> {
    this.EmailNotification = true;
    //await sleep(5000)
    this.EmailNotification = false;
  }

  async ToggleRoomLock(locked: boolean, roomIndex: any): Promise<void> {
    if (locked !== this.Rooms[roomIndex].data.Locked) {
      try {
        //TODO: WE HAVE TO SET ROOM LOCKED HERE
        //await this.customerService.ToggleRoomLock(this.Rooms[roomIndex].ID)
        this.Rooms[roomIndex].data.Locked = locked;
      } catch (error) {}
    }
  }

  Contacts_and_Anonymous() {
    return this.Contacts.concat(this.Anonymous);
  }

  formatTimestamp(timestamp: number): string {
    const messageDate = new Date(timestamp);
    const currentDate = new Date();

    const isToday =
      messageDate.getDate() === currentDate.getDate() &&
      messageDate.getMonth() === currentDate.getMonth() &&
      messageDate.getFullYear() === currentDate.getFullYear();

    const isThisYear = messageDate.getFullYear() === currentDate.getFullYear();

    const timeString = messageDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

    if (isToday) {
      return timeString;
    } else {
      const dateOptions: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "short",
      };

      if (!isThisYear) {
        dateOptions.year = "numeric";
      }

      //const dateString = messageDate.toLocaleDateString('fr-FR', dateOptions);
      const dateString = messageDate.toLocaleDateString([], dateOptions);
      return `${dateString} ${timeString}`;
    }
  }

  getTotalUnreadMessages(): number {
    let nbMessages = 0;
    for (let contact of this.Contacts_and_Anonymous()) {
      if (contact.UnreadMessagesNumber !== undefined && contact.UnreadMessagesNumber > 0) {
        nbMessages += contact.UnreadMessagesNumber;
      }
    }
    return nbMessages;
  }

  setUpgradeModal(state: boolean) {
    this.bUpgradeModal = state;
  }
}

async function sleep(duration: number): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}
