import { Component, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RtcService } from "src/app/services/rtc.service";
import { NotificationComponent } from "../notification/notification.component";

@Component({
  selector: "app-button-feedback",
  templateUrl: "./button-feedback.component.html",
  styleUrl: "./button-feedback.component.scss",
})
export class ButtonFeedbackComponent {
  @ViewChild("notificationComponent") notificationComponent!: NotificationComponent;

  isModalActive: boolean = false;
  feedbackForm: FormGroup;
  showThankYou: boolean = false;

  constructor(private rtc: RtcService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.feedbackForm = this.fb.group({
      textInput: ["", Validators.required],
    });
  }

  openModal() {
    this.isModalActive = true;
  }

  closeModal() {
    this.isModalActive = false;
    this.feedbackForm.reset({
      typeSelected: "",
      textInput: "",
    });
  }

  onSubmit() {
    if (this.feedbackForm.valid) {
      const feedback = {
        Text: this.feedbackForm.controls.textInput.value,
        //Timestamp: new Date().toISOString()
      };
      this.rtc.sendFeedbackMessage(feedback).subscribe((response) => {
        this.notificationComponent.displayNotification("feedbackSend");
      });
      this.closeModal();
    }
  }
}
