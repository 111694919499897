import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from "@angular/core";

import { Subscription } from "rxjs";

import { RtcService } from "../../services/rtc.service";
import { EmitterService } from "../../services/emitter.service";

import { Limitations } from "../../domain/domain";

@Component({
  selector: "app-file-uploader",
  templateUrl: "./file-uploader.component.html",
  styleUrls: ["./file-uploader.component.scss"],
})
export class FileUploaderComponent implements OnDestroy, OnInit {
  @Input() AppointmentID: string;
  @Input() Limitations: Limitations;
  @Input() Customer: any = {};
  @Output() OpenUpgradeModal = new EventEmitter<void>();
  //@Output() Used = new EventEmitter<string>()
  Uploading = false;
  NumberFilesSent: number = 0;

  Progress: number;

  private circumference = 16 * 2 * Math.PI;

  FixedCircleStyle = {
    "stroke-dasharray": "",
    "stroke-dashoffset": "",
  };
  CircleStyle = {
    "stroke-dasharray": "",
    "stroke-dashoffset": "",
  };

  constructor(private rtc: RtcService, private NgmsService: EmitterService) {
    this.NgmsService.EmitNgmsSettings$.subscribe({
      next: this.handleNewEmittedMsg.bind(this),
    });
  }

  handleNewEmittedMsg(data: any) {
    let messageRcv = data.message;
    let dataRcv = data.data;
    if (messageRcv == "OnMessageDeliveryProgress") {
      this.onmessagedeliveryprogress(dataRcv);
    }
  }

  ngOnInit(): void {
    this.resetProgress();
    /*this.eventSubscription = this.rtc.Events.subscribe(
            (msg: InMessage): void => {
                switch (msg.Type) {
                case 'file_upload_progress':
                    this.Progress = msg.Content.Progress
                    const fixedOffset = - this.Progress / 100 * this.circumference
                    const offset = this.circumference + fixedOffset
                    this.FixedCircleStyle['stroke-dashoffset'] = `${fixedOffset}`
                    this.CircleStyle['stroke-dashoffset'] = `${offset}`
                    break
                case 'file_upload_finished':
                    // falls through
                case 'file_upload_failed':
                    this.Uploading = false
                    this.resetProgress()
                    break
                }
            },
            (error): void => {
            },
            (): void => {
            },
        )*/
  }

  ngOnDestroy(): void {}

  private resetProgress(): void {
    this.Progress = 0;

    this.FixedCircleStyle = {
      "stroke-dasharray": `${this.circumference} ${this.circumference}`,
      "stroke-dashoffset": "0",
    };
    this.CircleStyle = {
      "stroke-dasharray": `${this.circumference} ${this.circumference}`,
      "stroke-dashoffset": `${this.circumference}`,
    };
  }

  onmessagedeliveryprogress(event: any) {
    this.Progress = Math.round((event.progressSize / event.contentSize) * 100);
    console.log("------- FILEUPLOADER / ON MESSAGE DELIVERY PROGRESS = " + this.Progress);
    const fixedOffset = (-this.Progress / 100) * this.circumference;
    const offset = this.circumference + fixedOffset;
    this.FixedCircleStyle["stroke-dashoffset"] = `${fixedOffset}`;
    this.CircleStyle["stroke-dashoffset"] = `${offset}`;
    if (this.Progress === 100) {
      this.Uploading = false;
      this.resetProgress();
    }
  }

  OpenUpgradePanel() {
    this.OpenUpgradeModal.emit();
  }

  onFileChange(event: any): void {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let read = new FileReader();
      read.readAsBinaryString(file);
      let myThis = this;
      read.onloadend = function () {
        myThis.NumberFilesSent++;
        myThis.Uploading = true;
        let content = {
          file: read.result,
          timestamp: new Date().toISOString(),
          logo: myThis.Customer?.LogoURI ? myThis.Customer?.LogoURI : "/assets/icons/profile-default.svg",
        };
        let options = {
          FromMe: true,
          content: JSON.stringify(content),
          contentType: "x-file",
          contentDisposition: "attachment",
          filename: file.name,
        };
        myThis.rtc.sendMessage(options);
        myThis.NgmsService.EmitNgmsSettings(JSON.stringify(options), "OnMessageDataChannel");
      };
      /*read.onloadend = function(){
                myThis.Uploading = true
                let options = {
                    FromMe: false,
                    content: read.result,
                    contentType: "text/plain",
                    contentDisposition: "attachment",
                    filename: file.name
                }
                myThis.rtc.sendMessage(options)
                options.FromMe = true
                myThis.NgmsService.EmitNgmsSettings(options, "OnMessage");
                //EMIT ONMESSAGE WITH FILE TO GET IT IN CHAT
            }*/
      //this.Used.emit('file')
    }
  }
}
