import { Component, ElementRef, Input, SimpleChanges, ViewChild } from "@angular/core";
import { Collaborator, DomainData, Limitations, Message } from "src/app/domain/domain";
import { RtcService } from "src/app/services/rtc.service";

@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrl: "./messages.component.scss",
})
export class MessagesComponent {
  @Input() Customer: any = {};
  @Input() Limitations: Limitations;
  @Input() messagesRecepient: string = "";
  @Input() isAnonymeDisplay: boolean = false;
  @Input() DomainData: DomainData;
  @Input() messagesTo: string = "";
  @Input() SearchField: string = "";
  @Input() lastMessage: Message = undefined;

  @Input() messagesDisplayed: Message[] = [];

  @Input() Contacts_and_Anonymous!: () => Collaborator[];
  @Input() backMessages!: () => void;
  @Input() onmessage!: (message: Message) => void;
  @Input() displayMessages!: (to: string, displayName: string) => void;
  @Input() sendClickInfoMessage!: (info: string) => void;
  @Input() deleteChatMessages!: (to: string, displayName: string) => void;
  @Input() deleteChatMessage!: (messageId: string) => void;

  messageData: string = "";
  isMessageHover: any[] = [];

  constructor(private rtc: RtcService) {
    console.log(this.messagesDisplayed);
  }

  sendChatMessage() {
    this.rtc.sendOfflineMessage(this.messagesTo, this.messageData); //, ""
    this.messageData = "";
  }

  messageInputChange(chatValue: string) {
    this.messageData = chatValue;
  }

  OnKeyDown(event: any) {
    if (event.keyCode === 13) {
      event.preventDefault();
      //document.getElementById("sendMessageSubmit").click();
      this.sendChatMessage();
      //this.scrollToBottom();
    }
  }

  formatTimestamp(timestamp: number): string {
    const messageDate = new Date(timestamp);
    const currentDate = new Date();

    const isToday =
      messageDate.getDate() === currentDate.getDate() &&
      messageDate.getMonth() === currentDate.getMonth() &&
      messageDate.getFullYear() === currentDate.getFullYear();

    const isThisYear = messageDate.getFullYear() === currentDate.getFullYear();

    const timeString = messageDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

    if (isToday) {
      return timeString;
    } else {
      const dateOptions: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "short",
      };

      if (!isThisYear) {
        dateOptions.year = "numeric";
      }

      //const dateString = messageDate.toLocaleDateString('fr-FR', dateOptions);
      const dateString = messageDate.toLocaleDateString([], dateOptions);
      return `${dateString} ${timeString}`;
    }
  }

  getHours(timestamp: number | string | Date) {
    return new Date(timestamp).toLocaleTimeString("default", { hour: "2-digit", minute: "2-digit" });
  }

  getLogoURIContact(from: string): string {
    for (let contact of this.Contacts_and_Anonymous()) {
      if (contact?.data?.Username == from) {
        return contact.data.LogoURI;
      }
    }
    if (from == "anonymous") {
      return "/assets/icons/profile-anonymous-default.svg";
    } else {
      return "/assets/icons/profile-default.svg";
    }
  }

  @ViewChild("messageContainer") private messageContainer: ElementRef;
  private scrollToBottom(): void {
    try {
      this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error("Scroll to bottom error:", err);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["lastMessage"]) {
      const currentValue = changes["lastMessage"].currentValue;
      if (this.messagesTo == currentValue?.From || this.messagesTo == currentValue?.To) {
        this.scrollToBottom();
      }
    }
  }
}
