<app-vizu-recaptcha #recaptchaComponent [userAction]="userAction" (authAction)="handleRecaptcha($event)"></app-vizu-recaptcha>

<app-vizu-home-layout *ngIf="DomainData !== undefined">
  <div class="landing-center">
    <div *ngIf="!bCompanyFound">
      <div class="name-form name-text">{{ "public_room.not_found_url_title" | translate }}</div>
      <div class="name-form step-step-unknown">{{ "public_room.not_found_main" | translate }}</div>
    </div>

    <div *ngIf="bCompanyFound && isActivated === undefined" class="panel">
      <h1 class="title has-text-centered poppins-semibold is-size-3-fullhd is-size-4-desktop is-size-5-touch">
        {{ "login.signup" | translate }}
      </h1>
      <form (ngSubmit)="onSubmit()" class="login-form fix-width">
        <div class="field mb-0">
          <label class="is-size-6-fullhd is-size-7-desktop is-size-7-touch">{{ "login.email" | translate }}</label>
          <input
            class="register-input"
            type="text"
            placeholder="john.doe@email.com"
            [(ngModel)]="customerService.CreateAccount.Email"
            name="create_email"
            (ngModelChange)="OnEmailChange($event)"
            [ngClass]="required['Email'] == true ? 'is-danger' : required['Email'] == false ? 'is-success' : ''"
          />
        </div>

        <div class="field mb-0">
          <label class="is-size-6-fullhd is-size-7-desktop is-size-7-touch">{{ "login.password" | translate }}</label>
          <div class="control has-icons-right">
            <input
              class="register-input"
              type="password"
              placeholder="xxxxxxxxxx"
              (ngModelChange)="CheckStrength($event)"
              [(ngModel)]="customerService.CreateAccount.Password"
              name="password"
              style="height: 100%; margin-bottom: 0"
              [ngClass]="{
                'is-danger': passwordStrength <= 40 && passwordStrength > 0,
                'is-warning': passwordStrength < 100 && passwordStrength > 40,
                'is-success': passwordStrength >= 100
              }"
            />
            <span *ngIf="passwordStrength === 100" class="icon is-small is-right" style="color: hsl(153, 49%, 40%)">
              <i class="fas fa-check"></i>
            </span>
          </div>
        </div>

        <progress
          *ngIf="customerService.CreateAccount.Password.length > 0"
          class="progress is-small my-2"
          value="{{ passwordStrength }}"
          max="100"
          [ngClass]="{
            'is-danger': passwordStrength <= 40,
            'is-warning': passwordStrength < 100 && passwordStrength > 40,
            'is-success': passwordStrength >= 100
          }"
        >
          {{ passwordStrength }}%
        </progress>

        <ul *ngIf="customerService.CreateAccount.Password.length > 0" class="red-strength">
          <li class="red-strength is-size-7">
            <span
              *ngIf="customerService.CreateAccount.Password.length >= 8"
              class="icon is-small is-right"
              style="color: hsl(153, 49%, 40%)"
            >
              <i class="fas fa-check"></i>
            </span>
            <span *ngIf="customerService.CreateAccount.Password.length < 8" class="icon is-small is-right" style="color: indianred">
              <i class="fas fa-xmark"></i>
            </span>
            <span
              style="margin-left: 5px"
              [ngStyle]="customerService.CreateAccount.Password.length >= 8 ? { color: 'hsl(153, 49%, 40%)' } : {}"
            >
              {{ "account_config.password_strength2" | translate }}
            </span>
          </li>
          <li class="red-strength is-size-7">
            <span
              *ngIf="hasRegex(customerService.CreateAccount.Password, lowerRegex)"
              class="icon is-small is-right"
              style="color: hsl(153, 49%, 40%)"
            >
              <i class="fas fa-check"></i>
            </span>
            <span
              *ngIf="!hasRegex(customerService.CreateAccount.Password, lowerRegex)"
              class="icon is-small is-right"
              style="color: indianred"
            >
              <i class="fas fa-xmark"></i>
            </span>
            <span
              style="margin-left: 5px"
              [ngStyle]="hasRegex(customerService.CreateAccount.Password, lowerRegex) ? { color: 'hsl(153, 49%, 40%)' } : {}"
            >
              {{ "account_config.password_strength3" | translate }}
            </span>
          </li>
          <li class="red-strength is-size-7">
            <span
              *ngIf="hasRegex(customerService.CreateAccount.Password, upperRegex)"
              class="icon is-small is-right"
              style="color: hsl(153, 49%, 40%)"
            >
              <i class="fas fa-check"></i>
            </span>
            <span
              *ngIf="!hasRegex(customerService.CreateAccount.Password, upperRegex)"
              class="icon is-small is-right"
              style="color: indianred"
            >
              <i class="fas fa-xmark"></i>
            </span>
            <span
              style="margin-left: 5px"
              [ngStyle]="hasRegex(customerService.CreateAccount.Password, upperRegex) ? { color: 'hsl(153, 49%, 40%)' } : {}"
            >
              {{ "account_config.password_strength4" | translate }}
            </span>
          </li>
          <li class="red-strength is-size-7">
            <span
              *ngIf="hasRegex(customerService.CreateAccount.Password, numberRegex)"
              class="icon is-small is-right"
              style="color: hsl(153, 49%, 40%)"
            >
              <i class="fas fa-check"></i>
            </span>
            <span
              *ngIf="!hasRegex(customerService.CreateAccount.Password, numberRegex)"
              class="icon is-small is-right"
              style="color: indianred"
            >
              <i class="fas fa-xmark"></i>
            </span>
            <span
              style="margin-left: 5px"
              [ngStyle]="hasRegex(customerService.CreateAccount.Password, numberRegex) ? { color: 'hsl(153, 49%, 40%)' } : {}"
            >
              {{ "account_config.password_strength5" | translate }}
            </span>
          </li>
          <li class="red-strength is-size-7">
            <span
              *ngIf="hasRegex(customerService.CreateAccount.Password, specialRegex)"
              class="icon is-small is-right"
              style="color: hsl(153, 49%, 40%)"
            >
              <i class="fas fa-check"></i>
            </span>
            <span
              *ngIf="!hasRegex(customerService.CreateAccount.Password, specialRegex)"
              class="icon is-small is-right"
              style="color: indianred"
            >
              <i class="fas fa-xmark"></i>
            </span>
            <span
              style="margin-left: 5px"
              [ngStyle]="hasRegex(customerService.CreateAccount.Password, specialRegex) ? { color: 'hsl(153, 49%, 40%)' } : {}"
            >
              {{ "account_config.password_strength6" | translate }}
            </span>
          </li>
        </ul>

        <div class="field mb-0">
          <label class="is-size-6-fullhd is-size-7-desktop is-size-7-touch">{{ "login.confirm_password" | translate }}</label>
          <div class="control has-icons-right">
            <input
              class="register-input"
              type="password"
              placeholder="xxxxxxxxxx"
              [(ngModel)]="customerService.CreateAccount.ConfirmPassword"
              name="cpassword"
              style="height: 100%; margin-bottom: 0"
              [ngClass]="{
                'is-success':
                  customerService.CreateAccount.Password == customerService.CreateAccount.ConfirmPassword &&
                  customerService.CreateAccount.Password.length > 0
              }"
            />
            <span
              *ngIf="customerService.CreateAccount.ConfirmPassword == customerService.CreateAccount.Password"
              class="icon is-small is-right"
              style="color: #888"
            >
              <i class="fas fa-check"></i>
            </span>
          </div>
        </div>

        <ul *ngIf="customerService.CreateAccount.ConfirmPassword.length > 0" class="red-strength">
          <li class="red-strength">
            <span
              *ngIf="customerService.CreateAccount.ConfirmPassword == customerService.CreateAccount.Password"
              class="icon is-small is-right"
              style="color: #888"
            >
              <i class="fas fa-check"></i>
            </span>
            <span
              *ngIf="customerService.CreateAccount.ConfirmPassword != customerService.CreateAccount.Password"
              class="icon is-small is-right"
              style="color: indianred"
            >
              <i class="fas fa-xmark"></i>
            </span>
            <span
              style="margin-left: 5px"
              [ngStyle]="
                customerService.CreateAccount.ConfirmPassword == customerService.CreateAccount.Password
                  ? { color: 'hsl(153, 49%, 40%)' }
                  : {}
              "
            >
              {{ "password_reset_new.same_password" | translate }}
            </span>
          </li>
        </ul>

        <!--<label>{{ 'login.password' | translate }}</label>
                <input type="password" [(ngModel)]="Local.Password" name="create_password" class="login-input">
                <label>{{ 'login.confirm_password' | translate }}</label>
                <input type="password" [(ngModel)]="Local.Password" name="create_password" class="login-input">
                -->
        <div class="field mb-0">
          <label class="is-size-6-fullhd is-size-7-desktop is-size-7-touch">{{ "login.displayname" | translate }}</label>
          <input
            type="text"
            [(ngModel)]="customerService.CreateAccount.DisplayName"
            placeholder="John Doe"
            name="create_displayname"
            class="register-input"
          />
        </div>

        <div class="field mb-0">
          <label class="is-size-6-fullhd is-size-7-desktop is-size-7-touch">{{ "login.promo_code" | translate }}</label>
          <input
            type="text"
            [(ngModel)]="customerService.CreateAccount.PromoCode"
            placeholder="ABCD1234"
            name="create_promo"
            class="register-input"
          />
        </div>
        <!--<label>{{ 'login.roomname' | translate }}</label>
                <input type="text" [(ngModel)]="customerService.CreateAccount.RoomName" placeholder="jdoe" name="create_roomname" class="register-input">-->

        <button type="submit" [class.is-loading]="inProgress" class="button is-medium is-danger is-outlined login-button">
          <div class="enter-button-base pointer">
            <div class="enter-button-out pointer">
              <div class="enter-button-in pointer">
                <span class="enter-button pointer"></span>
              </div>
            </div>
          </div>
          <b class="button-text pointer">{{ "login.signup" | translate }}</b>
        </button>
      </form>

      <div *ngIf="error" class="login-error has-text-danger">
        {{ ErrorMessage | translate }}
      </div>
    </div>

    <div *ngIf="bCompanyFound && isActivated === false" class="panel">
      <h1 class="title has-text-centered poppins-semibold">{{ "login.code_validation" | translate }}</h1>
      <form (ngSubmit)="onSubmitCode()" class="login-form fix-width">
        <p>
          {{ "login.email_validation_sent1" | translate }}
          <b>{{ customerService.CreateAccount.Email }}</b>
          .
        </p>
        <p>{{ "login.email_validation_sent2" | translate }}:</p>
        <br />
        <input
          type="number"
          name="registrationCode"
          placeholder=" X X X X X X "
          (change)="OnCodeValidationChange($event)"
          class="register-input text-centered"
        />
        <p *ngIf="error && !isResend" class="help is-danger">{{ ErrorMessage | translate }}</p>
        <p *ngIf="isResend" class="help is-success">{{ "register.resend_success" | translate }}</p>
        <button type="submit" [class.is-loading]="inProgress" class="button is-medium is-danger login-button">
          {{ "register.confirm" | translate }}
        </button>
        <button
          type="button"
          [class.is-loading]="inResendProgress"
          class="button is-medium is-danger login-button is-light"
          (click)="onResendEmail()"
        >
          {{ "register.resend" | translate }}
        </button>
      </form>
    </div>

    <div *ngIf="bCompanyFound && isActivated === true" class="panel">
      <h1 class="title has-text-centered poppins-semibold">{{ "login.signup" | translate }}</h1>
      <div class="login-form fix-width centered">
        <p>{{ "login.account_activated" | translate }}</p>
        <a class="button is-medium is-danger login-button width-80" href="/">{{ "login.login" | translate }}</a>
      </div>
    </div>
  </div>
</app-vizu-home-layout>
<app-loading-screen *ngIf="DomainData === undefined"></app-loading-screen>
