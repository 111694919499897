<div class="modal" [ngClass]="{ 'is-active': bDownloadFilesModalOpened }" style="z-index: 100">
  <div class="modal-background" (click)="closeModal()"></div>
  <div class="modal-card custom-width">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ "room_rating.files" | translate }}</p>
      <button class="delete" aria-label="close" (click)="closeModal()"></button>
    </header>
    <section class="modal-card-body">
      <div *ngFor="let message of Messages">
        <div *ngIf="message.Type == 'file'" class="message-text">
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="message['selected']" />
            {{ message.Text }}
          </label>
        </div>
      </div>

      <div *ngFor="let doc of allDocsShared">
        <div class="message-text">
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="doc['selected']" />
            {{ doc.Name }}
          </label>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success" (click)="downloadTar()">{{ "room_rating.downloads" | translate }}</button>
    </footer>
  </div>
</div>
