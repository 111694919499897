import { Component, Input } from "@angular/core";
import { CustomerService } from "../../services/customer.service";
import { DomainData } from "../../domain/domain";

@Component({
  selector: "app-vizu-home-layout",
  templateUrl: "./vizu-home-layout.component.html",
  styleUrl: "./vizu-home-layout.component.scss",
})
export class VizuHomeLayoutComponent {
  @Input() DomainData: DomainData;
  constructor(private customerService: CustomerService) {}

  domain = {
    name: "",
  };
  imageLoaded = false;
  bLogoFullPath = false;

  ngOnInit() {
    this.customerService.GetDomainPublicTemplate("").subscribe({
      next: (data) => {
        let domainData: any = data;
        this.DomainData = {
          Name: data.Name,
          SharedUsers: data.SharedUsers,
          RoomLogoURI: data.RoomLogoURI,
          RoomFriendlyName: data.RoomFriendlyName,
          CustomData: domainData.CustomData === "" ? "" : JSON.parse(domainData.CustomData),
        };
        let fullDomain = window.location.host.split(".");
        this.DomainData.Name = fullDomain[0];
        if (
          this.DomainData.CustomData.LogoURI.startsWith("https://") ||
          this.DomainData.CustomData.LogoURI.startsWith("http://") ||
          this.DomainData.CustomData.LogoURI.startsWith("/assets/")
        ) {
          this.bLogoFullPath = true;
        }
      },
      error: (e) => {
        this.DomainData = null;
      },
    });
  }

  setDomainMainColor() {
    let style = undefined;
    if (this.DomainData !== undefined && this.DomainData !== null) {
      style = {
        "background-color": this.DomainData.CustomData.ColorHomeMain,
      };
    } else {
      style = {
        "background-color": "rgba(63, 64, 86, 0.8)",
      };
    }
    return style;
  }

  setLoginBackground() {
    let style = undefined;
    if (this.DomainData !== undefined && this.DomainData !== null) {
      if (this.DomainData.CustomData !== undefined) {
        if (this.DomainData.CustomData.LoginBackground !== undefined) {
          if (this.DomainData.CustomData.LoginBackground === "") {
            style = {
              "background-color": "rgb(241 241 241)",
            };
          } else if (
            this.DomainData.CustomData.ImgBackground.startsWith("/") ||
            this.DomainData.CustomData.ImgBackground.startsWith("http")
          ) {
            style = {
              "background-image": "url('" + this.DomainData.CustomData.LoginBackground + "')",
            };
          } else {
            style = {
              "background-image":
                "url('" + "/assets/companies/" + this.DomainData.Name + "/" + this.DomainData.CustomData.LoginBackground + "')",
            };
          }
        } else {
          style = {
            "background-color": "rgb(241 241 241)",
          };
        }
      } else {
        style = {
          "background-color": "rgb(241 241 241)",
        };
      }
    } else {
      style = {
        "background-color": "rgb(241 241 241)",
      };
    }
    //return style; DISABLED
    return "";
  }

  setTextColor() {
    let style = undefined;
    if (this.DomainData !== undefined && this.DomainData !== null) {
      if (
        this.DomainData.CustomData.ColorHomeMain === "white" ||
        this.DomainData.CustomData.ColorHomeMain === "#FFFFFF" ||
        this.DomainData.CustomData.ColorHomeMain === "rgb(255,255,255)"
      ) {
        style = {
          color: "black",
        };
      } else {
        style = {
          color: "white",
        };
      }
    }
    return style;
  }
}
