import { Pipe, PipeTransform } from "@angular/core";
import { HistoryEntry } from "../domain/domain";

@Pipe({ name: "historyentry" })
export class HistoryEntryPipe implements PipeTransform {
  transform(values: HistoryEntry[], filter: string): HistoryEntry[] {
    if (!filter || filter.length === 0) {
      return values;
    }

    if (values.length === 0) {
      return values;
    }

    return values.filter((value: HistoryEntry) => {
      const nameFound = value.ToUri.toLowerCase().indexOf(filter.toLowerCase()) !== -1;

      if (nameFound) {
        return value;
      }
    });
  }
}
