import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-people-icon",
  templateUrl: "./people-icon.component.html",
  styleUrls: ["./people-icon.component.scss"],
})
export class PeopleIconComponent {
  @Input() On = false;
  @Input() isTouch = false;
}
