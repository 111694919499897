import { Component, ViewChild, OnInit } from "@angular/core";
import { CustomerService } from "../../services/customer.service";
import { RtcService } from "src/app/services/rtc.service";
import { DomainData } from "../../domain/domain";

import { VizuRecaptchaComponent } from "../vizu-recaptcha/vizu-recaptcha.component";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  @ViewChild("recaptchaComponent") recaptchaComponent!: VizuRecaptchaComponent;
  DomainData: DomainData;
  domain = {
    name: "",
  };
  imageLoaded = false;
  bLogoFullPath = false;
  inProgress = false;
  inResendProgress = false;

  bCompanyFound: boolean = false;
  userAction: string = "signup";
  userDetected: boolean = undefined;

  isResend = false;

  ErrorMessage = "";
  error = false;
  required: any = {};
  passwordStrength = 0;
  specialRegex = new RegExp("[^A-Za-z0-9]");
  upperRegex = new RegExp("[A-Z]");
  lowerRegex = new RegExp("[a-z]");
  numberRegex = new RegExp("[0-9]");
  lengthRegex = new RegExp(".{8,}");

  isActivated: boolean = undefined;

  constructor(private customerService: CustomerService, public rtc: RtcService) {}

  async ngOnInit(): Promise<void> {
    this.clearUser();
    if (this.customerService.CreateAccount.Email != "") {
      this.OnEmailChange(this.customerService.CreateAccount.Email);
    }
    this.domain.name = window.location.host;
    this.customerService.GetDomainPublicTemplate("").subscribe({
      next: (data: any) => {
        this.bCompanyFound = true;
        let domainData: any = data;
        this.DomainData = {
          Name: data.Name,
          SharedUsers: data.SharedUsers,
          RoomLogoURI: data.RoomLogoURI,
          RoomFriendlyName: data.RoomFriendlyName,
          CustomData: domainData.CustomData === "" ? "" : JSON.parse(domainData.CustomData),
        };
      },
      error: (response: any) => {
        /*this.error = true
			this.ErrorMessage = 'register.email_exist'
            this.ErrorMessage = 'register.roomname_exist'*/
        this.bCompanyFound = false;
        if (response.error) {
          if (response.error.title) {
            //this.error = response.error.title + '. '
          }
          if (response.error.parameters) {
            //this.error += response.error.parameters[0].reason;
            //this.required[response.error.parameters[0].name] = true;
          }
        }
      },
    });
  }

  onSubmit() {
    if (
      this.required["Email"] == false &&
      this.passwordStrength >= 100 &&
      this.customerService.CreateAccount.Password == this.customerService.CreateAccount.ConfirmPassword
    ) {
      this.error = false;
      this.ErrorMessage = "";
      this.inProgress = true;
      this.recaptchaComponent.callRecaptcha("login").subscribe({
        next: (token) => {
          this.rtc.getConfidenceLevel(token, this.userAction).subscribe(
            (confidenceServer: any) => {
              //Then check the confidence we got from the server
              this.checkActionToken(confidenceServer.confidence);
            },
            (error: any) => {
              console.log(`error:`, error);
              this.handleRecaptcha(true);
              this.registerUser();
              this.inProgress = false;
            }
          );
        },
        error: (e) => {
          console.log(`error:`, e);
          this.handleRecaptcha(true);
          this.registerUser();
          this.inProgress = false;
        },
      });
    }
  }

  registerUser() {
    this.customerService.RegisterUser().subscribe({
      next: (data: any) => {
        this.inProgress = false;
        console.log(data);
        this.isActivated = false;
        this.clearUser();
      },
      error: (response: any) => {
        this.inProgress = false;
        console.log(response);
        if (response.error) {
          this.error = true;
          if (response.error.parameters) {
            this.required[response.error.parameters[0].name] = true;
            const translation = response.error.parameters[0].translation;
            this.ErrorMessage = translation ? translation : "error";
          }
        }
        //this.DomainData = null;
      },
    });
  }

  onSubmitCode() {
    this.isResend = false;
    this.inProgress = true;
    this.customerService.ConfirmUser().subscribe({
      next: () => {
        this.inProgress = false;
        if (!this.isActivated) {
          this.isActivated = true;
        }
        this.clearUser();
      },
      error: (e: any) => {
        this.inProgress = false;
        console.log(e);

        if (e.error) {
          this.error = true;
          if (e.error.parameters) {
            this.required[e.error.parameters[0].name] = true;
            const translation = e.error.parameters[0].translation;
            this.ErrorMessage = translation ? translation : "";
          }
        }
      },
    });
  }

  onResendEmail() {
    this.ErrorMessage = "";
    this.inResendProgress = true;
    this.customerService.ResendEmailConfirmUser().subscribe({
      next: () => {
        this.inResendProgress = false;
        this.isResend = true;
      },
      error: (e: any) => {
        this.inResendProgress = false;
        console.log(e);

        if (e.error) {
          this.error = true;
          if (e.error.parameters) {
            this.required[e.error.parameters[0].name] = true;
            const translation = e.error.parameters[0].translation;
            this.ErrorMessage = translation ? translation : "error";
          }
        }
      },
    });
  }

  hasRegex(str: string, regex: RegExp): boolean {
    return regex.test(str);
  }

  CheckStrength(newPwd: any): number {
    let currentStrength = 0;

    if (this.hasRegex(newPwd, this.specialRegex)) {
      currentStrength += 20;
    }
    if (this.hasRegex(newPwd, this.upperRegex)) {
      currentStrength += 20;
    }
    if (this.hasRegex(newPwd, this.lowerRegex)) {
      currentStrength += 20;
    }
    if (this.hasRegex(newPwd, this.numberRegex)) {
      currentStrength += 20;
    }
    if (this.hasRegex(newPwd, this.lengthRegex)) {
      currentStrength += 20;
    }

    this.passwordStrength = currentStrength;
    return currentStrength;
  }

  OnCodeValidationChange(newValue: any) {
    this.isResend = false;
    this.ErrorMessage = "";
    this.customerService.CreateAccount.Code = newValue.target.value;
  }

  OnEmailChange(newValue: string) {
    if (
      String(newValue)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      this.required["Email"] = false;
    } else {
      this.required["Email"] = true;
    }
  }

  setDomainIcon() {
    let style = undefined;
    if (this.DomainData !== undefined && this.DomainData !== null) {
      if (this.DomainData.CustomData.LogoURI.startsWith("http")) {
        style = {
          background: "url('" + this.DomainData.CustomData.LogoURI + "')",
          //"background-size": "cover",
        };
      } else {
        style = {
          background: "url('/static/assets/companies/" + this.domain.name + "/" + this.DomainData.CustomData.LogoURI + "')",
          //"background-size": "cover",
        };
      }
    } else {
      style = "";
    }
    return style;
  }

  setDomainMainColor() {
    let style = undefined;
    if (this.DomainData !== undefined && this.DomainData !== null) {
      style = {
        "background-color": this.DomainData.CustomData.ColorHomeMain,
      };
    } else {
      style = {
        "background-color": "rgba(63, 64, 86, 0.8)",
      };
    }
    return style;
  }

  setImageBackground() {
    let style = undefined;
    if (this.DomainData !== undefined && this.DomainData !== null) {
      if (this.DomainData.CustomData !== undefined) {
        if (this.DomainData.CustomData.ImgBackground !== undefined) {
          if (this.DomainData.CustomData.ImgBackground === "") {
            style = {
              "background-image": "url('/assets/img/brown-wooden-style.jpg')",
            };
          } else if (
            this.DomainData.CustomData.ImgBackground.startsWith("/") ||
            this.DomainData.CustomData.ImgBackground.startsWith("http")
          ) {
            style = {
              "background-image": "url('" + this.DomainData.CustomData.ImgBackground + "')",
            };
          } else {
            style = {
              "background-image":
                "url('" + "/assets/companies/" + this.DomainData.Name + "/" + this.DomainData.CustomData.ImgBackground + "')",
            };
          }
        } else {
          style = {
            "background-image": "url('/assets/img/brown-wooden-style.jpg')",
          };
        }
      } else {
        style = {
          "background-image": "url('/assets/img/brown-wooden-style.jpg')",
        };
      }
    } else {
      style = {
        "background-image": "url('/assets/img/brown-wooden-style.jpg')",
      };
    }
    return style;
  }

  setLoginBackground() {
    let style = undefined;
    if (this.DomainData !== undefined && this.DomainData !== null) {
      if (this.DomainData.CustomData !== undefined) {
        if (this.DomainData.CustomData.LoginBackground !== undefined) {
          if (this.DomainData.CustomData.LoginBackground === "") {
            style = {
              "background-image": "url('/assets/img/brown-wooden-style.jpg')",
            };
          } else if (
            this.DomainData.CustomData.LoginBackground.startsWith("/") ||
            this.DomainData.CustomData.LoginBackground.startsWith("http")
          ) {
            style = {
              "background-image": "url('" + this.DomainData.CustomData.LoginBackground + "')",
            };
          } else {
            style = {
              "background-image":
                "url('" + "/assets/companies/" + this.DomainData.Name + "/" + this.DomainData.CustomData.LoginBackground + "')",
            };
          }
        } else {
          style = {
            "background-image": "url('/assets/img/brown-wooden-style.jpg')",
          };
        }
      } else {
        style = {
          "background-image": "url('/assets/img/brown-wooden-style.jpg')",
        };
      }
    } else {
      style = {
        "background-image": "url('/assets/img/brown-wooden-style.jpg')",
      };
    }
    return style;
  }

  setTextColor() {
    let style = undefined;
    if (this.DomainData !== undefined) {
      if (
        this.DomainData.CustomData.ColorHomeMain === "white" ||
        this.DomainData.CustomData.ColorHomeMain === "#FFFFFF" ||
        this.DomainData.CustomData.ColorHomeMain === "rgb(255,255,255)"
      ) {
        style = {
          color: "black",
        };
      } else {
        style = {
          color: "white",
        };
      }
    }
    return style;
  }

  setDomainAltColor() {
    let style = undefined;
    if (this.DomainData !== undefined) {
      style = {
        "background-color": this.DomainData.CustomData.ColorHomeAlt,
      };
    } else {
      style = "";
    }
    return style;
  }

  clearUser() {
    const email = this.customerService.CreateAccount.Email;
    this.customerService.CreateAccount = {
      Email: email,
      Password: "",
      ConfirmPassword: "",
      RoomName: "",
      DisplayName: "",
      Token: "",
      PromoCode: "",
      Code: "",
    };
  }

  handleRecaptcha(isAuthorized: boolean) {
    if (isAuthorized) {
      this.userDetected = true;
    } else {
      this.userDetected = false;
    }
  }

  checkActionToken(confidence: number) {
    if (confidence >= 0.3) {
      this.handleRecaptcha(true);
      this.registerUser();
    } else {
      this.handleRecaptcha(false);
      this.inProgress = false;
    }
  }
}
