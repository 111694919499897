<ng-container [ngSwitch]="state">
  <app-landing-page *ngSwitchCase="'landing_page'" (Login)="state = 'login'"></app-landing-page>
  <app-login *ngSwitchCase="'login'" (success)="onAuthenticated()"></app-login>

  <app-register *ngSwitchCase="'register'"></app-register>
  <!--<app-shell *ngSwitchCase="'authenticated'"></app-shell>-->
  <app-vizu-shell *ngSwitchCase="'authenticated'"></app-vizu-shell>
  <app-vizu-public *ngSwitchCase="'public'"></app-vizu-public>
  <app-loading-screen *ngSwitchCase="'loading'"></app-loading-screen>

  <app-verify-account *ngSwitchCase="'verify-account'"></app-verify-account>
  <app-password-reset *ngSwitchCase="'pwd-reset'"></app-password-reset>
  <app-password-reset-request *ngSwitchCase="'pwd-reset-request'"></app-password-reset-request>
</ng-container>
