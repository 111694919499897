import { Component, OnInit, Input } from "@angular/core";
import { CustomerService } from "../../services/customer.service";
import { RtcService } from "../../services/rtc.service";
import { DomainData } from "../../domain/domain";

@Component({
  selector: "app-landing-container",
  templateUrl: "./landing-container.component.html",
  styleUrls: ["./landing-container.component.scss"],
})
export class LandingContainerComponent implements OnInit {
  @Input() DomainData: DomainData;
  constructor(private customerService: CustomerService) {}

  domain = {
    name: "",
  };
  imageLoaded = false;
  bLogoFullPath = false;

  ngOnInit() {
    this.customerService.GetDomainPublicTemplate("").subscribe({
      next: (data) => {
        let domainData: any = data;
        this.DomainData = {
          Name: data.Name,
          SharedUsers: data.SharedUsers,
          RoomLogoURI: data.RoomLogoURI,
          RoomFriendlyName: data.RoomFriendlyName,
          CustomData: domainData.CustomData === "" ? "" : JSON.parse(domainData.CustomData),
        };
        this.customerService.setColors(this.DomainData.CustomData.BackgroundColor, this.DomainData.CustomData.TextColor);
      },
      error: (e) => {
        this.DomainData = null;
      },
    });
  }

  setDomainIcon() {
    let style = undefined;
    if (this.DomainData !== undefined && this.DomainData !== null) {
      if (this.DomainData.CustomData.LogoURI.startsWith("http")) {
        style = {
          background: "url('" + this.DomainData.CustomData.LogoURI + "')",
          //"background-size": "cover",
        };
      } else {
        style = {
          background: "url('/static/assets/companies/" + this.domain.name + "/" + this.DomainData.CustomData.LogoURI + "')",
          //"background-size": "cover",
        };
      }
    } else {
      style = "";
    }
    return style;
  }

  setDomainMainColor() {
    let style = undefined;
    if (this.DomainData !== undefined && this.DomainData !== null) {
      style = {
        "background-color": this.DomainData.CustomData.ColorHomeMain,
      };
    } else {
      style = {
        "background-color": "rgba(63, 64, 86, 0.8)",
      };
    }
    //return style; DISABLED
    return "";
  }

  setImageBackground() {
    let style = undefined;
    if (this.DomainData !== undefined && this.DomainData !== null) {
      if (this.DomainData.CustomData !== undefined) {
        if (this.DomainData.CustomData.ImgBackground !== undefined) {
          if (this.DomainData.CustomData.ImgBackground === "") {
            style = {
              "background-image": "url('/assets/img/brown-wooden-style.jpg')",
            };
          } else if (
            this.DomainData.CustomData.ImgBackground.startsWith("/") ||
            this.DomainData.CustomData.ImgBackground.startsWith("http")
          ) {
            style = {
              "background-image": "url('" + this.DomainData.CustomData.ImgBackground + "')",
            };
          } else {
            style = {
              "background-image":
                "url('" + "/assets/companies/" + this.DomainData.Name + "/" + this.DomainData.CustomData.ImgBackground + "')",
            };
          }
        } else {
          style = {
            "background-image": "url('/assets/img/brown-wooden-style.jpg')",
          };
        }
      } else {
        style = {
          "background-image": "url('/assets/img/brown-wooden-style.jpg')",
        };
      }
    } else {
      style = {
        "background-image": "url('/assets/img/brown-wooden-style.jpg')",
      };
    }
    //return style; DISABLED
    return "";
  }

  setTextColor() {
    let style = undefined;
    if (this.DomainData !== undefined && this.DomainData !== null) {
      if (
        this.DomainData.CustomData.ColorHomeMain === "white" ||
        this.DomainData.CustomData.ColorHomeMain === "#FFFFFF" ||
        this.DomainData.CustomData.ColorHomeMain === "rgb(255,255,255)"
      ) {
        style = {
          color: "black",
        };
      } else {
        style = {
          color: "white",
        };
      }
    }
    return style;
  }
}
