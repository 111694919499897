<div class="modal" [ngClass]="{ 'is-active': bUpgradeModalOpened }" style="z-index: 100">
  <div class="modal-background" (click)="closeModal()"></div>
  <div *ngIf="bShowOffers" class="modal-card custom-width">
    <header class="modal-card-head">
      <p class="modal-card-title"></p>
      <button class="delete" aria-label="close" (click)="closeModal()"></button>
    </header>
    <section class="modal-card-body">
      <div class="mb-6" style="text-align: center">
        <h3
          *ngIf="!isFeatureClicked"
          class="oranienbaum-regular has-text-centered is-size-3-fullhd is-size-4-desktop is-size-5-touch mb-4 text-blue"
        >
          {{ "upgrade_plan.title" | translate }}
        </h3>
        <h3
          *ngIf="isFeatureClicked"
          class="oranienbaum-regular has-text-centered is-size-3-fullhd is-size-4-desktop is-size-5-touch mb-4 text-blue"
        >
          {{ "upgrade_plan.alt_title" | translate }}
        </h3>
        <!--
        <p class="subtitle is-6">
          {{ "upgrade_plan.subtitle" | translate }}
          <a href="https://welcome.vizu.live/outil-de-visio-conference-francais" target="_blank">{{
            "upgrade_plan.subtitle_link" | translate
          }}</a
          >.
        </p>
        -->
      </div>

      <div class="tabs" style="place-content: center; display: none">
        <div class="buttons has-addons">
          <button class="button is-selected is-primary">{{ "upgrade_plan.monthly" | translate }}</button>
          <button class="button">{{ "upgrade_plan.annual" | translate }}</button>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div style="display: flex">
            <div class="column">
              <div class="box plan" (click)="planSelected = Plans.Pro" [ngClass]="{ 'selected-plan': planSelected == Plans.Pro }">
                <div style="height: 50%">
                  <h3 class="oranienbaum-regular has-text-centered is-size-4-fullhd is-size-5-desktop is-size-6-touch text-blue mb-1">
                    {{ "upgrade_plan.pro.title" | translate }}
                  </h3>
                  <p class="subtitle has-text-centered is-size-7 mb-4">{{ "upgrade_plan.pro.subtitle" | translate }}</p>
                </div>
                <p class="oranienbaum-regular has-text-centered is-size-3-fullhd is-size-4-desktop is-size-5-touch text-blue">10 €</p>
                <p class="oranienbaum-regular has-text-centered is-size-6 text-blue">{{ "upgrade_plan.perMonth" | translate }}</p>
              </div>
            </div>
            <div class="column">
              <div
                class="box plan"
                (click)="planSelected = Plans.Enterprise"
                [ngClass]="{ 'selected-plan': planSelected == Plans.Enterprise }"
              >
                <div style="height: 50%">
                  <h3 class="oranienbaum-regular has-text-centered is-size-4-fullhd is-size-5-desktop is-size-6-touch text-blue mb-1">
                    {{ "upgrade_plan.enterprise.title" | translate }}
                  </h3>
                  <p class="subtitle has-text-centered is-size-7 mb-4">{{ "upgrade_plan.enterprise.subtitle" | translate }}</p>
                </div>
                <p class="oranienbaum-regular has-text-centered is-size-3-fullhd is-size-4-desktop is-size-5-touch text-blue">15 €</p>
                <p class="oranienbaum-regular has-text-centered is-size-6 text-blue">
                  {{ "upgrade_plan.perMonth" | translate }}
                </p>
              </div>
            </div>
            <div class="column">
              <div
                class="box plan"
                [ngClass]="{ 'selected-plan': planSelected == Plans.Custom }"
                (click)="openLink('https://welcome.vizu.live/contact')"
              >
                <div style="height: 50%">
                  <h3 class="oranienbaum-regular has-text-centered is-size-4-fullhd is-size-5-desktop is-size-6-touch text-blue mb-1">
                    {{ "upgrade_plan.custom.title" | translate }}
                  </h3>
                  <p class="subtitle has-text-centered is-size-7 mb-4">{{ "upgrade_plan.custom.subtitle" | translate }}</p>
                </div>
                <p class="oranienbaum-regular has-text-centered is-size-4-fullhd is-size-5-desktop is-size-6-touch text-blue">
                  {{ "upgrade_plan.custom.quote" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="has-text-centered mt-6">
            <button class="button is-outlined is-blue is-medium vizu-padding pointer" (click)="upgradePlan()">
              {{ "upgrade_plan.buttons.valid" | translate }}
            </button>
          </div>
        </div>
        <div class="column">
          <div class="panel">
            <h3
              *ngIf="planSelected == Plans.Enterprise"
              class="oranienbaum-bold has-text-left is-size-3-fullhd is-size-4-desktop is-size-5-touch"
            >
              {{ "upgrade_plan.enterprise.advantages.title" | translate }}
            </h3>
            <p *ngIf="planSelected == Plans.Enterprise" class="subtitle is-7">
              {{ "upgrade_plan.enterprise.advantages.subtitle" | translate }}
            </p>
            <h3 *ngIf="planSelected == Plans.Pro" class="oranienbaum-bold has-text-left is-size-3-fullhd is-size-4-desktop is-size-5-touch">
              {{ "upgrade_plan.pro.advantages.title" | translate }}
            </h3>
            <p *ngIf="planSelected == Plans.Pro" class="subtitle is-7">{{ "upgrade_plan.pro.advantages.subtitle" | translate }}</p>

            <div class="feature-list">
              <div class="list">
                <div class="list-item">
                  <span class="icon has-text-success">
                    <i class="fa-regular fa-square-check"></i>
                  </span>
                  <span *ngIf="planSelected == Plans.Enterprise" class="list-text is-size-6">
                    {{ "upgrade_plan.enterprise.advantages.list.first" | translate }}
                  </span>
                  <span *ngIf="planSelected == Plans.Pro" class="list-text is-size-6">
                    {{ "upgrade_plan.pro.advantages.list.first" | translate }}
                  </span>
                </div>
                <div class="list-item">
                  <span class="icon has-text-success">
                    <i class="fa-regular fa-square-check"></i>
                  </span>
                  <span *ngIf="planSelected == Plans.Enterprise" class="list-text is-size-6">
                    {{ "upgrade_plan.enterprise.advantages.list.second" | translate }}
                  </span>
                  <span *ngIf="planSelected == Plans.Pro" class="list-text is-size-6">
                    {{ "upgrade_plan.pro.advantages.list.second" | translate }}
                  </span>
                </div>
                <div class="list-item">
                  <span class="icon has-text-success">
                    <i class="fa-regular fa-square-check"></i>
                  </span>
                  <span *ngIf="planSelected == Plans.Enterprise" class="list-text is-size-6">
                    {{ "upgrade_plan.enterprise.advantages.list.third" | translate }}
                  </span>
                  <span *ngIf="planSelected == Plans.Pro" class="list-text is-size-6">
                    {{ "upgrade_plan.pro.advantages.list.third" | translate }}
                  </span>
                </div>
                <div class="list-item">
                  <span class="icon has-text-success">
                    <i class="fa-regular fa-square-check"></i>
                  </span>
                  <span *ngIf="planSelected == Plans.Enterprise" class="list-text is-size-6">
                    {{ "upgrade_plan.enterprise.advantages.list.fourth" | translate }}
                  </span>
                  <span *ngIf="planSelected == Plans.Pro" class="list-text is-size-6">
                    {{ "upgrade_plan.pro.advantages.list.fourth" | translate }}
                  </span>
                </div>
                <div class="list-item">
                  <span class="icon has-text-success">
                    <i class="fa-regular fa-square-check"></i>
                  </span>
                  <span *ngIf="planSelected == Plans.Enterprise" class="list-text is-size-6">
                    {{ "upgrade_plan.enterprise.advantages.list.fifth" | translate }}
                  </span>
                  <span *ngIf="planSelected == Plans.Pro" class="list-text is-size-6">
                    {{ "upgrade_plan.pro.advantages.list.fifth" | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div *ngIf="!bShowOffers" class="modal-content custom-width">
    <app-vizu-payment
      (ClosePaymentModal)="closePaymentModal(true)"
      (ClosePaymentAndUpgradeModal)="closePaymentAndUpgradeModal()"
    ></app-vizu-payment>
  </div>
</div>
