import { Component, OnInit, Output, EventEmitter, AfterViewInit, ElementRef, ViewChild } from "@angular/core";
import { CustomerService } from "../../services/customer.service";
import { ActivatedRoute, Router } from "@angular/router";

// Stripe est chargé globalement depuis le script dans index.html
declare var Stripe: any;

@Component({
  selector: "app-vizu-payment",
  templateUrl: "./vizu-payment.component.html",
  styleUrls: ["./vizu-payment.component.scss"],
})
export class VizuPaymentComponent implements OnInit, AfterViewInit {
  @ViewChild("cardNumberElement") cardNumberElement!: ElementRef;
  @ViewChild("cardExpiryElement") cardExpiryElement!: ElementRef;
  @ViewChild("cardCvcElement") cardCvcElement!: ElementRef;
  @Output() ClosePaymentModal = new EventEmitter<boolean>();

  stripe: any;
  cardNumber: any;
  cardExpiry: any;
  cardCvc: any;
  paymentStatus: string | null = null;
  paymentStatusReason: string | null = null;
  successToken: string | null = null;

  constructor(private customerService: CustomerService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    // Initialisation de Stripe avec votre clé publique
    this.stripe = Stripe("pk_test_51QBFGSJTKQ4N6n2iZaKa0DXEUzSrujmZZO6yzcdS10p9tV4zK4FhRhEz9LqzI9TNP1eBoFdoH9kLm4GFVN3u9d9a00CpkJMqUI"); // Clé publique Stripe
  }

  ngAfterViewInit(): void {
    // Créez une instance de Stripe Elements et intégrez-la dans le formulaire
    const elements = this.stripe.elements();

    this.cardNumber = elements.create("cardNumber");
    this.cardNumber.mount(this.cardNumberElement.nativeElement);

    this.cardExpiry = elements.create("cardExpiry");
    this.cardExpiry.mount(this.cardExpiryElement.nativeElement);

    this.cardCvc = elements.create("cardCvc");
    this.cardCvc.mount(this.cardCvcElement.nativeElement);
  }

  closeModal(bCloseModal: boolean) {
    this.ClosePaymentModal.emit(bCloseModal);
  }

  handlePayment(event: Event) {
    event.preventDefault();

    // Créer une session de paiement via votre backend
    this.customerService.createCheckoutSession().subscribe((session) => {
      this.stripe
        .confirmCardPayment(session.client_secret, {
          payment_method: {
            card: this.cardNumber,
          },
        })
        .then((result: any) => {
          if (result.error) {
            this.paymentStatus = "fail";
            if (result.error.code === "card_declined") {
              this.paymentStatusReason = result.error.decline_code;
            } else {
              this.paymentStatusReason = result.error.code;
            }
          } else if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
            this.paymentStatus = "success";
            this.paymentStatusReason = "";
            this.successToken = session.token; // Utilisation du token de succès renvoyé par votre backend
            let myThis = this;
            setTimeout(myThis.closeModal, 2000, true);
          }
        });
    });
  }
}
