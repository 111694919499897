import { Directive, ElementRef, Input } from "@angular/core";
declare const ngmediaRTC: any;

@Directive({
  selector: "[appNgmediaVideo]",
})
export class NgmediaVideoDirective {
  @Input("appNgmediaVideo") stream = "";

  constructor(private el: ElementRef) {}

  private video(stream: any, element: any) {
    window.setTimeout(function () {
      if (stream) {
        if (stream.local) {
          if (ngmediaRTC !== undefined) {
            ngmediaRTC.setLocalMediaTag(stream.id);
          }
        } else {
          stream.stream.setRemoteMediaTag(stream.stream.streamId);
        }
      }
    });
  }

  ngOnInit() {
    this.video(this.stream, this.el);
  }
}
