import { Component, Input } from "@angular/core";
import { Limitations } from "../../../domain/domain";

@Component({
  selector: "app-shared-room",
  templateUrl: "./shared-room.component.html",
  styleUrl: "./shared-room.component.scss",
})
export class SharedRoomComponent {
  @Input() Customer: any = {};
  @Input() Rooms: any[];
  @Input() Limitations: Limitations;
  @Input() bAdminConfig: boolean = false;
  @Input() bAccountConfig: boolean = false;

  @Input() sendClickInfoMessage!: (info: string) => void;
  @Input() CopyLink!: (roomId: any) => void;
  @Input() displayQrCodeLink!: (showQrCodeElement: any) => void;
  @Input() displayQrCodeSms!: (showQrCodeElement: any) => void;
  @Input() EnterOwnRoom!: (room: string) => void;

  setDomainMainColor() {}
}
