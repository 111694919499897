import { Component } from "@angular/core";

@Component({
  selector: "app-loader-bars",
  templateUrl: "./loader-bars.component.html",
  styleUrls: ["./loader-bars.component.scss"],
})
export class LoaderBarsComponent {
  constructor() {}
}
