<nav class="navbar-header" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <p
      *ngIf="
        DomainData &&
        DomainData.CustomData != undefined &&
        DomainData.CustomData.CompanyLogoURI == undefined &&
        (DomainData.CustomData.LogoURI === undefined || DomainData.CustomData.LogoURI === '')
      "
      class="text-logo oranienbaum-regular"
    >
      {{ DomainData.CustomData.FriendlyName }}
    </p>
    <img
      *ngIf="
        DomainData &&
        DomainData.CustomData != undefined &&
        DomainData.CustomData.CompanyLogoURI == undefined &&
        DomainData.CustomData.LogoURI !== undefined &&
        DomainData.CustomData.LogoURI !== '' &&
        bLogoFullPath
      "
      class="vizu-logo"
      alt="Logo"
      src="{{ DomainData.CustomData.LogoURI }}"
    />
    <img
      *ngIf="DomainData && DomainData.CustomData != undefined && DomainData.CustomData.CompanyLogoURI != undefined"
      class="vizu-logo"
      alt="Logo"
      src="{{ DomainData.CustomData.CompanyLogoURI }}"
    />
    <img
      *ngIf="
        DomainData &&
        DomainData.CustomData != undefined &&
        DomainData.CustomData.CompanyLogoURI == undefined &&
        DomainData.CustomData.LogoURI !== undefined &&
        DomainData.CustomData.LogoURI !== '' &&
        !bLogoFullPath
      "
      class="vizu-logo"
      alt="Logo"
      src="{{ '/assets/companies/' + DomainData.Name + '/' + DomainData.CustomData.LogoURI }}"
    />
    <ng-content></ng-content>
  </div>
</nav>
