import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-vizu-upgrade-plan",
  templateUrl: "./vizu-upgrade-plan.component.html",
  styleUrls: ["./vizu-upgrade-plan.component.scss"],
})
export class VizuUpgradePlanComponent implements OnChanges {
  @Input() bUpgradeModalOpened: boolean;
  @Input() CurrentSubscription: string;
  @Output() CloseUpgradeModal = new EventEmitter<void>();

  Plans: any = {
    Free: "free",
    Pro: "pro",
    Enterprise: "enterprise",
    Custom: "custom",
  };

  planSelected: string = this.Plans.Pro;
  bShowOffers: boolean = true;
  isFeatureClicked = true;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.CurrentSubscription) {
      if (this.CurrentSubscription === "free") {
        this.planSelected = this.Plans.Pro;
      } else if (this.CurrentSubscription === "pro") {
        this.planSelected = this.Plans.Enterprise;
      }
    }
  }

  openModal() {
    this.bUpgradeModalOpened = true;
  }

  closePaymentAndUpgradeModal() {
    this.closeModal();
  }

  closePaymentModal(state: boolean) {
    this.bShowOffers = state;
  }

  closeModal() {
    this.bUpgradeModalOpened = false;
    this.CloseUpgradeModal.emit();
  }

  upgradePlan() {
    /*if (this.planSelected === this.Plans.Pro && this.CurrentSubscription !== "pro") {
      this.bShowOffers = !this.bShowOffers;
    } else {
      this.openLink("https://welcome.vizu.live/contact"); // TEMP (delete this)
      this.closeModal();
    }*/
    this.openLink("https://welcome.vizu.live/contact"); // TEMP (delete this)
    this.closeModal();
  }

  openLink(url: string) {
    window.open(url, "_blank");
  }
}
