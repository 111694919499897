import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sortByMessagesTimestamp",
})
export class SortByMessagesTimestampPipe implements PipeTransform {
  transform(contacts: any[], order: string = "asc"): any[] {
    if (!contacts || contacts.length === 0) {
      return [];
    }

    return contacts.sort((a, b) => {
      const lastMessageA = a.Messages[a.Messages.length - 1];
      const lastMessageB = b.Messages[b.Messages.length - 1];
      const timestampA = new Date(lastMessageA?.Timestamp).getTime();
      const timestampB = new Date(lastMessageB?.Timestamp).getTime();
      if (order === "asc") {
        return timestampA - timestampB;
      } else {
        return timestampB - timestampA;
      }
    });
  }
}
