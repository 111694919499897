import { HttpClientModule } from "@angular/common/http";
import { EnvironmentProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { EmitterService } from "./services/emitter.service";
import { ErrorService } from "./services/error.service";
import { ErrorHandler } from "@angular/core";
//import { ApiService } from './services/api.service';

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslationLoader } from "./translation/translation";
import { MatMenuModule } from "@angular/material/menu";

import { AppComponent } from "./app.component";
import { NgmediaVideoDirective } from "./ngmedia-video.directive";
import { FileUploaderComponent } from "./components/file-uploader/file-uploader.component";
import { LandingContainerComponent } from "./components/landing-container/landing-container.component";
import { LandingPageComponent } from "./components/landing-page/landing-page.component";
import { LoaderBarsComponent } from "./components/loader-bars/loader-bars.component";
import { LoaderPointsComponent } from "./components/loader-points/loader-points.component";
import { LoadingMenuComponent } from "./components/loading-menu/loading-menu.component";
import { LoadingScreenComponent } from "./components/loading-screen/loading-screen.component";
import { LoginComponent } from "./components/login/login.component";
import { RegisterComponent } from "./components/register/register.component";
import { MainComponent } from "./components/main/main.component";
import { VizuRoomComponent } from "./components/vizu-room/vizu-room.component";
import { VizuMainComponent } from "./components/vizu-main/vizu-main.component";
import { VizuPresenceComponent } from "./components/vizu-presence/vizu-presence.component";
import { VizuShellComponent } from "./components/vizu-shell/vizu-shell.component";
import { VizuHeaderComponent } from "./components/vizu-header/vizu-header.component";
import { VizuFooterComponent } from "./components/vizu-footer/vizu-footer.component";
import { VizuPublicComponent } from "./components/vizu-public/vizu-public.component";
import { VizuRecaptchaComponent } from "./components/vizu-recaptcha/vizu-recaptcha.component";
import { PeopleIconComponent } from "./components/people-icon/people-icon.component";
import { VizuAccountConfigComponent } from "./components/vizu-account-config/vizu-account-config.component";
import { VizuAdminConfigComponent } from "./components/vizu-admin-config/vizu-admin-config.component";
import { VizuEmojisComponent } from "./components/vizu-emojis/vizu-emojis.component";
import { ButtonSwitchComponent } from "./components/button-switch/button-switch.component";
import { VizuRatingComponent } from "./components/vizu-rating/vizu-rating.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { VizuAudioPermComponent } from "./components/vizu-audio-perm/vizu-audio-perm.component";
import { CollaboratorPipe } from "./pipes/collaborator.pipe";
import { HistoryEntryPipe } from "./pipes/historyentry.pipe";
import { SortByMessagesTimestampPipe } from "./pipes/sort-by-messages-timestamp.pipe";
import { ColorPickerModule } from "ngx-color-picker";
import { ImageCropperComponent } from "ngx-image-cropper";
//import { ImageCropperStandalone } from './components/image-cropper/image-cropper.component'

import { PasswordResetComponent } from "./components/password-reset/password-reset.component";
import { PasswordResetRequestComponent } from "./components/password-reset/password-reset-request/password-reset-request.component";
import { VizuCookieComponent } from "./components/vizu-cookie/vizu-cookie.component";
import { VizuUpgradePlanComponent } from "./components/vizu-upgrade-plan/vizu-upgrade-plan.component";
import { VizuDownloadFilesComponent } from "./components/vizu-download-files/vizu-download-files.component";
import { ButtonFeedbackComponent } from "./components/button-feedback/button-feedback.component";
import { VerifyAccountComponent } from "./components/verify-account/verify-account.component";
import { NotificationComponent } from "./components/notification/notification.component";
import { VizuHomeLayoutComponent } from "./components/vizu-home-layout/vizu-home-layout.component";

import { VizuBubblesComponent } from "./components/vizu-bubbles/vizu-bubbles.component";

import { SelfRoomComponent } from "./components/vizu-main/self-room/self-room.component";
import { SharedRoomComponent } from "./components/vizu-main/shared-room/shared-room.component";
import { ContactsComponent } from "./components/vizu-main/contacts/contacts.component";
import { MessagesComponent } from "./components/vizu-main/messages/messages.component";
import { VizuPaymentComponent } from "./components/vizu-payment/vizu-payment.component";

import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";

const RECAPTCHA_V3_STACKBLITZ_KEY = "6Lfkhg4qAAAAAFvyBoS7dKVjfJH5Vpy5s9Japijs";

//import { Hash } from 'crypto'

const appRoutes: Routes = [
  /*{
        path: 'signup',
        component: AddUserComponent,
    },
    {
        path: 'presignup',
        component: AddPreregisteredUserComponent,
    },
    {
        path: 'email',
        component: EmailValidationComponent,
    },
    {
        path: 'passwordreset',
        component: PasswordResetRequestComponent,
    },
    {
        path: 'password',
        component: PasswordResetComponent,
    },
    {
        path: 'login',
        component: LoginComponent,
    },*/
  {
    path: "",
    component: MainComponent,
  },
  {
    path: "register",
    component: MainComponent,
  },
  /*{
        path: ':id',
        component: VizuPublicComponent,
    },*/
];

function getBooleanFromLocalStorage(item: string) {
  return localStorage.getItem(item) === "true";
}

export let initErrorHandler = () => {
  let bErrorHandler = true;
  if (localStorage.getItem("VizuLive\\ErrorHandler") !== "null" && localStorage.getItem("VizuLive\\ErrorHandler") !== null) {
    bErrorHandler = getBooleanFromLocalStorage("VizuLive\\ErrorHandler");
  } else {
    localStorage.setItem("VizuLive\\ErrorHandler", "true");
  }
  return bErrorHandler;
  /*let errorHandlerProvider = undefined;
  if (bErrorHandler) {
    errorHandlerProvider = { provide: ErrorHandler, useClass: ErrorService };
  } else {
    errorHandlerProvider = {};
  }
  return errorHandlerProvider;*/
};

@NgModule({
  declarations: [
    AppComponent,
    NgmediaVideoDirective,
    FileUploaderComponent,
    LandingContainerComponent,
    LandingPageComponent,
    LoaderBarsComponent,
    LoaderPointsComponent,
    LoadingMenuComponent,
    LoadingScreenComponent,
    LoginComponent,
    RegisterComponent,
    MainComponent,
    VizuRoomComponent,
    VizuMainComponent,
    VizuPresenceComponent,
    VizuShellComponent,
    VizuHeaderComponent,
    VizuFooterComponent,
    VizuPublicComponent,
    VizuRecaptchaComponent,
    PeopleIconComponent,
    VizuAccountConfigComponent,
    VizuAdminConfigComponent,
    VizuEmojisComponent,
    ButtonSwitchComponent,
    VizuRatingComponent,
    VizuAudioPermComponent,
    CollaboratorPipe,
    HistoryEntryPipe,
    SortByMessagesTimestampPipe,
    PasswordResetComponent,
    PasswordResetRequestComponent,
    VizuCookieComponent,
    VizuUpgradePlanComponent,
    VizuDownloadFilesComponent,
    ButtonFeedbackComponent,
    VerifyAccountComponent,
    NotificationComponent,
    VizuHomeLayoutComponent,
    VizuBubblesComponent,
    SelfRoomComponent,
    SharedRoomComponent,
    ContactsComponent,
    MessagesComponent,
    VizuPaymentComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ColorPickerModule,
    MatMenuModule,
    RouterModule.forRoot(appRoutes),
    //RouterModule.forRoot(appRoutes, {useHash: true}),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
    }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    ImageCropperComponent,
    //ImageCropperStandalone
  ],
  providers: [
    //ApiService,
    EmitterService,
    { provide: Window },
    //Comment next line to disable error in vizu-errors
    initErrorHandler() ? { provide: ErrorHandler, useClass: ErrorService } : ErrorService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: RECAPTCHA_V3_STACKBLITZ_KEY },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
