export const fr = {
  landing_page: {
    main_text: "La Visioconférence Nouvelle Génération",
    video: "Chat vidéo",
    screensharing: "Partage d'écran",
    file_sharing: "Transfert de fichier",
    try: "Inscription",
    login: "Connexion",
  },
  add_user: {
    title: "Inscription",
    email: "Email",
    password: "Mot de passe",
    security: "Sécurité",
    name: "Nom affiché",
    submit: "S'inscrire",
    error: "Une erreur s'est produite",
    admin_added: "Utilisateur invité avec succès",
  },
  home: {
    description: "Retrouvez vos clients dans un lieu dédié, sécurisé et à votre image.",
  },
  register: {
    room_exist: "La room existe déjà.",
    email_exist: "L'adresse e-mail existe déjà.",
    confirm: "Confirmer",
    resend: "Renvoyer",
    resend_success: "Code renvoyé !",
    invalid_code: "Code incorrect",
  },
  login: {
    title: "Connexion",
    email: "Email",
    login: "Connexion",
    password: "Mot de passe",
    confirm_password: "Confirmer le mot de passe",
    displayname: "Nom affiché",
    roomname: "Nom de salle",
    submit: "Connexion",
    signup: "Inscription",
    forgot: "Mot de passe oublié ?",
    badcreds: "Mauvais email ou mot de passe",
    toomanyreq: "Trop de tentatives de connexion.\nVeuillez réessayer plus tard",
    error: "Une erreur s'est produite",
    remember: "Se souvenir de moi",
    new_to_vizu: "Nouveau sur Vizu.live ?",
    create_account: "Créer un compte",
    account_activated: "Votre compte est maintenant activé !",
    code_validation: "Validation",
    email_validation_sent1: "Un email contenant un code de validation à été envoyé à ",
    email_validation_sent2: "Entrez le code ci-dessous",
    promo_code: "Code promo (facultatif)",
  },
  password_reset: {
    title: "Réinitialiser le mot de passe",
    email: "Email",
    submit: "Réinitialiser",
    error: "Une erreur s'est produite",
    success: "Vous avez reçu un email pour réinitialiser votre mot de passe s'il est associé à un compte Vizu.live.",
  },
  password_reset_new: {
    password: "Nouveau mot de passe",
    comfirm_password: "Comfirmer le mot de passe",
    same_password: "Les mots de passe doivent être identiques",
    success: "Votre mot de passe a été modifié",
  },
  email_validation: {
    title: "Chargement",
    success: "Votre adresse email est vérifiée",
  },
  cookie: {
    description:
      "Vizu.live utilise des cookies pour permettre et améliorer l'utilisation du site Web. Veuillez consulter notre politique en matière de cookies pour plus d'informations.",
    settings: "Paramètres",
    allow: "Autoriser",
    cookie_settings: "Paramètres des cookies",
    necessary: {
      title: "Nécessaire",
      text: "Ces cookies sont nécessaires au fonctionnement de Vizu.live, ne peuvent pas être désactivés.",
    },
    performance: {
      title: "Performance",
      text: "Ces cookies contribuent à améliorer votre expérience en ligne en stockant les choix d'utilisateur sur le site Web. Par exemple, ils sont utilisés pour vous connecter automatiquement en mémorisant vos informations d'identification.",
    },
    advertising: {
      title: "Publicité",
      text: "Vizu.live ne collecte pas vos données à des fin publicitaire et n'a donc pas ce genre de cookie.",
    },
    accept: "Accepter",
    reject: "Rejeter",
    save: "Sauvegarder",
    cancel: "Annuler",
  },
  shell: {
    menu: {
      rooms: "Salles",
      appointments: "Rendez-vous",
      list: "Liste",
      new: "Nouvelle",
      configuration: "Configuration",
      account_config: "Compte",
      account_subscription: "Abonnement",
      description: "Page public",
      pricing: "Prix",
      call_logs: "Journal",
      admin_no_uppercase: "Eviter les majuscules",
      admin: "Administration",
      admin_new_user: "Nouvel utilisateur",
      admin_user_list: "Utilisateurs",
      admin_call_logs: "Journal d'appels",
      admin_current_calls: "Appels en cours",
      admin_feedback: "Feedback",
    },
    disconnected: {
      title: "Déconnexion",
      text: "Vous avez été déconnecté",
      reload: "Recharger",
    },
    shared_room: {
      available: "Disponible",
      busy: "Occupé",
    },
    room: {
      not_exist: "La salle demandée n'existe pas",
      welcolme: "Bienvenue ",
      my_room: "Mon bureau",
      room_of: "Bureau de ",
      room_link: "Lien vers la room",
      title: "Salles de réunion",
      copy: "Copier",
      paste: "Coller",
      invite: "Invitation de participants",
      locked_tooltip: "Le distant ne peut rejoindre sans acceptation",
      unlocked_tooltip: "Le distant peut rejoindre sans acceptation",
      send_email: "Inviter par email",
      send_sms: "Envoyer le lien par SMS",
      share: "Partager",
      qrcode_sms_title: "Inviter par SMS",
      qrcode_sms_1: "Ouvrez une application de lecture de QRCode avec votre smartphone",
      qrcode_sms_2: "Scannez le QRCode",
      qrcode_sms_3: "Envoyez l'invitation par SMS",
      qrcode_sms_number: "Remote phone number",
      qrcode_link: "Obtenir un QRCode",
      qrcode_link_title: "Partager le lien ou le QRCode",
      qrcode_sms: "Inviter par SMS",
      copy_link: "Copier le lien d'accès",
      error_audio: "Accès au microphone impossible !",
      error_video: "Accès à la caméra impossible !",
      error_solve: "Vérifier son branchement ou son utilisation",
      error_notfound1: "Impossible d'accéder aux périphériques",
      error_notfound2: "Vérifier qu'ils ne sont pas utilisés par une autre application",
      public: "public",
      private: "privé",
      room: "Salle",
      enter: "Entrer",
      join: "Rejoindre",
      team: "Equipe",
      attendees: "Participants",
      waiting: "En attente",
      free: "Libre",
      busy: "Occupé",
      collaborators: "Mes collaborateurs",
      reject: "Rejeter",
      reject_all: "Rejeter tous",
      accept_all: "Accepter tous",
      reject_call: "Rejeter l'appel",
      reject_with_message: "Rejeter avec un message",
      reject_message_placeholder: "Je ne suis pas disponible pour le moment, je vous propose de me rappeler plus tard.",
      call_to: "Appel vers ",
      call_cancel: "Annuler l'appel",
      call_reject_message: "Message de refus d'appel:",
      free_called_nomessage: "",
      end_user_message_forbidden:
        "Cet invité n'a pas pu vous laisser de message à cause de la limite gratuite de votre compte. Devenez Pro pour profiter de cette fonctionnalité",
      team_panel_hidden1: "Cette zone est dédiée aux comptes entreprise",
      team_panel_hidden2: "Pour avoir plus d'informations sur ce type d'abonnement cliquez ci-dessous",
      messages: {
        not_verfied: "Non vérifié",
        unknown_user_verbose: "Utilisateur inconnu (Externe), les messages provenant de personnes inconnues peuvent être indésirable.",
      },
    },
    feedback: {
      title: "Donnez nous votre avis",
      placeholder: "Votre avis",
      question_1_2: "Nous sommes désolés de l'apprendre ! Quels problèmes avez-vous rencontré ?",
      question_3_4: "Quelles sont vos impressions sur ce service ?",
      evaluate: "Evaluez la qualité de cette visioconférence :",
      thanks_1: "Merci d'avoir participé !",
      thanks_2: "Vous souhaitez également organiser vos propres visioconférences avec Vizu.Live ?",
      send: "Envoyer",
      sent: "Pas encore de compte ?",
      sent_arg1: "Inscription simple sans installation de logiciel",
      sent_arg2: "Invitatez avec un simple lien ou SMS",
      sent_arg3: "Collaborez sans limite de temps imposé",
      error: "Une erreur s'est produite",
      unavailable1: "Ce contact n'est pas disponible",
      unavailable2: "Vous pouvez lui laisser un message ci-dessous",
      unavailableTitle1: "Vous n'avez pas pu rencontrer votre interlocuteur.",
      unavailableTitle2: "Mais peut-être souhaitez-vous utiliser Vizu.Live pour vos propres visioconférences ?",
      content_message: "Message",
      coordinates_message: "Coordonnées",
      message_sent: "Le message a bien été envoyé à votre destinaire",
      comment: "Commentaire:",
    },
    buttons: {
      config: "Configuration",
      feedback: "Avis",
      logout: "Se déconnecter",
      close: "Fermer",
      lock: "Bloquer",
      unlock: "Débloquer",
    },
  },
  room_list: {
    attendees: "Participants",
    copy_link: "Copier le lien",
    no_room: "Vous n'avez pas encore de salle",
    new_room: "Créer une salle",
    enter_room: "Entrer dans la salle",
  },
  room_rating: {
    try_vizu: "Envie de tester vizu.live pour vos propres visioconférences ?",
    create: "Créer un compte gratuit :",
    register: "S'enregistrer",
    discover: "D'écouvrir nos solutions professionnelles",
  },
  new_room: {
    name: "Nom de la salle",
    submit_button: "Ajouter",
    add_attendee: "Ajouter un participant",
    contact_name: "Nom du participant",
    contact_email: "Email du participant",
    name_required: "Le nom de la salle est requis",
    email_required: "L'email du participant est requis",
    error: "Une erreur s'est produite",
    cancel_button: "Annuler",
    config: {
      audio: {
        name: "Audio",
        description: "Démarrer la conférence avec l'audio",
      },
      video: {
        name: "Vidéo",
        description: "Démarrer la conférence avec la vidéo",
      },
      picture: {
        name: "Photo",
        description: "Autoriser la prise de photos",
      },
      screen_sharing: {
        name: "Partage d'écran",
        description: "Autoriser le partage d'écran",
      },
      display_name: {
        name: "Noms des participants",
        description: "Demander le nom aux participants",
      },
    },
  },
  new_appointment: {
    contact_name: "Nom du contact",
    contact_email: "Email du contact",
    date: "Date",
    time: "Heure",
    submit_button: "Ajouter",
  },
  description_editor: {
    header: "Entête",
    title: "Titre",
    subtitle: "Sous-titre",
    sections: "Sections",
    add_section: "Ajouter une section",
    section_title: "Titre de la section",
    section_content: "Contenu de la section",
    save_button: "Sauver",
  },
  account_config: {
    confirm: "Confirmer",
    delete: "Supprimer",
    cancel: "Annuler",
    save: "Sauver",
    confirm_delete: "Êtes-vous sûr de la suppression ?",
    room_name: "Nom de la salle",
    apply: "Appliquer",
    profile_picture_uri: "URL de l'image de profil",
    profile_preview: "Aperçu de l'image de profil:",
    change_password: "Changer de mot de passe",
    change_email: "Changer d'adresse email",
    confirm_password: "Confirmer le mot de passe",
    old_password: "Mot de passe actuel",
    new_password: "Nouveau mot de passe",
    password_strength1: "Le mot de passe doit contenir au moins:",
    password_strength2: "8 caractères",
    password_strength3: "1 minuscule",
    password_strength4: "1 majuscule",
    password_strength5: "1 chiffre",
    password_strength6: "1 caractère spécial",
    new_email: "Nouvelle adresse email",
    browse: "Choisissez un fichier…",
    import_profile: "Import de l'avatar",
    import_company: "Import du logo d'entreprise",
    background_color: "Couleur de fond",
  },
  admin_config: {
    company_filter: "Filtre d'entreprise",
    companies: "Entreprises",
    users: "Utilisateurs",
    rooms: "Salles",
    no_room: "Pas de salle",
    no_user: "Pas d'utilisateur",
    no_company: "Pas d'entreprise",
    enabled: "Activé",
    select: "Sélectionner",
    room: {
      display_name: "Nom affiché",
      room: "Salle",
      company: "Entreprise",
      edit_room: "Editer la room",
      new_room: "Nouvelle room",
    },
    user: {
      display_name: "Nom affiché",
      admin: "Administrateur",
      room: "Salle",
      email: "Email",
      company: "Entreprise",
      firstname: "Prénom",
      lastname: "Nom",
      edit_user: "Editer l'utilisateur",
      new_user: "Nouvel utilisateur",
      subscription_type: "Type de Souscription",
      password: "Mot de passe",
    },
    company: {
      company: "Entreprise",
      display_name: "Nom affiché",
      company_url: "Lien de l'entreprise",
      edit_company: "Editer l'entreprise",
      new_company: "Nouvelle entreprise",
      logouri: "Lien du logo",
      import_company_picture: "Import de l'image d'entreprise",
      background_color: "Couleur de fond",
    },
  },
  upgrade_plan: {
    title: "Mettre à niveau son abonnement",
    alt_title: "Cette fonctionnalité est disponible sur les offres payantes",
    subtitle: "Si vous avez besoin de plus d'informations, veuillez consulter la ",
    subtitle_link: "Grille de tarification",
    monthly: "Mensuelle",
    annual: "Annuel",
    popular: "Populaire",
    perMonth: "/ mois",
    buttons: {
      valid: "Mettre à niveau",
      cancel: "Annuler",
    },
    enterprise: {
      title: "Entreprise",
      subtitle: "La version la plus complète de Vizu.Live",
      advantages: {
        title: "Que contient l'offre Entreprise ?",
        subtitle: "Inclut les avantages Free et Pro pour seulement 15€ par utilisateur/mois",
        list: {
          first: "Sous domaine et noms de salles réservés",
          second: "1 room partagée tous les 3 utilisateurs",
          third: "Messagerie instantannée au sein de votre équipe",
          fourth: "Customisation des images/logos",
          fifth: "Support technical prenium",
        },
      },
    },
    pro: {
      title: "Pro",
      subtitle: "Idéal pour les startups",
      advantages: {
        title: "Que contient l'offre Pro ?",
        subtitle: " ",
        list: {
          first: "Pas de limite de temps",
          second: "Messagerie hors ligne",
          third: "Jusqu'à 16 participants",
          fourth: "Personnaliser l'arrière-plan et le logo",
          fifth: "Assistance client par mail",
        },
      },
    },
    free: {
      title: "Free",
      subtitle: "Pour découvrire Vizu.Live",
      advantages: {
        title: "Que contient l'offre Free ?",
        subtitle: " ",
        list: {
          first: "Une room personnelle",
          second: "Jusqu'à 3 participants",
          third: "Visioconférence jusqu'à 45 minutes",
          fourth: "Transfert de fichiers pendant la visioconférence",
          fifth: "Partage d'écrans & pdf",
        },
      },
    },
    custom: {
      title: "Personnaliser",
      subtitle: "Demander une licence personnalisée",
      contact: "Contacter",
      quote: "Sur devis",
    },
  },
  subscription: {
    subscribe_button: "Souscrire",
    ok: "Votre abonnement est en cours",
    unsubscribe: "Se désabonner",
    period_end: "Fin de la période en cours",
    update_card_button: "Mettre à jour",
    overdue: "Votre abonnement est en retard de paiement depuis le",
    payment_update: "Vous pouvez mettre à jour vos information de paiement",
    trial_period_end: "Fin de la période d'essai",
    trial_overdue: "La période d'essai est terminée depuis le",
    payment_errors: {
      generic_decline: "La carte a été refusée pour une raison inconnue",
      insufficient_funds: "La carte dispose de fonds insuffisants  pour complété cet achat",
      lost_card: "Le paiement a été refusé car la carte a été reportée comme perdue",
      stolen_card: "Le paiement a été refusé car la carte a été reportée comme volée",
      expired_card: "La carte a expiré",
      incorrect_cvc: "Le numéro de CVC est incorrect",
      processing_error: "Une erreur s'est produite avec cette carte",
      incorrect_number: "Le numéro de carte est incorrect",
      card_velocity_exceeded: "La dépense limite a été atteinte sur cette carte",
    },
  },
  common: {
    error: "Une erreur s'est produite",
    link_failure1: "Connexion au serveur impossible",
    link_failure2: "Nous tentons de rétablir la connexion",
    already_used: "Ce nom est déjà réservé",
    too_small: "Ce nom est trop court",
    reload: "Recharger",
  },
  loading: "Chargement",
  focus_modal: {
    title: "Action requise",
    body1: "Nouveau document reçu",
    body2: "Cliquez ici pour le voir",
  },
  add_participant_modal: {
    title: "Ajouter un participant",
    body: "Entrer le numéro de téléphone ou le nom du contact à ajouter à l'appel",
  },
  public_room: {
    help: "Besoin d'aide ?",
    init: "Chargement",
    refused: "Vous n'avez pas été autorisé à entrer dans la salle.",
    welcome: "Bienvenue chez ",
    not_found_title: "La salle est introuvable",
    not_found_url_title: "La société est introuvable",
    not_found_main: "Etes-vous sûr d'avoir rentré la bonne adresse ?",
    step1: "1. Verification acces microphone",
    step2: "2. Verification acces camera",
    you_will_enter: "Vous allez entrer dans la salle de",
    get_name: "Saisisssez votre nom",
    get_text: "Saisisssez votre question",
    get_name_placeholder: "Nom",
    get_name_submit: "Rejoindre",
    security_test: "Tests de sécurité en cours...",
    media_granted1: "Nous essayons d'accéder à votre microphone ou webcam",
    media_granted2: "Votre navigateur va vous demander d'accéder à ces périphériques",
    media_granted3: "Merci de cliquer sur le bouton Autoriser.",
    media_ask_auth: "Demander l'authorisation",
    media_access: "Accès à la caméra et au micro.",
    media_access_error: "Echec de l'accès à la caméra et au micro.",
    media_access_not_supported_old1: "ATTENTION: Votre navigateur ne supporte pas les communications audio et vidéo temps réel.",
    media_access_not_supported_old2: "Utilisez un navigateur récent pour accéder à ce site (exemple: Chrome, Firefox, Edge, Safari...).",
    media_access_not_supported_compat1:
      "Votre navigateur ne supporte pas les communications audio et vidéo temps réel sur cet appareil Apple.",
    media_access_not_supported_compat2: "Utilisez Safari pour accéder à ce site.",
    media_access_not_supported3: "En continuant, vous ne pourrez utiliser que le partage de documents et le chat texte.",
    name_error: "Le nom est obligatoire, merci d'entrer votre nom d'affichage",
    waiting: "Votre rendez-vous va démarrer",
    sub_waiting: "Votre hôte à été informé de votre demande d'accès.",
    no_answer: "Pas de réponse",
    let_message: "Laissez lui un message",
    let_message_btn: "Laisser un message",
    finished: "Votre rendez-vous est terminé.",
    mirror_message: "Un dernier coup d'oeil dans le miroir ?",
    drag_and_drop_1: "glisser / déposer un PDF ou une image",
    drag_and_drop_2: "ou",
    drag_and_drop_3: "cliquer et uploader",
    unavailable: "L'utilisateur est actuellement occupé et vous a laissé ce message:",
    room_full_title: "Cette réunion a déjà atteint son nombre maximum de participants",
    room_full_text1: "Nous avons informé l'organisateur que vous ne pouvez pas rejoindre sa réunion",
    room_full_text2: "Nous vous invitons à le contacter directement",
  },
  private_room: {
    invite: "Envoyez ce lien pour inviter des personnes à la conférence",
  },
  room: {
    conference: "Conférence",
    share_link: "Partager",
    modal_share_link_title: "Partager le lien",
    share_screen: "Partager l'écran",
    sharing_img_pdf: "Transfert de PDF ou Image",
    stop_share_screen: "Arrêter le partage",
    chat: "Afficher le chat",
    stop_chat: "Cacher le chat",
    swap_camera: "Changer de caméra",
    whiteboard: "Tableau blanc",
    add_participant: "Ajouter un Participant",
    waiting_for_attendees: "En attente des participants",
    media_connecting: "Connexion vidéo en cours",
    out_failed: "La connexion est impossible",
    in_failed: "La connexion est impossible",
    out_failed_connected: "Vous avez été déconnecté",
    in_failed_connected: "Vous avez été déconnecté",
    retry: "Retenter",
    take_picture: "Prendre photo",
    download: "Télécharger",
    picture: "Photo",
    medialess: "Continuer sans média",
    upgrade: "Mettre à niveau",
    permissions_denied_cam_and_mic_title: "Autorisations du Microphone et de la Caméra refusées",
    permissions_denied_mic_title: "Autorisations d'accès au Microphone refusées",
    permissions_denied_cam_title: "Autorisations d'accès à la Caméra refusées",
    permissions_denied_content_1:
      "1. Cliquez sur l'icône Informations de la page située à gauche de la barre d'adresse de votre navigateur",
    permissions_denied_content_cam_and_mic_2: "2. Activez le Microphone et la Caméra",
    permissions_denied_content_mic_2: "2. Activez le Microphone",
    permissions_denied_content_cam_2: "2. Activez la Caméra",
    permissions_denied_content_3: "3. Actualisez votre onglet",
    appointment_close_soon_title: "Limite de temps bientôt atteinte !",
    appointment_close_soon_text1:
      "Malheureusement, votre rendez-vous va automatiquement se terminer dans 5 minutes car vous ne bénéficiez que d'un compte Gratuit...",
    appointment_close_soon_text2: "Passez votre compte en Pro pour ne plus avoir de limites !",
    appointment_max_title: "Limite de participants atteinte !",
    appointment_max_text1: "Vous avez atteint la limite de 2 invités sur votre compte Gratuit.",
    appointment_max_text2: "Pour accepter jusqu'à 16 invités, vous devez passer en version Pro.",
    participant_missed_text1: "souhaite rejoindre la salle, malheureusement vous avez atteint la limite de participants",
    participant_missed_text2: "Nous l'avons informé qu'il ne pouvait pas vous rejoindre",
    participant_missed_text3: "Devenez Pro pour augmenter cette limite",
    message_forbidden: "dispose d'un compte gratuit, seuls les comptes payants peuvent recevoir des messages",
  },
  call_logs: {
    number: "Nombre d'appels:",
    customer: "Utilisateur",
    room: "Salle",
    beginning: "Début",
    end: "Fin",
    duration: "Durée",
    indicator: "Indicateur",
    browser: "OS/Navigateur",
  },
  user_list: {
    number: "Utilisateurs enregistrés:",
    email: "Email",
    name: "Nom",
    is_admin: "Admin",
    subscription_start: "Début abonnement",
    subscription_end: "Fin abonnement",
    delete: "Supprimer",
  },
  notification: {
    edit: {
      success: "Les modifications ont bien été prises en compte.",
      failed: "Une erreur est survenue, modifications non pris en compte.",
    },
    copy_link: "Lien copié dans le presse-papiers.",
    send_file_pending: "Envoi du fichier en cours...",
    feedback: "L'avis a bien été envoyé.",
    message: "Le message a bien été envoyé.",
  },
  feedback: {
    title: "Avis",
    type: {
      title: "Type",
      options: {
        select: "Sélectionnez une option",
        problem: "Problème",
        suggestion: "Suggestion",
        help: "Besoin d'aide",
        other: "Autre",
      },
    },
    message: {
      title: "Message",
      placeholder: "Écrire ici...",
    },
    button: {
      submit: "Envoyer",
      cancel: "Annuler",
    },
  },
  free_version: "version gratuite",
  Free_Version: "Version Gratuite",
  pro_version: "version pro",
};
