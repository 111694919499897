<app-loading-menu *ngIf="Loading"></app-loading-menu>
<div *ngIf="Error && !Loading" class="error-reload">
  <div class="error-reload-text">{{ "common.error" | translate }}</div>
  <button type="button" (click)="reload()" class="button is-danger">
    {{ "common.reload" | translate }}
  </button>
</div>
<ng-container *ngIf="!Loading && !Error">
  <br />
  <div class="box form-box">
    <form>
      <div class="field">
        <label class="label">{{ "add_user.name" | translate }}</label>
        <div class="control">
          <input type="text" [(ngModel)]="Params.Name" name="Name" class="input" />
        </div>
      </div>
      <div *ngIf="errorMonitor.Name" class="error-text has-text-danger">
        {{ "common.error" | translate }}
      </div>
      <br />
      <div class="field">
        <div class="control">
          <button type="submit" (click)="ChangeName()" [class.is-loading]="SubmittingName" class="button is-danger">
            {{ "account_config.save" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="box form-box">
    <form>
      <div class="field">
        <label class="label">{{ "account_config.room_name" | translate }}</label>
        <div class="control">
          <input type="text" [(ngModel)]="Params.Room" (ngModelChange)="roomUpdateKey()" name="Name" class="input room-input" />
        </div>
      </div>
      <div *ngIf="errorMonitor.Room" class="error-text has-text-danger">
        {{ "common.already_used" | translate }}
      </div>
      <div *ngIf="SmallRoom" class="error-text has-text-danger">
        {{ "common.too_small" | translate }}
      </div>
      <br />
      <div class="field">
        <div class="control">
          <button type="submit" (click)="ChangeRoom()" [class.is-loading]="SubmittingName" class="button is-danger">
            {{ "account_config.save" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="Params.SubscriptionType === 'pro'" class="box form-box">
    <form>
      <div class="field">
        <p class="label">{{ "account_config.import_company" | translate }}</p>
      </div>
      <div class="field">
        <div class="control">
          <div class="file is-danger" [class]="companyImageFileName !== undefined ? 'has-name' : ''">
            <label class="file-label">
              <input class="file-input" type="file" name="resume" (change)="onFileChangeEvent($event)" accept=".gif,.jpg,.jpeg,.png,.svg" />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">{{ "account_config.browse" | translate }}</span>
              </span>
              <span *ngIf="companyImageFileName !== undefined" class="file-name">{{ companyImageFileName }}</span>
            </label>
          </div>
          <br />
          <!-- (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()" -->
        </div>
      </div>
      <br />
      <br />
      <img class="logo" [src]="companyLogoImage" />
      <div class="field">
        <div class="control">
          <button type="submit" (click)="ChangeCompanyLogoURI()" [class.is-loading]="SubmittingCompanyLogoURI" class="button is-danger">
            {{ "account_config.save" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="box form-box">
    <form>
      <div class="field">
        <label class="label">{{ "account_config.import_profile" | translate }}</label>
      </div>
      <div class="field">
        <div class="control">
          <div class="file is-danger" [class]="imageCropperFileName !== undefined ? 'has-name' : ''">
            <label class="file-label">
              <input class="file-input" type="file" name="resume" (change)="fileChangeEvent($event)" accept=".gif,.jpg,.jpeg,.png,.svg" />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">{{ "account_config.browse" | translate }}</span>
              </span>
              <span *ngIf="imageCropperFileName !== undefined" class="file-name">{{ imageCropperFileName }}</span>
            </label>
          </div>

          <br *ngIf="imageChangedEvent !== null" />
          <image-cropper
            [hidden]="imageChangedEvent === null"
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            (imageCropped)="imageCropped($event)"
            format="png"
          ></image-cropper>
          <!-- (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()" -->
          <br *ngIf="croppedImage !== ''" />
          <img *ngIf="croppedImage !== ''" width="80" height="80" [src]="croppedImage" style="border-radius: 10px" />
          <br *ngIf="croppedImage !== ''" />
        </div>
      </div>

      <div *ngIf="errorMonitor.LogoURI" class="error-text has-text-danger">
        {{ "common.error" | translate }}
      </div>

      <br />

      <div class="field">
        <div class="control">
          <button type="submit" (click)="ChangeLogoURI()" [class.is-loading]="SubmittingLogoURI" class="button is-danger">
            {{ "account_config.save" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="Params.SubscriptionType === 'pro'" class="box form-box">
    <form>
      <div class="flex-row-container space-between">
        <div>
          <label class="label">{{ "account_config.background_color" | translate }}</label>
          <div class="control">
            <input
              [value]="Params.BackgroundColor"
              [(colorPicker)]="Params.BackgroundColor"
              [style.background]="Params.BackgroundColor"
              [style.color]="getContrastingTextColor(Params.BackgroundColor)"
              (colorPickerChange)="Params.BackgroundColor = $event; RefreshCanvas()"
              class="input"
            />
            <!-- (keyup)="checkUserTyping()" -->
          </div>
        </div>
        <div *ngIf="false">
          <label class="label">Text Color</label>
          <div class="control">
            <input
              [value]="Params.TextColor"
              [(colorPicker)]="Params.TextColor"
              [style.background]="Params.TextColor"
              [style.color]="getContrastingTextColor(Params.TextColor)"
              (colorPickerChange)="Params.TextColor = $event; RefreshCanvas()"
              class="input"
            />
            <!-- (keyup)="checkUserTyping()" -->
          </div>
        </div>
        <div></div>
        <div></div>
      </div>
      <br />
      <canvas #canvasPreview width="180" height="100"></canvas>
      <br />
      <br />
      <div class="control">
        <button type="submit" (click)="ChangeColors()" [class.is-loading]="SubmittingLogoURI" class="button is-danger">
          {{ "account_config.save" | translate }}
        </button>
      </div>
    </form>
  </div>

  <app-notification #notificationComponent></app-notification>
</ng-container>
