<div #messageContainer class="main-container" [style.background-color]="Limitations?.TeamPanelHidden ? '#B9B9C1' : ''">
  <div *ngIf="Limitations?.TeamPanelHidden === false">
    <div class="contact-card-row" *ngFor="let Contact of Contacts | collaborator : SearchField">
      <div class="contact-info" style="overflow: hidden">
        <ng-container [ngSwitch]="Contact.Type">
          <div *ngSwitchCase="'User'" style="display: contents">
            <img
              *ngIf="Contact.data.LogoURI != undefined && Contact.data.LogoURI != ''"
              height="80"
              width="80"
              src="{{ Contact.data.LogoURI }}"
              class="profile-custom"
            />
            <img
              *ngIf="Contact.data.LogoURI == undefined || Contact.data.LogoURI == ''"
              height="80"
              width="80"
              src="/assets/icons/profile-default.svg"
              class="profile-custom"
            />
          </div>

          <div *ngSwitchCase="'UserContacts'" style="display: contents">
            <img class="profile-custom" src="/assets/icons/profile-default.svg" width="80" height="80" />
          </div>
        </ng-container>

        <app-vizu-presence [bMessageView]="false" [Contact]="Contact" [DomainData]="DomainData"></app-vizu-presence>

        <b class="classic-text short-name">{{ Contact.DisplayName }}</b>
      </div>

      <svg
        width="40"
        height="40"
        class="pointer"
        (click)="sendClickInfoMessage('EnterCollaboratorRoom'); EnterOtherRoom(Contact.data.Room)"
      >
        <image xlink:href="/assets/icons/phone-call.svg" width="40" height="40" />
      </svg>

      <svg [matMenuTriggerFor]="menuContact" width="40" height="40" class="pointer">
        <image xlink:href="/assets/icons/menu-bar.svg" width="40" height="40" />
      </svg>

      <mat-menu #menuContact="matMenu">
        <button mat-menu-item (click)="displayMessages(Contact.To, Contact.DisplayName)">
          <span class="icon" style="align-items: unset">
            <svg width="35" height="35"><image xlink:href="/assets/icons/email.svg" width="35" height="35" /></svg>
          </span>
          <span style="padding-left: 10px">Messagerie</span>
        </button>
        <button mat-menu-item (click)="CopyLink(Contact.data.Room)" id="{{ Contact.data.Room }}">
          <span class="icon" style="align-items: unset">
            <svg width="30" height="30"><image xlink:href="/assets/icons/copy.svg" width="30" height="30" /></svg>
          </span>
          <span style="padding-left: 10px">Lien</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <!-- subscription type free ou pro -->
  <div *ngIf="Customer.SubscriptionType !== 'enterprise' && Limitations?.TeamPanelHidden" class="team-limitation-text">
    <div class="subtitle has-text-centered is-6">
      {{ "shell.room.team_panel_hidden1" | translate }}
    </div>
    <div class="subtitle has-text-centered is-6">
      {{ "shell.room.team_panel_hidden2" | translate }}
    </div>
    <br />
    <input
      type="button"
      class="button is-black is-outlined"
      value="{{ 'room.upgrade' | translate }}"
      (click)="setUpgradeModal(true)"
      style="margin: 0px 60px; width: 200px; align-self: center"
    />
  </div>

  <!-- entreprise cloisonnée -->
  <div *ngIf="Customer.SubscriptionType === 'enterprise' && Limitations?.TeamPanelHidden"></div>
</div>
